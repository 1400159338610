"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var FETCHING_CONFIG = 'FETCHING_CONFIG';
var FETCHING_SUCCESS_CONFIG = 'FETCHING_SUCCESS_CONFIG';
var _default = {
  FETCHING_CONFIG: FETCHING_CONFIG,
  FETCHING_SUCCESS_CONFIG: FETCHING_SUCCESS_CONFIG
};
exports.default = _default;