"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _counters = _interopRequireDefault(require("./counters"));

var _accommodation = require("../../Constants/accommodation");

var _types = require("../Ducks/AccommodationSearch/types");

var _getCountersMiddlewar = (0, _counters.default)({
  namespace: _types.namespace,
  stateKey: _accommodation.STATE_KEY,
  counterRules: _accommodation.GUESTS_COUNTER_RULES
}),
    validateGuests = _getCountersMiddlewar.validateCounters;

var _default = {
  validateGuests: validateGuests
};
exports.default = _default;