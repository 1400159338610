// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".travelAlerts__backToTop--N5dmf{color:#ff5115;font-size:.75rem;font-weight:700}.travelAlerts__backToTop--N5dmf:hover{color:#db3a00}.travelAlerts__footerText--KiO53{text-align:center}@media only screen and (min-width:48em){.travelAlerts__footerText--KiO53{text-align:inherit}}.travelAlerts__ctaButton--Mq4i7{text-align:center}@media only screen and (min-width:48em){.travelAlerts__ctaButton--Mq4i7{text-align:inherit}}img{height:auto;max-width:100%}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/TravelAlerts/travelAlerts.scss"],"names":[],"mappings":"AAAA,gCAAW,aAAA,CAAc,gBAAA,CAAiB,eAAe,CAAC,sCAAiB,aAAa,CAAC,iCAAY,iBAAiB,CAAC,wCAAwC,iCAAY,kBAAkB,CAAC,CAAC,gCAAW,iBAAiB,CAAC,wCAAwC,gCAAW,kBAAkB,CAAC,CAAC,IAAI,WAAA,CAAY,cAAc","sourcesContent":[".backToTop{color:#ff5115;font-size:.75rem;font-weight:700}.backToTop:hover{color:#db3a00}.footerText{text-align:center}@media only screen and (min-width:48em){.footerText{text-align:inherit}}.ctaButton{text-align:center}@media only screen and (min-width:48em){.ctaButton{text-align:inherit}}img{height:auto;max-width:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backToTop": "travelAlerts__backToTop--N5dmf",
	"footerText": "travelAlerts__footerText--KiO53",
	"ctaButton": "travelAlerts__ctaButton--Mq4i7"
};
export default ___CSS_LOADER_EXPORT___;
