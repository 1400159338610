"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.darken = darken;
exports.lighten = lighten;

var _kewler = require("kewler");

/* eslint-disable */

/**
 * darken a color
 * @function darken
 * @memberof Utils/color
 * @returns {string}
 */
function darken(c, amount) {
  return (0, _kewler.color)(c)((0, _kewler.lightness)(-amount))();
}
/**
 * lighten a color
 * @function lighten
 * @memberof Utils/color
 * @returns {string}
 */


function lighten(c, amount) {
  return (0, _kewler.color)(c)((0, _kewler.lightness)(amount))();
}