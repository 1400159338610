"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRIP_TYPES = exports.TRAVELLERS_COUNTER_RULES = exports.STATE_KEY = exports.QUERY_KEY = exports.NUMBER_CALENDAR_WEEKS_TO_DISPLAY = exports.NAMESPACE = exports.MPS_PREPOPULATION_ELEMENT_ID = exports.MONTHS_CALENDAR_JUMP = exports.HISTORY_EXCEPTION_LIST = exports.FUZZY_SEARCH_COUNTRY_OVERRIDES = exports.FLIGHTSEARCH_NAMESPACE = exports.FARE_CACHE_QUERY_KEY = exports.FARE_CACHE_DISABLED_MESSAGE = exports.ERROR_CODES = exports.DEFAULT_ADULTS_COUNT = exports.DAY_PICKER_MODE = exports.DATA_KEY = exports.BOOKING_ENGINE_QUERY_KEY = exports.ANALYTICS_RECENT_SEARCH = exports.ANALYTICS_EVENT_TYPES = exports.ANALYTICS_CHANGE_TYPES = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _counters = require("./counters");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STATE_KEY = 'flightSearch';
exports.STATE_KEY = STATE_KEY;
var CLUB_JETSTAR = 'clubjetstar';
var CURRENCY_NAME = 'Currency';
var DEPARTURE_DATE = 'selected-departure-date';
var FOCUSED_DATE = 'focused-date';
var IS_CALENDAR_OPEN = 'is-calendar-open';
var DESTINATION = 'destination';
var FLEXIBLE = 'flexible';
var FLIGHT_TYPE = 'flight-type';
var INBOUND_ARRIVAL_TIME = 'dotcomFCReturnArrivalTime';
var INBOUND_CORRELATION_ID = 'dotcomFCReturnCorrelationId';
var INBOUND_INCLUDE_MEMBER = 'dotcomFCReturnIncludeMember';
var INBOUND_DATE = 'departuredate2';
var INBOUND_DEPARTURE_TIME = 'dotcomFCReturnDepartureTime';
var INBOUND_DESTINATION = 'destination2';
var INBOUND_FLIGHT_ID = 'dotcomFCReturnFlightId';
var INBOUND_LOWEST_FARE = 'dotcomFCReturnLowestFare';
var INBOUND_PORT = 'origin2';
var INBOUND_PRICE_SHOWN = 'dotcomFCReturnPriceShown';
var ORIGIN = 'origin';
var OUTBOUND_ARRIVAL_TIME = 'dotcomFCOutboundArrivalTime';
var OUTBOUND_CORRELATION_ID = 'dotcomFCOutboundCorrelationId';
var OUTBOUND_INCLUDE_MEMBER = 'dotcomFCOutboundIncludeMember';
var OUTBOUND_DATE = 'departuredate1';
var OUTBOUND_DEPARTURE_TIME = 'dotcomFCOutboundDepartureTime';
var OUTBOUND_DESTINATION = 'destination1';
var OUTBOUND_FARE = 'dotcomFCOutboundFare';
var OUTBOUND_FLIGHT_ID = 'dotcomFCOutboundFlightId';
var OUTBOUND_PORT = 'origin1';
var OUTBOUND_PRICE_SHOWN = 'dotcomFCOutboundPriceShown';
var PRICES_HIDDEN = 'dotcomFCPricesHidden';
var RETURN_DATE = 'selected-return-date';
var OUTBOUND_MEMBER_PRICE_SHOWN = 'dotcomFCOutboundMemberPriceShown';
var OUTBOUND_MEMBER_FARE = 'dotcomFCOutboundMemberFare';
var OUTBOUND_MEMBER_CORRELATION_ID = 'dotcomFCOutboundMemberCorrelationId';
var OUTBOUND_MEMBER_INCLUDE_MEMBER = 'dotcomFCOutboundMemberIncludeMember';
var OUTBOUND_MEMBER_FLIGHT_ID = 'dotcomFCOutboundMemberFlightId';
var OUTBOUND_MEMBER_DEPARTURE_TIME = 'dotcomFCOutboundMemberDepartureTime';
var OUTBOUND_MEMBER_ARRIVAL_TIME = 'dotcomFCOutboundMemberArrivalTime';
var INBOUND_MEMBER_PRICE_SHOWN = 'dotcomFCReturnMemberPriceShown';
var INBOUND_MEMBER_FARE = 'dotcomFCReturnMemberFare';
var INBOUND_MEMBER_CORRELATION_ID = 'dotcomFCReturnMemberCorrelationId';
var INBOUND_MEMBER_INCLUDE_MEMBER = 'dotcomFCReturnMemberIncludeMember';
var INBOUND_MEMBER_FLIGHT_ID = 'dotcomFCReturnMemberFlightId';
var INBOUND_MEMBER_DEPARTURE_TIME = 'dotcomFCReturnMemberDepartureTime';
var INBOUND_MEMBER_ARRIVAL_TIME = 'dotcomFCReturnMemberArrivalTime';
var MPS_PREPOPULATION_ELEMENT_ID = 'mps-prepopulation';
exports.MPS_PREPOPULATION_ELEMENT_ID = MPS_PREPOPULATION_ELEMENT_ID;
var MONTHS_CALENDAR_JUMP = 7;
exports.MONTHS_CALENDAR_JUMP = MONTHS_CALENDAR_JUMP;
var NUMBER_CALENDAR_WEEKS_TO_DISPLAY = 6;
exports.NUMBER_CALENDAR_WEEKS_TO_DISPLAY = NUMBER_CALENDAR_WEEKS_TO_DISPLAY;
var DEFAULT_ADULTS_COUNT = 1;
exports.DEFAULT_ADULTS_COUNT = DEFAULT_ADULTS_COUNT;
var TRAVELLERS_COUNTER_RULES = {
  MIN_TOTAL: 1,
  TOTAL: 50,
  ADULTS_CHILDREN_INFANT_RATIO: 4,
  ADULTS_INFANTS_RATIO: 1
};
exports.TRAVELLERS_COUNTER_RULES = TRAVELLERS_COUNTER_RULES;

var QUERY_KEY = _objectSpread(_objectSpread({
  ORIGIN: ORIGIN,
  DESTINATION: DESTINATION,
  FLIGHT_TYPE: FLIGHT_TYPE
}, _counters.COUNT_QUERY_KEY), {}, {
  FLEXIBLE: FLEXIBLE,
  DEPARTURE_DATE: DEPARTURE_DATE,
  RETURN_DATE: RETURN_DATE,
  MONTHS_CALENDAR_JUMP: MONTHS_CALENDAR_JUMP,
  CLUB_JETSTAR: CLUB_JETSTAR,
  FOCUSED_DATE: FOCUSED_DATE,
  IS_CALENDAR_OPEN: IS_CALENDAR_OPEN
}); // Pricing Service


exports.QUERY_KEY = QUERY_KEY;
var SUBQUERIES_NUMBER = 'n';
var ARRIVALS = 'arrivals';
var CULTURE_INFO = 'cultureInfo';
var DEPARTURES = 'departures';
var CURRENCY_CODE = 'currencyCode';
var DIRECTION = 'direction';
var END = 'end';
var FLIGHT_COUNT = 'flightCount';
var FROM = 'from';
var INCLUDE_FEES = 'includeFees';
var INCLUDE_MEMBER = 'includeMember';
var PAX_COUNT = 'paxCount';
var FARE_CACHE_DISABLED_MESSAGE = 'Fare Cache is disabled.';
exports.FARE_CACHE_DISABLED_MESSAGE = FARE_CACHE_DISABLED_MESSAGE;
var FARE_CACHE_QUERY_KEY = {
  SUBQUERIES_NUMBER: SUBQUERIES_NUMBER,
  ARRIVALS: ARRIVALS,
  CULTURE_INFO: CULTURE_INFO,
  DEPARTURES: DEPARTURES,
  CURRENCY_CODE: CURRENCY_CODE,
  DIRECTION: DIRECTION,
  END: END,
  FLIGHT_COUNT: FLIGHT_COUNT,
  FROM: FROM,
  INCLUDE_FEES: INCLUDE_FEES,
  INCLUDE_MEMBER: INCLUDE_MEMBER,
  PAX_COUNT: PAX_COUNT
};
exports.FARE_CACHE_QUERY_KEY = FARE_CACHE_QUERY_KEY;
var HISTORY_EXCEPTION_LIST = {
  'flight-type': '3'
}; // Booking Engine

exports.HISTORY_EXCEPTION_LIST = HISTORY_EXCEPTION_LIST;
var BOOKING_ENGINE_QUERY_KEY = {
  ADULTS: _counters.ADULTS,
  CHILDREN: _counters.CHILDREN,
  CURRENCY_NAME: CURRENCY_NAME,
  INBOUND_ARRIVAL_TIME: INBOUND_ARRIVAL_TIME,
  INBOUND_CORRELATION_ID: INBOUND_CORRELATION_ID,
  INBOUND_INCLUDE_MEMBER: INBOUND_INCLUDE_MEMBER,
  INBOUND_DATE: INBOUND_DATE,
  INBOUND_DEPARTURE_TIME: INBOUND_DEPARTURE_TIME,
  INBOUND_DESTINATION: INBOUND_DESTINATION,
  INBOUND_FLIGHT_ID: INBOUND_FLIGHT_ID,
  INBOUND_LOWEST_FARE: INBOUND_LOWEST_FARE,
  INBOUND_PORT: INBOUND_PORT,
  INBOUND_PRICE_SHOWN: INBOUND_PRICE_SHOWN,
  INFANTS: _counters.INFANTS,
  OUTBOUND_ARRIVAL_TIME: OUTBOUND_ARRIVAL_TIME,
  OUTBOUND_CORRELATION_ID: OUTBOUND_CORRELATION_ID,
  OUTBOUND_INCLUDE_MEMBER: OUTBOUND_INCLUDE_MEMBER,
  OUTBOUND_DATE: OUTBOUND_DATE,
  OUTBOUND_DEPARTURE_TIME: OUTBOUND_DEPARTURE_TIME,
  OUTBOUND_DESTINATION: OUTBOUND_DESTINATION,
  OUTBOUND_FARE: OUTBOUND_FARE,
  OUTBOUND_FLIGHT_ID: OUTBOUND_FLIGHT_ID,
  OUTBOUND_PORT: OUTBOUND_PORT,
  OUTBOUND_PRICE_SHOWN: OUTBOUND_PRICE_SHOWN,
  PRICES_HIDDEN: PRICES_HIDDEN,
  OUTBOUND_MEMBER_PRICE_SHOWN: OUTBOUND_MEMBER_PRICE_SHOWN,
  OUTBOUND_MEMBER_FARE: OUTBOUND_MEMBER_FARE,
  OUTBOUND_MEMBER_CORRELATION_ID: OUTBOUND_MEMBER_CORRELATION_ID,
  OUTBOUND_MEMBER_INCLUDE_MEMBER: OUTBOUND_MEMBER_INCLUDE_MEMBER,
  OUTBOUND_MEMBER_FLIGHT_ID: OUTBOUND_MEMBER_FLIGHT_ID,
  OUTBOUND_MEMBER_DEPARTURE_TIME: OUTBOUND_MEMBER_DEPARTURE_TIME,
  OUTBOUND_MEMBER_ARRIVAL_TIME: OUTBOUND_MEMBER_ARRIVAL_TIME,
  INBOUND_MEMBER_PRICE_SHOWN: INBOUND_MEMBER_PRICE_SHOWN,
  INBOUND_MEMBER_FARE: INBOUND_MEMBER_FARE,
  INBOUND_MEMBER_CORRELATION_ID: INBOUND_MEMBER_CORRELATION_ID,
  INBOUND_MEMBER_INCLUDE_MEMBER: INBOUND_MEMBER_INCLUDE_MEMBER,
  INBOUND_MEMBER_FLIGHT_ID: INBOUND_MEMBER_FLIGHT_ID,
  INBOUND_MEMBER_DEPARTURE_TIME: INBOUND_MEMBER_DEPARTURE_TIME,
  INBOUND_MEMBER_ARRIVAL_TIME: INBOUND_MEMBER_ARRIVAL_TIME
}; // Data attribute keys

exports.BOOKING_ENGINE_QUERY_KEY = BOOKING_ENGINE_QUERY_KEY;
var DATA_DESTINATION = 'data-destination';
var DATA_ORIGIN = 'data-origin';
var DATA_KEY = {
  DATA_DESTINATION: DATA_DESTINATION,
  DATA_ORIGIN: DATA_ORIGIN
}; // Analytics

exports.DATA_KEY = DATA_KEY;
var NAMESPACE = 'Jetstar';
exports.NAMESPACE = NAMESPACE;
var FLIGHTSEARCH_NAMESPACE = 'flightsearch';
exports.FLIGHTSEARCH_NAMESPACE = FLIGHTSEARCH_NAMESPACE;
var DESTINATION_TYPE = 'destination';
var ORIGIN_TYPE = 'origin';
var ROUTING_TYPE = 'routingType';
var PAX_COUNTER = 'paxCounter';
var ACCURACY_PERCENT = 'accuracyPercent';
var DEPARTURE_CALENDER = 'departureCalendar';
var RETURN_CALENDER = 'returnCalendar'; // TODO - check with analytics team if this should be a more
// universal name and probably live in Constants/tracking.js

var TAB_CHANGE = 'flight-search-tab-click';
var ANALYTICS_EVENT_TYPES = {
  TAB_CHANGE: TAB_CHANGE
};
exports.ANALYTICS_EVENT_TYPES = ANALYTICS_EVENT_TYPES;
var ANALYTICS_CHANGE_TYPES = {
  DESTINATION_TYPE: DESTINATION_TYPE,
  ORIGIN_TYPE: ORIGIN_TYPE,
  ROUTING_TYPE: ROUTING_TYPE,
  PAX_COUNTER: PAX_COUNTER,
  ACCURACY_PERCENT: ACCURACY_PERCENT,
  DEPARTURE_CALENDER: DEPARTURE_CALENDER,
  RETURN_CALENDER: RETURN_CALENDER
};
exports.ANALYTICS_CHANGE_TYPES = ANALYTICS_CHANGE_TYPES;
var ANALYTICS_RECENT_SEARCH = 'flight-recent-search'; // Error codes

exports.ANALYTICS_RECENT_SEARCH = ANALYTICS_RECENT_SEARCH;
var SUCCESS = 0;
var DATES_WARNING = 10000;
var PARTIAL_DATES_WARNING = 10010;
var ERROR_CODES = {
  SUCCESS: SUCCESS,
  DATES_WARNING: DATES_WARNING,
  PARTIAL_DATES_WARNING: PARTIAL_DATES_WARNING
}; // Fuzzy Search Overrides
// TODO: JR-11759 - Remove constants and replace with sitecore values

exports.ERROR_CODES = ERROR_CODES;
var CHINA = 'China';
var USA = 'USA';
var FUZZY_SEARCH_COUNTRY_OVERRIDES = {
  CAN: CHINA,
  CGO: CHINA,
  CSX: CHINA,
  HAK: CHINA,
  HFE: CHINA,
  HGH: CHINA,
  HKG: "Hong Kong SAR, ".concat(CHINA),
  KHH: CHINA,
  KWE: CHINA,
  MFM: "Macau SAR, ".concat(CHINA),
  NGB: CHINA,
  PVG: CHINA,
  SWA: CHINA,
  SYX: CHINA,
  TPE: "Taiwan, ".concat(CHINA),
  WUH: CHINA,
  XUZ: CHINA,
  HNL: "Hawaii, ".concat(USA)
}; // eslint-disable-next-line prettier/prettier

exports.FUZZY_SEARCH_COUNTRY_OVERRIDES = FUZZY_SEARCH_COUNTRY_OVERRIDES;
var TRIP_TYPES =
/** @type {const} **/
{
  RETURN: 'Return',
  ONE_WAY: 'Oneway' // eslint-disable-next-line prettier/prettier

};
/**
 * @typedef {TRIP_TYPES[keyof TRIP_TYPES]} TripType
 */

exports.TRIP_TYPES = TRIP_TYPES;
var DAY_PICKER_MODE =
/** @type {const} */
{
  RANGE: 'range',
  SINGLE: 'single'
};
exports.DAY_PICKER_MODE = DAY_PICKER_MODE;