"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.namespace = exports.default = void 0;
var namespace = 'HOLIDAY_SEARCH'; // Dates

exports.namespace = namespace;
var FETCHING_DATES_ERROR_HOLIDAY_SEARCH = 'FETCHING_DATES_ERROR_HOLIDAY_SEARCH';
var FETCHING_DATES_HOLIDAY_SEARCH = 'FETCHING_DATES_HOLIDAY_SEARCH';
var FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH = 'FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH';
var RESET_DATES_HOLIDAY_SEARCH = 'RESET_DATES_HOLIDAY_SEARCH';
var SET_DATES_HOLIDAY_SEARCH = 'SET_DATES_HOLIDAY_SEARCH'; // Location

var RESET_LOCATION_HOLIDAY_SEARCH = 'RESET_LOCATION_HOLIDAY_SEARCH';
var SET_LOCATION_HOLIDAY_SEARCH = 'SET_LOCATION_HOLIDAY_SEARCH';
var SET_VALID_LOCATION_HOLIDAY_SEARCH = 'SET_VALID_LOCATION_HOLIDAY_SEARCH';
var ON_ADD_ROOM = 'ON_ADD_ROOM';
var ON_REMOVE_ROOM = 'ON_REMOVE_ROOM';
var ON_SET_ROOMS = 'ON_SET_ROOM'; //custom counter types

var ON_INCREMENT_COUNTER = 'ON_INCREMENT_COUNTER';
var ON_DECREMENT_COUNTER = 'ON_DECREMENT_COUNTER';
var ONCHANGE_COUNTER = 'ONCHANGE_COUNTER';
var ON_INIT_COUNTER = 'ON_INIT_COUNTER';
var SET_PAX = 'SET_PAX';
var _default = {
  // Dates
  FETCHING_DATES_ERROR_HOLIDAY_SEARCH: FETCHING_DATES_ERROR_HOLIDAY_SEARCH,
  FETCHING_DATES_HOLIDAY_SEARCH: FETCHING_DATES_HOLIDAY_SEARCH,
  FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH: FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH,
  RESET_DATES_HOLIDAY_SEARCH: RESET_DATES_HOLIDAY_SEARCH,
  SET_DATES_HOLIDAY_SEARCH: SET_DATES_HOLIDAY_SEARCH,
  // Location
  RESET_LOCATION_HOLIDAY_SEARCH: RESET_LOCATION_HOLIDAY_SEARCH,
  SET_LOCATION_HOLIDAY_SEARCH: SET_LOCATION_HOLIDAY_SEARCH,
  SET_VALID_LOCATION_HOLIDAY_SEARCH: SET_VALID_LOCATION_HOLIDAY_SEARCH,
  //Rooms
  ON_ADD_ROOM: ON_ADD_ROOM,
  ON_REMOVE_ROOM: ON_REMOVE_ROOM,
  ON_SET_ROOMS: ON_SET_ROOMS,
  //customer counter
  ON_INCREMENT_COUNTER: ON_INCREMENT_COUNTER,
  ON_DECREMENT_COUNTER: ON_DECREMENT_COUNTER,
  ONCHANGE_COUNTER: ONCHANGE_COUNTER,
  ON_INIT_COUNTER: ON_INIT_COUNTER,
  SET_PAX: SET_PAX
};
exports.default = _default;