"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.measurePerformance = exports.createPerformanceMarker = void 0;

/**
 * Window Performance helpers
 * @namespace Utils/performance
 */
var measurePerformanceAvailable = !!(window.performance && window.performance.mark && window.performance.clearMarks && window.performance.measure);
/**
 * Create a performance marker
 * @function createPerformanceMarker
 * @memberof Utils/performance
 * @param {string} markerName - name used to identify the marker created
 * @return {void}
 */

var createPerformanceMarker = function createPerformanceMarker(marker) {
  if (measurePerformanceAvailable && marker) {
    window.performance.clearMarks(marker);
    window.performance.mark(marker);
  }
};
/**
 * Create a measure (timestamp) between two markers
 * @function measurePerformance
 * @memberof Utils/performance
 * @param {string} measureName - name used to identify the measure
 * @param {string} markerStart - name used to identify the start marker
 * @param {string} markerEnd - name used to identify the end marker
 * @return {void}
 */


exports.createPerformanceMarker = createPerformanceMarker;

var measurePerformance = function measurePerformance(measureKey, markerStart, markerEnd) {
  measurePerformanceAvailable && window.performance.measure(measureKey, markerStart, markerEnd);
};

exports.measurePerformance = measurePerformance;