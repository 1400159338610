"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ICON_NAMES = exports.ICON_CATEGORIES = void 0;
// types
var EXPANDMORE = 'expand-more';
var EXPANDLESS = 'expand-less';
var BACKWARDARROW = 'arrow-backward';
var FORWARDARROW = 'arrow-forward';
var ICONAIRPORT = 'IconAirport';
var ICONINTERFACE = 'IconInterface';
var ICONPRODUCT = 'IconProduct';
var ICONUNIVERSAL = 'IconUniversal';
var ICON_NAMES = {
  BACKWARDARROW: BACKWARDARROW,
  EXPANDLESS: EXPANDLESS,
  EXPANDMORE: EXPANDMORE,
  FORWARDARROW: FORWARDARROW
};
exports.ICON_NAMES = ICON_NAMES;
var ICON_CATEGORIES = {
  ICONAIRPORT: ICONAIRPORT,
  ICONINTERFACE: ICONINTERFACE,
  ICONPRODUCT: ICONPRODUCT,
  ICONUNIVERSAL: ICONUNIVERSAL
};
exports.ICON_CATEGORIES = ICON_CATEGORIES;