"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.breakpoint = void 0;
var XSMALL = 767;
var SMALL = 768;
var MEDIUM = 960;
var LARGE = 1290;
var XLARGE = 1590;
var breakpoint = {
  XSMALL: XSMALL,
  SMALL: SMALL,
  MEDIUM: MEDIUM,
  LARGE: LARGE,
  XLARGE: XLARGE
};
exports.breakpoint = breakpoint;