// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._calendar-heading__calendarHeading--AWTPH{color:#707070;display:-webkit-box;display:-webkit-flex;display:flex;font-size:.75rem;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;letter-spacing:.0125rem;line-height:.875rem;text-align:center}._calendar-heading__calendarMonth--9Ksmx{width:14.2857142857%;padding:1.25rem 0}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Calendar/_calendar-heading.scss"],"names":[],"mappings":"AAAA,2CAAiB,aAAA,CAAc,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,gBAAA,CAAiB,wBAAA,CAAA,qCAAA,CAAA,6BAAA,CAA8B,uBAAA,CAAwB,mBAAA,CAAoB,iBAAiB,CAAC,yCAAe,oBAAA,CAAqB,iBAAiB","sourcesContent":[".calendarHeading{color:#707070;display:flex;font-size:.75rem;justify-content:space-between;letter-spacing:.0125rem;line-height:.875rem;text-align:center}.calendarMonth{width:14.2857142857%;padding:1.25rem 0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarHeading": "_calendar-heading__calendarHeading--AWTPH",
	"calendarMonth": "_calendar-heading__calendarMonth--9Ksmx"
};
export default ___CSS_LOADER_EXPORT___;
