"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Image", {
  enumerable: true,
  get: function get() {
    return _Image.default;
  }
});
Object.defineProperty(exports, "ResponsiveImage", {
  enumerable: true,
  get: function get() {
    return _ResponsiveImage.default;
  }
});

var _Image = _interopRequireDefault(require("./Image"));

var _ResponsiveImage = _interopRequireDefault(require("./ResponsiveImage"));