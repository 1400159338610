"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VARIATIONS = exports.LEVELS = void 0;
var VANILLA = 'Vanilla';
var INTERFACE = 'Interface';
var WHITE = 'white';
var VARIATIONS = {
  VANILLA: VANILLA,
  INTERFACE: INTERFACE,
  WHITE: WHITE
};
exports.VARIATIONS = VARIATIONS;
var LEVELS = [1, 2, 3, 4, 5, 6];
exports.LEVELS = LEVELS;