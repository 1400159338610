"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var ON_INCREMENT_COUNTER = function ON_INCREMENT_COUNTER(namespace) {
  return "ON_INCREMENT_COUNTER_".concat(namespace);
};

var ON_DECREMENT_COUNTER = function ON_DECREMENT_COUNTER(namespace) {
  return "ON_DECREMENT_COUNTER_".concat(namespace);
};

var ONCHANGE_COUNTER = function ONCHANGE_COUNTER(namespace) {
  return "ONCHANGE_COUNTER_".concat(namespace);
};

var ON_INIT_COUNTER = function ON_INIT_COUNTER(namespace) {
  return "ON_INIT_COUNTER_".concat(namespace);
};

var SET_PAX = function SET_PAX(namespace) {
  return "SET_PAX_".concat(namespace);
};

var _default = {
  ON_INCREMENT_COUNTER: ON_INCREMENT_COUNTER,
  ON_DECREMENT_COUNTER: ON_DECREMENT_COUNTER,
  ONCHANGE_COUNTER: ONCHANGE_COUNTER,
  ON_INIT_COUNTER: ON_INIT_COUNTER,
  SET_PAX: SET_PAX
};
exports.default = _default;