"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _actions = require("./actions");

var _handleActions;

var initialState = (0, _immutable.Map)({
  isFetching: false,
  data: (0, _immutable.Map)({})
});
exports.initialState = initialState;

var _default = (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, _actions.fetchingUserLocation, function (state, action) {
  return state.merge(action.payload);
}), (0, _defineProperty2.default)(_handleActions, _actions.fetchingUserLocationSuccess, function (state, action) {
  return state.merge(action.payload);
}), (0, _defineProperty2.default)(_handleActions, _actions.fetchingUserLocationError, function (state, action) {
  return state.merge(action.payload);
}), _handleActions), initialState);

exports.default = _default;