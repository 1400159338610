"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_QS_TAB = exports.AUTOMATION_ATTRIBUTE = void 0;
var DEFAULT_QS_TAB = 'tab';
/*
NOTE: future state need to be target multiple tabs on same page by identifier
option to use square bracket notation as qs lib can parse
ie. ?tab[x]=1&tab[y]=2
...parses to:
tab:
     x: "1"
     y: "2"
*/

exports.DEFAULT_QS_TAB = DEFAULT_QS_TAB;
var TAB_CONTAINER = 'tabContainer';
var AUTOMATION_ATTRIBUTE = {
  TAB_CONTAINER: TAB_CONTAINER
};
exports.AUTOMATION_ATTRIBUTE = AUTOMATION_ATTRIBUTE;