"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TIMEOUT_DURATION = exports.AUTOMATION_ATTRIBUTE = exports.API_URL = exports.ANCHOR_ID = void 0;
var API_URL = '/{0}-{1}/apiservices/travelalerts';
exports.API_URL = API_URL;
var AUTOMATION_ATTRIBUTE = 'travelAlerts';
exports.AUTOMATION_ATTRIBUTE = AUTOMATION_ATTRIBUTE;
var TIMEOUT_DURATION = 1000;
exports.TIMEOUT_DURATION = TIMEOUT_DURATION;
var ANCHOR_ID = 'travel-alerts-top';
exports.ANCHOR_ID = ANCHOR_ID;
var _default = {
  ANCHOR_ID: ANCHOR_ID
};
exports.default = _default;