"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createSetPax = exports.createCounterInit = exports.createCounterIncrement = exports.createCounterDecrement = exports.createCounterChange = void 0;

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

// constants
var createCounterChange = function createCounterChange(namespace) {
  return (0, _reduxActions.createAction)(_types.default.ONCHANGE_COUNTER(namespace), function (id, count) {
    return {
      id: id,
      count: count
    };
  });
};

exports.createCounterChange = createCounterChange;

var createCounterDecrement = function createCounterDecrement(namespace) {
  return (0, _reduxActions.createAction)(_types.default.ON_DECREMENT_COUNTER(namespace), function (id) {
    return {
      id: id
    };
  });
};

exports.createCounterDecrement = createCounterDecrement;

var createCounterIncrement = function createCounterIncrement(namespace) {
  return (0, _reduxActions.createAction)(_types.default.ON_INCREMENT_COUNTER(namespace), function (id) {
    return {
      id: id
    };
  });
};

exports.createCounterIncrement = createCounterIncrement;

var createCounterInit = function createCounterInit(namespace) {
  return (0, _reduxActions.createAction)(_types.default.ON_INIT_COUNTER(namespace), function (data) {
    return {
      data: data
    };
  });
};

exports.createCounterInit = createCounterInit;

var createSetPax = function createSetPax(namespace) {
  return (0, _reduxActions.createAction)(_types.default.SET_PAX(namespace), function (data) {
    return {
      data: data
    };
  });
};

exports.createSetPax = createSetPax;

var _default = function _default(namespace) {
  return {
    onCounterChange: createCounterChange(namespace),
    onCounterDecrement: createCounterDecrement(namespace),
    onCounterIncrement: createCounterIncrement(namespace),
    onCounterInit: createCounterInit(namespace),
    setPax: createSetPax(namespace)
  };
};

exports.default = _default;