"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.row = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _emotion = require("emotion");

var _styleConst = _interopRequireDefault(require("../styleConst"));

var _templateObject;

var row = (0, _emotion.css)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n    padding-bottom: 25px;\n    label {\n        font-size: 14px;\n        font-weight: bold;\n    }\n    .error {\n        color: ", ";\n    }\n"])), _styleConst.default.colors.error);
exports.row = row;