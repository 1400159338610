// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../node_modules/sass-loader/dist/cjs.js??ref--5-3!./table.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._table-cell-header__cell--oEDr8{color:#222;font-weight:700;line-height:1.25;padding:1.125rem .5rem;text-align:center;vertical-align:top}@media only screen and (min-width:48em){._table-cell-header__cell--oEDr8{padding:1.125rem}}._table-cell-header__imageWrapper--H\\+fIW{display:-webkit-box;display:-webkit-flex;display:flex;height:6.25rem;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;margin-bottom:1.125rem}@media screen and (min-width:80.625em){._table-cell-header__imageWrapper--H\\+fIW{margin-bottom:1.5rem}}._table-cell-header__image--vgT8X{height:100%;max-width:100%;width:auto}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Table/_table-cell-header.scss"],"names":[],"mappings":"AAAA,iCAAwC,UAAA,CAAW,eAAA,CAAiB,gBAAA,CAAiB,sBAAA,CAAuB,iBAAA,CAAkB,kBAAkB,CAAC,wCAAwC,iCAAM,gBAAgB,CAAC,CAAC,0CAAc,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,cAAA,CAAe,uBAAA,CAAA,8BAAA,CAAA,sBAAA,CAAuB,sBAAsB,CAAC,uCAAwC,0CAAc,oBAAoB,CAAC,CAAC,kCAAO,WAAA,CAAY,cAAA,CAAe,UAAU","sourcesContent":[".cell{composes:cell from \"./table.scss\";color:#222;font-weight:bold;line-height:1.25;padding:1.125rem .5rem;text-align:center;vertical-align:top}@media only screen and (min-width:48em){.cell{padding:1.125rem}}.imageWrapper{display:flex;height:6.25rem;justify-content:center;margin-bottom:1.125rem}@media screen and (min-width: 80.625em){.imageWrapper{margin-bottom:1.5rem}}.image{height:100%;max-width:100%;width:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "_table-cell-header__cell--oEDr8 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["cell"] + "",
	"imageWrapper": "_table-cell-header__imageWrapper--H+fIW",
	"image": "_table-cell-header__image--vgT8X"
};
export default ___CSS_LOADER_EXPORT___;
