"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _holidaySearchCounters = _interopRequireDefault(require("./holidaySearchCounters"));

var _holiday = require("../../Constants/holiday");

var _types = require("../Ducks/HolidaySearch/types");

var _getCountersMiddlewar = (0, _holidaySearchCounters.default)({
  counterRules: _holiday.GUESTS_COUNTER_RULES,
  namespace: _types.namespace,
  stateKey: _holiday.STATE_KEY
}),
    validateHolidayGuests = _getCountersMiddlewar.validateCounters;

var _default = {
  validateHolidayGuests: validateHolidayGuests
};
exports.default = _default;