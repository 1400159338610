"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeRouteFromAirport = exports.removeExpiredRoutes = exports.normalizeFlightSchedule = exports.findExpiredRoutes = exports.filterNoDestination = exports.filterByCountryCode = exports.filterByAirportCode = exports.carrierTypeLookup = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.regexp.split.js");

require("core-js/modules/es7.object.values.js");

var _momentEs = _interopRequireDefault(require("moment-es6"));

var _immutable = require("immutable");

/**
 * Flight Schedule helpers
 * @namespace Utils/flightschedule
 */
var getDefaultSchema = function getDefaultSchema() {
  return {
    Airports: {
      ById: {},
      AllIds: []
    },
    Countries: {
      ById: {},
      AllIds: []
    },
    StartDates: {
      ById: {},
      AllIds: []
    },
    EndDates: {
      ById: {},
      AllIds: []
    },
    FuzzySearch: {
      Airports: [],
      AirportsByCountry: []
    }
  };
};
/**
 * Filter normalised data by airport code
 * @function filterByAirportCode
 * @memberof Utils/flightschedule
 * @param {string} originCode - airport code
 * @param {Object} schedule - data that will be filtered
 * @return {Object}
 */


var filterByAirportCode = function filterByAirportCode(schedule) {
  var originCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'MEL';
  var currentAirport = schedule.getIn(['data', 'Airports', 'ById', originCode]);
  var routes = currentAirport && currentAirport.get('Routes');
  return routes && schedule.updateIn(['data', 'FuzzySearch', 'Airports'], function (ids) {
    return ids.filter(function (v, k) {
      return routes.includes(v.get('Id'));
    });
  }).updateIn(['data', 'FuzzySearch', 'AirportsByCountry'], function (ids) {
    return ids.map(function (country) {
      return country.map(function (airportsInCountry) {
        return airportsInCountry // only return airports from origin routes list
        .map(function (airport) {
          return routes.includes(airport.get('Id')) ? airport : null;
        }).filter(function (c) {
          return c && c.size > 0;
        });
      });
    }) // only return countries that have airports
    .filter(function (c) {
      return c.first().size > 0;
    });
  });
};
/**
 * Filter normalised data by country code
 * @function filterByCountryCode
 * @memberof Utils/flightschedule
 * @param {string} originCode - country code
 * @param {Object} schedule - data that will be filtered
 * @return {Object}
 */


exports.filterByAirportCode = filterByAirportCode;

var filterByCountryCode = function filterByCountryCode(schedule) {
  var countryCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'AU';
  var currentCountry = schedule.getIn(['data', 'Countries', 'ById', countryCode, 'Airports']);
  return schedule.updateIn(['data', 'FuzzySearch', 'Airports'], function (ids) {
    return ids.filter(function (v, k) {
      return currentCountry.includes(v.get('Id'));
    });
  }).updateIn(['data', 'FuzzySearch', 'AirportsByCountry'], function (ids) {
    return ids.map(function (country) {
      return country.map(function (airportsInCountry) {
        return airportsInCountry // only return airports from origin routes list
        .map(function (airport) {
          return currentCountry.includes(airport.get('Id')) ? airport : null;
        }).filter(function (c) {
          return c && c.size > 0;
        });
      });
    }) // only return countries that have airports
    .filter(function (c) {
      return c.first().size > 0;
    });
  });
};
/**
 * Filter aiports with no destination routes
 * @function filterNoDestination
 * @memberof Utils/flightschedule
 * @param {Object} schedule - data that will be filtered
 * @return {Object}
 */


exports.filterByCountryCode = filterByCountryCode;

var filterNoDestination = function filterNoDestination(schedule) {
  return schedule.updateIn(['data', 'FuzzySearch', 'Airports'], function (countries) {
    return countries.filter(function (v, k) {
      return v.get('Routes').first() !== '';
    });
  }).updateIn(['data', 'FuzzySearch', 'AirportsByCountry'], function (countries) {
    return countries.map(function (country) {
      return country.map(function (airportsInCountry) {
        return airportsInCountry.filter(function (port) {
          return port.get('Routes').first() !== '';
        });
      });
    }) // only return countries that have airports
    .filter(function (c) {
      return c.first().size > 0;
    });
  });
};
/**
 * Create schedule dates object indexed by airport code
 * @function addScheduleDates
 * @memberof Utils/flightschedule
 * @param {Object} obj - object to merge with
 * @param {Object} data - the dates that will be added to object
 * @return {string} airportCode
 */


exports.filterNoDestination = filterNoDestination;

var addScheduleDates = function addScheduleDates(obj, data, airportCode) {
  var tmpObj = obj;
  tmpObj.ById = tmpObj.ById || {};
  tmpObj.AllIds = tmpObj.AllIds || [];
  var ById = obj.ById,
      AllIds = obj.AllIds;
  ById[airportCode] = ById[airportCode] || {};
  ById[airportCode] = Array.isArray(ById[airportCode]) ? [].concat((0, _toConsumableArray2.default)(ById[airportCode]), [data]) : [data];
  return !AllIds.includes(airportCode) && AllIds.push(airportCode);
};
/**
 * Flatten flight schedule data to be easily mapped
 * @function normalizeFlightSchedule
 * @memberof Utils/flightschedule
 * @param {Object} schedule - received from get flight schedule api
 * @return {Object} flattened object
 */


var normalizeFlightSchedule = function normalizeFlightSchedule(schedule) {
  var schema = getDefaultSchema();
  var Countries = schema.Countries,
      arprts = schema.Airports,
      FuzzySearch = schema.FuzzySearch;
  schedule.forEach(function (country) {
    var CountryCode = country.CountryCode,
        DefaultCurrencyCode = country.DefaultCurrencyCode,
        SortOrder = country.SortOrder,
        Country = country.Country,
        Airports = country.Airports;
    Countries.ById[CountryCode] = {
      Airports: [],
      DefaultCurrencyCode: DefaultCurrencyCode,
      SortOrder: SortOrder
    };
    Countries.AllIds.push({
      Name: Country,
      Id: CountryCode,
      Type: 'country'
    });
    FuzzySearch.AirportsByCountry.push((0, _defineProperty2.default)({}, Country, []));
    Airports.forEach(function (airport) {
      var AdditionalCurrency = airport.AdditionalCurrency,
          airportDefaultCurrencyCode = airport.DefaultCurrencyCode,
          CityPairCarrierType = airport.CityPairCarrierType,
          Key = airport.Key,
          Lat = airport.Lat,
          Long = airport.Long,
          Name = airport.Name,
          Region = airport.Region,
          Routes = airport.Routes,
          ScheduledDates = airport.ScheduledDates;

      if (CityPairCarrierType) {
        Object.keys(CityPairCarrierType).forEach(function (item) {
          CityPairCarrierType[item] = CityPairCarrierType[item].split('|');
        });
      }

      arprts.ById[Key] = {
        Country: CountryCode,
        CityPairCarrierType: CityPairCarrierType,
        Lat: Lat,
        Long: Long,
        Name: Name,
        Region: Region,
        Routes: Routes.split('|')
      }; // this data will be used exclusively for fuzzySearch

      var fuzzySearchAirportData = {
        Name: Name,
        Id: Key,
        Region: Region,
        CountryCode: CountryCode,
        Country: Country,
        Routes: Routes.split('|')
      };
      FuzzySearch.Airports.push(fuzzySearchAirportData);
      FuzzySearch.AirportsByCountry[FuzzySearch.AirportsByCountry.length - 1][Country].push(fuzzySearchAirportData);

      if (Array.isArray(AdditionalCurrency)) {
        arprts.ById[Key].AdditionalCurrency = (0, _toConsumableArray2.default)(AdditionalCurrency);
      }

      if (airportDefaultCurrencyCode) {
        arprts.ById[Key].DefaultCurrencyCode = airportDefaultCurrencyCode;
      }

      arprts.AllIds.push({
        Name: Name,
        Id: Key,
        Type: 'airport',
        Region: Region
      });
      Countries.ById[CountryCode].Airports.push(Key);

      if (ScheduledDates) {
        ScheduledDates.forEach(function (date) {
          var EndDate = date.EndDate,
              StartDate = date.StartDate;

          if (EndDate) {
            addScheduleDates(schema.EndDates, date, Key);
          }

          if (StartDate) {
            addScheduleDates(schema.StartDates, date, Key);
          }
        });
      }
    });
  });
  return (0, _immutable.fromJS)(schema);
};

exports.normalizeFlightSchedule = normalizeFlightSchedule;

var findExpiredRoutes = function findExpiredRoutes(enddates) {
  var ById = enddates.get('ById');
  var AllIds = enddates.get('AllIds');
  var today = (0, _momentEs.default)();
  var removeRouteIds = AllIds.reduce(function (accum, id) {
    var endDateList = ById.get(id);

    if (!endDateList) {
      return accum;
    }

    var result = endDateList.reduce(function (prev, next) {
      if (today.isAfter((0, _momentEs.default)(next.get('EndDate')))) {
        prev.push([id, next.get('Key')]);
      }

      return prev;
    }, []);
    return accum.concat(result);
  }, []);
  return removeRouteIds;
};

exports.findExpiredRoutes = findExpiredRoutes;

var removeRouteFromAirport = function removeRouteFromAirport(airports, routes) {
  var removedRouteIds = routes.reduce(function (prev, route) {
    var _route = (0, _slicedToArray2.default)(route, 2),
        from = _route[0],
        to = _route[1];

    var resultRoutes = prev.getIn([from, 'Routes']).filter(function (val) {
      return val !== to;
    });
    return prev.setIn([from, 'Routes'], resultRoutes);
  }, airports.get('ById'));
  return airports.set('ById', removedRouteIds);
};

exports.removeRouteFromAirport = removeRouteFromAirport;

var removeExpiredRoutes = function removeExpiredRoutes(schedule) {
  return schedule.set('Airports', removeRouteFromAirport(schedule.get('Airports'), findExpiredRoutes(schedule.get('EndDates'))));
};
/**
 * Lookup city pair & return Carrier Flight Type GUID
 * @function carrierTypeLookup
 * @memberof Utils/flightschedule
 * @param {Map} cityPairCarrierTypes
 * @param {Map} cityPairCarrierTypeData
 * @param {string} destination - destination IATA code
 * @param {string} origin - origin IATA code (passed to assist error logging only)
 * @return {string} - carrier flight type GUID
 */


exports.removeExpiredRoutes = removeExpiredRoutes;

var carrierTypeLookup = function carrierTypeLookup(cityPairCarrierTypes, cityPairCarrierTypeData, destination, origin) {
  var isFlightTypeDefined = false;

  if (!cityPairCarrierTypeData) {
    return '';
  }

  var carrierFlightTypeGUID = cityPairCarrierTypeData.filter(function (item, key) {
    return item.includes(destination);
  }).keySeq().first();

  if (!carrierFlightTypeGUID) {
    throw new Error("FlightSchedule: carrierTypeLookup: destination lookup failed: ".concat(origin, " > ").concat(destination));
  }

  var carrierTypes = Object.values(cityPairCarrierTypes.toJS());
  carrierTypes.forEach(function (value) {
    if (carrierFlightTypeGUID === value) {
      isFlightTypeDefined = true;
    }
  });

  if (!isFlightTypeDefined) {
    throw new Error("FlightSchedule: carrierTypeLookup: cityPairCarrierType ".concat(carrierFlightTypeGUID, " is not defined in site values API"));
  }

  return carrierFlightTypeGUID;
};

exports.carrierTypeLookup = carrierTypeLookup;