"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var FETCHING_FLIGHT_SCHEDULE = 'FETCHING_FLIGHT_SCHEDULE';
var FETCHING_SUCCESS_FLIGHT_SCHEDULE = 'FETCHING_SUCCESS_FLIGHT_SCHEDULE';
var FETCHING_LOCAL_SUCCESS_FLIGHT_SCHEDULE = 'FETCHING_LOCAL_SUCCESS_FLIGHT_SCHEDULE';
var _default = {
  FETCHING_FLIGHT_SCHEDULE: FETCHING_FLIGHT_SCHEDULE,
  FETCHING_SUCCESS_FLIGHT_SCHEDULE: FETCHING_SUCCESS_FLIGHT_SCHEDULE,
  FETCHING_LOCAL_SUCCESS_FLIGHT_SCHEDULE: FETCHING_LOCAL_SUCCESS_FLIGHT_SCHEDULE
};
exports.default = _default;