"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// webforms type
var WEBFORMS_ADD_INVALID_WEBFORM = 'WEBFORMS_ADD_INVALID_WEBFORM';
var WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT = 'WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT';
var WEBFORMS_HIDE_WEBFORM_VALIDATION = 'WEBFORMS_HIDE_WEBFORM_VALIDATION';
var WEBFORMS_INIT_WEBFORM = 'WEBFORMS_INIT_WEBFORM';
var WEBFORMS_INIT_WEBFORM_ELEMENT = 'WEBFORMS_INIT_WEBFORM_ELEMENT';
var WEBFORMS_ONCHANGE_WEBFORM_ELEMENT = 'WEBFORMS_ONCHANGE_WEBFORM_ELEMENT';
var WEBFORMS_REMOVE_INVALID_WEBFORM = 'WEBFORMS_REMOVE_INVALID_WEBFORM';
var WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT = 'WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT';
var WEBFORMS_SET_WEBFORM = 'WEBFORMS_SET_WEBFORM';
var WEBFORMS_SET_WEBFORM_ELEMENT = 'WEBFORMS_SET_WEBFORM_ELEMENT';
var WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM = 'WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM';
var WEBFORMS_SET_WEBFORM_ELEMENT_VALUE = 'WEBFORMS_SET_WEBFORM_ELEMENT_VALUE';
var WEBFORMS_SHOW_WEBFORM_VALIDATION = 'WEBFORMS_SHOW_WEBFORM_VALIDATION';
var _default = {
  WEBFORMS_ADD_INVALID_WEBFORM: WEBFORMS_ADD_INVALID_WEBFORM,
  WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT: WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT,
  WEBFORMS_HIDE_WEBFORM_VALIDATION: WEBFORMS_HIDE_WEBFORM_VALIDATION,
  WEBFORMS_INIT_WEBFORM: WEBFORMS_INIT_WEBFORM,
  WEBFORMS_INIT_WEBFORM_ELEMENT: WEBFORMS_INIT_WEBFORM_ELEMENT,
  WEBFORMS_ONCHANGE_WEBFORM_ELEMENT: WEBFORMS_ONCHANGE_WEBFORM_ELEMENT,
  WEBFORMS_REMOVE_INVALID_WEBFORM: WEBFORMS_REMOVE_INVALID_WEBFORM,
  WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT: WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT,
  WEBFORMS_SET_WEBFORM: WEBFORMS_SET_WEBFORM,
  WEBFORMS_SET_WEBFORM_ELEMENT: WEBFORMS_SET_WEBFORM_ELEMENT,
  WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM: WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM,
  WEBFORMS_SET_WEBFORM_ELEMENT_VALUE: WEBFORMS_SET_WEBFORM_ELEMENT_VALUE,
  WEBFORMS_SHOW_WEBFORM_VALIDATION: WEBFORMS_SHOW_WEBFORM_VALIDATION
};
exports.default = _default;