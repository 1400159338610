// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._field-button__fieldButtonInner--64XTa{display:block;position:relative}._field-button__fieldButtonIcon--t688t{-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;height:1.5rem;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:1.5rem}@media only screen and (min-width:48em){._field-button__fieldButtonIcon--t688t{height:2rem;width:2rem}}._field-button__fieldButtonIcon--t688t>*{max-height:100%;max-width:100%}._field-button__fieldButtonLabel--Sh5ZB{display:block;overflow:hidden;padding-right:2rem;text-overflow:ellipsis;white-space:nowrap}._field-button__fieldButtonGuidance--Lrs0H span{color:#0692fc}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/FieldButton/_field-button.scss"],"names":[],"mappings":"AAAA,wCAAkB,aAAA,CAAc,iBAAiB,CAAC,uCAAiB,wBAAA,CAAA,0BAAA,CAAA,kBAAA,CAAmB,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,aAAA,CAAc,uBAAA,CAAA,8BAAA,CAAA,sBAAA,CAAuB,iBAAA,CAAkB,OAAA,CAAQ,OAAA,CAAQ,kCAAA,CAAA,0BAAA,CAA2B,YAAY,CAAC,wCAAwC,uCAAiB,WAAA,CAAY,UAAU,CAAC,CAAC,yCAAmB,eAAA,CAAgB,cAAc,CAAC,wCAAkB,aAAA,CAAc,eAAA,CAAgB,kBAAA,CAAmB,sBAAA,CAAuB,kBAAkB,CAAC,gDAA0B,aAAa","sourcesContent":[".fieldButtonInner{display:block;position:relative}.fieldButtonIcon{align-items:center;display:flex;height:1.5rem;justify-content:center;position:absolute;right:0;top:50%;transform:translateY(-50%);width:1.5rem}@media only screen and (min-width:48em){.fieldButtonIcon{height:2rem;width:2rem}}.fieldButtonIcon>*{max-height:100%;max-width:100%}.fieldButtonLabel{display:block;overflow:hidden;padding-right:2rem;text-overflow:ellipsis;white-space:nowrap}.fieldButtonGuidance span{color:#0692fc}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldButtonInner": "_field-button__fieldButtonInner--64XTa",
	"fieldButtonIcon": "_field-button__fieldButtonIcon--t688t",
	"fieldButtonLabel": "_field-button__fieldButtonLabel--Sh5ZB",
	"fieldButtonGuidance": "_field-button__fieldButtonGuidance--Lrs0H"
};
export default ___CSS_LOADER_EXPORT___;
