"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.holidayIdMapping = exports.UTM_QUERY_PARAMS = exports.SUBMIT_QUERY_KEYS = exports.SUBMIT = exports.STATE_KEY = exports.LOCATIONS = exports.LOCATION = exports.INVALID_CHARS = exports.HOLIDAY_QUERY_RULES = exports.HOLIDAY_KEYS = exports.HOLIDAY_AUTO_DATA = exports.GUESTS_COUNTER_RULES = exports.GUESTS = exports.DEFAULT_ROOMS_COUNT = exports.DEFAULT_ADULTS_COUNT = exports.DATES = exports.CALENDAR = exports.BOOKING_QUERY_KEYS = void 0;

require("core-js/modules/es6.number.constructor.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _strings = require("../Utils/strings");

var _counters = require("./counters");

var _HOLIDAY_AUTO_DATA;

// utils
// Common
var prefix = 'holiday';
var STATE_KEY = 'holidaySearch';
exports.STATE_KEY = STATE_KEY;
var DEFAULT_ADULTS_COUNT = 2;
exports.DEFAULT_ADULTS_COUNT = DEFAULT_ADULTS_COUNT;
var DEFAULT_ROOMS_COUNT = 1;
exports.DEFAULT_ROOMS_COUNT = DEFAULT_ROOMS_COUNT;
var LOCATION = 'location';
exports.LOCATION = LOCATION;
var LOCATIONS = 'locations';
exports.LOCATIONS = LOCATIONS;
var DATES = 'dates';
exports.DATES = DATES;
var CALENDAR = 'calendar';
exports.CALENDAR = CALENDAR;
var GUESTS = 'travellers';
exports.GUESTS = GUESTS;
var SUBMIT = 'submit';
exports.SUBMIT = SUBMIT;

var camelCaseWithPrefix = function camelCaseWithPrefix(label) {
  return "".concat(prefix).concat((0, _strings.capitalize)(label));
};

var HOLIDAY_AUTO_DATA = (_HOLIDAY_AUTO_DATA = {}, (0, _defineProperty2.default)(_HOLIDAY_AUTO_DATA, LOCATIONS, camelCaseWithPrefix(LOCATIONS)), (0, _defineProperty2.default)(_HOLIDAY_AUTO_DATA, DATES, camelCaseWithPrefix(DATES)), (0, _defineProperty2.default)(_HOLIDAY_AUTO_DATA, CALENDAR, camelCaseWithPrefix(CALENDAR)), (0, _defineProperty2.default)(_HOLIDAY_AUTO_DATA, GUESTS, camelCaseWithPrefix(GUESTS)), (0, _defineProperty2.default)(_HOLIDAY_AUTO_DATA, SUBMIT, camelCaseWithPrefix(SUBMIT)), _HOLIDAY_AUTO_DATA); // Counter

exports.HOLIDAY_AUTO_DATA = HOLIDAY_AUTO_DATA;
var COUNTER = 'counter';
var TOTAL = 'total'; // Dates

var DAYS = 'days';
var DEFAULT_DATE_FNS_FORMAT_STRING = "EEE do LLL ''yy";
var END_DATE = 'endDate';
var IS_FETCHING = 'isFetching';
var MAX_NIGHTS = 29;
var MIN_NIGHTS = 1;
var START_DATE = 'startDate'; // Locations

var AIRPORT = 'airport';
var CODE = 'code';
var DIRECTION = 'direction';
var LABEL = 'label';
var NAME = 'name';
var VALID = 'valid';
var INBOUND = 'inbound';
var OUTBOUND = 'outbound'; // Traveller

var TRAVELLER_CONFIRM = 'ConfirmTravellersRooms'; // Storage

var HOLIDAY_CACHE = 'HolidayPackages'; // Rooms

var ROOM = 'room';
var ROOMS = 'rooms';
var TRAVELLERS = 'travellers';
var TOTAL_ROOMS = 'totalRooms';
var TOTAL_PAX = 'totalPax'; //room

var COUNT = 'count';
var GUEST_LABEL = 'GuestLabels';
var LABEL_STR = 'Label';
var ORDER = 'order';
var ROOM_ID = 'roomId';
var ROOM_COUNTER = 'roomCounter';
var ROOMS_LABEL = 'Rooms';
var ROOM_LABEL = 'Room';
var SINGLE_ROOM_ENTRIES = 3;
var UTM_QUERY_PARAMS = {
  UTM_SOURCE: 'jetstar',
  MEDIUM: 'web',
  CAMPAIGN: 'home-mps'
};
exports.UTM_QUERY_PARAMS = UTM_QUERY_PARAMS;
var HOLIDAY_KEYS = {
  // Counter
  COUNTER: COUNTER,
  TOTAL: TOTAL,
  // Dates
  DATES: DATES,
  DAYS: DAYS,
  DEFAULT_DATE_FNS_FORMAT_STRING: DEFAULT_DATE_FNS_FORMAT_STRING,
  END_DATE: END_DATE,
  IS_FETCHING: IS_FETCHING,
  // Storage
  HOLIDAY_CACHE: HOLIDAY_CACHE,
  MAX_NIGHTS: MAX_NIGHTS,
  MIN_NIGHTS: MIN_NIGHTS,
  START_DATE: START_DATE,
  // Location
  AIRPORT: AIRPORT,
  CODE: CODE,
  DIRECTION: DIRECTION,
  INBOUND: INBOUND,
  LABEL: LABEL,
  LOCATIONS: LOCATIONS,
  NAME: NAME,
  OUTBOUND: OUTBOUND,
  VALID: VALID,
  // Traveller
  TRAVELLER_CONFIRM: TRAVELLER_CONFIRM,
  // Rooms
  ROOM: ROOM,
  ROOMS: ROOMS,
  TRAVELLERS: TRAVELLERS,
  TOTAL_ROOMS: TOTAL_ROOMS,
  TOTAL_PAX: TOTAL_PAX,
  //Room
  COUNT: COUNT,
  ORDER: ORDER,
  ROOM_COUNTER: ROOM_COUNTER,
  ROOM_ID: ROOM_ID,
  SINGLE_ROOM_ENTRIES: SINGLE_ROOM_ENTRIES,
  //labels
  GUEST_LABEL: GUEST_LABEL,
  LABEL_STR: LABEL_STR,
  ROOMS_LABEL: ROOMS_LABEL,
  ROOM_LABEL: ROOM_LABEL
}; // Submit query keys

exports.HOLIDAY_KEYS = HOLIDAY_KEYS;
var DATE_OF_DEPARTURE = 'departing';
var DATE_OF_RETURN = 'returning';
var DESTINATION = 'destination';
var DESTINATION_NAME = 'destinationName';
var GROUPS = 'groups';
var ORIGIN = 'origin';
var PID = 'pid';
var SUBMIT_QUERY_KEYS = {
  ADULTS: _counters.ADULTS,
  CHILDREN: _counters.CHILDREN,
  DATE_OF_DEPARTURE: DATE_OF_DEPARTURE,
  DATE_OF_RETURN: DATE_OF_RETURN,
  DESTINATION: DESTINATION,
  DESTINATION_NAME: DESTINATION_NAME,
  GROUPS: GROUPS,
  INFANTS: _counters.INFANTS,
  ORIGIN: ORIGIN
};
exports.SUBMIT_QUERY_KEYS = SUBMIT_QUERY_KEYS;
var BOOKING_QUERY_KEYS = {
  DATE_OF_DEPARTURE: DATE_OF_DEPARTURE,
  DATE_OF_RETURN: DATE_OF_RETURN,
  DESTINATION: DESTINATION,
  DESTINATION_NAME: DESTINATION_NAME,
  ORIGIN: ORIGIN,
  PID: PID
}; // Guests

exports.BOOKING_QUERY_KEYS = BOOKING_QUERY_KEYS;
var GUESTS_COUNTER_RULES = {
  MIN_TOTAL: 1,
  TOTAL: 50,
  ADULTS_CHILDREN_INFANT_RATIO: Number.POSITIVE_INFINITY,
  ADULTS_INFANTS_RATIO: Number.POSITIVE_INFINITY
}; //Strings

exports.GUESTS_COUNTER_RULES = GUESTS_COUNTER_RULES;
var INVALID_CHARS = /[°"§%()\\[\]{}=\\?´`'#<>|,;.:+_-]+/g; // Holiday search query rules

exports.INVALID_CHARS = INVALID_CHARS;
var HOLIDAY_QUERY_RULES = {
  MAX_TOTAL_PAX: 25,
  MIN_TOTAL_PAX: 1
};
exports.HOLIDAY_QUERY_RULES = HOLIDAY_QUERY_RULES;
var holidayIdMapping = {
  OOL: 'gold-coast',
  AYQ: 'uluru',
  CNS: 'cairns',
  PTI: 'port-douglas',
  HBA: 'hobart',
  MCY: 'sunshine-coast',
  PPP: 'whitsundays',
  WSY: 'airlie-beach',
  RAR: 'rarotonga',
  BNK: 'byron-bay',
  BNE: 'brisbane',
  HTI: 'hamilton-island',
  CBR: 'canberra',
  ADL: 'adelaide',
  AVV: 'melbourne',
  MEL: 'melbourne',
  SYD: 'sydney',
  PER: 'perth',
  LST: 'launceston',
  TSV: 'townsville-magnetic-island',
  WLG: 'wellington',
  AKL: 'auckland',
  DRW: 'darwin',
  BQB: 'margaret-river-region',
  HVB: 'hervey-bay-kgari',
  NTL: 'port-stephens-newcastle',
  ZQN: 'queenstown',
  CHC: 'christchurch',
  DPS: 'bali',
  SGN: 'ho-chi-minh-city',
  MKY: 'mackay',
  KIX: 'osaka',
  HNL: 'hawaii',
  NRT: 'tokyo',
  BKK: 'bangkok',
  SIN: 'singapore',
  HKT: 'phuket',
  UKY: 'kyoto',
  NAN: 'fiji',
  ICN: 'seoul'
};
exports.holidayIdMapping = holidayIdMapping;