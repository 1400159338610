"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.namespace = exports.default = void 0;
var namespace = 'ACCOMMODATION_SEARCH'; // Accommodation Type

exports.namespace = namespace;
var ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH = 'ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH';
var ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH = 'ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH'; // Location

var RESET_LOCATION_ACCOMMODATION_SEARCH = 'RESET_LOCATION_ACCOMMODATION_SEARCH';
var RESET_SEARCH_ACCOMMODATION_SEARCH = 'RESET_SEARCH_ACCOMMODATION_SEARCH';
var SEARCH_LOCATION_ACCOMMODATION_SEARCH = 'SEARCH_LOCATION_ACCOMMODATION_SEARCH';
var SET_LOCATION_ACCOMMODATION_SEARCH = 'SET_LOCATION_ACCOMMODATION_SEARCH'; // Dates

var RESET_DATES_ACCOMMODATION_SEARCH = 'RESET_DATES_ACCOMMODATION_SEARCH';
var SET_DATES_ACCOMMODATION_SEARCH = 'SET_DATES_ACCOMMODATION_SEARCH'; // Business

var SET_BUSINESS_ACCOMMODATION_SEARCH = 'SET_BUSINESS_ACCOMMODATION_SEARCH';
var RESET_VALIDATION_ACCOMMODATION_SEARCH = 'RESET_VALIDATION_ACCOMMODATION_SEARCH';
var SET_INVALID_LOCATION_ACCOMMODATION_SEARCH = 'SET_INVALID_LOCATION_ACCOMMODATION_SEARCH';
var _default = {
  ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH: ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH,
  ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH: ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH,
  RESET_DATES_ACCOMMODATION_SEARCH: RESET_DATES_ACCOMMODATION_SEARCH,
  RESET_LOCATION_ACCOMMODATION_SEARCH: RESET_LOCATION_ACCOMMODATION_SEARCH,
  RESET_SEARCH_ACCOMMODATION_SEARCH: RESET_SEARCH_ACCOMMODATION_SEARCH,
  SEARCH_LOCATION_ACCOMMODATION_SEARCH: SEARCH_LOCATION_ACCOMMODATION_SEARCH,
  SET_BUSINESS_ACCOMMODATION_SEARCH: SET_BUSINESS_ACCOMMODATION_SEARCH,
  SET_DATES_ACCOMMODATION_SEARCH: SET_DATES_ACCOMMODATION_SEARCH,
  SET_LOCATION_ACCOMMODATION_SEARCH: SET_LOCATION_ACCOMMODATION_SEARCH,
  RESET_VALIDATION_ACCOMMODATION_SEARCH: RESET_VALIDATION_ACCOMMODATION_SEARCH,
  SET_INVALID_LOCATION_ACCOMMODATION_SEARCH: SET_INVALID_LOCATION_ACCOMMODATION_SEARCH
};
exports.default = _default;