// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._calendar-week__calendarWeek--P0tw-{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;position:relative}@media only screen and (min-width:48em){._calendar-week__calendarWeek--P0tw-{-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}}._calendar-week__monthName--h3\\+tQ{font-size:.6875rem;line-height:1rem;font-weight:700;text-transform:uppercase;color:#222;text-align:center;margin:.25rem 0;letter-spacing:.03125rem}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Calendar/_calendar-week.scss"],"names":[],"mappings":"AAAA,qCAAc,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,sBAAA,CAAA,cAAA,CAAe,uBAAA,CAAA,8BAAA,CAAA,sBAAA,CAAuB,iBAAiB,CAAC,wCAAwC,qCAAc,oBAAA,CAAA,gCAAA,CAAA,wBAAwB,CAAC,CAAC,mCAAW,kBAAA,CAAmB,gBAAA,CAAiB,eAAA,CAAiB,wBAAA,CAAyB,UAAA,CAAW,iBAAA,CAAkB,eAAA,CAAgB,wBAAwB","sourcesContent":[".calendarWeek{display:flex;flex-wrap:wrap;justify-content:center;position:relative}@media only screen and (min-width:48em){.calendarWeek{justify-content:flex-end}}.monthName{font-size:.6875rem;line-height:1rem;font-weight:bold;text-transform:uppercase;color:#222;text-align:center;margin:.25rem 0;letter-spacing:.03125rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarWeek": "_calendar-week__calendarWeek--P0tw-",
	"monthName": "_calendar-week__monthName--h3+tQ"
};
export default ___CSS_LOADER_EXPORT___;
