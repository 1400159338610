"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VARIATIONS = exports.STICKY_FOOTER_BANNER_AUTO_DATA = void 0;
// automation attribute
var STICKY_FOOTER_BANNER_AUTO_DATA = 'stickyFooterBanner'; // types

exports.STICKY_FOOTER_BANNER_AUTO_DATA = STICKY_FOOTER_BANNER_AUTO_DATA;
var CLUBJETSTAR = 'clubJetstar';
var VANILLA = 'vanilla';
var VARIATIONS = {
  CLUBJETSTAR: CLUBJETSTAR,
  VANILLA: VANILLA
};
exports.VARIATIONS = VARIATIONS;