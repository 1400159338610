"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalPAX = exports.getInfants = exports.getErrors = exports.getChildren = exports.getAdults = void 0;

var _counters = require("../../../Constants/counters");

// constants
var ADULTS_KEY = _counters.COUNT_KEYS.ADULTS_KEY,
    CHILDREN_KEY = _counters.COUNT_KEYS.CHILDREN_KEY,
    INFANTS_KEY = _counters.COUNT_KEYS.INFANTS_KEY;
var COUNT_KEY = _counters.COUNTER_KEYS.COUNT_KEY,
    ERRORS_KEY = _counters.COUNTER_KEYS.ERRORS_KEY,
    TOTAL_KEY = _counters.COUNTER_KEYS.TOTAL_KEY;

var getAdults = function getAdults(state) {
  return state.counter.getIn([COUNT_KEY, ADULTS_KEY]);
};

exports.getAdults = getAdults;

var getChildren = function getChildren(state) {
  return state.counter.getIn([COUNT_KEY, CHILDREN_KEY]);
};

exports.getChildren = getChildren;

var getErrors = function getErrors(state) {
  return state.counter.get(ERRORS_KEY);
};

exports.getErrors = getErrors;

var getInfants = function getInfants(state) {
  return state.counter.getIn([COUNT_KEY, INFANTS_KEY]);
};

exports.getInfants = getInfants;

var getTotalPAX = function getTotalPAX(state) {
  return state.counter.get(TOTAL_KEY);
};

exports.getTotalPAX = getTotalPAX;