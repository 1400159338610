"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof3 = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCurrencySettings = exports.priceFormatter = exports.getFlattenPrices = exports.getCurrentCurrencySettings = void 0;

require("core-js/modules/es6.map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.map.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var Immutable = _interopRequireWildcard(require("immutable"));

var _accounting = _interopRequireDefault(require("accounting"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Price point helpers
 * @namespace Utils/price
 */

/**
 * Return a flatten immutable price map
 * @function getFlattenPrices
 * @memberof Utils/price
 * @param {string} data - data stored in redux's calendar pricing model
 * @return {Map} - a flatten price map
 */
var getFlattenPrices = function getFlattenPrices(data) {
  var isMemberPrice = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!data || !Immutable.Iterable.isIterable(data) || (0, _typeof2.default)(data) !== 'object') {
    return new Map();
  }

  return data.map(function (item) {
    if (!item || !Immutable.Iterable.isIterable(item) || (0, _typeof2.default)(item) !== 'object') {
      return null;
    }

    var fareItem = item.get(isMemberPrice ? 'member' : 'starter');
    if (!fareItem) return null;
    var d = fareItem.get('days');
    var isInaccurate = fareItem.get('inaccuratePrice');
    var validPrice = d && !isInaccurate ? d.map(function (i) {
      return i.getIn([0, 'price']) >= 0 ? i.getIn([0, 'price']) : null;
    }) : null;
    return validPrice;
  }).flatten(true);
};
/**
 * Format number into a current culture price point format
 * @function priceFormatter
 * @memberof Utils/price
 * @param {number} price - price value
 * @param {currency} currency - currency settings: symbol, format, precision
 * @return {string} - current culture price format
 */


exports.getFlattenPrices = getFlattenPrices;

var priceFormatter = function priceFormatter(price) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (typeof price !== 'number') {
    return null;
  }

  if (!currency) {
    return _accounting.default.formatMoney(price);
  }

  return _accounting.default.formatMoney(price, {
    symbol: currency.get('symbol'),
    format: currency.get('format'),
    precision: currency.get('precision')
  });
};
/**
 * Update currency settings based on origin port
 * @function updateCurrencySettings
 * @memberof Utils/price
 * @param {object} currentPosConfig - price object
 */


exports.priceFormatter = priceFormatter;

var updateCurrencySettings = function updateCurrencySettings() {
  var currentPosConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

  if (!currentPosConfig) {
    return;
  }

  _accounting.default.settings.currency = _objectSpread(_objectSpread({}, _accounting.default.settings.currency), currentPosConfig);
};
/**
 * Get current currency settings
 * @function getCurrentCurrencySettings
 * @memberof Utils/price
 * @return {object}
 */


exports.updateCurrencySettings = updateCurrencySettings;

var getCurrentCurrencySettings = function getCurrentCurrencySettings() {
  return _accounting.default.settings.currency;
};

exports.getCurrentCurrencySettings = getCurrentCurrencySettings;