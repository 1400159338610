// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._calendar__calendar--iOjt0{left:50%;position:relative;-webkit-transform:translate(-50%);transform:translate(-50%);width:100vw;display:block;margin:1rem 0}@media only screen and (min-width:48em){._calendar__calendar--iOjt0{left:auto;-webkit-transform:none;transform:none;width:100%}}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Calendar/_calendar.scss"],"names":[],"mappings":"AAAA,4BAAU,QAAA,CAAS,iBAAA,CAAkB,iCAAA,CAAA,yBAAA,CAA6B,WAAA,CAAY,aAAA,CAAc,aAAa,CAAC,wCAAwC,4BAAU,SAAA,CAAU,sBAAA,CAAA,cAAA,CAAe,UAAU,CAAC","sourcesContent":[".calendar{left:50%;position:relative;transform:translate(-50%, 0);width:100vw;display:block;margin:1rem 0}@media only screen and (min-width:48em){.calendar{left:auto;transform:none;width:100%}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": "_calendar__calendar--iOjt0"
};
export default ___CSS_LOADER_EXPORT___;
