// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._dropdown-item-wrapper__dropdownItemWrapper--MJfUX{border-top:.0625rem solid #dedede;margin-top:-.0625rem;padding-bottom:.0625rem}._dropdown-item-wrapper__isHeaderless--4svGv{padding-top:1.25rem}._dropdown-item-wrapper__header--neFKo{color:#707070;font-family:Helvetica Neue,Roboto,Arial,sans-serif;font-size:.75rem;font-weight:700;line-height:1.67;margin-bottom:.5rem;padding:1.25rem .75rem 0}._dropdown-item-wrapper__content--Ub1Ff{padding-bottom:.625rem}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/DropdownItemWrapper/_dropdown-item-wrapper.scss"],"names":[],"mappings":"AAAA,oDAAqB,iCAAA,CAAkC,oBAAA,CAAsB,uBAAuB,CAAC,6CAAc,mBAAmB,CAAC,uCAAQ,aAAA,CAAc,kDAAA,CAAyD,gBAAA,CAAiB,eAAA,CAAgB,gBAAA,CAAiB,mBAAA,CAAoB,wBAAwB,CAAC,wCAAS,sBAAsB","sourcesContent":[".dropdownItemWrapper{border-top:.0625rem solid #dedede;margin-top:-0.0625rem;padding-bottom:.0625rem}.isHeaderless{padding-top:1.25rem}.header{color:#707070;font-family:\"Helvetica Neue\",\"Roboto\",\"Arial\",sans-serif;font-size:.75rem;font-weight:700;line-height:1.67;margin-bottom:.5rem;padding:1.25rem .75rem 0}.content{padding-bottom:.625rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownItemWrapper": "_dropdown-item-wrapper__dropdownItemWrapper--MJfUX",
	"isHeaderless": "_dropdown-item-wrapper__isHeaderless--4svGv",
	"header": "_dropdown-item-wrapper__header--neFKo",
	"content": "_dropdown-item-wrapper__content--Ub1Ff"
};
export default ___CSS_LOADER_EXPORT___;
