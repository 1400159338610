"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.travellers = exports.locations = exports.initialState = exports.initialRooms = exports.initialLocations = exports.initialDates = exports.default = exports.dates = void 0;

require("core-js/modules/es6.array.filter.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _redux = require("redux");

var _reduxActions = require("redux-actions");

var _immutable = require("immutable");

var _flightType = require("../../../Constants/flightType");

var _holiday = require("../../../Constants/holiday");

var _actions = require("./actions");

var _fromJS, _handleActions, _INBOUND, _OUTBOUND, _fromJS2, _handleActions2, _fromJS3, _handleActions3;

// =======================================================================
// Constants
var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;
var DATES = _holiday.HOLIDAY_KEYS.DATES,
    END_DATE = _holiday.HOLIDAY_KEYS.END_DATE,
    START_DATE = _holiday.HOLIDAY_KEYS.START_DATE,
    AIRPORT = _holiday.HOLIDAY_KEYS.AIRPORT,
    CODE = _holiday.HOLIDAY_KEYS.CODE,
    DIRECTION = _holiday.HOLIDAY_KEYS.DIRECTION,
    LABEL = _holiday.HOLIDAY_KEYS.LABEL,
    NAME = _holiday.HOLIDAY_KEYS.NAME,
    VALID = _holiday.HOLIDAY_KEYS.VALID,
    ORDER = _holiday.HOLIDAY_KEYS.ORDER,
    ROOM = _holiday.HOLIDAY_KEYS.ROOM,
    ROOMS = _holiday.HOLIDAY_KEYS.ROOMS,
    ROOM_COUNTER = _holiday.HOLIDAY_KEYS.ROOM_COUNTER,
    ROOM_ID = _holiday.HOLIDAY_KEYS.ROOM_ID,
    TOTAL_ROOMS = _holiday.HOLIDAY_KEYS.TOTAL_ROOMS,
    TOTAL_PAX = _holiday.HOLIDAY_KEYS.TOTAL_PAX,
    TRAVELLERS = _holiday.HOLIDAY_KEYS.TRAVELLERS; // Dates

var initialDates = (0, _immutable.fromJS)((_fromJS = {}, (0, _defineProperty2.default)(_fromJS, END_DATE, null), (0, _defineProperty2.default)(_fromJS, START_DATE, null), _fromJS));
exports.initialDates = initialDates;
var dates = (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, _actions.setDates, function (state, action) {
  var payload = action.payload;
  return state.set(START_DATE, payload.getIn([DATES, START_DATE])).set(END_DATE, payload.getIn([DATES, END_DATE]));
}), (0, _defineProperty2.default)(_handleActions, _actions.resetDate, function (state, action) {
  return state.set(START_DATE, null).set(END_DATE, null);
}), (0, _defineProperty2.default)(_handleActions, _actions.fetchingDates, function (state, action) {
  var direction = action.payload.direction;
  return state.setIn([direction, 'isFetching'], true);
}), (0, _defineProperty2.default)(_handleActions, _actions.fetchingDatesError, function (state, action) {
  var direction = action.payload.direction;
  return state.setIn([direction, 'isFetching'], false);
}), (0, _defineProperty2.default)(_handleActions, _actions.fetchingDatesSuccess, function (state, action) {
  var _action$payload = action.payload,
      direction = _action$payload.direction,
      route = _action$payload.route,
      startDate = _action$payload.startDate,
      data = _action$payload.data;
  return state.setIn([direction, 'isFetching'], false).setIn([direction, 'routes', route, 'days', startDate], data);
}), _handleActions), initialDates); // Locations

exports.dates = dates;
var initialLocations = (0, _immutable.fromJS)((_fromJS2 = {}, (0, _defineProperty2.default)(_fromJS2, INBOUND, (_INBOUND = {}, (0, _defineProperty2.default)(_INBOUND, AIRPORT, null), (0, _defineProperty2.default)(_INBOUND, CODE, null), (0, _defineProperty2.default)(_INBOUND, LABEL, null), (0, _defineProperty2.default)(_INBOUND, NAME, null), (0, _defineProperty2.default)(_INBOUND, VALID, null), _INBOUND)), (0, _defineProperty2.default)(_fromJS2, OUTBOUND, (_OUTBOUND = {}, (0, _defineProperty2.default)(_OUTBOUND, AIRPORT, null), (0, _defineProperty2.default)(_OUTBOUND, CODE, null), (0, _defineProperty2.default)(_OUTBOUND, LABEL, null), (0, _defineProperty2.default)(_OUTBOUND, NAME, null), (0, _defineProperty2.default)(_OUTBOUND, VALID, null), _OUTBOUND)), _fromJS2));
exports.initialLocations = initialLocations;
var locations = (0, _reduxActions.handleActions)((_handleActions2 = {}, (0, _defineProperty2.default)(_handleActions2, _actions.resetLocation, function (state, action) {
  var direction = action.payload.get(DIRECTION);
  return state.withMutations(function (map) {
    map.setIn([direction, NAME], null).setIn([direction, CODE], null).setIn([direction, AIRPORT], null).setIn([direction, LABEL], null).setIn([direction, VALID], null);
  });
}), (0, _defineProperty2.default)(_handleActions2, _actions.setLocation, function (state, action) {
  var payload = action.payload;
  var direction = payload.get(DIRECTION);
  var code = payload.get(CODE);
  var name = payload.get(NAME);
  var airport = payload.get(AIRPORT);
  var label = direction === INBOUND ? "".concat(name) : "".concat(name, " - ").concat(code);
  var isValid = payload.get(VALID);
  return state.withMutations(function (map) {
    map.setIn([direction, NAME], name).setIn([direction, CODE], code).setIn([direction, AIRPORT], airport || code).setIn([direction, LABEL], label).setIn([direction, VALID], isValid);
  });
}), (0, _defineProperty2.default)(_handleActions2, _actions.setValidLocation, function (state, action) {
  var payload = action.payload;
  var direction = payload.get(DIRECTION);
  var isValid = payload.get(VALID);
  return state.setIn([direction, VALID], isValid);
}), _handleActions2), initialLocations); // Rooms

exports.locations = locations;
var initialRooms = (0, _immutable.fromJS)((_fromJS3 = {}, (0, _defineProperty2.default)(_fromJS3, ORDER, []), (0, _defineProperty2.default)(_fromJS3, ROOMS, {}), (0, _defineProperty2.default)(_fromJS3, TOTAL_PAX, null), (0, _defineProperty2.default)(_fromJS3, TOTAL_ROOMS, null), _fromJS3));
exports.initialRooms = initialRooms;

var countTotalPax = function countTotalPax(rooms) {
  return rooms.reduce(function (acc, room) {
    var counter = room.getIn([ROOM_COUNTER, 'count']);
    var totalPax = counter.reduce(function (accumulate, currentValue) {
      return currentValue + accumulate;
    }, 0);
    return acc + totalPax;
  }, 0);
};

var updateCount = function updateCount(state, action) {
  var payload = action.payload;

  if (payload instanceof _immutable.Map) {
    payload = payload.toJS();
  }

  var _payload = payload,
      count = _payload.count,
      errors = _payload.errors,
      id = _payload.id,
      total = _payload.total;
  var updateState = state.updateIn([ROOMS, payload[ROOM_ID], ROOM_COUNTER, 'count', id], function (val) {
    return typeof count !== 'undefined' ? count : val;
  }).updateIn([ROOMS, payload[ROOM_ID], ROOM_COUNTER, 'errors'], function (val) {
    return typeof errors !== 'undefined' ? errors : val;
  }).updateIn([ROOMS, payload[ROOM_ID], ROOM_COUNTER, 'total'], function (val) {
    return typeof total !== 'undefined' ? total : val;
  });
  return updateState.set(TOTAL_PAX, countTotalPax(updateState.get(ROOMS)));
};

var travellers = (0, _reduxActions.handleActions)((_handleActions3 = {}, (0, _defineProperty2.default)(_handleActions3, _actions.onSetRooms, function (state, action) {
  var payload = action.payload;
  var newRooms = payload.getIn([TRAVELLERS, ROOMS]);
  var updatedState = state.set(ROOMS, newRooms).set(TOTAL_ROOMS, newRooms.size).set(ORDER, payload.get(ORDER));
  return updatedState.set(TOTAL_PAX, countTotalPax(updatedState.get(ROOMS)));
}), (0, _defineProperty2.default)(_handleActions3, _actions.onAddRoom, function (state, action) {
  var payload = action.payload;
  var roomId = payload.get(ROOM).valueSeq().first().get(ROOM_ID);
  var currentRooms = state.get(ROOMS);
  var updatedState = state.set(ROOMS, currentRooms.merge(payload.get(ROOM))).set(TOTAL_ROOMS, state.get(TOTAL_ROOMS) + 1).set(ORDER, state.get(ORDER).push(roomId));
  return updatedState.set(TOTAL_PAX, countTotalPax(payload.get(ROOM)) + state.get(TOTAL_PAX));
}), (0, _defineProperty2.default)(_handleActions3, _actions.onRemoveRoom, function (state, action) {
  var payload = action.payload;
  var newTotalRoom = state.get(TOTAL_ROOMS) - 1;
  var currentOrder = state.get(ORDER);
  var updatedState = state.withMutations(function (map) {
    map.deleteIn([ROOMS, payload]).set(TOTAL_ROOMS, newTotalRoom);
    map.set(ORDER, currentOrder.filter(function (id) {
      return id !== payload;
    }));
  });
  return updatedState.set(TOTAL_PAX, countTotalPax(updatedState.get(ROOMS)));
}), (0, _defineProperty2.default)(_handleActions3, _actions.onCounterChange, function (state, action) {
  return updateCount(state, action);
}), (0, _defineProperty2.default)(_handleActions3, _actions.onCounterDecrement, function (state, action) {
  return updateCount(state, action);
}), (0, _defineProperty2.default)(_handleActions3, _actions.onCounterIncrement, function (state, action) {
  return updateCount(state, action);
}), (0, _defineProperty2.default)(_handleActions3, _actions.onCounterInit, function (state, action) {
  var payload = action.payload;
  var roomId = payload.getIn(['data', ROOM_ID]);
  return payload.get('data') && state.setIn([ROOMS, roomId, ROOM_COUNTER, 'isLoaded'], payload.getIn(['data', 'isLoaded']));
}), _handleActions3), initialRooms);
exports.travellers = travellers;
var initialState = {
  travellers: initialRooms,
  dates: initialDates,
  locations: initialLocations
};
exports.initialState = initialState;

var _default = (0, _redux.combineReducers)({
  travellers: travellers,
  dates: dates,
  locations: locations
});

exports.default = _default;