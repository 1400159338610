"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setValidLocation = exports.setLocation = exports.setDates = exports.resetLocation = exports.resetDate = exports.onSetRooms = exports.onRemoveRoom = exports.onCounterInit = exports.onCounterIncrement = exports.onCounterDecrement = exports.onCounterChange = exports.onAddRoom = exports.fetchingDatesSuccess = exports.fetchingDatesError = exports.fetchingDates = void 0;

var _reduxActions = require("redux-actions");

var _immutable = require("immutable");

var _types = _interopRequireDefault(require("./types"));

/* istanbul ignore file */
// =======================================================================
// constants
// state
// =======================================================================
var FETCHING_DATES_ERROR_HOLIDAY_SEARCH = _types.default.FETCHING_DATES_ERROR_HOLIDAY_SEARCH,
    FETCHING_DATES_HOLIDAY_SEARCH = _types.default.FETCHING_DATES_HOLIDAY_SEARCH,
    FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH = _types.default.FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH,
    RESET_DATES_HOLIDAY_SEARCH = _types.default.RESET_DATES_HOLIDAY_SEARCH,
    SET_DATES_HOLIDAY_SEARCH = _types.default.SET_DATES_HOLIDAY_SEARCH,
    ON_ADD_ROOM = _types.default.ON_ADD_ROOM,
    ON_DECREMENT_COUNTER = _types.default.ON_DECREMENT_COUNTER,
    ON_INCREMENT_COUNTER = _types.default.ON_INCREMENT_COUNTER,
    ON_REMOVE_ROOM = _types.default.ON_REMOVE_ROOM,
    ON_SET_ROOMS = _types.default.ON_SET_ROOMS,
    ONCHANGE_COUNTER = _types.default.ONCHANGE_COUNTER,
    ON_INIT_COUNTER = _types.default.ON_INIT_COUNTER,
    RESET_LOCATION_HOLIDAY_SEARCH = _types.default.RESET_LOCATION_HOLIDAY_SEARCH,
    SET_LOCATION_HOLIDAY_SEARCH = _types.default.SET_LOCATION_HOLIDAY_SEARCH,
    SET_VALID_LOCATION_HOLIDAY_SEARCH = _types.default.SET_VALID_LOCATION_HOLIDAY_SEARCH; // Dates

var fetchingDates = (0, _reduxActions.createAction)(FETCHING_DATES_HOLIDAY_SEARCH, function (direction) {
  return {
    direction: direction
  };
});
exports.fetchingDates = fetchingDates;
var fetchingDatesSuccess = (0, _reduxActions.createAction)(FETCHING_DATES_SUCCESS_HOLIDAY_SEARCH, function (direction, route, startDate, data) {
  return {
    direction: direction,
    route: route,
    startDate: startDate,
    data: data
  };
});
exports.fetchingDatesSuccess = fetchingDatesSuccess;
var fetchingDatesError = (0, _reduxActions.createAction)(FETCHING_DATES_ERROR_HOLIDAY_SEARCH, function (direction) {
  return {
    direction: direction
  };
});
exports.fetchingDatesError = fetchingDatesError;
var resetDate = (0, _reduxActions.createAction)(RESET_DATES_HOLIDAY_SEARCH, function () {
  return null;
});
exports.resetDate = resetDate;
var setDates = (0, _reduxActions.createAction)(SET_DATES_HOLIDAY_SEARCH, function (dates) {
  return (0, _immutable.fromJS)({
    dates: dates
  });
}); // Location

exports.setDates = setDates;
var resetLocation = (0, _reduxActions.createAction)(RESET_LOCATION_HOLIDAY_SEARCH, function (direction) {
  return (0, _immutable.fromJS)({
    direction: direction
  });
});
exports.resetLocation = resetLocation;
var setLocation = (0, _reduxActions.createAction)(SET_LOCATION_HOLIDAY_SEARCH, function (direction, code, name, airport, valid) {
  return (0, _immutable.fromJS)({
    direction: direction,
    code: code,
    name: name,
    airport: airport,
    valid: valid
  });
});
exports.setLocation = setLocation;
var setValidLocation = (0, _reduxActions.createAction)(SET_VALID_LOCATION_HOLIDAY_SEARCH, function (direction, valid) {
  return (0, _immutable.fromJS)({
    direction: direction,
    valid: valid
  });
}); // Rooms;

exports.setValidLocation = setValidLocation;
var onSetRooms = (0, _reduxActions.createAction)(ON_SET_ROOMS, function (travellers, order) {
  return (0, _immutable.fromJS)({
    travellers: travellers,
    order: order
  });
});
exports.onSetRooms = onSetRooms;
var onAddRoom = (0, _reduxActions.createAction)(ON_ADD_ROOM, function (room) {
  return (0, _immutable.fromJS)({
    room: room
  });
});
exports.onAddRoom = onAddRoom;
var onRemoveRoom = (0, _reduxActions.createAction)(ON_REMOVE_ROOM, function (roomId) {
  return roomId;
}); //counter
//TODO: to be consolidated with common counter

exports.onRemoveRoom = onRemoveRoom;
var onCounterChange = (0, _reduxActions.createAction)(ONCHANGE_COUNTER, function (count, id, roomId) {
  return (0, _immutable.fromJS)({
    count: count,
    id: id,
    roomId: roomId
  });
});
exports.onCounterChange = onCounterChange;
var onCounterDecrement = (0, _reduxActions.createAction)(ON_DECREMENT_COUNTER, function (count, id, roomId) {
  return (0, _immutable.fromJS)({
    count: count,
    id: id,
    roomId: roomId
  });
});
exports.onCounterDecrement = onCounterDecrement;
var onCounterIncrement = (0, _reduxActions.createAction)(ON_INCREMENT_COUNTER, function (count, id, roomId) {
  return (0, _immutable.fromJS)({
    count: count,
    id: id,
    roomId: roomId
  });
});
exports.onCounterIncrement = onCounterIncrement;
var onCounterInit = (0, _reduxActions.createAction)(ON_INIT_COUNTER, function (data) {
  return (0, _immutable.fromJS)({
    data: data
  });
});
exports.onCounterInit = onCounterInit;