"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateLocation = exports.validateAllLocations = exports.validateAllCalendars = exports.toggleSubmitLoader = exports.submitDataToDotREZ = exports.showGuidanceMessage = exports.setLocationAndScheduleDates = exports.setFlightSearchConfig = exports.setFlightSearch = exports.selectFlightType = exports.santizeDatesFromQuery = exports.sanitizeDatesFromSchedule = exports.resetValidation = exports.resetGuidanceMessage = exports.resetDates = exports.resetCalendarPricing = exports.resetCalendarDatesAndView = exports.isRouteValid = exports.initialiseLocations = exports.initialiseFlightType = exports.initialiseFlexible = exports.initialiseCounter = exports.initialiseClubJetstar = exports.initialiseCalendars = exports.getScheduleStartDate = exports.getScheduleEndDate = exports.getFinalLocations = exports.getCalendarViewStartDates = exports.fetchCalendarPricing = exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.number.constructor.js");

require("core-js/modules/es6.regexp.search.js");

require("core-js/modules/es6.array.find.js");

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

require("core-js/modules/es6.object.assign.js");

var _immutable = require("immutable");

var _momentEs = _interopRequireDefault(require("moment-es6"));

var _qs = _interopRequireDefault(require("qs"));

var _counters = require("../../../Constants/counters");

var _dates = require("../../../Constants/dates");

var _flightSearch = require("../../../Constants/flightSearch");

var _flightType = require("../../../Constants/flightType");

var actions = _interopRequireWildcard(require("./actions"));

var _operations = require("../../Common/Counter/operations");

var _selectors = require("./selectors");

var _api = require("../../../Utils/api");

var _dates2 = require("../../../Utils/dates");

var _flightsearch = require("../../../Utils/flightsearch");

var _numbers = require("../../../Utils/numbers");

var _strings = require("../../../Utils/strings");

var _validation = require("../../../Utils/validation");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RETURN = _flightType.FLIGHT_TYPE_IDS.RETURN,
    ONE_WAY = _flightType.FLIGHT_TYPE_IDS.ONE_WAY,
    MULTI_CITY = _flightType.FLIGHT_TYPE_IDS.MULTI_CITY;
var ADULTS = _counters.PAX_TYPES.ADULTS,
    CHILDREN = _counters.PAX_TYPES.CHILDREN,
    INFANTS = _counters.PAX_TYPES.INFANTS;
var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;
var addInvalidSector = actions.addInvalidSector,
    fetchingPrices = actions.fetchingPrices,
    fetchingPricesSuccess = actions.fetchingPricesSuccess,
    loadCalendars = actions.loadCalendars,
    onClubJetstarChange = actions.onClubJetstarChange,
    onCounterChange = actions.onCounterChange,
    onCounterInit = actions.onCounterInit,
    onFlexibleChange = actions.onFlexibleChange,
    onFlightSearchConfigSet = actions.onFlightSearchConfigSet,
    onFlightTypeChange = actions.onFlightTypeChange,
    onGuidanceMessageReset = actions.onGuidanceMessageReset,
    onGuidanceMessageShow = actions.onGuidanceMessageShow,
    onInitFlightType = actions.onInitFlightType,
    removeInvalidSector = actions.removeInvalidSector,
    resetCalendarDates = actions.resetCalendarDates,
    resetLocation = actions.resetLocation,
    resetPrices = actions.resetPrices,
    resetSearchLocation = actions.resetSearchLocation,
    setCalendarDates = actions.setCalendarDates,
    setCalendarSchedule = actions.setCalendarSchedule,
    setCalendarViewStartDates = actions.setCalendarViewStartDates,
    setCurrency = actions.setCurrency,
    setInvalidCalendar = actions.setInvalidCalendar,
    setInvalidLocation = actions.setInvalidLocation,
    setLocation = actions.setLocation,
    setPax = actions.setPax,
    toggleFlightSearchSubmitLoader = actions.toggleFlightSearchSubmitLoader;
var defaultDateFormat = (0, _dates2.dateFormatter)(_dates.DEFAULT_DATE_FORMAT);

var resetCalendarPricing = function resetCalendarPricing(sectorId) {
  return function (dispatch) {
    dispatch(resetPrices(sectorId));
  };
};

exports.resetCalendarPricing = resetCalendarPricing;

var initialiseCounter = function initialiseCounter(settings) {
  var extraRules = {
    sales: {
      fareInPairs: Boolean(Number(settings.get('FareInPairs')))
    }
  };
  return (0, _operations.createInitialiseCounter)({
    settings: settings,
    actions: {
      onCounterChange: onCounterChange,
      onCounterInit: onCounterInit
    },
    stateKey: _flightSearch.STATE_KEY,
    counterRules: _flightSearch.TRAVELLERS_COUNTER_RULES,
    extraRules: extraRules
  });
};

exports.initialiseCounter = initialiseCounter;

var selectFlightType = function selectFlightType(val) {
  switch (val) {
    case ONE_WAY:
      return ONE_WAY;

    case MULTI_CITY:
      return MULTI_CITY;

    case RETURN:
    default:
      return RETURN;
  }
};

exports.selectFlightType = selectFlightType;

var initialiseFlexible = function initialiseFlexible() {
  return function (dispatch, getState) {
    var _getState = getState(),
        flightSearch = _getState.flightSearch;

    var query = _qs.default.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    var queryVal = query[_flightSearch.QUERY_KEY.FLEXIBLE];
    var val = (0, _validation.validateFlexibleQuery)(flightSearch, queryVal);
    dispatch(onFlexibleChange(val));
  };
};

exports.initialiseFlexible = initialiseFlexible;

var initialiseClubJetstar = function initialiseClubJetstar() {
  return function (dispatch, getState) {
    var _getState2 = getState(),
        flightSearch = _getState2.flightSearch;

    var query = _qs.default.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    var queryVal = query[_flightSearch.QUERY_KEY.CLUB_JETSTAR];
    var val = (0, _validation.validateClubJetstarQuery)(flightSearch, queryVal);
    dispatch(onClubJetstarChange(val));
  };
};

exports.initialiseClubJetstar = initialiseClubJetstar;

var initialiseFlightType = function initialiseFlightType(labels, configId, multiCityEnabled) {
  return function (dispatch, getState) {
    var labelsFallback = (0, _strings.immutableStringDefaultVal)(labels);

    var _getState3 = getState(),
        flightSearch = _getState3.flightSearch;

    var query = _qs.default.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    var queryVal = query[_flightSearch.QUERY_KEY.FLIGHT_TYPE];
    var val = String((0, _validation.validateFlightTypeQuery)(flightSearch, queryVal)); // TODO temp until multi-city enabled

    var sanitizedVal = val === MULTI_CITY ? RETURN : val;
    var flightTypeLabels = {
      returnFlight: labelsFallback(_flightType.FLIGHT_TYPE_LABELS[RETURN]),
      onewayFlight: labelsFallback(_flightType.FLIGHT_TYPE_LABELS[ONE_WAY]),
      multicityFlight: labelsFallback(_flightType.FLIGHT_TYPE_LABELS[MULTI_CITY])
    };
    dispatch(onInitFlightType(configId, flightTypeLabels, selectFlightType(sanitizedVal), multiCityEnabled));
  };
};

exports.initialiseFlightType = initialiseFlightType;

var resetDates = function resetDates(dispatch) {
  return function (sectorId) {
    dispatch(resetCalendarDates(sectorId));
  };
};

exports.resetDates = resetDates;

var getScheduleStartDate = function getScheduleStartDate(schedule) {
  return function (_ref) {
    var outboundCode = _ref.outboundCode,
        inboundCode = _ref.inboundCode,
        initialDate = _ref.initialDate;
    var startDate = initialDate;
    var outboundSchedule = schedule.getIn(['StartDates', 'ById', outboundCode]);
    var inboundSchedule = schedule.getIn(['StartDates', 'ById', inboundCode]);
    var outboundScheduleStartDate = outboundSchedule && outboundSchedule.find(function (item) {
      return item.get('Key') === inboundCode;
    });
    var inboundScheduleStartDate = inboundSchedule && inboundSchedule.find(function (item) {
      return item.get('Key') === outboundCode;
    });

    if (outboundScheduleStartDate) {
      var outboundStartDate = (0, _momentEs.default)(outboundScheduleStartDate.get('StartDate'));
      startDate = outboundStartDate && outboundStartDate.isAfter(startDate, 'day') ? outboundStartDate : startDate;
    }

    if (inboundScheduleStartDate) {
      var inboundStartDate = (0, _momentEs.default)(inboundScheduleStartDate.get('StartDate'));
      startDate = inboundStartDate && inboundStartDate.isAfter(startDate, 'day') ? inboundStartDate : startDate;
    }

    return defaultDateFormat(startDate);
  };
};

exports.getScheduleStartDate = getScheduleStartDate;

var getScheduleEndDate = function getScheduleEndDate(schedule) {
  return function (_ref2) {
    var outboundCode = _ref2.outboundCode,
        inboundCode = _ref2.inboundCode,
        initialDate = _ref2.initialDate,
        _ref2$fareCache = _ref2.fareCache,
        fareCache = _ref2$fareCache === void 0 ? true : _ref2$fareCache;
    var endDate = initialDate;
    var outboundSchedule = schedule.getIn(['EndDates', 'ById', outboundCode]);
    var inboundSchedule = schedule.getIn(['EndDates', 'ById', inboundCode]);
    var outboundScheduleEndDate = outboundSchedule && outboundSchedule.find(function (item) {
      return item.get('Key') === inboundCode;
    });
    var inboundScheduleEndDate = inboundSchedule && inboundSchedule.find(function (item) {
      return item.get('Key') === outboundCode;
    });

    if (outboundScheduleEndDate) {
      var outboundEndDate = fareCache ? (0, _momentEs.default)(outboundScheduleEndDate.get('EndDate')) : null;
      endDate = outboundEndDate && endDate.isAfter(outboundEndDate, 'day') ? outboundEndDate : endDate;
    }

    if (inboundScheduleEndDate) {
      var inboundStartDate = fareCache ? (0, _momentEs.default)(inboundScheduleEndDate.get('EndDate')) : null;
      endDate = inboundStartDate && endDate.isAfter(inboundStartDate, 'day') ? inboundStartDate : endDate;
    }

    return defaultDateFormat(endDate);
  };
};

exports.getScheduleEndDate = getScheduleEndDate;

var sanitizeDatesFromSchedule = function sanitizeDatesFromSchedule(scheduleStart, scheduleEnd, outbound, inbound, sectorId) {
  return function (dispatch, getState) {
    var outboundLimited = outbound && outbound.isSameOrAfter(scheduleStart, 'day') && outbound.isSameOrBefore(scheduleEnd, 'day') ? outbound.format(_dates.DEFAULT_DATE_FORMAT) : null;
    var inboundLimited = inbound && inbound.isSameOrAfter(scheduleStart, 'day') && inbound.isSameOrBefore(scheduleEnd, 'day') ? inbound.format(_dates.DEFAULT_DATE_FORMAT) : null;
    var resettingDates = resetDates(dispatch);

    if (!outboundLimited) {
      resettingDates(sectorId);
    } else {
      dispatch(setCalendarDates(sectorId, (0, _immutable.fromJS)({
        outbound: {
          date: outboundLimited
        },
        inbound: {
          date: inboundLimited
        }
      })));
    }
  };
};

exports.sanitizeDatesFromSchedule = sanitizeDatesFromSchedule;

var setLocationAndScheduleDates = function setLocationAndScheduleDates(sectorId, direction, name, code, configId) {
  return function (dispatch, getState) {
    dispatch(setLocation(sectorId, direction, name, code));

    var _getState4 = getState(),
        flightSchedule = _getState4.flightSchedule,
        config = _getState4.config,
        flightSearch = _getState4.flightSearch,
        schema = _getState4.schema;

    var advancedDays = schema.getIn(['data', 'config', configId, 'SearchFlightsCalendarDaysInAdvance']) || 401;
    var scheduleStartDate = getScheduleStartDate(flightSchedule.get('data'));
    var scheduleEndDate = getScheduleEndDate(flightSchedule.get('data'));
    var locations = flightSearch.locations,
        calendars = flightSearch.calendars;
    var route = locations.get(sectorId);
    var outboundCode = route.getIn(['outbound', 'code']);
    var inboundCode = route.getIn(['inbound', 'code']);
    var start = (0, _momentEs.default)();
    var end = start.clone().add(advancedDays, 'day');
    var outbound = defaultDateFormat(calendars.getIn(['sectors', sectorId, 'outbound', 'selectedDate', 'date']) || null);
    var inbound = defaultDateFormat(calendars.getIn(['sectors', sectorId, 'inbound', 'selectedDate', 'date']) || null);
    var originCurrencyCode = (0, _selectors.getFlightSearchCurrencyCode)(flightSearch, flightSchedule);
    var originCurrencyModel = config.getIn(['data', 'currencies', originCurrencyCode]);

    if (originCurrencyModel) {
      var symbol = "<span class='currency'>".concat(originCurrencyModel.get('Symbol'), "</span>"); //TODO: get format from JR-10317 API update.

      var currencyFormat = (0, _strings.format)(originCurrencyModel.get('Pattern'), '%s', '%v');
      var precision = 0; // can't use sitecore settings until we figure out where is the source of truth

      dispatch(setCurrency(symbol, currencyFormat, precision));
    }

    if (outboundCode && inboundCode) {
      dispatch(onGuidanceMessageShow(sectorId));
    }

    if (start.format(_dates.DEFAULT_DATE_FORMAT) !== calendars.getIn(['sectors', sectorId, 'schedule', 'startDate']) || end.format(_dates.DEFAULT_DATE_FORMAT) !== calendars.getIn(['sectors', sectorId, 'schedule', 'endDate'])) {
      var startDate = scheduleStartDate({
        outboundCode: outboundCode,
        inboundCode: inboundCode,
        initialDate: start
      });
      var endDate = scheduleEndDate({
        outboundCode: outboundCode,
        inboundCode: inboundCode,
        initialDate: end
      });
      var calendarEndDate = scheduleEndDate({
        outboundCode: outboundCode,
        inboundCode: inboundCode,
        initialDate: end,
        fareCache: false
      });
      dispatch(setCalendarSchedule(sectorId, (0, _immutable.fromJS)({
        startDate: startDate,
        endDate: endDate,
        calendarEndDate: calendarEndDate
      }))); // limit dates to within schedule range

      dispatch(sanitizeDatesFromSchedule(startDate, endDate, outbound, inbound, sectorId));
    }
  };
};

exports.setLocationAndScheduleDates = setLocationAndScheduleDates;

var santizeDatesFromQuery = function santizeDatesFromQuery(outbound, inbound, flightType, SearchFlightsCalendarDaysInAdvance) {
  var maxCalendarEndDate = (0, _momentEs.default)().add(SearchFlightsCalendarDaysInAdvance, 'days');
  var isOutboundValid = outbound && outbound.isSameOrAfter((0, _momentEs.default)(), 'day') && outbound.isSameOrBefore(maxCalendarEndDate);
  var isInboundValid = inbound && inbound.isSameOrAfter(outbound, 'day') && inbound.isSameOrAfter((0, _momentEs.default)(), 'day') && inbound.isSameOrBefore(maxCalendarEndDate);

  if (isOutboundValid && (flightType === ONE_WAY || flightType === RETURN && !isInboundValid)) {
    return (0, _immutable.fromJS)({
      outbound: {
        date: outbound
      },
      inbound: {
        date: null
      }
    });
  }

  if (flightType === RETURN && isOutboundValid && isInboundValid) {
    return (0, _immutable.fromJS)({
      outbound: {
        date: outbound
      },
      inbound: {
        date: inbound
      }
    });
  }

  return (0, _immutable.fromJS)({
    outbound: {
      date: null
    },
    inbound: {
      date: null
    }
  });
};

exports.santizeDatesFromQuery = santizeDatesFromQuery;

var getCalendarViewStartDates = function getCalendarViewStartDates(selectedDates) {
  var outbound = selectedDates.get('outbound') ? selectedDates.get('outbound').get('date') : null;
  var inbound = selectedDates.get('inbound') ? selectedDates.get('inbound').get('date') : null;
  var isOutboundStartOfMonthValid = outbound && (0, _momentEs.default)((0, _dates2.getFirstDayOfMonth)(outbound)()).isSameOrAfter((0, _momentEs.default)(), 'day'); // for defining calendar start dates, not user fly dates

  var startDateOfOutboundCalendarView = outbound && isOutboundStartOfMonthValid ? (0, _dates2.getFirstDayOfMonth)(outbound)() : (0, _momentEs.default)().format(_dates.DEFAULT_DATE_FORMAT);

  var setStartDateOfInboundCalendarView = function setStartDateOfInboundCalendarView() {
    switch (true) {
      case outbound && inbound && (0, _momentEs.default)(inbound).format(_dates.DEFAULT_SCHEDULE_MONTH_FORMAT) === (0, _momentEs.default)(outbound).format(_dates.DEFAULT_SCHEDULE_MONTH_FORMAT):
        return (0, _momentEs.default)(outbound).format(_dates.DEFAULT_DATE_FORMAT);

      case inbound !== null:
        return (0, _dates2.getFirstDayOfMonth)(inbound)();

      case outbound !== null:
        return (0, _momentEs.default)(outbound).format(_dates.DEFAULT_DATE_FORMAT);

      default:
        return (0, _momentEs.default)().format(_dates.DEFAULT_DATE_FORMAT);
    }
  };

  return (0, _immutable.fromJS)({
    outbound: {
      startDate: startDateOfOutboundCalendarView
    },
    inbound: {
      startDate: setStartDateOfInboundCalendarView()
    }
  });
};

exports.getCalendarViewStartDates = getCalendarViewStartDates;

var initialiseCalendars = function initialiseCalendars(sectorId, settings) {
  return function (dispatch, getState) {
    var _getState5 = getState(),
        flightSearch = _getState5.flightSearch;

    var calendars = flightSearch.calendars,
        sectors = flightSearch.sectors;
    var sectorIds = sectors.get('sectorIds');

    if (!sectorIds.size || !sectorId) {
      return;
    }

    var calendarDaysInAdvance = settings.get('SearchFlightsCalendarDaysInAdvance');
    var resettingDates = resetDates(dispatch);

    var query = _qs.default.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    var outboundDateQuery = (0, _dates2.dateFormatterToString)(query[_flightSearch.QUERY_KEY.DEPARTURE_DATE], _dates.DEFAULT_DATE_FORMAT);
    var inboundDateQuery = (0, _dates2.dateFormatterToString)(query[_flightSearch.QUERY_KEY.RETURN_DATE], _dates.DEFAULT_DATE_FORMAT);
    var flightTypeQuery = query[_flightSearch.QUERY_KEY.FLIGHT_TYPE];
    var currentFlightType = String((0, _validation.validateFlightTypeQuery)(flightSearch, flightTypeQuery));
    var outbound = defaultDateFormat(outboundDateQuery) || null;
    var inbound = defaultDateFormat(inboundDateQuery) || null; // validate query param dates

    var validateQueryDates = santizeDatesFromQuery(outbound, inbound, flightTypeQuery, calendarDaysInAdvance);
    var sanitizedOutbound = validateQueryDates.get('outbound').get('date');
    var sanitizedInbound = validateQueryDates.get('inbound').get('date');

    if (!calendars.get('sectors').size || calendars.getIn(['sectors', sectorId, 'outbound', 'selectedDate', 'date']) === null) {
      dispatch(loadCalendars(sectorId, 'outbound'));
      dispatch(setCalendarViewStartDates(sectorId, getCalendarViewStartDates(validateQueryDates)));
    }

    switch (currentFlightType) {
      case RETURN:
        if (outbound) {
          dispatch(setCalendarDates(sectorId, (0, _immutable.fromJS)({
            outbound: {
              date: sanitizedOutbound && sanitizedOutbound.format(_dates.DEFAULT_DATE_FORMAT)
            },
            inbound: {
              date: sanitizedInbound && sanitizedInbound.format(_dates.DEFAULT_DATE_FORMAT)
            }
          })));
          break;
        }

        resettingDates(sectorId);
        break;

      case ONE_WAY:
        if (outbound) {
          dispatch(setCalendarDates(sectorId, (0, _immutable.fromJS)({
            outbound: {
              date: sanitizedOutbound && sanitizedOutbound.format(_dates.DEFAULT_DATE_FORMAT)
            },
            inbound: {
              date: null
            }
          })));
          break;
        }

        resettingDates(sectorId);
        break;

      default:
        resettingDates(sectorId);
    }
  };
};

exports.initialiseCalendars = initialiseCalendars;

var resetCalendarDatesAndView = function resetCalendarDatesAndView(sectorId) {
  return function (dispatch) {
    var resettingDates = resetDates(dispatch);
    resettingDates(sectorId);
    dispatch(setCalendarViewStartDates(sectorId, getCalendarViewStartDates((0, _immutable.fromJS)({
      outbound: {
        date: null
      },
      inbound: {
        date: null
      }
    }))));
  };
};

exports.resetCalendarDatesAndView = resetCalendarDatesAndView;

var validateAllCalendars = function validateAllCalendars() {
  return function (dispatch, getState) {
    var _getState6 = getState(),
        flightSearch = _getState6.flightSearch;

    var invalidSectorId = (0, _selectors.getCalendarError)(flightSearch);

    if (!(0, _selectors.getLocationsError)(flightSearch) && invalidSectorId) {
      dispatch(setInvalidCalendar(invalidSectorId, true));
      dispatch(addInvalidSector(invalidSectorId));
    }
  };
};

exports.validateAllCalendars = validateAllCalendars;

var isRouteValid = function isRouteValid(origin, destination) {
  return function (dispatch, getState) {
    var _getState7 = getState(),
        flightSchedule = _getState7.flightSchedule;

    return flightSchedule.hasIn(['data', 'Airports', 'ById', origin]) && flightSchedule.getIn(['data', 'Airports', 'ById', origin, 'Routes']).includes(destination);
  };
};

exports.isRouteValid = isRouteValid;

var validateLocation = function validateLocation(sectorId, cb) {
  return function (dispatch, getState) {
    var _getState8 = getState(),
        flightSearch = _getState8.flightSearch;

    var currentSector = flightSearch.locations.get(sectorId);
    var isOutboundInvalid = typeof currentSector.getIn(['outbound', 'code']) !== 'string';
    var isInboundValid = typeof currentSector.getIn(['inbound', 'code']) !== 'string';

    if (isOutboundInvalid) {
      dispatch(setInvalidLocation(sectorId, 'outbound', true));
      dispatch(addInvalidSector(sectorId));
      cb(isOutboundInvalid);
      return;
    }

    if (isInboundValid) {
      dispatch(setInvalidLocation(sectorId, 'inbound', true));
      dispatch(addInvalidSector(sectorId));
      cb(isInboundValid);
      return;
    }

    cb();
  };
};

exports.validateLocation = validateLocation;

var validateAllLocations = function validateAllLocations() {
  return function (dispatch, getState) {
    var _getState9 = getState(),
        flightSearch = _getState9.flightSearch;

    var invalidSectorId = (0, _selectors.getLocationsError)(flightSearch);
    var location = flightSearch.locations.get(invalidSectorId);

    if (invalidSectorId) {
      if (!location.getIn(['outbound', 'code'])) {
        dispatch(setInvalidLocation(invalidSectorId, 'outbound', true));
        dispatch(addInvalidSector(invalidSectorId));
      }

      if (!location.getIn(['inbound', 'code'])) {
        dispatch(setInvalidLocation(invalidSectorId, 'inbound', true));
        dispatch(addInvalidSector(invalidSectorId));
      }
    }
  };
};

exports.validateAllLocations = validateAllLocations;

var resetValidation = function resetValidation() {
  return function (dispatch, getState) {
    var sectors = getState().flightSearch.sectors;
    var sectorIds = sectors.get('sectorIds');
    sectorIds.forEach(function (sectorId) {
      dispatch(removeInvalidSector(sectorId));
      dispatch(setInvalidLocation(sectorId, 'outbound', false));
      dispatch(setInvalidLocation(sectorId, 'inbound', false));
      dispatch(setInvalidCalendar(sectorId, false));
    });
  };
};

exports.resetValidation = resetValidation;

var transformPricingResponseData = function transformPricingResponseData(responseData, route, settings) {
  if (!responseData) return null;
  var accuracyPercent = responseData.accuracyPercent,
      feesIncluded = responseData.feesIncluded,
      includeMember = responseData.includeMember,
      correlationId = responseData.correlationId;
  var inaccuratePrice = accuracyPercent < settings.get('AccuracyThreshold');
  var immutableData = (0, _immutable.fromJS)(Object.assign({}, responseData || {}));
  var pricing = immutableData.getIn(['routes', route, 'flights']);
  var initialData = (0, _immutable.fromJS)({
    correlationId: correlationId,
    accuracyPercent: accuracyPercent,
    feesIncluded: feesIncluded,
    inaccuratePrice: inaccuratePrice,
    includeMember: includeMember
  });
  var data = initialData.set('days', pricing);
  return data;
};

var fetchCalendarPricing = function fetchCalendarPricing(url, sectorId, direction, startDate, route, settings) {
  return function (dispatch, getState) {
    dispatch(fetchingPrices(sectorId, direction));
    return (0, _api.fetchApi)(url).then(function (response) {
      var starterData = response.data.length > 0 ? transformPricingResponseData(response.data[0], route, settings) : transformPricingResponseData(response.data, route, settings);
      var memberData = response.data.length > 1 ? transformPricingResponseData(response.data[1], route, settings) : null;
      dispatch(fetchingPricesSuccess(sectorId, direction, startDate, starterData, memberData));
      return [starterData, memberData];
    }).catch(function (error) {
      var errorString = JSON.stringify(error);
      throw Error("Pricing Service API: Status: ".concat(error.response ? error.response.status : '', ", Error: ").concat(errorString, ", URL: ").concat(url));
    });
  };
};

exports.fetchCalendarPricing = fetchCalendarPricing;

var resetGuidanceMessage = function resetGuidanceMessage(sectorId) {
  return function (dispatch) {
    dispatch(onGuidanceMessageReset(sectorId));
  };
};

exports.resetGuidanceMessage = resetGuidanceMessage;

var showGuidanceMessage = function showGuidanceMessage(sectorId) {
  return function (dispatch) {
    dispatch(onGuidanceMessageShow(sectorId));
  };
};

exports.showGuidanceMessage = showGuidanceMessage;

var setFlightSearch = function setFlightSearch(sectorId, configId, data) {
  return function (dispatch) {
    var _count;

    var originName = data.get('originName') || '';
    var originIata = data.get('originIata') || 'MEL';
    var destinationName = data.get('destinationName') || '';
    var destinationIata = data.get('destinationIata') || 'SYD';
    var flightType = data.get('flightType') || RETURN;
    /*eslint radix: ["error", "as-needed"]*/

    var adults = parseInt(data.get('adults')) || 1;
    var children = parseInt(data.get('children')) || 0;
    var infants = parseInt(data.get('infants')) || 0;

    var departureDate = data.get('departureDate') || _momentEs.default.format(_dates.DEFAULT_DATE_FORMAT);

    var departureViewDate = departureDate;
    var returnDate = data.get('returnDate') || '';
    var returnViewDate = flightType === RETURN ? data.get('returnDate') || _momentEs.default.format(_dates.DEFAULT_DATE_FORMAT) : data.get('departureDate') || _momentEs.default.format(_dates.DEFAULT_DATE_FORMAT);
    var counterData = (0, _immutable.fromJS)({
      count: (_count = {}, (0, _defineProperty2.default)(_count, ADULTS, adults), (0, _defineProperty2.default)(_count, CHILDREN, children), (0, _defineProperty2.default)(_count, INFANTS, infants), _count),
      errors: {
        adults: {
          min: 0,
          max: 0
        },
        children: {
          min: 1,
          max: 0
        },
        infants: {
          min: 1,
          max: 0
        },
        total: 0,
        minTotal: 0,
        adultsInfantsRatio: 0,
        adultsChildrenRatio: 0,
        fareInPairs: 0
      },
      total: (0, _numbers.sumOfAll)(adults, children, infants)
    });
    var datesOneWayData = (0, _immutable.fromJS)({
      outbound: {
        date: (0, _momentEs.default)(departureDate).format(_dates.DEFAULT_DATE_FORMAT)
      },
      inbound: {
        date: null
      }
    });
    var datesReturnData = (0, _immutable.fromJS)({
      outbound: {
        date: (0, _momentEs.default)(departureDate).format(_dates.DEFAULT_DATE_FORMAT)
      },
      inbound: {
        date: (0, _momentEs.default)(returnDate).format(_dates.DEFAULT_DATE_FORMAT)
      }
    });
    var datesOneWayViewData = (0, _immutable.fromJS)({
      outbound: {
        date: (0, _momentEs.default)(departureViewDate).format(_dates.DEFAULT_DATE_FORMAT)
      },
      inbound: null
    });
    var datesReturnViewData = (0, _immutable.fromJS)({
      outbound: {
        date: (0, _momentEs.default)(departureViewDate).format(_dates.DEFAULT_DATE_FORMAT)
      },
      inbound: {
        date: (0, _momentEs.default)(returnViewDate).format(_dates.DEFAULT_DATE_FORMAT)
      }
    });
    dispatch(onFlightTypeChange(configId, flightType));
    dispatch(setPax(counterData));
    dispatch(setLocationAndScheduleDates(sectorId, OUTBOUND, originName, originIata, configId));
    dispatch(setLocationAndScheduleDates(sectorId, INBOUND, destinationName, destinationIata, configId));
    dispatch(setCalendarDates(sectorId, flightType === RETURN ? datesReturnData : datesOneWayData));
    dispatch(setCalendarViewStartDates(sectorId, getCalendarViewStartDates(flightType === RETURN ? datesReturnViewData : datesOneWayViewData)));
    dispatch(resetValidation());
  };
};

exports.setFlightSearch = setFlightSearch;

var setFlightSearchConfig = function setFlightSearchConfig(sourceIdentifier) {
  return function (dispatch) {
    dispatch(onFlightSearchConfigSet(sourceIdentifier));
  };
};

exports.setFlightSearchConfig = setFlightSearchConfig;

var getFinalLocations = function getFinalLocations(sectorId) {
  return function (dispatch, getState) {
    var _getState10 = getState(),
        flightSchedule = _getState10.flightSchedule,
        userLocation = _getState10.userLocation;

    var flightScheduleIds = flightSchedule.getIn(['data', 'Airports', 'ById']);
    var userLoc = userLocation.get('data');
    var userLocPort = userLoc ? userLoc.get('Key') : null;
    var query = (0, _immutable.fromJS)(_qs.default.parse(window.location.search, {
      ignoreQueryPrefix: true
    }));
    var queryDestination = query.get(_flightSearch.QUERY_KEY.DESTINATION);
    var queryOrigin = query.get(_flightSearch.QUERY_KEY.ORIGIN);
    var pageDataElement = document.getElementById(_flightSearch.MPS_PREPOPULATION_ELEMENT_ID);
    var pageDestination = pageDataElement ? pageDataElement.getAttribute(_flightSearch.DATA_KEY.DATA_DESTINATION) : '';
    var pageOrigin = pageDataElement ? pageDataElement.getAttribute(_flightSearch.DATA_KEY.DATA_ORIGIN) : '';
    var hasValidQueryOrigin = queryOrigin && flightScheduleIds && flightScheduleIds.has(queryOrigin);

    if (hasValidQueryOrigin) {
      var hasValidQueryCityPair = queryDestination && dispatch(isRouteValid(queryOrigin, queryDestination));

      if (!hasValidQueryCityPair) {
        dispatch(resetCalendarDatesAndView(sectorId));
        dispatch(resetLocation(sectorId, INBOUND));
        dispatch(resetSearchLocation(sectorId, INBOUND));
      }

      return (0, _immutable.fromJS)({
        origin: queryOrigin,
        destination: hasValidQueryCityPair ? queryDestination : null
      });
    }

    var hasValidQueryDestination = queryDestination && flightScheduleIds && flightScheduleIds.has(queryDestination);

    if (hasValidQueryDestination) {
      if (dispatch(isRouteValid(userLocPort, queryDestination))) {
        return (0, _immutable.fromJS)({
          origin: userLocPort,
          destination: queryDestination
        });
      }
    }

    dispatch(resetCalendarDatesAndView(sectorId));
    dispatch(resetLocation(sectorId, OUTBOUND));
    dispatch(resetSearchLocation(sectorId, OUTBOUND));
    dispatch(resetLocation(sectorId, INBOUND));
    dispatch(resetSearchLocation(sectorId, INBOUND));
    var hasValidPagePort2Port = pageOrigin && pageDestination && dispatch(isRouteValid(pageOrigin, pageDestination));

    if (hasValidPagePort2Port) {
      return (0, _immutable.fromJS)({
        origin: pageOrigin,
        destination: pageDestination
      });
    }

    var hasValidPageDestination = pageDestination && dispatch(isRouteValid(userLocPort, pageDestination));
    return (0, _immutable.fromJS)({
      origin: userLocPort,
      destination: hasValidPageDestination ? pageDestination : null
    });
  };
};

exports.getFinalLocations = getFinalLocations;

var initialiseLocations = function initialiseLocations(sectorId, configId) {
  return function (dispatch, getState) {
    var _getState11 = getState(),
        flightSchedule = _getState11.flightSchedule;

    var finalLocations = dispatch(getFinalLocations(sectorId));
    var finalOrigin = finalLocations.get('origin');
    var finalDestination = finalLocations.get('destination');

    if (finalOrigin) {
      var finalOriginName = flightSchedule.getIn(['data', 'Airports', 'ById', finalOrigin, 'Name']);
      dispatch(setLocationAndScheduleDates(sectorId, OUTBOUND, finalOriginName, finalOrigin, configId));
    }

    if (finalDestination) {
      var finalDestinationName = flightSchedule.getIn(['data', 'Airports', 'ById', finalDestination, 'Name']);
      dispatch(setLocationAndScheduleDates(sectorId, INBOUND, finalDestinationName, finalDestination, configId));
    }
  };
};

exports.initialiseLocations = initialiseLocations;

var submitDataToDotREZ = function submitDataToDotREZ() {
  return function (dispatch, getState) {
    var _getState12 = getState(),
        flightSearch = _getState12.flightSearch,
        flightSchedule = _getState12.flightSchedule,
        siteConfig = _getState12.config,
        schema = _getState12.schema;

    var isLoading = flightSearch.isLoading,
        flightSearchConfig = flightSearch.config;
    var flightSearchSchema = schema.get(flightSearchConfig.get('id'));
    if (isLoading) return;
    dispatch(resetValidation());
    dispatch(validateAllLocations());
    dispatch(validateAllCalendars());
    var isValid = (0, _selectors.isLocationsValid)(flightSearch) && (0, _selectors.isCalendarValid)(flightSearch);

    if (isValid) {
      var submitModel = (0, _flightsearch.getSubmitModel)(flightSearch, flightSchedule, flightSearchSchema, siteConfig);
      var bookingUrl = (0, _flightsearch.createBookingUrl)((0, _flightsearch.getBookingURL)(flightSearchSchema), submitModel);

      if (bookingUrl) {
        dispatch(toggleFlightSearchSubmitLoader(true));
        (0, _flightsearch.triggerFareCacheInsightTrackEvent)(submitModel.toJS());
        window.location.href = bookingUrl;
      }
    }
  };
};

exports.submitDataToDotREZ = submitDataToDotREZ;

var toggleSubmitLoader = function toggleSubmitLoader(loading) {
  return function (dispatch) {
    dispatch(toggleFlightSearchSubmitLoader(loading));
  };
};

exports.toggleSubmitLoader = toggleSubmitLoader;

var _default = _objectSpread(_objectSpread({}, actions), {}, {
  defaultDateFormat: defaultDateFormat,
  fetchCalendarPricing: fetchCalendarPricing,
  getFinalLocations: getFinalLocations,
  initialiseCalendars: initialiseCalendars,
  initialiseClubJetstar: initialiseClubJetstar,
  initialiseCounter: initialiseCounter,
  initialiseFlexible: initialiseFlexible,
  initialiseFlightType: initialiseFlightType,
  initialiseLocations: initialiseLocations,
  isRouteValid: isRouteValid,
  resetCalendarDatesAndView: resetCalendarDatesAndView,
  resetCalendarPricing: resetCalendarPricing,
  resetGuidanceMessage: resetGuidanceMessage,
  resetValidation: resetValidation,
  setFlightSearch: setFlightSearch,
  setFlightSearchConfig: setFlightSearchConfig,
  setLocationAndScheduleDates: setLocationAndScheduleDates,
  submitDataToDotREZ: submitDataToDotREZ,
  showGuidanceMessage: showGuidanceMessage,
  validateAllCalendars: validateAllCalendars,
  validateAllLocations: validateAllLocations,
  validateLocation: validateLocation,
  toggleSubmitLoader: toggleSubmitLoader
});

exports.default = _default;