"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _types = _interopRequireDefault(require("../Ducks/FlightSchedule/types"));

var _localStorage = require("../../Utils/localStorage");

var _flightschedule = require("../../Utils/flightschedule");

var flightSchedule = function flightSchedule(store) {
  return function (next) {
    return function (action) {
      var type = action.type,
          payload = action.payload;

      if (type === _types.default.FETCHING_SUCCESS_FLIGHT_SCHEDULE) {
        var payloadData = payload.get('data');
        var data = payloadData.get('schedule');
        var key = payloadData.get('storageKey');
        var processedData = (0, _flightschedule.removeExpiredRoutes)(data);
        (0, _localStorage.saveFlightSchedule)({
          key: key,
          data: data
        });
        return next({
          type: type,
          payload: payload.set('data', processedData)
        });
      }

      if (type === _types.default.FETCHING_LOCAL_SUCCESS_FLIGHT_SCHEDULE) {
        var _payloadData = payload.get('data');

        var _processedData = (0, _flightschedule.removeExpiredRoutes)(_payloadData);

        return next({
          type: type,
          payload: payload.set('data', _processedData)
        });
      }

      return next(action);
    };
  };
};

var _default = flightSchedule;
exports.default = _default;