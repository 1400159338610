"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TABLE_ATTRIBUTES_TYPE = exports.ROW_TYPES = void 0;
// row types
var BODY = 'body';
var HEADER = 'header';
var FOOTER = 'footer';
var ROW_TYPES = {
  BODY: BODY,
  HEADER: HEADER,
  FOOTER: FOOTER
}; //child type

exports.ROW_TYPES = ROW_TYPES;
var TABLE_ATTRIBUTES_TYPE = 'comparison-table-attributes';
exports.TABLE_ATTRIBUTES_TYPE = TABLE_ATTRIBUTES_TYPE;