"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.search.js");

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

require("core-js/modules/es6.object.assign.js");

require("core-js/modules/es6.regexp.replace.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _qs = _interopRequireDefault(require("qs"));

var _immutable = require("immutable");

var _flightSearch = require("../../Constants/flightSearch");

var _types = _interopRequireWildcard(require("../Ducks/FlightSearch/types"));

var _types2 = _interopRequireDefault(require("../Common/Counter/types"));

var _Store = require("../Store");

var _analytics = require("../../Utils/analytics");

var _selectors = require("../Ducks/FlightSearch/selectors");

var _strings = require("../../Utils/strings");

var _dates = require("../../Utils/dates");

var _object = require("../../Utils/object");

var _flightType = require("../../Constants/flightType");

var _dates2 = require("../../Constants/dates");

var _counters = _interopRequireDefault(require("./counters"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH = _types.default.ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH,
    ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH = _types.default.ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH,
    SET_LOCATION_FLIGHT_SEARCH = _types.default.SET_LOCATION_FLIGHT_SEARCH,
    RESET_LOCATION_FLIGHT_SEARCH = _types.default.RESET_LOCATION_FLIGHT_SEARCH,
    SET_CALENDAR_DATES = _types.default.SET_CALENDAR_DATES,
    RESET_CALENDAR_DATES = _types.default.RESET_CALENDAR_DATES,
    ONCHANGE_FLEXIBLE_FLIGHT_SEARCH = _types.default.ONCHANGE_FLEXIBLE_FLIGHT_SEARCH,
    ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH = _types.default.ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH;
var ON_INCREMENT_COUNTER = _types2.default.ON_INCREMENT_COUNTER,
    ON_DECREMENT_COUNTER = _types2.default.ON_DECREMENT_COUNTER,
    ONCHANGE_COUNTER = _types2.default.ONCHANGE_COUNTER;

var _getCountersMiddlewar = (0, _counters.default)({
  namespace: _types.namespace,
  stateKey: _flightSearch.STATE_KEY,
  counterRules: _flightSearch.TRAVELLERS_COUNTER_RULES
}),
    validateTravellers = _getCountersMiddlewar.validateCounters;
/*
History
*/


var travellers = function travellers(action) {
  var ADULTS = _flightSearch.QUERY_KEY.ADULTS,
      CHILDREN = _flightSearch.QUERY_KEY.CHILDREN,
      INFANTS = _flightSearch.QUERY_KEY.INFANTS;
  var _action$payload = action.payload,
      id = _action$payload.id,
      count = _action$payload.count;

  switch (id) {
    case 'adults':
      return (0, _defineProperty2.default)({}, ADULTS, count);

    case 'children':
      return (0, _defineProperty2.default)({}, CHILDREN, count);

    case 'infants':
      return (0, _defineProperty2.default)({}, INFANTS, count);

    default:
      return {};
  }
};

var flightType = function flightType(action, state) {
  var flightSearch = state.flightSearch;
  var type = action.type;
  var FLIGHT_TYPE = _flightSearch.QUERY_KEY.FLIGHT_TYPE,
      RETURN_DATE = _flightSearch.QUERY_KEY.RETURN_DATE;
  var inboundDate = (0, _selectors.getCalendarDate)(flightSearch, 'inbound');
  var selectedFlightType = action.payload.get('selected');

  if (selectedFlightType === _flightType.FLIGHT_TYPE_IDS.ONE_WAY) {
    var _ref4;

    return _ref4 = {}, (0, _defineProperty2.default)(_ref4, FLIGHT_TYPE, selectedFlightType), (0, _defineProperty2.default)(_ref4, RETURN_DATE, null), _ref4;
  }

  if (type === ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH) {
    var _ref5;

    return _ref5 = {}, (0, _defineProperty2.default)(_ref5, FLIGHT_TYPE, selectedFlightType), (0, _defineProperty2.default)(_ref5, RETURN_DATE, inboundDate && inboundDate.get('date')), _ref5;
  }

  return (0, _defineProperty2.default)({}, FLIGHT_TYPE, action.payload.get('selected'));
};

var clubjetstar = function clubjetstar(action) {
  return (0, _defineProperty2.default)({}, _flightSearch.QUERY_KEY.CLUB_JETSTAR, action.payload.data);
};

var flexible = function flexible(action) {
  return (0, _defineProperty2.default)({}, _flightSearch.QUERY_KEY.FLEXIBLE, action.payload.data);
};

var locations = function locations(action) {
  var _ref9;

  var ORIGIN = _flightSearch.QUERY_KEY.ORIGIN,
      DESTINATION = _flightSearch.QUERY_KEY.DESTINATION,
      DEPARTURE_DATE = _flightSearch.QUERY_KEY.DEPARTURE_DATE,
      RETURN_DATE = _flightSearch.QUERY_KEY.RETURN_DATE;
  var _action$payload2 = action.payload,
      direction = _action$payload2.direction,
      code = _action$payload2.code;

  switch (direction) {
    case 'outbound':
      return _ref9 = {}, (0, _defineProperty2.default)(_ref9, ORIGIN, code), (0, _defineProperty2.default)(_ref9, DEPARTURE_DATE, null), (0, _defineProperty2.default)(_ref9, RETURN_DATE, null), _ref9;

    case 'inbound':
      return (0, _defineProperty2.default)({}, DESTINATION, code);

    default:
      return {};
  }
};

var calendar = function calendar(action) {
  var _ref11;

  var DEPARTURE_DATE = _flightSearch.QUERY_KEY.DEPARTURE_DATE,
      RETURN_DATE = _flightSearch.QUERY_KEY.RETURN_DATE,
      FLIGHT_TYPE = _flightSearch.QUERY_KEY.FLIGHT_TYPE;
  var _action$payload3 = action.payload,
      data = _action$payload3.data,
      selectedFlightType = _action$payload3[FLIGHT_TYPE];
  return _ref11 = {}, (0, _defineProperty2.default)(_ref11, DEPARTURE_DATE, data && data.getIn(['outbound', 'date'])), (0, _defineProperty2.default)(_ref11, RETURN_DATE, data && selectedFlightType !== _flightType.FLIGHT_TYPE_IDS.ONE_WAY && data.getIn(['inbound', 'date'])), _ref11;
};

var flightSearch = function flightSearch(query, action, store) {
  var type = action.type;
  var state = store.getState();
  var FLIGHT_TYPE = _flightSearch.QUERY_KEY.FLIGHT_TYPE;

  switch (type) {
    case ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH:
    case ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH:
      return _objectSpread(_objectSpread({}, query), flightType(action, state));

    case ON_INCREMENT_COUNTER(_types.namespace):
    case ON_DECREMENT_COUNTER(_types.namespace):
    case ONCHANGE_COUNTER(_types.namespace):
      return _objectSpread(_objectSpread({}, query), travellers(action));

    case SET_LOCATION_FLIGHT_SEARCH:
    case RESET_LOCATION_FLIGHT_SEARCH:
      return _objectSpread(_objectSpread({}, query), locations(action));

    case SET_CALENDAR_DATES:
    case RESET_CALENDAR_DATES:
      return _objectSpread(_objectSpread({}, query), calendar(_objectSpread(_objectSpread({}, action), {}, {
        payload: _objectSpread(_objectSpread({}, action.payload), {}, (0, _defineProperty2.default)({}, FLIGHT_TYPE, query[FLIGHT_TYPE]))
      })));

    case ONCHANGE_FLEXIBLE_FLIGHT_SEARCH:
      return _objectSpread(_objectSpread({}, query), flexible(action));

    case ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH:
      return _objectSpread(_objectSpread({}, query), clubjetstar(action));

    default:
      return query;
  }
};

var validatePreflightSearch = function validatePreflightSearch(state) {
  var analyticsUtil = new _analytics.Analytics();
  var config = state.config,
      flightSearchData = state.flightSearch,
      schema = state.schema;
  var flightSearchConfig = flightSearchData.config;
  var configId = flightSearchConfig.get('id');
  var settings = schema.get(configId);
  var analyticsStateData = (0, _selectors.getAnalyticsStateData)(flightSearchData, config, settings);
  var hasLocationsAndCalendarsLoaded = (0, _selectors.isLocationsAndCalendarsLoaded)(flightSearchData);
  var isValidTravellers = (0, _selectors.getTotalTravellers)(flightSearchData) > 0;
  var isValidFlightSearch = (0, _selectors.getFlightSearchValidity)(flightSearchData);
  var isValidLocations = (0, _selectors.isLocationsValid)(flightSearchData);
  var isValidCalendar = (0, _selectors.isCalendarValid)(flightSearchData);

  if (hasLocationsAndCalendarsLoaded && isValidTravellers && isValidFlightSearch && isValidLocations && isValidCalendar) {
    analyticsUtil.loaded(analyticsStateData);
  }
};

var historyFlightSearch = function historyFlightSearch(store) {
  return function (next) {
    return function (action) {
      var type = action.type;
      var DEPARTURE_DATE = _flightSearch.QUERY_KEY.DEPARTURE_DATE,
          RETURN_DATE = _flightSearch.QUERY_KEY.RETURN_DATE;
      var query = window.location.search;

      var queryObj = _qs.default.parse(query, {
        ignoreQueryPrefix: true
      });

      var exceptionList = (0, _immutable.fromJS)(_flightSearch.HISTORY_EXCEPTION_LIST);

      if ([ON_INCREMENT_COUNTER(_types.namespace), ON_DECREMENT_COUNTER(_types.namespace), ONCHANGE_COUNTER(_types.namespace), ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH, ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH, SET_LOCATION_FLIGHT_SEARCH, RESET_LOCATION_FLIGHT_SEARCH, RESET_CALENDAR_DATES, SET_CALENDAR_DATES, ONCHANGE_FLEXIBLE_FLIGHT_SEARCH, ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH].includes(type)) {
        var _modifiedDates;

        var newState = flightSearch(queryObj, action, store); //only return qs params that don't exist in exception rules

        var sanitizedQuery = (0, _object.sanitizeMap)((0, _immutable.fromJS)(newState), exceptionList); // query string date format different to application so have to modify

        var modifiedDates = (_modifiedDates = {}, (0, _defineProperty2.default)(_modifiedDates, DEPARTURE_DATE, (0, _dates.dateFormatterToString)(newState[DEPARTURE_DATE], _dates2.DEFAULT_QS_DATE_FORMAT)), (0, _defineProperty2.default)(_modifiedDates, RETURN_DATE, (0, _dates.dateFormatterToString)(newState[RETURN_DATE], _dates2.DEFAULT_QS_DATE_FORMAT)), _modifiedDates);
        var modifiedState = Object.assign(sanitizedQuery.toJS(), modifiedDates);

        var url = _qs.default.stringify(modifiedState, {
          encode: true,
          skipNulls: true,
          addQueryPrefix: true,
          strictNullHandling: true,
          sort: _strings.alphabeticalSort
        });

        if (url) {
          _Store.history.replace(url, modifiedState);
        }

        next(action); // validate if pre-flight search criteria met AFTER store updated

        if ([ON_INCREMENT_COUNTER(_types.namespace), ON_DECREMENT_COUNTER(_types.namespace), ONCHANGE_COUNTER(_types.namespace), ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH, RESET_CALENDAR_DATES, SET_CALENDAR_DATES].includes(type)) {
          return validatePreflightSearch(store.getState());
        }

        return null;
      }

      return next(action);
    };
  };
};

var multiCityRedirect = function multiCityRedirect(store) {
  return function (next) {
    return function (action) {
      var type = action.type;

      if (type === ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH || type === ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH) {
        var payload = action.payload;
        var selectedFlightType = payload.get('selected');

        if (selectedFlightType !== _flightType.FLIGHT_TYPE_IDS['MULTI_CITY']) {
          return next(action);
        }

        var state = store.getState();
        var url = state.schema.getIn(['data', 'config', payload.get('id'), 'MultiCityUrl', 'Url']);

        if (typeof url === 'string' && url !== '') {
          window.location.href = url;
        }
      }

      return next(action);
    };
  };
};

var _default = {
  historyFlightSearch: historyFlightSearch,
  validateTravellers: validateTravellers,
  multiCityRedirect: multiCityRedirect
};
exports.default = _default;