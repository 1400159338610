"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var FETCHING_USER_LOCATION = 'FETCHING_USER_LOCATION';
var FETCHING_ERROR_USER_LOCATION = 'FETCHING_ERROR_USER_LOCATION';
var FETCHING_SUCCESS_USER_LOCATION = 'FETCHING_SUCCESS_USER_LOCATION';
var _default = {
  FETCHING_USER_LOCATION: FETCHING_USER_LOCATION,
  FETCHING_ERROR_USER_LOCATION: FETCHING_ERROR_USER_LOCATION,
  FETCHING_SUCCESS_USER_LOCATION: FETCHING_SUCCESS_USER_LOCATION
};
exports.default = _default;