"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchingLocalFlightScheduleSuccess = exports.fetchingFlightScheduleSuccess = exports.fetchingFlightSchedule = void 0;

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var fetchingFlightSchedule = (0, _reduxActions.createAction)(_types.default.FETCHING_FLIGHT_SCHEDULE, function () {
  return (0, _immutable.Map)({
    isFetching: true
  });
});
exports.fetchingFlightSchedule = fetchingFlightSchedule;
var fetchingFlightScheduleSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_SUCCESS_FLIGHT_SCHEDULE, function (data) {
  return (0, _immutable.Map)({
    isFetching: false
  }).set('data', data);
});
exports.fetchingFlightScheduleSuccess = fetchingFlightScheduleSuccess;
var fetchingLocalFlightScheduleSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_LOCAL_SUCCESS_FLIGHT_SCHEDULE, function (data) {
  return (0, _immutable.Map)({
    isFetching: false
  }).set('data', data);
});
exports.fetchingLocalFlightScheduleSuccess = fetchingLocalFlightScheduleSuccess;