"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.camelizeKeysInMap = void 0;

var _immutable = require("immutable");

var _strings = require("./strings");

// react
// global modules
// types
// components
// constants
// state
// utils
// assets

/**
 * Make Map key from underscore to camelCase
 *
 * @function camelizeKeysInMap
 * @memberof Utils/map
 * @param {Map} map
 * @returns {Map} - return original map with camelCase keys
 */
var camelizeKeysInMap = function camelizeKeysInMap(inputMap) {
  return inputMap.mapKeys(function (key) {
    return (0, _strings.titleCaseToCamel)(key);
  });
};

exports.camelizeKeysInMap = camelizeKeysInMap;