"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchComponentData = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _api = require("../../../Utils/api");

var _actions = require("./actions");

var fetchComponentData = function fetchComponentData(url, id) {
  return function (dispatch) {
    return (0, _api.fetchApi)(url).then(function (response) {
      var schema = (0, _immutable.fromJS)((0, _defineProperty2.default)({}, id, response.data));
      dispatch((0, _actions.getComponentData)(schema));
      return schema;
    }).catch(function (error) {
      throw Error("Component Data API: ".concat(JSON.stringify(error)));
    });
  };
};

exports.fetchComponentData = fetchComponentData;