"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var FETCHING_LABELS = 'FETCHING_LABELS';
var FETCHING_SUCCESS_LABELS = 'FETCHING_SUCCESS_LABELS';
var _default = {
  FETCHING_LABELS: FETCHING_LABELS,
  FETCHING_SUCCESS_LABELS: FETCHING_SUCCESS_LABELS
};
exports.default = _default;