"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STYLES = exports.SIZES = exports.COLOURS = void 0;
var SIZE_DEFAULT = '';
var SMALL = 'sm';
var MEDIUM = 'md';
var LARGE = 'lg';
var XLARGE = 'xl';
var SIZES = {
  DEFAULT: SIZE_DEFAULT,
  SMALL: SMALL,
  MEDIUM: MEDIUM,
  LARGE: LARGE,
  XLARGE: XLARGE
}; // COLOR REF: https://app.zeplin.io/project/57da0389a9e9c1386b5428d2/screen/57da08cdd49bbd143609e90d

/* eslint-disable */

exports.SIZES = SIZES;
var PRIMARY = 'primary'; //#ff5115

var SECONDARY = 'secondary'; //#0692fc

var TERTIARY = 'tertiary'; //#fda108

var BRAND_CAMPAIGN = 'brand-campaign'; //#bd1e52

var BRAND_CLUBJETSTAR = 'brand-clubjetstar'; //#3b3697

var NEUTRAL_TINT_0 = 'neutral-tint-0'; //#ffffff

var NEUTRAL_TINT_10 = 'neutral-tint-10'; //#f4f4f4

var NEUTRAL_TINT_20 = 'neutral-tint-20'; //#eeeeee

var NEUTRAL_TINT_30 = 'neutral-tint-30'; //#dedede

var NEUTRAL_TINT_40 = 'neutral-tint-40'; //#767676

var NEUTRAL_TINT_50 = 'neutral-tint-50'; //#707070

var NEUTRAL_TINT_60 = 'neutral-tint-60'; //#444444

var NEUTRAL_TINT_70 = 'neutral-tint-70'; //#333333

var NEUTRAL_TINT_80 = 'neutral-tint-80'; //#222222

var NEUTRAL_TINT_90 = 'neutral-tint-90'; //#111111

var NEUTRAL_TINT_100 = 'neutral-tint-100'; //#000000

var STATUS_SUCCESS = 'status-success'; //#00c249

var STATUS_ERROR = 'status-error'; //#fe0002

var STATUS_WARNING = 'status-warning'; //#fff2cc

var STATUS_WARNING_HEAVY = 'status-warning-heavy'; //#ffe699

var STATUS_INFO = 'status-info'; //#e3f3fc

var LEGEND_PURPLE = 'legend-purple'; //#b30177

var LEGEND_GREEN = 'legend-green'; //#029967

var LEGEND_BLUE = 'legend-blue'; //#00ace5

var ACCESSIBLE_PRIMARY = 'accessible-primary'; //#dc3b00

var ACCESSIBLE_SECONDARY = 'accessible-secondary'; //#0479d2

/* eslint-enable */

var COLOUR_DEFAULT = '';
var LEGACY_P1 = 'p1'; //#ff5115

var LEGACY_P2 = 'p2'; //#434244

var LEGACY_S1 = 's1'; //#248dc0

var LEGACY_S4 = 's4'; //#bd1e52

var LEGACY_B4 = 'b4'; //#3b3697

var COLOURS = {
  DEFAULT: COLOUR_DEFAULT,
  PRIMARY: PRIMARY,
  // SECONDARY,
  // TERTIARY,
  // BRAND_CAMPAIGN,
  BRAND_CLUBJETSTAR: BRAND_CLUBJETSTAR,
  // NEUTRAL_TINT_0,
  // NEUTRAL_TINT_10,
  // NEUTRAL_TINT_20,
  // NEUTRAL_TINT_30,
  // NEUTRAL_TINT_40,
  // NEUTRAL_TINT_50,
  // NEUTRAL_TINT_60,
  // NEUTRAL_TINT_70,
  // NEUTRAL_TINT_80,
  // NEUTRAL_TINT_90,
  // NEUTRAL_TINT_100,
  // STATUS_SUCCESS,
  // STATUS_ERROR,
  // STATUS_WARNING,
  // STATUS_WARNING_HEAVY,
  // STATUS_INFO,
  // LEGEND_PURPLE,
  // LEGEND_GREEN,
  // LEGEND_BLUE,
  ACCESSIBLE_PRIMARY: ACCESSIBLE_PRIMARY,
  // ACCESSIBLE_SECONDARY,
  LEGACY_P1: LEGACY_P1,
  LEGACY_P2: LEGACY_P2,
  LEGACY_S1: LEGACY_S1,
  LEGACY_S4: LEGACY_S4,
  LEGACY_B4: LEGACY_B4
};
exports.COLOURS = COLOURS;
var STYLE_DEFAULT = '';
var SOLID = 'a'; //solid

var GHOST = 'b'; //outline

var TEXTCOLOUR = 'c'; //text colour

var PILL = 'e'; //pill

var STYLES = {
  DEFAULT: STYLE_DEFAULT,
  SOLID: SOLID,
  GHOST: GHOST,
  TEXTCOLOUR: TEXTCOLOUR,
  PILL: PILL
};
exports.STYLES = STYLES;