"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ModalContentSimple", {
  enumerable: true,
  get: function get() {
    return _ModalContentSimple.default;
  }
});

var _ModalContentSimple = _interopRequireDefault(require("./ModalContentSimple"));