"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ScreenTypes = void 0;
var GLOBAL_LOGIN_INIT = 'GLOBAL_LOGIN_INIT';
var ACCOUNT_ONLOGOUT = 'ACCOUNT_ONLOGOUT';
var DROPDOWN_ONTOGGLE = 'DROPDOWN_ONTOGGLE';
var ON_RENEWAL_CLOSE = 'ON_RENEWAL_CLOSE';
var CJ_RENEWAL_FETCH = 'CJ_RENEWAL_FETCH';
var CJ_RENEWAL_FETCH_SUCCESS = 'CJ_RENEWAL_FETCH_SUCCESS';
var ON_AUTH_READY = 'ON_AUTH_READY';
var ON_AUTH_REDIRECT = 'ON_AUTH_REDIRECT';
var PROFILE_ONFETCH = 'PROFILE_ONFETCH';
var PROFILE_ONFETCH_SUCCESS = 'PROFILE_ONFETCH_SUCCESS';
var ON_LOGIN = 'ON_LOGIN';
var _default = {
  GLOBAL_LOGIN_INIT: GLOBAL_LOGIN_INIT,
  ACCOUNT_ONLOGOUT: ACCOUNT_ONLOGOUT,
  DROPDOWN_ONTOGGLE: DROPDOWN_ONTOGGLE,
  ON_RENEWAL_CLOSE: ON_RENEWAL_CLOSE,
  CJ_RENEWAL_FETCH: CJ_RENEWAL_FETCH,
  CJ_RENEWAL_FETCH_SUCCESS: CJ_RENEWAL_FETCH_SUCCESS,
  ON_AUTH_READY: ON_AUTH_READY,
  ON_AUTH_REDIRECT: ON_AUTH_REDIRECT,
  PROFILE_ONFETCH: PROFILE_ONFETCH,
  PROFILE_ONFETCH_SUCCESS: PROFILE_ONFETCH_SUCCESS,
  ON_LOGIN: ON_LOGIN
};
exports.default = _default;
var ScreenTypes = {
  none: '',
  guest: 'guest',
  member: 'member',
  clubjetstar: 'clubjetstar',
  clubjetstarGracePeriod: 'clubjetstarGracePeriod'
};
exports.ScreenTypes = ScreenTypes;