"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PARTNER_OFFERS_KEYS = exports.CTA_ACTIONS = exports.COLSPAN = exports.AUTOMATION_ATTRIBUTE_CTA = exports.API_REQUEST_QUERY_KEY = void 0;
// Automated Testing
var AUTOMATION_ATTRIBUTE_CTA = 'partnerOfferGridCta'; // API Request query key

exports.AUTOMATION_ATTRIBUTE_CTA = AUTOMATION_ATTRIBUTE_CTA;
var PAGE = 'page';
var GRID_ID = 'partnerOfferGridID';
var API_REQUEST_QUERY_KEY = {
  GRID_ID: GRID_ID,
  PAGE: PAGE
};
exports.API_REQUEST_QUERY_KEY = API_REQUEST_QUERY_KEY;
var ACTION_DISABLED = 'None or Disabled';
var ACTION_LINK = 'Link';
var ACTION_PAGINATION = 'Pagination';
var CTA_ACTIONS = {
  DISABLED: ACTION_DISABLED,
  LINK: ACTION_LINK,
  PAGINATION: ACTION_PAGINATION
}; // Partner Offer Container Keys

exports.CTA_ACTIONS = CTA_ACTIONS;
var ALT_KEY = 'alt';
var CJ_SIGNUP_MODAL_CONTENT_KEY = 'CJSignupModalContent';
var CJ_SIGNUP_MODAL_CTA_LABEL_KEY = 'CJSignupModalCTALabel';
var CJ_SIGNUP_MODAL_CTA_URL_KEY = 'CJSignupModalCTAURL';
var CJ_SIGNUP_MODAL_CTA_COLOUR_THEME_KEY = 'CJSignupModalCTAColourTheme';
var CJ_SIGNUP_MODAL_HEADER_KEY = 'CJSignupModalHeader';
var COUPON_CODE_LABEL_KEY = 'CouponCodeLabel';
var CTA_ACTION = 'CTAAction';
var CTA_LABEL = 'CTALabel';
var CTA_LINK = 'CTALink';
var DEFAULT_OFFER_IMAGE_KEY = 'DefaultOfferImage';
var GUID_KEY = 'Id';
var HEADING_LABEL_KEY = 'Heading';
var MAX_OFFERS_INITIAL_LOAD_KEY = 'MaximumNumberOfOffersInitialLoad';
var MAX_OFFERS_KEY = 'MaximumNumberOfOffers';
var MODAL_TRIGGER_LABEL_KEY = 'ModalTriggerLabel';
var OFFER_CTA_LABEL_KEY = 'OfferCTALabel';
var OFFER_CTA_TARGET_KEY = 'OfferCTATarget';
var PAGINATION_OFFERS_TO_LOAD_KEY = 'PaginationNumberOfOffersToLoad';
var TARGET_KEY = 'Target';
var TITLE_KEY = 'title';
var URL_KEY = 'Url';
var PARTNER_OFFERS_KEYS = {
  ALT_KEY: ALT_KEY,
  CJ_SIGNUP_MODAL_CONTENT_KEY: CJ_SIGNUP_MODAL_CONTENT_KEY,
  CJ_SIGNUP_MODAL_CTA_LABEL_KEY: CJ_SIGNUP_MODAL_CTA_LABEL_KEY,
  CJ_SIGNUP_MODAL_CTA_URL_KEY: CJ_SIGNUP_MODAL_CTA_URL_KEY,
  CJ_SIGNUP_MODAL_CTA_COLOUR_THEME_KEY: CJ_SIGNUP_MODAL_CTA_COLOUR_THEME_KEY,
  CJ_SIGNUP_MODAL_HEADER_KEY: CJ_SIGNUP_MODAL_HEADER_KEY,
  CTA_ACTION: CTA_ACTION,
  CTA_LABEL: CTA_LABEL,
  CTA_LINK: CTA_LINK,
  COUPON_CODE_LABEL_KEY: COUPON_CODE_LABEL_KEY,
  DEFAULT_OFFER_IMAGE_KEY: DEFAULT_OFFER_IMAGE_KEY,
  GUID_KEY: GUID_KEY,
  HEADING_LABEL_KEY: HEADING_LABEL_KEY,
  MAX_OFFERS_INITIAL_LOAD_KEY: MAX_OFFERS_INITIAL_LOAD_KEY,
  MAX_OFFERS_KEY: MAX_OFFERS_KEY,
  MODAL_TRIGGER_LABEL_KEY: MODAL_TRIGGER_LABEL_KEY,
  OFFER_CTA_LABEL_KEY: OFFER_CTA_LABEL_KEY,
  OFFER_CTA_TARGET_KEY: OFFER_CTA_TARGET_KEY,
  PAGINATION_OFFERS_TO_LOAD_KEY: PAGINATION_OFFERS_TO_LOAD_KEY,
  TARGET_KEY: TARGET_KEY,
  TITLE_KEY: TITLE_KEY,
  URL_KEY: URL_KEY
}; // Display

exports.PARTNER_OFFERS_KEYS = PARTNER_OFFERS_KEYS;
var XSMALL_COLSPAN = 12;
var SMALL_COLSPAN = 12;
var MEDIUM_COLSPAN = 4;
var COLSPAN = {
  XSMALL_COLSPAN: XSMALL_COLSPAN,
  SMALL_COLSPAN: SMALL_COLSPAN,
  MEDIUM_COLSPAN: MEDIUM_COLSPAN
};
exports.COLSPAN = COLSPAN;