"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cookie = require("../../../Utils/cookie");

var _actions = require("./actions");

var fetchUserLocation = function fetchUserLocation() {
  return function (dispatch) {
    dispatch((0, _actions.fetchingUserLocation)());
    return (0, _cookie.getUserLocation)().then(function (userLocation) {
      dispatch((0, _actions.fetchingUserLocationSuccess)(userLocation));
    }, function () {
      return dispatch((0, _actions.fetchingUserLocationError)());
    });
  };
};

var _default = fetchUserLocation;
exports.default = _default;