"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DealCTA", {
  enumerable: true,
  get: function get() {
    return _DealCTA.default;
  }
});
Object.defineProperty(exports, "DealCard", {
  enumerable: true,
  get: function get() {
    return _DealCard.default;
  }
});

var _DealCard = _interopRequireDefault(require("./DealCard"));

var _DealCTA = _interopRequireDefault(require("./DealCTA"));