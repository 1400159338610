"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var TABS_INIT = 'TABS_INIT';
var TABS_ON_CHANGE = 'TABS_ON_CHANGE';
var _default = {
  TABS_INIT: TABS_INIT,
  TABS_ON_CHANGE: TABS_ON_CHANGE
};
exports.default = _default;