"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showWebformValidation = exports.setWebformElementValue = exports.setWebformElementInWebform = exports.setWebformElement = exports.setWebform = exports.removeInvalidWebformElement = exports.removeInvalidWebform = exports.hideWebformValidation = exports.addInvalidWebformElement = exports.addInvalidWebform = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var _webforms = require("../../../Constants/webforms");

// =======================================================================
// react
// global modules
// types
// components
// constants
// state
// utils
// assets
// =======================================================================
// webforms type
var WEBFORMS_ADD_INVALID_WEBFORM = _types.default.WEBFORMS_ADD_INVALID_WEBFORM,
    WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT = _types.default.WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT,
    WEBFORMS_HIDE_WEBFORM_VALIDATION = _types.default.WEBFORMS_HIDE_WEBFORM_VALIDATION,
    WEBFORMS_REMOVE_INVALID_WEBFORM = _types.default.WEBFORMS_REMOVE_INVALID_WEBFORM,
    WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT = _types.default.WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT,
    WEBFORMS_SET_WEBFORM = _types.default.WEBFORMS_SET_WEBFORM,
    WEBFORMS_SET_WEBFORM_ELEMENT = _types.default.WEBFORMS_SET_WEBFORM_ELEMENT,
    WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM = _types.default.WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM,
    WEBFORMS_SET_WEBFORM_ELEMENT_VALUE = _types.default.WEBFORMS_SET_WEBFORM_ELEMENT_VALUE,
    WEBFORMS_SHOW_WEBFORM_VALIDATION = _types.default.WEBFORMS_SHOW_WEBFORM_VALIDATION;
var WEBFORM_ELEMENT_VALUE_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ELEMENT_VALUE_KEY,
    WEBFORM_ELEMENT_ID_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ELEMENT_ID_KEY,
    WEBFORM_ID_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ID_KEY; // actions

var setWebform = (0, _reduxActions.createAction)(WEBFORMS_SET_WEBFORM, function (webformID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ID_KEY, webformID));
});
exports.setWebform = setWebform;
var setWebformElement = (0, _reduxActions.createAction)(WEBFORMS_SET_WEBFORM_ELEMENT, function (webformElementID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ELEMENT_ID_KEY, webformElementID));
});
exports.setWebformElement = setWebformElement;
var setWebformElementValue = (0, _reduxActions.createAction)(WEBFORMS_SET_WEBFORM_ELEMENT_VALUE, function (webformElementID, webformElementValue) {
  var _fromJS3;

  return (0, _immutable.fromJS)((_fromJS3 = {}, (0, _defineProperty2.default)(_fromJS3, WEBFORM_ELEMENT_ID_KEY, webformElementID), (0, _defineProperty2.default)(_fromJS3, WEBFORM_ELEMENT_VALUE_KEY, webformElementValue), _fromJS3));
});
exports.setWebformElementValue = setWebformElementValue;
var setWebformElementInWebform = (0, _reduxActions.createAction)(WEBFORMS_SET_WEBFORM_ELEMENT_IN_WEBFORM, function (webformElementID, webformID) {
  var _fromJS4;

  return (0, _immutable.fromJS)((_fromJS4 = {}, (0, _defineProperty2.default)(_fromJS4, WEBFORM_ELEMENT_ID_KEY, webformElementID), (0, _defineProperty2.default)(_fromJS4, WEBFORM_ID_KEY, webformID), _fromJS4));
});
exports.setWebformElementInWebform = setWebformElementInWebform;
var addInvalidWebform = (0, _reduxActions.createAction)(WEBFORMS_ADD_INVALID_WEBFORM, function (webformID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ID_KEY, webformID));
});
exports.addInvalidWebform = addInvalidWebform;
var removeInvalidWebform = (0, _reduxActions.createAction)(WEBFORMS_REMOVE_INVALID_WEBFORM, function (webformID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ID_KEY, webformID));
});
exports.removeInvalidWebform = removeInvalidWebform;
var addInvalidWebformElement = (0, _reduxActions.createAction)(WEBFORMS_ADD_INVALID_WEBFORM_ELEMENT, function (webformElementID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ELEMENT_ID_KEY, webformElementID));
});
exports.addInvalidWebformElement = addInvalidWebformElement;
var removeInvalidWebformElement = (0, _reduxActions.createAction)(WEBFORMS_REMOVE_INVALID_WEBFORM_ELEMENT, function (webformElementID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ELEMENT_ID_KEY, webformElementID));
});
exports.removeInvalidWebformElement = removeInvalidWebformElement;
var showWebformValidation = (0, _reduxActions.createAction)(WEBFORMS_SHOW_WEBFORM_VALIDATION, function (webformID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ID_KEY, webformID));
});
exports.showWebformValidation = showWebformValidation;
var hideWebformValidation = (0, _reduxActions.createAction)(WEBFORMS_HIDE_WEBFORM_VALIDATION, function (webformID) {
  return (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ID_KEY, webformID));
});
exports.hideWebformValidation = hideWebformValidation;