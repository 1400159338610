// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../../../node_modules/sass-loader/dist/cjs.js??ref--5-3!../_base.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._radio__label--XmKNS{font-size:1.125rem;font-weight:700}._radio__labelDisabled--IqTcE{}._radio__input--YmX3B{}._radio__input--YmX3B:disabled:checked~._radio__checkmark--sQRA3:after{background-color:#707070}._radio__checkmark--sQRA3{top:calc(50% - .75rem);border-radius:1.5rem}._radio__checkmark--sQRA3:after{background-color:#ff5115;border-radius:.875rem;height:.875rem;left:.25rem;top:calc(50% - .4375rem);width:.875rem}.is-gy ._radio__checkmark--sQRA3:after{background-color:#707070}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Forms/SelectionControl/RadioButton/_radio.scss"],"names":[],"mappings":"AAAA,sBAA2C,kBAAA,CAAmB,eAAe,CAAC,8BAA0D,CAAC,sBAA0C,CAAC,uEAAyC,wBAAwB,CAAC,0BAAmD,sBAAA,CAAwB,oBAAoB,CAAC,gCAAiB,wBAAA,CAAyB,qBAAA,CAAsB,cAAA,CAAe,WAAA,CAAY,wBAAA,CAA0B,aAAa,CAAC,uCAAiC,wBAAwB","sourcesContent":[".label{composes:label from \"../_base.scss\";font-size:1.125rem;font-weight:700}.labelDisabled{composes:labelDisabled from \"../_base.scss\"}.input{composes:input from \"../_base.scss\"}.input:disabled:checked~.checkmark:after{background-color:#707070}.checkmark{composes:checkmark from \"../_base.scss\";top:calc(50% - 0.75rem);border-radius:1.5rem}.checkmark:after{background-color:#ff5115;border-radius:.875rem;height:.875rem;left:.25rem;top:calc(50% - 0.4375rem);width:.875rem}:global(.is-gy) .checkmark:after{background-color:#707070}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "_radio__label--XmKNS " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label"] + "",
	"labelDisabled": "_radio__labelDisabled--IqTcE " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["labelDisabled"] + "",
	"input": "_radio__input--YmX3B " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["input"] + "",
	"checkmark": "_radio__checkmark--sQRA3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["checkmark"] + ""
};
export default ___CSS_LOADER_EXPORT___;
