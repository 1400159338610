"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FLIGHT_TYPE_LABELS = exports.FLIGHT_TYPE_IDS = exports.FLIGHT_DIRECTION = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _FLIGHT_TYPE_LABELS;

var FLIGHT_TYPE_IDS = {
  ONE_WAY: '1',
  RETURN: '2',
  MULTI_CITY: '3'
};
exports.FLIGHT_TYPE_IDS = FLIGHT_TYPE_IDS;
var ONE_WAY = FLIGHT_TYPE_IDS.ONE_WAY,
    RETURN = FLIGHT_TYPE_IDS.RETURN,
    MULTI_CITY = FLIGHT_TYPE_IDS.MULTI_CITY;
var ONE_WAY_LABEL = 'OneWay';
var RETURN_LABEL = 'Return';
var MULTI_CITY_LABEL = 'MultiCity';
var FLIGHT_TYPE_LABELS = (_FLIGHT_TYPE_LABELS = {}, (0, _defineProperty2.default)(_FLIGHT_TYPE_LABELS, ONE_WAY, ONE_WAY_LABEL), (0, _defineProperty2.default)(_FLIGHT_TYPE_LABELS, RETURN, RETURN_LABEL), (0, _defineProperty2.default)(_FLIGHT_TYPE_LABELS, MULTI_CITY, MULTI_CITY_LABEL), _FLIGHT_TYPE_LABELS);
exports.FLIGHT_TYPE_LABELS = FLIGHT_TYPE_LABELS;
var FLIGHT_DIRECTION = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound'
};
exports.FLIGHT_DIRECTION = FLIGHT_DIRECTION;