"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setGlobalContextProperty = exports.logError = exports.logAction = void 0;

var _browserRum = require("@datadog/browser-rum");

/**
 * @type {typeof datadogRum.addError}
 */
var logError = function logError(err, context) {
  if (_browserRum.datadogRum.getInitConfiguration()) {
    _browserRum.datadogRum.addError(err instanceof Error ? err : new Error(err), context);
  }
};
/**
 * @type {typeof datadogRum.addAction}
 */


exports.logError = logError;

var logAction = function logAction(action, context) {
  if (_browserRum.datadogRum.getInitConfiguration()) {
    _browserRum.datadogRum.addAction(action, context);
  }
};
/**
 * @type {typeof datadogRum.setGlobalContextProperty}
 */


exports.logAction = logAction;

var setGlobalContextProperty = function setGlobalContextProperty(key, value) {
  if (_browserRum.datadogRum.getInitConfiguration()) {
    _browserRum.datadogRum.setGlobalContextProperty(key, value);
  }
};

exports.setGlobalContextProperty = setGlobalContextProperty;