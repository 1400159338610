"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USERSTATES_ORDER = exports.USERSTATES = exports.TRIGGER_SIGN_IN = exports.TRIGGER_REDIRECT = exports.TIMEOUT_DURATION = exports.RECOMMENDED = exports.RECENT_SEARCH_WEB = exports.RECENT_SEARCH = exports.PERSONALISATION = exports.MAX_RECOMMENDED_DESTINATION = exports.MAX_RECENT_SEARCH = exports.BOXEVER_START_MARKER = exports.BOXEVER_SCRIPT_ID = exports.BOXEVER_END_MARKER = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _USERSTATES_ORDER;

var CLUBJETSTAR_RENEWAL_NOTIFICATION_COOKIE = 'clubjetstar-renewal-notification';
var CLUBJETSTAR_RENEWAL_NOTIFICATION_PROGRAM_IGNORE = ['JQV'];
var CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD = 'grace-period';
var CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_RENEWAL_PERIOD = 'renewal-period';
var GIGYA_KVP = 'gigya';
var GIGYA_REGISTRATION_QS_TRIGGER = 'showsignup';
var GIGYA_SIGNIN_QS_TRIGGER = 'showsignin';
var PERSONALISATION = {
  CLUBJETSTAR_RENEWAL_NOTIFICATION_COOKIE: CLUBJETSTAR_RENEWAL_NOTIFICATION_COOKIE,
  CLUBJETSTAR_RENEWAL_NOTIFICATION_PROGRAM_IGNORE: CLUBJETSTAR_RENEWAL_NOTIFICATION_PROGRAM_IGNORE,
  CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD: CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD,
  CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_RENEWAL_PERIOD: CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_RENEWAL_PERIOD,
  GIGYA_KVP: GIGYA_KVP,
  GIGYA_REGISTRATION_QS_TRIGGER: GIGYA_REGISTRATION_QS_TRIGGER,
  GIGYA_SIGNIN_QS_TRIGGER: GIGYA_SIGNIN_QS_TRIGGER
};
exports.PERSONALISATION = PERSONALISATION;
var ALL_MEMBERS = 'allMembers';
var CLUBJETSTAR_MEMBER = 'clubJetstarMember';
var GUEST = 'guest';
var MEMBER_GRACE_PERIOD = 'memberInGracePeriod';
var MEMBER_RENEWAL_PERIOD = 'memberInRenewalPeriod';
var STANDARD_MEMBER = 'standardMember';
var USERSTATES = {
  ALL_MEMBERS: ALL_MEMBERS,
  CLUBJETSTAR_MEMBER: CLUBJETSTAR_MEMBER,
  GUEST: GUEST,
  MEMBER_GRACE_PERIOD: MEMBER_GRACE_PERIOD,
  MEMBER_RENEWAL_PERIOD: MEMBER_RENEWAL_PERIOD,
  STANDARD_MEMBER: STANDARD_MEMBER
};
exports.USERSTATES = USERSTATES;
var USERSTATES_ORDER = (_USERSTATES_ORDER = {}, (0, _defineProperty2.default)(_USERSTATES_ORDER, MEMBER_GRACE_PERIOD, 1), (0, _defineProperty2.default)(_USERSTATES_ORDER, MEMBER_RENEWAL_PERIOD, 2), (0, _defineProperty2.default)(_USERSTATES_ORDER, CLUBJETSTAR_MEMBER, 3), (0, _defineProperty2.default)(_USERSTATES_ORDER, STANDARD_MEMBER, 4), (0, _defineProperty2.default)(_USERSTATES_ORDER, ALL_MEMBERS, 5), (0, _defineProperty2.default)(_USERSTATES_ORDER, GUEST, 6), _USERSTATES_ORDER);
exports.USERSTATES_ORDER = USERSTATES_ORDER;
var BOXEVER_END_MARKER = 'end_boxever_request';
exports.BOXEVER_END_MARKER = BOXEVER_END_MARKER;
var BOXEVER_SCRIPT_ID = 'boxeverScript';
exports.BOXEVER_SCRIPT_ID = BOXEVER_SCRIPT_ID;
var BOXEVER_START_MARKER = 'start_boxever_request';
exports.BOXEVER_START_MARKER = BOXEVER_START_MARKER;
var MAX_RECENT_SEARCH = 3;
exports.MAX_RECENT_SEARCH = MAX_RECENT_SEARCH;
var MAX_RECOMMENDED_DESTINATION = 3;
exports.MAX_RECOMMENDED_DESTINATION = MAX_RECOMMENDED_DESTINATION;
var RECENT_SEARCH = 'recentSearch';
exports.RECENT_SEARCH = RECENT_SEARCH;
var RECENT_SEARCH_WEB = 'recent_searches_web';
exports.RECENT_SEARCH_WEB = RECENT_SEARCH_WEB;
var RECOMMENDED = 'recommended';
exports.RECOMMENDED = RECOMMENDED;
var TIMEOUT_DURATION = 1000;
exports.TIMEOUT_DURATION = TIMEOUT_DURATION;
var TRIGGER_SIGN_IN = 'trigger-sign-in';
exports.TRIGGER_SIGN_IN = TRIGGER_SIGN_IN;
var TRIGGER_REDIRECT = 'returnUrl';
exports.TRIGGER_REDIRECT = TRIGGER_REDIRECT;