"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.STATES = void 0;
var DEFAULT = 'default';
var SELECTED = 'selected';
var DISABLED = 'disabled';
var STATES = {
  DEFAULT: DEFAULT,
  SELECTED: SELECTED,
  DISABLED: DISABLED
};
exports.STATES = STATES;
var IMAGE = 'image';
var IMAGE_WITH_TITLE = 'imageWithTitle';
var TYPES = {
  IMAGE: IMAGE,
  IMAGE_WITH_TITLE: IMAGE_WITH_TITLE
};
exports.TYPES = TYPES;