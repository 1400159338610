// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._partner-offers__partnerOffers--o\\+Vjs{display:block}._partner-offers__partnerOffers--o\\+Vjs{padding-top:3rem;padding-bottom:3rem;background:#f4f4f4}@media only screen and (min-width:48em){._partner-offers__partnerOffers--o\\+Vjs{padding-top:3rem}}@media only screen and (min-width:80.625em){._partner-offers__partnerOffers--o\\+Vjs{padding-top:4rem}}@media only screen and (min-width:48em){._partner-offers__partnerOffers--o\\+Vjs{padding-bottom:3rem}}@media only screen and (min-width:80.625em){._partner-offers__partnerOffers--o\\+Vjs{padding-bottom:4rem}}._partner-offers__heading--3Ipip{margin-bottom:1.5rem;text-align:center}@media only screen and (min-width:48em){._partner-offers__heading--3Ipip{margin-bottom:1.5rem}}@media only screen and (min-width:80.625em){._partner-offers__heading--3Ipip{margin-bottom:2rem}}._partner-offers__cta--c9a4X{margin-top:.5rem}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/PartnerOffers/_partner-offers.scss"],"names":[],"mappings":"AAAA,2CAAkB,aAAa,CAAC,wCAAe,gBAAA,CAAiB,mBAAA,CAAoB,kBAAkB,CAAC,wCAAwC,wCAAe,gBAAgB,CAAC,CAAC,4CAA4C,wCAAe,gBAAgB,CAAC,CAAC,wCAAwC,wCAAe,mBAAmB,CAAC,CAAC,4CAA4C,wCAAe,mBAAmB,CAAC,CAAC,iCAAS,oBAAA,CAAqB,iBAAiB,CAAC,wCAAwC,iCAAS,oBAAoB,CAAC,CAAC,4CAA4C,iCAAS,kBAAkB,CAAC,CAAC,6BAAK,gBAAgB","sourcesContent":["div.partnerOffers{display:block}.partnerOffers{padding-top:3rem;padding-bottom:3rem;background:#f4f4f4}@media only screen and (min-width:48em){.partnerOffers{padding-top:3rem}}@media only screen and (min-width:80.625em){.partnerOffers{padding-top:4rem}}@media only screen and (min-width:48em){.partnerOffers{padding-bottom:3rem}}@media only screen and (min-width:80.625em){.partnerOffers{padding-bottom:4rem}}.heading{margin-bottom:1.5rem;text-align:center}@media only screen and (min-width:48em){.heading{margin-bottom:1.5rem}}@media only screen and (min-width:80.625em){.heading{margin-bottom:2rem}}.cta{margin-top:.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partnerOffers": "_partner-offers__partnerOffers--o+Vjs",
	"heading": "_partner-offers__heading--3Ipip",
	"cta": "_partner-offers__cta--c9a4X"
};
export default ___CSS_LOADER_EXPORT___;
