"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CardPromotion", {
  enumerable: true,
  get: function get() {
    return _CardPromotion2.default;
  }
});
Object.defineProperty(exports, "CardPromotionSkeleton", {
  enumerable: true,
  get: function get() {
    return _CardPromotion.default;
  }
});

var _CardPromotion = _interopRequireDefault(require("./CardPromotion.skeleton"));

var _CardPromotion2 = _interopRequireDefault(require("./CardPromotion"));