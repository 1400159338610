"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _actions = require("./actions");

var initialState = (0, _immutable.Map)({});
exports.initialState = initialState;

var _default = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.getComponentData, function (state, action) {
  return state.merge(action.payload);
}), initialState);

exports.default = _default;