"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = void 0;
var ALERT = 'alert';
var ERROR = 'error';
var SUCCESS = 'success';
var INFO = 'info';
var TYPES = {
  ALERT: ALERT,
  ERROR: ERROR,
  SUCCESS: SUCCESS,
  INFO: INFO
};
exports.TYPES = TYPES;