"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWeeksByMonth = exports.getCalendarWeekDays = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _eachDayOfInterval = _interopRequireDefault(require("date-fns/eachDayOfInterval"));

var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));

var _endOfWeek = _interopRequireDefault(require("date-fns/endOfWeek"));

var _format = _interopRequireDefault(require("date-fns/format"));

var _getWeekOfMonth = _interopRequireDefault(require("date-fns/getWeekOfMonth"));

var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));

var _startOfWeek = _interopRequireDefault(require("date-fns/startOfWeek"));

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var getCalendarWeekDays = function getCalendarWeekDays() {
  var weekStartsOn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _constants.DEFAULT_WEEK_STARTS_ON;
  var formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.CALENDAR_WEEK_DAY_FORMAT_STRING;
  var now = new Date();
  var weekInterval = {
    start: (0, _startOfWeek.default)(now, {
      weekStartsOn: weekStartsOn
    }),
    end: (0, _endOfWeek.default)(now, {
      weekStartsOn: weekStartsOn
    })
  };
  return (0, _eachDayOfInterval.default)(weekInterval).reduce(function (acc, date, index) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2.default)({}, index, (0, _format.default)(date, formatString)));
  }, {});
};

exports.getCalendarWeekDays = getCalendarWeekDays;

var getWeeksByMonth = function getWeeksByMonth(startDate) {
  var weekStartsOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.DEFAULT_WEEK_STARTS_ON;
  var datesInterval = {
    start: (0, _startOfMonth.default)(startDate),
    end: (0, _endOfMonth.default)(startDate)
  };
  return (0, _eachDayOfInterval.default)(datesInterval).reduce(function (total, current) {
    var week = (0, _getWeekOfMonth.default)(current, {
      weekStartsOn: weekStartsOn
    });
    return _objectSpread(_objectSpread({}, total), {}, (0, _defineProperty2.default)({}, week, [].concat((0, _toConsumableArray2.default)(total[week] ? total[week] : []), [current])));
  }, {});
};

exports.getWeeksByMonth = getWeeksByMonth;