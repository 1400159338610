"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.THEMES = exports.AUTOMATION_ATTRIBUTE = void 0;
var DEFAULT = ''; //TODO: fix inconsistent naming convention

var TRANSPARENT = 'Transparent';
var PRIMARY = 'primary';
var CLUBJETSTAR = 'brand-clubjetstar';
var THEMES = {
  CLUBJETSTAR: CLUBJETSTAR,
  DEFAULT: DEFAULT,
  PRIMARY: PRIMARY,
  TRANSPARENT: TRANSPARENT
};
exports.THEMES = THEMES;
var DEFAULT_TYPE = 'boxFree';
var BOX = 'box';
var TYPES = {
  DEFAULT: DEFAULT_TYPE,
  BOX: BOX
};
exports.TYPES = TYPES;
var AUTOMATION_ATTRIBUTE = 'rppp';
exports.AUTOMATION_ATTRIBUTE = AUTOMATION_ATTRIBUTE;