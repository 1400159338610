// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._calendar__calendar--0CbBy{width:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column}._calendar__calendar--0CbBy,._calendar__calendarMonth--6pfZ4{display:-webkit-box;display:-webkit-flex;display:flex}._calendar__calendarMonth--6pfZ4{-webkit-flex-wrap:wrap;flex-wrap:wrap}._calendar__calendarWeek--\\+ccJE{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;width:100%}._calendar__calendarWeek--\\+ccJE:last-child{-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start}._calendar__calendarMonthsWrapper--3m5kC{display:-webkit-box;display:-webkit-flex;display:flex;margin-bottom:auto}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/CalendarDropdown/_calendar.scss"],"names":[],"mappings":"AAAA,4BAAU,UAAA,CAAW,2BAAA,CAAa,4BAAA,CAAA,6BAAA,CAAA,qBAAqB,CAAC,6DAAnC,mBAAA,CAAA,oBAAA,CAAA,YAAa,CAAsB,iCAA4B,sBAAA,CAAA,cAAc,CAAC,iCAAc,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,oBAAA,CAAA,gCAAA,CAAA,wBAAA,CAAyB,UAAU,CAAC,4CAAyB,sBAAA,CAAA,kCAAA,CAAA,0BAA0B,CAAC,yCAAuB,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,kBAAkB","sourcesContent":[".calendar{width:100%;display:flex;flex-direction:column}.calendarMonth{display:flex;flex-wrap:wrap}.calendarWeek{display:flex;justify-content:flex-end;width:100%}.calendarWeek:last-child{justify-content:flex-start}.calendarMonthsWrapper{display:flex;margin-bottom:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": "_calendar__calendar--0CbBy",
	"calendarMonth": "_calendar__calendarMonth--6pfZ4",
	"calendarWeek": "_calendar__calendarWeek--+ccJE",
	"calendarMonthsWrapper": "_calendar__calendarMonthsWrapper--3m5kC"
};
export default ___CSS_LOADER_EXPORT___;
