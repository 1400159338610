"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchingConfigSuccess = exports.fetchingConfig = void 0;

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var fetchingConfig = (0, _reduxActions.createAction)(_types.default.FETCHING_CONFIG, function () {
  return (0, _immutable.Map)({
    isFetching: true
  });
});
exports.fetchingConfig = fetchingConfig;
var fetchingConfigSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_SUCCESS_CONFIG, function (data) {
  return (0, _immutable.Map)({
    isFetching: false
  }).set('data', data);
});
exports.fetchingConfigSuccess = fetchingConfigSuccess;