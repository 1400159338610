"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.THEMES = void 0;
var VANILLA = 'vanilla';
var SEARCH = 'search';
var THEMES = {
  VANILLA: VANILLA,
  SEARCH: SEARCH
};
exports.THEMES = THEMES;