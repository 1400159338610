"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialWebformsState = exports.default = void 0;

require("core-js/modules/es6.array.filter.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _actions = require("./actions");

var _webforms = require("../../../Constants/webforms");

var _fromJS, _handleActions;

// state
// utils
// assets
//==============================================================================
var ALL_WEBFORM_ELEMENT_IDS_KEY = _webforms.WEBFORMS_KEYS.ALL_WEBFORM_ELEMENT_IDS_KEY,
    ALL_WEBFORM_IDS_KEY = _webforms.WEBFORMS_KEYS.ALL_WEBFORM_IDS_KEY,
    BY_WEBFORM_ELEMENT_KEY = _webforms.WEBFORMS_KEYS.BY_WEBFORM_ELEMENT_KEY,
    BY_WEBFORM_KEY = _webforms.WEBFORMS_KEYS.BY_WEBFORM_KEY,
    INVALID_ELEMENTS_KEY = _webforms.WEBFORMS_KEYS.INVALID_ELEMENTS_KEY,
    INVALID_FORMS_KEY = _webforms.WEBFORMS_KEYS.INVALID_FORMS_KEY,
    SHOW_VALIDATION_BY_WEBFORM_KEY = _webforms.WEBFORMS_KEYS.SHOW_VALIDATION_BY_WEBFORM_KEY,
    WEBFORM_ELEMENT_VALUE_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ELEMENT_VALUE_KEY,
    WEBFORM_ELEMENT_ID_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ELEMENT_ID_KEY,
    WEBFORM_ID_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ID_KEY; // initial state

var initialWebformsState = (0, _immutable.fromJS)((_fromJS = {}, (0, _defineProperty2.default)(_fromJS, ALL_WEBFORM_ELEMENT_IDS_KEY, []), (0, _defineProperty2.default)(_fromJS, ALL_WEBFORM_IDS_KEY, []), (0, _defineProperty2.default)(_fromJS, BY_WEBFORM_ELEMENT_KEY, {}), (0, _defineProperty2.default)(_fromJS, BY_WEBFORM_KEY, {}), (0, _defineProperty2.default)(_fromJS, INVALID_ELEMENTS_KEY, []), (0, _defineProperty2.default)(_fromJS, INVALID_FORMS_KEY, []), (0, _defineProperty2.default)(_fromJS, SHOW_VALIDATION_BY_WEBFORM_KEY, []), _fromJS));
exports.initialWebformsState = initialWebformsState;

var _default = (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, _actions.setWebform, function (state, action) {
  var webformID = action.payload.get(WEBFORM_ID_KEY);
  return state.update(ALL_WEBFORM_IDS_KEY, function (ids) {
    return ids.push(webformID);
  }).setIn([BY_WEBFORM_KEY, webformID], (0, _immutable.fromJS)([]));
}), (0, _defineProperty2.default)(_handleActions, _actions.setWebformElement, function (state, action) {
  var webformElementID = action.payload.get(WEBFORM_ELEMENT_ID_KEY);
  return state.update(ALL_WEBFORM_ELEMENT_IDS_KEY, function (ids) {
    return ids.push(webformElementID);
  }).setIn([BY_WEBFORM_ELEMENT_KEY, webformElementID], (0, _immutable.fromJS)({}));
}), (0, _defineProperty2.default)(_handleActions, _actions.setWebformElementValue, function (state, action) {
  var webformElementID = action.payload.get(WEBFORM_ELEMENT_ID_KEY);
  var webformElementValue = action.payload.get(WEBFORM_ELEMENT_VALUE_KEY);
  return state.setIn([BY_WEBFORM_ELEMENT_KEY, webformElementID], (0, _immutable.fromJS)((0, _defineProperty2.default)({}, WEBFORM_ELEMENT_VALUE_KEY, webformElementValue)));
}), (0, _defineProperty2.default)(_handleActions, _actions.setWebformElementInWebform, function (state, action) {
  var webformElementID = action.payload.get(WEBFORM_ELEMENT_ID_KEY);
  var webformID = action.payload.get(WEBFORM_ID_KEY);
  return state.updateIn([BY_WEBFORM_KEY, webformID], function (webform) {
    return webform.push(webformElementID);
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.addInvalidWebform, function (state, action) {
  var webformID = action.payload.get(WEBFORM_ID_KEY);
  return state.update(INVALID_FORMS_KEY, function (forms) {
    return forms.push(webformID).toSet().toList();
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.removeInvalidWebform, function (state, action) {
  var webformID = action.payload.get(WEBFORM_ID_KEY);
  return state.update(INVALID_FORMS_KEY, function (forms) {
    return forms.filter(function (formID) {
      return formID !== webformID;
    });
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.addInvalidWebformElement, function (state, action) {
  var webformElementID = action.payload.get(WEBFORM_ELEMENT_ID_KEY);
  return state.update(INVALID_ELEMENTS_KEY, function (elements) {
    return elements.push(webformElementID).toSet().toList();
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.removeInvalidWebformElement, function (state, action) {
  var webformElementID = action.payload.get(WEBFORM_ELEMENT_ID_KEY);
  return state.update(INVALID_ELEMENTS_KEY, function (elements) {
    return elements.filter(function (id) {
      return id !== webformElementID;
    });
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.showWebformValidation, function (state, action) {
  var webformID = action.payload.get(WEBFORM_ID_KEY);
  return state.update(SHOW_VALIDATION_BY_WEBFORM_KEY, function (elements) {
    return elements.push(webformID).toSet().toList();
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.hideWebformValidation, function (state, action) {
  var webformID = action.payload.get(WEBFORM_ID_KEY);
  return state.update(SHOW_VALIDATION_BY_WEBFORM_KEY, function (webforms) {
    return webforms.filter(function (id) {
      return id !== webformID;
    });
  });
}), _handleActions), initialWebformsState);

exports.default = _default;