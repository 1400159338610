"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = void 0;
var BUTTON = 'button';
var MODAL = 'modal';
var TYPES = {
  BUTTON: BUTTON,
  MODAL: MODAL
};
exports.TYPES = TYPES;