"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWebformElementsInWebform = exports.getIsWebformValidationVisible = exports.getIsWebformValid = exports.getIsWebformInvalid = exports.getIsWebformElementValid = exports.getIsWebformElementPopulated = exports.getIsWebformElementInvalid = exports.getInvalidWebformElements = void 0;

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

var _immutable = require("immutable");

var _webforms = require("../../../Constants/webforms");

//==============================================================================
// react
// global modules
// import type { Webforms as WebformsType } from '../../../Lib/FlowTyped/Webforms';
// actions
// components
// constants
// state
// utils
// assets
//==============================================================================
var BY_WEBFORM_ELEMENT_KEY = _webforms.WEBFORMS_KEYS.BY_WEBFORM_ELEMENT_KEY,
    BY_WEBFORM_KEY = _webforms.WEBFORMS_KEYS.BY_WEBFORM_KEY,
    INVALID_ELEMENTS_KEY = _webforms.WEBFORMS_KEYS.INVALID_ELEMENTS_KEY,
    INVALID_FORMS_KEY = _webforms.WEBFORMS_KEYS.INVALID_FORMS_KEY,
    SHOW_VALIDATION_BY_WEBFORM_KEY = _webforms.WEBFORMS_KEYS.SHOW_VALIDATION_BY_WEBFORM_KEY,
    WEBFORM_ELEMENT_VALUE_KEY = _webforms.WEBFORMS_KEYS.WEBFORM_ELEMENT_VALUE_KEY;

var getIsWebformValid = function getIsWebformValid(state, webformID) {
  var invalidForms = state.get(INVALID_FORMS_KEY);
  var isWebformValid = !(invalidForms && invalidForms.includes(webformID));
  return isWebformValid;
};

exports.getIsWebformValid = getIsWebformValid;

var getIsWebformInvalid = function getIsWebformInvalid(state, webformID) {
  var invalidForms = state.get(INVALID_FORMS_KEY);
  var isFormInvalid = invalidForms && invalidForms.includes(webformID);
  return isFormInvalid;
};

exports.getIsWebformInvalid = getIsWebformInvalid;

var getIsWebformElementValid = function getIsWebformElementValid(state, webformElementID) {
  var invalidElements = state.get(INVALID_ELEMENTS_KEY);
  var isWebformElementValid = !(invalidElements && invalidElements.includes(webformElementID));
  return isWebformElementValid;
};

exports.getIsWebformElementValid = getIsWebformElementValid;

var getIsWebformElementInvalid = function getIsWebformElementInvalid(state, webformElementID) {
  var invalidElements = state.get(INVALID_ELEMENTS_KEY);
  var isWebformElementInvalid = invalidElements && invalidElements.includes(webformElementID);
  return isWebformElementInvalid;
};

exports.getIsWebformElementInvalid = getIsWebformElementInvalid;

var getIsWebformValidationVisible = function getIsWebformValidationVisible(state, webformID) {
  var webformsDisplayingValidation = state.get(SHOW_VALIDATION_BY_WEBFORM_KEY) || (0, _immutable.List)();
  var isWebformValidationDisplayed = webformsDisplayingValidation && webformsDisplayingValidation.includes(webformID);
  return isWebformValidationDisplayed;
};

exports.getIsWebformValidationVisible = getIsWebformValidationVisible;

var getWebformElementsInWebform = function getWebformElementsInWebform(state, webformID) {
  var byWebform = state.get(BY_WEBFORM_KEY);
  var webformElements = byWebform && byWebform.get(webformID) ? byWebform && byWebform.get(webformID) : (0, _immutable.List)();
  return webformElements;
};

exports.getWebformElementsInWebform = getWebformElementsInWebform;

var getInvalidWebformElements = function getInvalidWebformElements(state, webformID) {
  var invalidElements = state.get(INVALID_ELEMENTS_KEY);
  return invalidElements || (0, _immutable.List)();
};

exports.getInvalidWebformElements = getInvalidWebformElements;

var getIsWebformElementPopulated = function getIsWebformElementPopulated(state, webformElementID) {
  var value = state.getIn([BY_WEBFORM_ELEMENT_KEY, webformElementID, WEBFORM_ELEMENT_VALUE_KEY]);

  if (value === null || value === undefined) {
    return false;
  }

  return true;
};

exports.getIsWebformElementPopulated = getIsWebformElementPopulated;