// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._image__responsiveImg--qX96c{max-width:100%}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Image/_image.scss"],"names":[],"mappings":"AAAA,8BAAe,cAAc","sourcesContent":[".responsiveImg{max-width:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveImg": "_image__responsiveImg--qX96c"
};
export default ___CSS_LOADER_EXPORT___;
