"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.TARGETS = exports.STYLES = exports.STATES = exports.JCL_VARIANTS = exports.JCL_SIZES = void 0;
// common
var DEFAULT = ''; // states

var DISABLED = 'is-disabled';
var EMPTY = 'is-empty';
var ERROR = 'is-error';
var GUIDANCE = 'is-guidance';
var HOVER = 'is-hover';
var SELECTED = 'is-selected';
var WAITING = 'is-waiting';
var STATES = {
  DEFAULT: DEFAULT,
  DISABLED: DISABLED,
  EMPTY: EMPTY,
  ERROR: ERROR,
  GUIDANCE: GUIDANCE,
  HOVER: HOVER,
  SELECTED: SELECTED,
  WAITING: WAITING
}; // styles

exports.STATES = STATES;
var CALENDAR_CTA = 'calendar-review-cta';
var DROPDOWN_CALENDAR_CTA = 'dropdown-calendar-cta';
var DROPDOWN_CONFIRM = 'dropdown-confirm';
var DROPDOWN_CONFIRM_NO_BORDER = 'dropdown-confirm-no-border';
var DROPDOWN_LIST = 'dropdown-list';
var DROPDOWN_TRIGGER = 'dropdown-trigger';
var INVERSE = 'inverse';
var OUTLINE_PRIMARY = 'outlinePrimary';
var OUTLINE_PRIMARY_FULL_WIDTH = 'outlinePrimaryFullWidth';
var PRIMARY = 'primary';
var PRIMARY_CENTRALIZED = 'primary-centralized';
var RPPP_HEADER_CTA = 'rpppHeaderCTA';
var RPPP_HEADER_CTA_CJ = 'rpppHeaderCTACJ';
var RPPP_PRIMARY_CTA = 'rpppPrimaryCTA';
var SUPPORTING = 'supporting';
var TERTIARY = 'tertiary';
var TERTIARY_WITH_BACKGROUND = 'tertiaryWithBackground';
var TEXT_CTA = 'text-cta';
var TEXT_LINK = 'textLink';
var WIDGET = 'widget';
var WIDGET_CTA = 'widget-cta'; // JCL Naming

var BRAND_400 = 'brand400';
var BRAND_600 = 'brand600';
var DIALOGUE_ALTERNATIVE_400 = 'dialogue-alternate-400';
var INVERSE_CLUBJETSTAR_300_FULL_WIDTH = 'inverseClubjetstar300FullWidth';
var INVERSE_CLUBJETSTAR_400 = 'inverseClubjetstar400';
var OUTLINE_BRAND_300 = 'outlineBrand300';
var OUTLINE_BRAND_300_FULL_WIDTH = 'outlineBrand300FullWidth';
var OUTLINE_BRAND_400 = 'outlineBrand400';
var OUTLINE_BRAND_400_FULL_WIDTH = 'outlineBrand400FullWidth';
var OUTLINE_BRAND_500 = 'outlineBrand500';
var SUPPORTING_400 = 'supporting400';
var SUPPORTING_400_FULL_WIDTH = 'supporting400FullWidth';
var CLUBJETSTAR = 'clubjetstar';
var CLUBJETSTAR_OUTLINE = 'clubjetstar-outline';
var PRIMARY_FULLWIDTH = 'primary__fullWidth';
var PRIMARY_400 = 'primary__size400';
var PRIMARY_500 = 'primary__size500';
var CLUBJETSTAR_FULLWIDTH = 'clubjetstar__fullWidth';
var CLUBJETSTAR_400 = 'clubjetstar__size400';
var CLUBJETSTAR_500 = 'clubjetstar__size500';
var CLUBJETSTAR_OUTLINE_FULLWIDTH = 'clubjetstar-outline__fullWidth';
var CLUBJETSTAR_OUTLINE_400 = 'clubjetstar-outline__size400';
var CLUBJETSTAR_OUTLINE_500 = 'clubjetstar-outline__size500';
var STYLES = {
  CALENDAR_CTA: CALENDAR_CTA,
  DEFAULT: DEFAULT,
  DROPDOWN_CALENDAR_CTA: DROPDOWN_CALENDAR_CTA,
  DROPDOWN_CONFIRM: DROPDOWN_CONFIRM,
  DROPDOWN_CONFIRM_NO_BORDER: DROPDOWN_CONFIRM_NO_BORDER,
  DROPDOWN_LIST: DROPDOWN_LIST,
  DROPDOWN_TRIGGER: DROPDOWN_TRIGGER,
  INVERSE: INVERSE,
  OUTLINE_PRIMARY: OUTLINE_PRIMARY,
  OUTLINE_PRIMARY_FULL_WIDTH: OUTLINE_PRIMARY_FULL_WIDTH,
  PRIMARY: PRIMARY,
  CLUBJETSTAR: CLUBJETSTAR,
  CLUBJETSTAR_OUTLINE: CLUBJETSTAR_OUTLINE,
  PRIMARY_FULLWIDTH: PRIMARY_FULLWIDTH,
  PRIMARY_400: PRIMARY_400,
  PRIMARY_500: PRIMARY_500,
  CLUBJETSTAR_FULLWIDTH: CLUBJETSTAR_FULLWIDTH,
  CLUBJETSTAR_400: CLUBJETSTAR_400,
  CLUBJETSTAR_500: CLUBJETSTAR_500,
  CLUBJETSTAR_OUTLINE_FULLWIDTH: CLUBJETSTAR_OUTLINE_FULLWIDTH,
  CLUBJETSTAR_OUTLINE_400: CLUBJETSTAR_OUTLINE_400,
  CLUBJETSTAR_OUTLINE_500: CLUBJETSTAR_OUTLINE_500,
  PRIMARY_CENTRALIZED: PRIMARY_CENTRALIZED,
  RPPP_HEADER_CTA: RPPP_HEADER_CTA,
  RPPP_HEADER_CTA_CJ: RPPP_HEADER_CTA_CJ,
  RPPP_PRIMARY_CTA: RPPP_PRIMARY_CTA,
  SUPPORTING: SUPPORTING,
  TERTIARY: TERTIARY,
  TERTIARY_WITH_BACKGROUND: TERTIARY_WITH_BACKGROUND,
  TEXT_CTA: TEXT_CTA,
  TEXT_LINK: TEXT_LINK,
  WIDGET: WIDGET,
  WIDGET_CTA: WIDGET_CTA,
  // JCL Naming
  BRAND_400: BRAND_400,
  BRAND_600: BRAND_600,
  DIALOGUE_ALTERNATIVE_400: DIALOGUE_ALTERNATIVE_400,
  INVERSE_CLUBJETSTAR_300_FULL_WIDTH: INVERSE_CLUBJETSTAR_300_FULL_WIDTH,
  INVERSE_CLUBJETSTAR_400: INVERSE_CLUBJETSTAR_400,
  OUTLINE_BRAND_300_FULL_WIDTH: OUTLINE_BRAND_300_FULL_WIDTH,
  OUTLINE_BRAND_300: OUTLINE_BRAND_300,
  OUTLINE_BRAND_400_FULL_WIDTH: OUTLINE_BRAND_400_FULL_WIDTH,
  OUTLINE_BRAND_400: OUTLINE_BRAND_400,
  OUTLINE_BRAND_500: OUTLINE_BRAND_500,
  SUPPORTING_400_FULL_WIDTH: SUPPORTING_400_FULL_WIDTH,
  SUPPORTING_400: SUPPORTING_400
}; // JCL Sizes
// TODO: Import these const from JCL directly

exports.STYLES = STYLES;
var size200 = '200';
var size300 = '300';
var size400 = '400';
var size500 = '500';
var size600 = '600';
var JCL_SIZES = {
  size200: size200,
  size300: size300,
  size400: size400,
  size500: size500,
  size600: size600
};
exports.JCL_SIZES = JCL_SIZES;
var ALTERNATE = 'alternate';
var BRAND = 'brand';
var OUTLINE_BRAND = 'outlineBrand';
var TEXT = 'text';
var JCL_VARIANTS = {
  ALTERNATE: ALTERNATE,
  BRAND: BRAND,
  CLUBJETSTAR: CLUBJETSTAR,
  CLUBJETSTAR_OUTLINE: CLUBJETSTAR_OUTLINE,
  OUTLINE_BRAND: OUTLINE_BRAND,
  TEXT: TEXT
}; // types

exports.JCL_VARIANTS = JCL_VARIANTS;
var ANCHOR = 'a';
var BUTTON = 'button';
var SPAN = 'span';
var SUBMIT = 'submit';
var TYPES = {
  ANCHOR: ANCHOR,
  BUTTON: BUTTON,
  SPAN: SPAN,
  SUBMIT: SUBMIT
}; // targets

exports.TYPES = TYPES;
var BLANK = '_blank';
var PARENT = '_parent';
var SELF = '_self';
var TOP = '_top';
var TARGETS = {
  BLANK: BLANK,
  PARENT: PARENT,
  SELF: SELF,
  TOP: TOP
};
exports.TARGETS = TARGETS;