"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var GET_COMPONENT_DATA = 'GET_COMPONENT_DATA';
var _default = {
  GET_COMPONENT_DATA: GET_COMPONENT_DATA
};
exports.default = _default;