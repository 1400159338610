"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _immutable = require("immutable");

var _api = require("../../../Utils/api");

var _actions = require("./actions");

var fetchConfig = function fetchConfig(url) {
  return function (dispatch) {
    dispatch((0, _actions.fetchingConfig)());
    return (0, _api.fetchApi)(url).then(function (response) {
      var data = response.data;
      var immutableData = (0, _immutable.fromJS)(data);
      dispatch((0, _actions.fetchingConfigSuccess)(immutableData));
      return immutableData;
    }).catch(function (error) {
      throw Error("Site Values API - ".concat(JSON.stringify(error)));
    });
  };
};

var _default = fetchConfig;
exports.default = _default;