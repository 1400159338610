"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SELECTION_CONTROL_TYPES = void 0;
// types
var CHECKBOX = 'checkbox';
var RADIO = 'radio';
var SELECTION_CONTROL_TYPES = {
  CHECKBOX: CHECKBOX,
  RADIO: RADIO
};
exports.SELECTION_CONTROL_TYPES = SELECTION_CONTROL_TYPES;