// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._tooltip__tooltipWrapper--nhSk2{position:relative}._tooltip__tooltipTrigger--YN15G{cursor:pointer;display:inline-block;padding:.5rem .3125rem .1875rem}._tooltip__tooltipTrigger--YN15G:focus{outline:1px dotted #222}._tooltip__triggerClickArea--Q6kK6{cursor:pointer;height:100%;left:0;position:absolute;top:0;width:100%}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Tooltip/_tooltip.scss"],"names":[],"mappings":"AAAA,iCAAgB,iBAAiB,CAAC,iCAAgB,cAAA,CAAe,oBAAA,CAAqB,+BAAwC,CAAC,uCAAsB,uBAAuB,CAAC,mCAAkB,cAAA,CAAe,WAAA,CAAY,MAAA,CAAO,iBAAA,CAAkB,KAAA,CAAM,UAAU","sourcesContent":[".tooltipWrapper{position:relative}.tooltipTrigger{cursor:pointer;display:inline-block;padding:.5rem .3125rem .1875rem .3125rem}.tooltipTrigger:focus{outline:dotted 1px #222}.triggerClickArea{cursor:pointer;height:100%;left:0;position:absolute;top:0;width:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWrapper": "_tooltip__tooltipWrapper--nhSk2",
	"tooltipTrigger": "_tooltip__tooltipTrigger--YN15G",
	"triggerClickArea": "_tooltip__triggerClickArea--Q6kK6"
};
export default ___CSS_LOADER_EXPORT___;
