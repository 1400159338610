"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WEEK_START_DAYS = exports.TIME_TAG_MONTH_FORMAT_STRING = exports.TIME_TAG_DATE_FORMAT_STRING = exports.KEY_CODES = exports.DEFAULT_WEEK_STARTS_ON = exports.CALENDAR_WEEK_DAY_FORMAT_STRING = exports.CALENDAR_HEADER_FORMAT_STRING = exports.CALENDAR_DAY_FORMAT_STRING = void 0;
var CALENDAR_WEEK_DAY_FORMAT_STRING = 'EEEEE';
exports.CALENDAR_WEEK_DAY_FORMAT_STRING = CALENDAR_WEEK_DAY_FORMAT_STRING;
var CALENDAR_HEADER_FORMAT_STRING = 'MMMM yyyy';
exports.CALENDAR_HEADER_FORMAT_STRING = CALENDAR_HEADER_FORMAT_STRING;
var CALENDAR_DAY_FORMAT_STRING = 'd';
exports.CALENDAR_DAY_FORMAT_STRING = CALENDAR_DAY_FORMAT_STRING;
var TIME_TAG_MONTH_FORMAT_STRING = 'y-LL';
exports.TIME_TAG_MONTH_FORMAT_STRING = TIME_TAG_MONTH_FORMAT_STRING;
var TIME_TAG_DATE_FORMAT_STRING = 'y-LL-dd'; // Week start days

exports.TIME_TAG_DATE_FORMAT_STRING = TIME_TAG_DATE_FORMAT_STRING;
var SUNDAY = 0;
var MONDAY = 1;
var TUESDAY = 2;
var WEDNESDAY = 3;
var THURSDAY = 4;
var FRIDAY = 5;
var SATURDAY = 6;
var WEEK_START_DAYS = {
  SUNDAY: SUNDAY,
  MONDAY: MONDAY,
  TUESDAY: TUESDAY,
  WEDNESDAY: WEDNESDAY,
  THURSDAY: THURSDAY,
  FRIDAY: FRIDAY,
  SATURDAY: SATURDAY
};
exports.WEEK_START_DAYS = WEEK_START_DAYS;
var DEFAULT_WEEK_STARTS_ON = MONDAY;
exports.DEFAULT_WEEK_STARTS_ON = DEFAULT_WEEK_STARTS_ON;
var UP = 38;
var DOWN = 40;
var LEFT = 37;
var RIGHT = 39;
var KEY_CODES = {
  UP: UP,
  DOWN: DOWN,
  LEFT: LEFT,
  RIGHT: RIGHT
};
exports.KEY_CODES = KEY_CODES;