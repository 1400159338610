"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveFlightSchedule = exports.getFlightSchedule = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.promise.js");

var _localforage = _interopRequireDefault(require("localforage"));

var FLIGHT_SEARCH_DATA_KEY = 'FLIGHTSCHEDULES';
var FLIGHT_SEARCH_UNIQUE_KEY = 'FLIGHTSCHEDULES_VER';

_localforage.default.config({
  name: 'Jetstar',
  version: 1.0,
  size: 4980736,
  // Size of database, in bytes. WebSQL-only for now.
  storeName: 'dotcom',
  description: 'Jetstar'
});
/**
 * Locale Storage helpers
 * @namespace Utils/localestorage
 */

/**
 * Retrieve flight schedule data from local storage
 * @function getFlightSchedule
 * @memberof Utils/localestorage
 * @param {string} newKey - unique local storage identifier
 * @returns {promise}
 */


var getFlightSchedule = function getFlightSchedule(newKey) {
  return new Promise(function (res, rej) {
    try {
      _localforage.default.getItem(FLIGHT_SEARCH_UNIQUE_KEY).then(function (key) {
        if (key === newKey) {
          _localforage.default.getItem(FLIGHT_SEARCH_DATA_KEY).then(function (schedule) {
            return res(schedule);
          });
        } else {
          res(undefined);
        }
      });
    } catch (e) {
      rej(e);
    }
  });
};
/**
 * Save flight schedule to local storage
 * @function saveFlightSchedule
 * @memberof Utils/localStorage
 * @param {string} key
 * @param {object} data - flight schedule data
 */


exports.getFlightSchedule = getFlightSchedule;

var saveFlightSchedule = function saveFlightSchedule(_ref) {
  var key = _ref.key,
      data = _ref.data;

  _localforage.default.setItem(FLIGHT_SEARCH_UNIQUE_KEY, key);

  _localforage.default.setItem(FLIGHT_SEARCH_DATA_KEY, data.toJS());
};

exports.saveFlightSchedule = saveFlightSchedule;