"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_CHILD_TYPES = exports.RADIO_GROUP_KEYS = exports.RADIO_GROUP_ATTRIBUTES = void 0;
//TODO: rename to radioGroupContainer
// Accepted child types
var CARD = 'card';
var SET_CHILD_TYPES = {
  CARD: CARD
}; // HTML Attributes

exports.SET_CHILD_TYPES = SET_CHILD_TYPES;
var AUTOMATION_ATTRIBUTE = 'radioGroup';
var ROLE = 'radiogroup';
var RADIO_GROUP_ATTRIBUTES = {
  AUTOMATION_ATTRIBUTE: AUTOMATION_ATTRIBUTE,
  ROLE: ROLE
}; // Keys

exports.RADIO_GROUP_ATTRIBUTES = RADIO_GROUP_ATTRIBUTES;
var DEFAULT_VALUE_KEY = 'DefaultValue';
var ERROR_HELP_TEXT = 'ErrorHelpText';
var ID_KEY = 'Id';
var NAME_KEY = 'Name';
var OPTIONS_IMAGE_KEY = 'Image';
var OPTIONS_KEY = 'Options';
var OPTIONS_LABEL_KEY = 'Label';
var READ_ONLY_KEY = 'ReadOnly';
var REQUIRED_KEY = 'Required';
var VALUE_KEY = 'Value';
var RADIO_GROUP_KEYS = {
  DEFAULT_VALUE_KEY: DEFAULT_VALUE_KEY,
  ERROR_HELP_TEXT: ERROR_HELP_TEXT,
  ID_KEY: ID_KEY,
  NAME_KEY: NAME_KEY,
  OPTIONS_IMAGE_KEY: OPTIONS_IMAGE_KEY,
  OPTIONS_KEY: OPTIONS_KEY,
  OPTIONS_LABEL_KEY: OPTIONS_LABEL_KEY,
  READ_ONLY_KEY: READ_ONLY_KEY,
  REQUIRED_KEY: REQUIRED_KEY,
  VALUE_KEY: VALUE_KEY
};
exports.RADIO_GROUP_KEYS = RADIO_GROUP_KEYS;