"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onTabChanged = exports.init = void 0;

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var init = (0, _reduxActions.createAction)(_types.default.TABS_INIT, function () {
  return null;
});
exports.init = init;
var onTabChanged = (0, _reduxActions.createAction)(_types.default.TABS_ON_CHANGE, function (request) {
  return request;
});
exports.onTabChanged = onTabChanged;