"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NUMBER_DAYS_IN_WEEK = exports.DEFAULT_SCHEDULE_MONTH_FORMAT = exports.DEFAULT_RECENT_SEARCH_FORMAT = exports.DEFAULT_QS_DATE_FORMAT = exports.DEFAULT_MOMENT_FORMAT = exports.DEFAULT_LEGACY_DEAL_API_DATE_FORMAT = exports.DEFAULT_GROUP_BOOKING_FORM_FORMAT = exports.DEFAULT_FLOCK_SEARCH_DATE_FORMAT_ALT = exports.DEFAULT_FLOCK_SEARCH_DATE_FORMAT = exports.DEFAULT_DEAL_API_DATE_FORMAT = exports.DEFAULT_DATE_YEAR_FORMAT = exports.DEFAULT_DATE_MONTH_FORMAT = exports.DEFAULT_DATE_FORMAT_FNS = exports.DEFAULT_DATE_FORMAT = exports.DEFAULT_DATE_DAY_FORMAT = exports.DEFAULT_BOOKING_ENGINE_DATE_FORMAT = exports.DEFAULT_ACCOMMODATION_SEARCH_FORMAT = exports.DEAL_AVAILABLE_TIME_FORMAT = exports.DEAL_AVAILABLE_DATE_FORMAT = exports.CLUBJETSTAR_RENEWAL_PERIOD = exports.CLUBJETSTAR_GRACE_PERIOD = void 0;
var CLUBJETSTAR_GRACE_PERIOD = 5;
exports.CLUBJETSTAR_GRACE_PERIOD = CLUBJETSTAR_GRACE_PERIOD;
var CLUBJETSTAR_RENEWAL_PERIOD = 60;
exports.CLUBJETSTAR_RENEWAL_PERIOD = CLUBJETSTAR_RENEWAL_PERIOD;
var DEFAULT_ACCOMMODATION_SEARCH_FORMAT = 'ddd D MMM';
exports.DEFAULT_ACCOMMODATION_SEARCH_FORMAT = DEFAULT_ACCOMMODATION_SEARCH_FORMAT;
var DEFAULT_BOOKING_ENGINE_DATE_FORMAT = 'YYYY-MM-DD';
exports.DEFAULT_BOOKING_ENGINE_DATE_FORMAT = DEFAULT_BOOKING_ENGINE_DATE_FORMAT;
var DEFAULT_GROUP_BOOKING_FORM_FORMAT = 'DD/MM/YYYY';
exports.DEFAULT_GROUP_BOOKING_FORM_FORMAT = DEFAULT_GROUP_BOOKING_FORM_FORMAT;
var DEFAULT_DATE_DAY_FORMAT = 'D';
exports.DEFAULT_DATE_DAY_FORMAT = DEFAULT_DATE_DAY_FORMAT;
var DEFAULT_DATE_FORMAT = 'YYYYMMDD';
exports.DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
var DEFAULT_DATE_FORMAT_FNS = 'yyyyMMdd';
exports.DEFAULT_DATE_FORMAT_FNS = DEFAULT_DATE_FORMAT_FNS;
var DEFAULT_DATE_MONTH_FORMAT = 'MMMM';
exports.DEFAULT_DATE_MONTH_FORMAT = DEFAULT_DATE_MONTH_FORMAT;
var DEFAULT_DATE_YEAR_FORMAT = 'YYYY';
exports.DEFAULT_DATE_YEAR_FORMAT = DEFAULT_DATE_YEAR_FORMAT;
var DEFAULT_DEAL_API_DATE_FORMAT = 'DD-MM-YYYY';
exports.DEFAULT_DEAL_API_DATE_FORMAT = DEFAULT_DEAL_API_DATE_FORMAT;
var DEFAULT_MOMENT_FORMAT = 'YYYY-MM-DD';
exports.DEFAULT_MOMENT_FORMAT = DEFAULT_MOMENT_FORMAT;
var DEFAULT_QS_DATE_FORMAT = 'DD-MM-YYYY';
exports.DEFAULT_QS_DATE_FORMAT = DEFAULT_QS_DATE_FORMAT;
var DEFAULT_RECENT_SEARCH_FORMAT = 'ddd D MMM YYYY';
exports.DEFAULT_RECENT_SEARCH_FORMAT = DEFAULT_RECENT_SEARCH_FORMAT;
var DEFAULT_SCHEDULE_MONTH_FORMAT = 'YYYYMM';
exports.DEFAULT_SCHEDULE_MONTH_FORMAT = DEFAULT_SCHEDULE_MONTH_FORMAT;
var NUMBER_DAYS_IN_WEEK = 7;
exports.NUMBER_DAYS_IN_WEEK = NUMBER_DAYS_IN_WEEK;
var DEFAULT_FLOCK_SEARCH_DATE_FORMAT = 'yyyy-MM-dd';
exports.DEFAULT_FLOCK_SEARCH_DATE_FORMAT = DEFAULT_FLOCK_SEARCH_DATE_FORMAT;
var DEFAULT_FLOCK_SEARCH_DATE_FORMAT_ALT = 'dd-MM-yyyy';
exports.DEFAULT_FLOCK_SEARCH_DATE_FORMAT_ALT = DEFAULT_FLOCK_SEARCH_DATE_FORMAT_ALT;
var DEFAULT_LEGACY_DEAL_API_DATE_FORMAT = 'dd-MM-yyyy';
exports.DEFAULT_LEGACY_DEAL_API_DATE_FORMAT = DEFAULT_LEGACY_DEAL_API_DATE_FORMAT;
var DEAL_AVAILABLE_TIME_FORMAT = "h.mmaaaaa'm'";
exports.DEAL_AVAILABLE_TIME_FORMAT = DEAL_AVAILABLE_TIME_FORMAT;
var DEAL_AVAILABLE_DATE_FORMAT = 'EEEE dd MMMM yyyy';
exports.DEAL_AVAILABLE_DATE_FORMAT = DEAL_AVAILABLE_DATE_FORMAT;