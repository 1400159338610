"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importLocale = exports.defineLocale = void 0;

/**
 * Locale helpers
 * @namespace Utils/locale
 */

/**
 * Dynamically import moment locale
 * @function importLocale
 * @memberof Utils/locale
 * @param {string} val - culture code
 * @returns {promise}
 */
var importLocale = function importLocale(val) {
  var locale = val.toLowerCase();

  switch (locale) {
    case 'en-hk':
      return import('../Lib/MomentLocale/en-hk');

    case 'en-id':
      return import('../Lib/MomentLocale/en-id');

    case 'en-ie':
      return import('../Lib/MomentLocale/en-ie');

    case 'en-jp':
      return import('../Lib/MomentLocale/en-jp');

    case 'en-my':
      return import('../Lib/MomentLocale/en-my');

    case 'en-nz':
      return import('../Lib/MomentLocale/en-nz');

    case 'en-ph':
      return import('../Lib/MomentLocale/en-ph');

    case 'en-sg':
      return import('../Lib/MomentLocale/en-sg');

    case 'en-th':
      return import('../Lib/MomentLocale/en-th');

    case 'en-us':
      return import('../Lib/MomentLocale/en-us');

    case 'en-vn':
      return import('../Lib/MomentLocale/en-vn');

    case 'id-id':
      return import('../Lib/MomentLocale/id-id');

    case 'ja-jp':
      return import('../Lib/MomentLocale/ja-jp');

    case 'ko-kr':
      return import('../Lib/MomentLocale/ko-kr');

    case 'ms-my':
      return import('../Lib/MomentLocale/ms-my');

    case 'th-th':
      return import('../Lib/MomentLocale/th-th');

    case 'vi-vn':
      return import('../Lib/MomentLocale/vi-vn');

    case 'zh-cn':
      return import('../Lib/MomentLocale/zh-cn');

    case 'zh-hk':
      return import('../Lib/MomentLocale/zh-hk');

    case 'zh-sg':
      return import('../Lib/MomentLocale/zh-sg');

    case 'zh-tw':
      return import('../Lib/MomentLocale/zh-tw');

    case 'en-au':
    default:
      return import('../Lib/MomentLocale/en-au');
  }
};
/**
 * Determine if configuration and moment dependencies are available
 * @function defineLocale
 * @memberof Utils/locale
 * @param {object} config - site configuration
 * @param {object} locale - moment locale
 * @returns {boolean}
 */


exports.importLocale = importLocale;

var defineLocale = function defineLocale(_ref) {
  var config = _ref.config,
      locale = _ref.locale;
  var configData = config.get('data');
  var localeData = locale.get('data');
  return JSON.stringify(configData) !== JSON.stringify({}) || JSON.stringify(localeData) !== JSON.stringify({});
};

exports.defineLocale = defineLocale;