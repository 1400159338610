"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _AccommodationSearch = require("./AccommodationSearch");

var _Config = require("./Config");

var _FlightSchedule = require("./FlightSchedule");

var _FlightSearch = require("./FlightSearch");

var _GlobalLogin = require("./GlobalLogin");

var _HolidaySearch = require("./HolidaySearch");

var _Labels = require("./Labels");

var _Locale = require("./Locale");

var _Schema = require("./Schema");

var _Tabs = require("./Tabs");

var _UserLocation = require("./UserLocation");

var _Webforms = require("./Webforms");

// global modules
// state
var reducers = {
  accommodationSearch: _AccommodationSearch.accommodationSearch,
  config: _Config.config,
  flightSchedule: _FlightSchedule.flightSchedule,
  flightSearch: _FlightSearch.flightSearch,
  globalLogin: _GlobalLogin.globalLogin,
  holidaySearch: _HolidaySearch.holidaySearch,
  labels: _Labels.labels,
  locale: _Locale.locale,
  schema: _Schema.schema,
  tabs: _Tabs.tabs,
  userLocation: _UserLocation.userLocation,
  webforms: _Webforms.webforms
};

var _default = function _default() {
  return (0, _redux.combineReducers)(reducers);
};

exports.default = _default;