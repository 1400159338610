// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._radio-error__error--EZ\\+EC{padding-bottom:.75rem;color:#fe0002;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-flex:1;-webkit-flex:1 0 100%;flex:1 0 100%}@media only screen and (min-width:48em){._radio-error__error--EZ\\+EC{padding-bottom:.75rem}}@media only screen and (min-width:80.625em){._radio-error__error--EZ\\+EC{padding-bottom:1rem}}._radio-error__iconWrapper--pJW7-{-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto;height:1.5rem;width:1.5rem;margin-right:.25rem}._radio-error__errorText--FF2\\+C{-webkit-box-flex:1;-webkit-flex:1 1 auto;flex:1 1 auto;font-size:1rem;font-weight:700;line-height:1.5}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/RadioGroup/_radio-error.scss"],"names":[],"mappings":"AAAA,6BAAO,qBAAA,CAAsB,aAAA,CAAc,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,kBAAA,CAAA,qBAAA,CAAA,aAAa,CAAC,wCAAwC,6BAAO,qBAAqB,CAAC,CAAC,4CAA4C,6BAAO,mBAAmB,CAAC,CAAC,kCAAa,kBAAA,CAAA,qBAAA,CAAA,aAAA,CAAc,aAAA,CAAc,YAAA,CAAa,mBAAmB,CAAC,iCAAW,kBAAA,CAAA,qBAAA,CAAA,aAAA,CAAc,cAAA,CAAe,eAAA,CAAgB,eAAe","sourcesContent":[".error{padding-bottom:.75rem;color:#fe0002;display:flex;flex:1 0 100%}@media only screen and (min-width:48em){.error{padding-bottom:.75rem}}@media only screen and (min-width:80.625em){.error{padding-bottom:1rem}}.iconWrapper{flex:0 0 auto;height:1.5rem;width:1.5rem;margin-right:.25rem}.errorText{flex:1 1 auto;font-size:1rem;font-weight:700;line-height:1.5}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "_radio-error__error--EZ+EC",
	"iconWrapper": "_radio-error__iconWrapper--pJW7-",
	"errorText": "_radio-error__errorText--FF2+C"
};
export default ___CSS_LOADER_EXPORT___;
