"use strict";

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleFlightSearchSubmitLoader = exports.setPax = exports.setLocation = exports.setInvalidLocation = exports.setInvalidCalendar = exports.setCurrency = exports.setCalendarViewStartDates = exports.setCalendarSector = exports.setCalendarSchedule = exports.setCalendarNavigationPrevDate = exports.setCalendarNavigationNextDate = exports.setCalendarNavigationData = exports.setCalendarDates = exports.searchLocation = exports.resetSearchLocation = exports.resetPrices = exports.resetLocation = exports.resetCalendarDates = exports.removeSector = exports.removeInvalidSector = exports.onToggleDropDown = exports.onInitFlightType = exports.onInitDropDown = exports.onGuidanceMessageShow = exports.onGuidanceMessageReset = exports.onFlightTypeChange = exports.onFlightSearchConfigSet = exports.onFlexibleChange = exports.onCounterInit = exports.onCounterIncrement = exports.onCounterDecrement = exports.onCounterChange = exports.onClubJetstarChange = exports.loadLocations = exports.loadCalendars = exports.fetchingPricesSuccess = exports.fetchingPrices = exports.addSector = exports.addMultipleSectors = exports.addInvalidSector = void 0;

require("core-js/modules/es6.object.assign.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireWildcard(require("./types"));

var _Counter = require("../../Common/Counter");

var _flightType = require("../../../Constants/flightType");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// types
// state
// constants
var RETURN = _flightType.FLIGHT_TYPE_IDS.RETURN,
    ONE_WAY = _flightType.FLIGHT_TYPE_IDS.ONE_WAY,
    MULTI_CITY = _flightType.FLIGHT_TYPE_IDS.MULTI_CITY;

var _createCounterActions = (0, _Counter.createCounterActions)(_types.namespace),
    onCounterChange = _createCounterActions.onCounterChange,
    onCounterDecrement = _createCounterActions.onCounterDecrement,
    onCounterIncrement = _createCounterActions.onCounterIncrement,
    onCounterInit = _createCounterActions.onCounterInit,
    setPax = _createCounterActions.setPax;

exports.setPax = setPax;
exports.onCounterInit = onCounterInit;
exports.onCounterIncrement = onCounterIncrement;
exports.onCounterDecrement = onCounterDecrement;
exports.onCounterChange = onCounterChange;
var onInitFlightType = (0, _reduxActions.createAction)(_types.default.ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH, function (id, _ref, selected, multiCityEnabled) {
  var _initData;

  var returnFlight = _ref.returnFlight,
      multicityFlight = _ref.multicityFlight,
      onewayFlight = _ref.onewayFlight;
  var initData = (_initData = {}, (0, _defineProperty2.default)(_initData, RETURN, returnFlight), (0, _defineProperty2.default)(_initData, ONE_WAY, onewayFlight), _initData);
  var multiCityData = (0, _defineProperty2.default)({}, MULTI_CITY, multicityFlight);
  return (0, _immutable.fromJS)({
    id: id,
    data: multiCityEnabled ? Object.assign(initData, multiCityData) : initData,
    selected: selected
  });
});
exports.onInitFlightType = onInitFlightType;
var onFlightTypeChange = (0, _reduxActions.createAction)(_types.default.ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH, function (id, selected) {
  return (0, _immutable.fromJS)({
    id: id,
    selected: selected
  });
});
exports.onFlightTypeChange = onFlightTypeChange;
var onInitDropDown = (0, _reduxActions.createAction)(_types.default.ON_INIT_DROP_DOWN_FLIGHT_SEARCH, function (data) {
  return {
    data: data
  };
});
exports.onInitDropDown = onInitDropDown;
var onToggleDropDown = (0, _reduxActions.createAction)(_types.default.ON_TOGGLE_DROP_DOWN_FLIGHT_SEARCH, function (data) {
  return {
    data: data
  };
});
exports.onToggleDropDown = onToggleDropDown;
var onFlexibleChange = (0, _reduxActions.createAction)(_types.default.ONCHANGE_FLEXIBLE_FLIGHT_SEARCH, function (data) {
  return {
    data: data
  };
});
exports.onFlexibleChange = onFlexibleChange;
var onClubJetstarChange = (0, _reduxActions.createAction)(_types.default.ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH, function (data) {
  return {
    data: data
  };
});
exports.onClubJetstarChange = onClubJetstarChange;
var addSector = (0, _reduxActions.createAction)(_types.default.ADD_SECTOR_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.addSector = addSector;
var addInvalidSector = (0, _reduxActions.createAction)(_types.default.ADD_INVALID_SECTOR_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.addInvalidSector = addInvalidSector;
var removeSector = (0, _reduxActions.createAction)(_types.default.REMOVE_SECTOR_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.removeSector = removeSector;
var removeInvalidSector = (0, _reduxActions.createAction)(_types.default.REMOVE_INVALID_SECTOR_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.removeInvalidSector = removeInvalidSector;
var addMultipleSectors = (0, _reduxActions.createAction)(_types.default.ADD_MULTIPLE_SECTORS_FLIGHT_SEARCH, function (sectorIds) {
  return {
    sectorIds: sectorIds
  };
});
exports.addMultipleSectors = addMultipleSectors;
var setCalendarSector = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_SECTOR_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.setCalendarSector = setCalendarSector;
var loadLocations = (0, _reduxActions.createAction)(_types.default.LOAD_LOCATIONS_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.loadLocations = loadLocations;
var setLocation = (0, _reduxActions.createAction)(_types.default.SET_LOCATION_FLIGHT_SEARCH, function (sectorId, direction, name, code) {
  return {
    sectorId: sectorId,
    direction: direction,
    name: name,
    code: code
  };
});
exports.setLocation = setLocation;
var setInvalidLocation = (0, _reduxActions.createAction)(_types.default.SET_LOCATION_INVALID_FLIGHT_SEARCH, function (sectorId, direction, invalid) {
  return {
    sectorId: sectorId,
    direction: direction,
    invalid: invalid
  };
});
exports.setInvalidLocation = setInvalidLocation;
var resetLocation = (0, _reduxActions.createAction)(_types.default.RESET_LOCATION_FLIGHT_SEARCH, function (sectorId, direction) {
  return {
    sectorId: sectorId,
    direction: direction
  };
});
exports.resetLocation = resetLocation;
var searchLocation = (0, _reduxActions.createAction)(_types.default.SEARCH_LOCATION_FLIGHT_SEARCH, function (sectorId, direction, query) {
  return {
    sectorId: sectorId,
    direction: direction,
    query: query
  };
});
exports.searchLocation = searchLocation;
var resetSearchLocation = (0, _reduxActions.createAction)(_types.default.RESET_SEARCH_LOCATION_FLIGHT_SEARCH, function (sectorId, direction) {
  return {
    sectorId: sectorId,
    direction: direction
  };
});
exports.resetSearchLocation = resetSearchLocation;
var loadCalendars = (0, _reduxActions.createAction)(_types.default.LOAD_CALENDARS_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.loadCalendars = loadCalendars;
var setCalendarViewStartDates = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_START_DATES_FLIGHT_SEARCH, function (sectorId, data) {
  return {
    sectorId: sectorId,
    data: data
  };
});
exports.setCalendarViewStartDates = setCalendarViewStartDates;
var setCalendarSchedule = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_SCHEDULE_FLIGHT_SEARCH, function (sectorId, data) {
  return {
    sectorId: sectorId,
    data: data
  };
});
exports.setCalendarSchedule = setCalendarSchedule;
var setCalendarNavigationPrevDate = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_NAVIGATION_PREV_DATE_FLIGHT_SEARCH, function (sectorId, direction, date) {
  return {
    sectorId: sectorId,
    direction: direction,
    date: date
  };
});
exports.setCalendarNavigationPrevDate = setCalendarNavigationPrevDate;
var setCalendarNavigationNextDate = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_NAVIGATION_NEXT_DATE_FLIGHT_SEARCH, function (sectorId, direction, date) {
  return {
    sectorId: sectorId,
    direction: direction,
    date: date
  };
});
exports.setCalendarNavigationNextDate = setCalendarNavigationNextDate;
var setCalendarNavigationData = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_DATES_NAVIGATION_FLIGHT_SEARCH, function (sectorId, direction, dates) {
  return {
    sectorId: sectorId,
    direction: direction,
    dates: dates
  };
});
exports.setCalendarNavigationData = setCalendarNavigationData;
var setCalendarDates = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_DATES, function (sectorId, data) {
  return {
    sectorId: sectorId,
    data: data
  };
});
exports.setCalendarDates = setCalendarDates;
var setCurrency = (0, _reduxActions.createAction)(_types.default.SET_CURRENCY, function (symbol, format, precision) {
  return {
    symbol: symbol,
    format: format,
    precision: precision
  };
});
exports.setCurrency = setCurrency;
var resetCalendarDates = (0, _reduxActions.createAction)(_types.default.RESET_CALENDAR_DATES, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.resetCalendarDates = resetCalendarDates;
var setInvalidCalendar = (0, _reduxActions.createAction)(_types.default.SET_CALENDAR_INVALID_FLIGHT_SEARCH, function (sectorId, invalid) {
  return {
    sectorId: sectorId,
    invalid: invalid
  };
});
exports.setInvalidCalendar = setInvalidCalendar;
var fetchingPrices = (0, _reduxActions.createAction)(_types.default.FETCHING_PRICES_FLIGHT_SEARCH, function (sectorId, direction) {
  return {
    sectorId: sectorId,
    direction: direction
  };
});
exports.fetchingPrices = fetchingPrices;
var fetchingPricesSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_PRICES_SUCCESS_FLIGHT_SEARCH, function (sectorId, direction, startDate, starterData, memberData) {
  return {
    sectorId: sectorId,
    direction: direction,
    startDate: startDate,
    starterData: starterData,
    memberData: memberData
  };
});
exports.fetchingPricesSuccess = fetchingPricesSuccess;
var resetPrices = (0, _reduxActions.createAction)(_types.default.RESET_PRICES_FLIGHT_SEARCH, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.resetPrices = resetPrices;
var onGuidanceMessageReset = (0, _reduxActions.createAction)(_types.default.RESET_GUIDANCE_MESSAGE, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.onGuidanceMessageReset = onGuidanceMessageReset;
var onGuidanceMessageShow = (0, _reduxActions.createAction)(_types.default.SHOW_GUIDANCE_MESSAGE, function (sectorId) {
  return {
    sectorId: sectorId
  };
});
exports.onGuidanceMessageShow = onGuidanceMessageShow;
var onFlightSearchConfigSet = (0, _reduxActions.createAction)(_types.default.SET_FLIGHT_SEARCH_CONFIG, function (sourceIdentifier) {
  return {
    sourceIdentifier: sourceIdentifier
  };
});
exports.onFlightSearchConfigSet = onFlightSearchConfigSet;
var toggleFlightSearchSubmitLoader = (0, _reduxActions.createAction)(_types.default.TOGGLE_FLIGHT_SEARCH_SUBMIT_LOADING, function (loading) {
  return {
    loading: loading
  };
});
exports.toggleFlightSearchSubmitLoader = toggleFlightSearchSubmitLoader;