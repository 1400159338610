// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._disclaimer__disclaimer--Y3YKE{font-size:.6875rem;line-height:1rem;color:#707070;text-align:center}@media only screen and (min-width:48em){._disclaimer__disclaimer--Y3YKE{font-size:.6875rem;line-height:1rem}}@media only screen and (min-width:80.625em){._disclaimer__disclaimer--Y3YKE{font-size:.6875rem;line-height:1rem}}._disclaimer__normal--LaeU-{margin-top:.75rem}._disclaimer__collapsed--sCKh\\+{margin-top:0;text-align:left}@media only screen and (min-width:48em){._disclaimer__collapsed--sCKh\\+{margin-top:1.5rem}}@media only screen and (min-width:48em)and (min-width:48em){._disclaimer__collapsed--sCKh\\+{margin-top:1.5rem}}@media only screen and (min-width:48em)and (min-width:80.625em){._disclaimer__collapsed--sCKh\\+{margin-top:2rem}}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/HotelsWidget/_disclaimer.scss"],"names":[],"mappings":"AAAA,gCAAY,kBAAA,CAAmB,gBAAA,CAAiB,aAAA,CAAc,iBAAiB,CAAC,wCAAwC,gCAAY,kBAAA,CAAmB,gBAAgB,CAAC,CAAC,4CAA4C,gCAAY,kBAAA,CAAmB,gBAAgB,CAAC,CAAC,4BAAQ,iBAAiB,CAAC,gCAAW,YAAA,CAAa,eAAe,CAAC,wCAAwC,gCAAW,iBAAiB,CAAC,CAAC,4DAA4D,gCAAW,iBAAiB,CAAC,CAAC,gEAAgE,gCAAW,eAAe,CAAC","sourcesContent":[".disclaimer{font-size:.6875rem;line-height:1rem;color:#707070;text-align:center}@media only screen and (min-width:48em){.disclaimer{font-size:.6875rem;line-height:1rem}}@media only screen and (min-width:80.625em){.disclaimer{font-size:.6875rem;line-height:1rem}}.normal{margin-top:.75rem}.collapsed{margin-top:0;text-align:left}@media only screen and (min-width:48em){.collapsed{margin-top:1.5rem}}@media only screen and (min-width:48em)and (min-width:48em){.collapsed{margin-top:1.5rem}}@media only screen and (min-width:48em)and (min-width:80.625em){.collapsed{margin-top:2rem}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": "_disclaimer__disclaimer--Y3YKE",
	"normal": "_disclaimer__normal--LaeU-",
	"collapsed": "_disclaimer__collapsed--sCKh+"
};
export default ___CSS_LOADER_EXPORT___;
