// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._calendarMonthNavigation__calendarMonthNavigation--j3UZT{left:50%;position:relative;-webkit-transform:translate(-50%);transform:translate(-50%);width:100vw;clear:both;display:block;margin:.5rem 0}@media only screen and (min-width:48em){._calendarMonthNavigation__calendarMonthNavigation--j3UZT{left:auto;-webkit-transform:none;transform:none;width:100%}}._calendarMonthNavigation__swiperContainer--Sndxc{padding-bottom:1px;padding-top:1px}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/CalendarMonthNavigation/_calendarMonthNavigation.scss"],"names":[],"mappings":"AAAA,0DAAyB,QAAA,CAAS,iBAAA,CAAkB,iCAAA,CAAA,yBAAA,CAA6B,WAAA,CAAY,UAAA,CAAW,aAAA,CAAc,cAAc,CAAC,wCAAwC,0DAAyB,SAAA,CAAU,sBAAA,CAAA,cAAA,CAAe,UAAU,CAAC,CAAC,kDAAiB,kBAAA,CAAmB,eAAe","sourcesContent":[".calendarMonthNavigation{left:50%;position:relative;transform:translate(-50%, 0);width:100vw;clear:both;display:block;margin:.5rem 0}@media only screen and (min-width:48em){.calendarMonthNavigation{left:auto;transform:none;width:100%}}.swiperContainer{padding-bottom:1px;padding-top:1px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarMonthNavigation": "_calendarMonthNavigation__calendarMonthNavigation--j3UZT",
	"swiperContainer": "_calendarMonthNavigation__swiperContainer--Sndxc"
};
export default ___CSS_LOADER_EXPORT___;
