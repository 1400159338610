"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MESSAGE_ACCORDION_EMPTY = exports.AUTOMATION_ATTRIBUTE = exports.ACCORDION_SCROLL_MS_DELAY = exports.ACCORDION_QUERY_KEY = void 0;
var MESSAGE_ACCORDION_EMPTY = 'Accordion component is empty. Please configure child items or remove component';
exports.MESSAGE_ACCORDION_EMPTY = MESSAGE_ACCORDION_EMPTY;
var ACCORDION = 'accordion';
var ACCORDION_ITEM = 'accordionItem';
var AUTOMATION_ATTRIBUTE = {
  ACCORDION: ACCORDION,
  ACCORDION_ITEM: ACCORDION_ITEM
};
exports.AUTOMATION_ATTRIBUTE = AUTOMATION_ATTRIBUTE;
var ACCORDION_QUERY_KEY = 'accordion';
exports.ACCORDION_QUERY_KEY = ACCORDION_QUERY_KEY;
var ACCORDION_SCROLL_MS_DELAY = 1000;
exports.ACCORDION_SCROLL_MS_DELAY = ACCORDION_SCROLL_MS_DELAY;