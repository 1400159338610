"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HTML_ELEMENT = void 0;
var BUTTON = 'button';
var DIV = 'div';
var LINK = 'a';
var HTML_ELEMENT = {
  BUTTON: BUTTON,
  DIV: DIV,
  LINK: LINK
};
exports.HTML_ELEMENT = HTML_ELEMENT;