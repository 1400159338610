"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _immutable = require("immutable");

var _api = require("../../../Utils/api");

var _actions = require("./actions");

var fetchLabels = function fetchLabels(url) {
  return function (dispatch) {
    dispatch((0, _actions.fetchingLabels)());
    return (0, _api.fetchApi)(url).then(function (labels) {
      return dispatch((0, _actions.fetchingLabelsSuccess)((0, _immutable.fromJS)(labels.data)));
    }).catch(function (error) {
      throw Error("Labels API: ".concat(JSON.stringify(error)));
    });
  };
};

var _default = fetchLabels;
exports.default = _default;