"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.POSITIONING = exports.BOUNDING_ATTRIBUTE = void 0;
var TOP = 'Top';
var BOTTOM = 'Bottom';
var LEFT = 'Left';
var RIGHT = 'Right';
var MIDDLE = 'Middle';
var BOUNDING_ATTRIBUTE = 'data-tooltip-bounding';
exports.BOUNDING_ATTRIBUTE = BOUNDING_ATTRIBUTE;
var POSITIONING = {
  HORIZONTAL: {
    LEFT: LEFT,
    RIGHT: RIGHT,
    MIDDLE: MIDDLE
  },
  VERTICAL: {
    TOP: TOP,
    BOTTOM: BOTTOM
  }
};
exports.POSITIONING = POSITIONING;