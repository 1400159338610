"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.location = exports.initialLocation = exports.initialAccommodationType = exports.default = exports.dates = exports.business = exports.accommodationType = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _redux = require("redux");

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _accommodation = require("../../../Constants/accommodation");

var _types = require("./types");

var _Counter = require("../../Common/Counter");

var _actions = require("./actions");

var _handleActions, _handleActions2, _handleActions3;

var HOTEL = _accommodation.ACCOMMODATION_TYPE_IDS.HOTEL; // Accommodation Type

var initialAccommodationType = (0, _immutable.fromJS)({
  selected: HOTEL,
  accommodationTypes: {}
});
exports.initialAccommodationType = initialAccommodationType;
var accommodationType = (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, _actions.onAccommodationTypeChange, function (state, action) {
  return state.update('selected', function (type) {
    return action.payload.get('selected');
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onInitAccommodationType, function (state, action) {
  var payload = action.payload;
  var selected = payload.get('selected');
  var initLabels = state.set('accommodationTypes', payload.get('data'));
  return selected ? initLabels.set('selected', selected) : initLabels;
}), _handleActions), initialAccommodationType); // Location

exports.accommodationType = accommodationType;
var initialLocation = (0, _immutable.fromJS)({
  query: '',
  selected: {
    id: null,
    label: null,
    type: null,
    url: null
  },
  invalid: false
});
exports.initialLocation = initialLocation;
var location = (0, _reduxActions.handleActions)((_handleActions2 = {}, (0, _defineProperty2.default)(_handleActions2, _actions.setLocation, function (state, action) {
  var payload = action.payload;
  return state.setIn(['selected', 'id'], payload.getIn(['selected', 'id'])).setIn(['selected', 'label'], payload.getIn(['selected', 'label'])).setIn(['selected', 'type'], payload.getIn(['selected', 'type'])).setIn(['selected', 'url'], payload.getIn(['selected', 'url'])).set('invalid', false);
}), (0, _defineProperty2.default)(_handleActions2, _actions.resetLocation, function (state) {
  return state.setIn(['selected', 'id'], null).setIn(['selected', 'label'], null).setIn(['selected', 'type'], null).setIn(['selected', 'url'], null);
}), (0, _defineProperty2.default)(_handleActions2, _actions.setSearchLocation, function (state, action) {
  var payload = action.payload;
  return state.set('query', payload['query']);
}), (0, _defineProperty2.default)(_handleActions2, _actions.resetSearchLocation, function (state) {
  return state.set('query', '');
}), (0, _defineProperty2.default)(_handleActions2, _actions.resetValidation, function (state) {
  return state.set('invalid', false);
}), (0, _defineProperty2.default)(_handleActions2, _actions.setInvalidLocation, function (state, action) {
  return state.set('invalid', action.payload.isInvalid);
}), _handleActions2), initialLocation);
exports.location = location;
var initialDates = (0, _immutable.fromJS)({
  checkIn: null,
  checkOut: null
}); // Calendar

var dates = (0, _reduxActions.handleActions)((_handleActions3 = {}, (0, _defineProperty2.default)(_handleActions3, _actions.setDates, function (state, action) {
  var payload = action.payload;
  return state.set('checkIn', payload.getIn(['dates', 'checkIn'])).set('checkOut', payload.getIn(['dates', 'checkOut']));
}), (0, _defineProperty2.default)(_handleActions3, _actions.resetDates, function (state, action) {
  return state.set('checkIn', null).set('checkOut', null);
}), _handleActions3), initialDates); // Business

exports.dates = dates;
var initialBusiness = (0, _immutable.fromJS)({
  isBusinessTrip: false
});
var business = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.setBusiness, function (state, action) {
  var payload = action.payload;
  return state.set('isBusinessTrip', payload['isBusinessTrip']);
}), initialBusiness);
exports.business = business;

var _default = (0, _redux.combineReducers)({
  counter: (0, _Counter.createCounterReducer)(_types.namespace, _accommodation.DEFAULT_ADULTS_COUNT),
  accommodationType: accommodationType,
  location: location,
  dates: dates,
  business: business
});

exports.default = _default;