"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchApi = exports.fetchAllRequests = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _axios = _interopRequireDefault(require("axios"));

var _serializeJavascript = _interopRequireDefault(require("serialize-javascript"));

var _strings = require("../Utils/strings");

/**
 * Api helpers
 * @namespace Utils/api
 */

/**
 * Axios api call
 * @function fetchApi
 * @memberof Utils/api
 * @param {string} url
 * @param {boolean} isJSON
 * @returns {promise}
 */
var fetchApi = function fetchApi(url) {
  var isJSON = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return _axios.default.get(url).then(function (response) {
    // isJSON = true up to 3 x faster:
    // see https://www.npmjs.com/package/serialize-javascript#optionsisjson
    if ((0, _typeof2.default)(response) === 'object' && response.data.length) {
      var sanitizedResponse = (0, _serializeJavascript.default)(response.data, {
        isJSON: isJSON
      });
      response.data = (0, _strings.deSerialize)(sanitizedResponse);
    }

    return response;
  });
};
/**
 * Axios api call
 * @function fetchAllRequests
 * @memberof Utils/api
 * @param {array} requests
 * @returns {promise}
 */


exports.fetchApi = fetchApi;

var fetchAllRequests = function fetchAllRequests(requests) {
  return _axios.default.all(requests);
};

exports.fetchAllRequests = fetchAllRequests;