"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getComponentData = void 0;

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var getComponentData = (0, _reduxActions.createAction)(_types.default.GET_COMPONENT_DATA, function (data) {
  return data;
});
exports.getComponentData = getComponentData;