"use strict";

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.regexp.search.js");

require("core-js/modules/es6.object.assign.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _dateFns = require("date-fns");

var _immutable = require("immutable");

var _qs = _interopRequireDefault(require("qs"));

var _flightType = require("../../../Constants/flightType");

var _holiday = require("../../../Constants/holiday");

var actions = _interopRequireWildcard(require("./actions"));

var _selectors = require("./selectors");

var _counters = require("../../../Constants/counters");

var _strings = require("../../../Utils/strings");

var _api = require("../../../Utils/api");

var _holidaySearch = require("../../../Utils/holidaySearch");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// =======================================================================
// Dates
var fetchingDates = actions.fetchingDates,
    fetchingDatesError = actions.fetchingDatesError,
    fetchingDatesSuccess = actions.fetchingDatesSuccess,
    setDates = actions.setDates;
var END_DATE = _holiday.HOLIDAY_KEYS.END_DATE,
    START_DATE = _holiday.HOLIDAY_KEYS.START_DATE;

var initializeHolidayDates = function initializeHolidayDates(defaultNumberOfNights, minNights, maxNights) {
  return function (dispatch) {
    var defaultCheckIn = (0, _dateFns.startOfTomorrow)();

    var getDefaultNights = function getDefaultNights() {
      if (defaultNumberOfNights < minNights) return minNights;
      if (defaultNumberOfNights > maxNights) return maxNights;
      return defaultNumberOfNights;
    };

    var defaultNights = getDefaultNights(); // do not set dates if default number of nights is zero

    if (defaultNights) {
      var _dates;

      var defaultCheckOut = (0, _dateFns.addDays)(defaultCheckIn, getDefaultNights());
      var dates = (_dates = {}, (0, _defineProperty2.default)(_dates, START_DATE, defaultCheckIn), (0, _defineProperty2.default)(_dates, END_DATE, defaultCheckOut), _dates);
      dispatch(setDates(dates));
    }
  };
};

var setHolidaysDates = function setHolidaysDates(dates) {
  return function (dispatch) {
    var _allDates;

    var start = dates.start,
        end = dates.end;
    if (!start || !end) return;
    var allDates = (_allDates = {}, (0, _defineProperty2.default)(_allDates, START_DATE, start), (0, _defineProperty2.default)(_allDates, END_DATE, end), _allDates);
    dispatch(setDates(allDates));
  };
};

var setLocation = actions.setLocation,
    setValidLocation = actions.setValidLocation;
var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;

var initializeHolidayLocation = function initializeHolidayLocation(settings) {
  return function (dispatch, getState) {
    var _getState = getState(),
        userLocation = _getState.userLocation,
        config = _getState.config,
        flightSchedule = _getState.flightSchedule;

    var posCountryCode = config.getIn(['data', 'countryCode']);
    var userLocationCountryCode = userLocation.getIn(['data', 'Country']); // Is user in pos country?

    if (posCountryCode === userLocationCountryCode) {
      var code = userLocation.getIn(['data', 'Key']);
      var name = userLocation.getIn(['data', 'Name']);
      dispatch(setLocation(OUTBOUND, code, name, code, true));
    } else {
      var _code = config.getIn(['data', 'airportCode']);

      var _name = flightSchedule.getIn(['data', 'Airports', 'ById', _code, 'Name']);

      dispatch(setLocation(OUTBOUND, _code, _name, _code, true));
    }
  };
};

var resetInboundValidation = function resetInboundValidation() {
  return function (dispatch, getState) {
    var _getState2 = getState(),
        holidaySearch = _getState2.holidaySearch;

    var isInboundValid = holidaySearch.locations.getIn(['inbound', 'valid']) === false;
    isInboundValid && dispatch(setValidLocation(INBOUND, null));
  };
};

var validateAllHolidayLocation = function validateAllHolidayLocation() {
  return function (dispatch, getState) {
    var _getState3 = getState(),
        holidaySearch = _getState3.holidaySearch;

    var isInboundValid = !!holidaySearch.locations.getIn(['inbound', 'code']);
    var isOutboundValid = !!holidaySearch.locations.getIn(['outbound', 'code']);

    if (!isInboundValid) {
      dispatch(setValidLocation(INBOUND, isInboundValid));
    }

    if (!isOutboundValid) {
      dispatch(setValidLocation(OUTBOUND, isOutboundValid));
    }
  };
}; //Rooms


var onSetRooms = actions.onSetRooms;
var ROOM_COUNTER = _holiday.HOLIDAY_KEYS.ROOM_COUNTER,
    ROOMS = _holiday.HOLIDAY_KEYS.ROOMS,
    ROOM_ID = _holiday.HOLIDAY_KEYS.ROOM_ID,
    TRAVELLERS = _holiday.HOLIDAY_KEYS.TRAVELLERS;

var initializeRooms = function initializeRooms(defaultRooms) {
  return function (dispatch, getState) {
    var travellersData = (0, _defineProperty2.default)({}, ROOMS, _objectSpread({}, (0, _holidaySearch.getInitialRooms)(defaultRooms)));
    var order = Object.keys(travellersData.rooms);
    dispatch(onSetRooms(travellersData, order));
  };
}; //counter
//TODO: to be consolidated with common counter HIG-536


var initializeHolidayCounter = function initializeHolidayCounter(settings) {
  return function (dispatch, getState) {
    var state = getState()[_holiday.STATE_KEY] || {};
    var rooms = state[TRAVELLERS].get(ROOMS);
    rooms.forEach(function (room) {
      var roomId = room.get(ROOM_ID);
      var roomCounter = room.get(ROOM_COUNTER);

      if (!roomCounter) {
        return;
      }

      if (roomCounter.get('isLoaded')) {
        return;
      }

      var onCounterChange = actions.onCounterChange,
          onCounterInit = actions.onCounterInit;
      var ignoreQueryParams = true;
      var query = ignoreQueryParams ? [] : _qs.default.parse(window.location.search, {
        ignoreQueryPrefix: true
      });
      var ADULTS_CHILDREN_INFANT_RATIO = _holiday.GUESTS_COUNTER_RULES.ADULTS_CHILDREN_INFANT_RATIO,
          ADULTS_INFANTS_RATIO = _holiday.GUESTS_COUNTER_RULES.ADULTS_INFANTS_RATIO,
          MIN_TOTAL = _holiday.GUESTS_COUNTER_RULES.MIN_TOTAL,
          TOTAL = _holiday.GUESTS_COUNTER_RULES.TOTAL;
      var ADULTS = _counters.PAX_TYPES.ADULTS,
          CHILDREN = _counters.PAX_TYPES.CHILDREN,
          INFANTS = _counters.PAX_TYPES.INFANTS;
      dispatch(onCounterInit((0, _defineProperty2.default)({
        rules: {
          adults: {
            min: settings.get('MinimumAdults'),
            max: settings.get('MaximumAdults')
          },
          children: {
            min: settings.get('MinimumChildren'),
            max: settings.get('MaximumChildren')
          },
          infants: {
            min: settings.get('MinimumInfants'),
            max: settings.get('MaximumInfants')
          },
          total: settings.get('TotalPassengers') || TOTAL,
          minTotal: settings.get('MinimumNumberOfPassengers') || MIN_TOTAL,
          adultsChildrenRatio: settings.get('AdultChildRatio') || ADULTS_CHILDREN_INFANT_RATIO,
          adultsInfantsRatio: settings.get('AdultInfantRatio') || ADULTS_INFANTS_RATIO
        },
        isLoaded: true
      }, ROOM_ID, roomId)));
      var adultsCount = (0, _selectors.getAdultsByRoomId)(state, roomId);
      var childrenCount = (0, _selectors.getChildrenByRoomId)(state, roomId);
      var infantsCount = (0, _selectors.getInfantsByRoomId)(state, roomId);
      dispatch(onCounterChange((0, _strings.initialCount)(query[_counters.COUNT_QUERY_KEY.ADULTS], settings.get('ROM_Settings_DefaultAdultsPerRoom'), adultsCount), ADULTS, roomId));
      dispatch(onCounterChange((0, _strings.initialCount)(query[_counters.COUNT_QUERY_KEY.CHILDREN], settings.get('ROM_Settings_DefaultChildrenPerRoom'), childrenCount), CHILDREN, roomId));
      dispatch(onCounterChange((0, _strings.initialCount)(query[_counters.COUNT_QUERY_KEY.INFANTS], settings.get('ROM_Settings_DefaultInfantsPerRoom'), infantsCount), INFANTS, roomId));
    });
  };
};

var fetchCalendarDates = function fetchCalendarDates(url, direction, startDate, route) {
  return function (dispatch, getState) {
    dispatch(fetchingDates(direction));

    if (!url) {
      throw Error('No API url is defined for Holiday Search');
    }

    return (0, _api.fetchApi)(url).then(function (response) {
      var immutableData = (0, _immutable.fromJS)(Object.assign({}, response.data || {}));
      var data = immutableData.getIn(['routes', route, 'flights']);
      var dates = Array.from(data.keys());
      dispatch(fetchingDatesSuccess(direction, route, startDate, dates));
      return data;
    }).catch(function (error) {
      dispatch(fetchingDatesError(direction));
      var errorString = JSON.stringify(error.response.statusText);
      throw Error("Pricing Service API: Status: ".concat(error.response.status, ", Error: ").concat(errorString, ", URL: ").concat(url));
    });
  };
};

var _default = _objectSpread(_objectSpread({}, actions), {}, {
  fetchCalendarDates: fetchCalendarDates,
  initializeHolidayDates: initializeHolidayDates,
  initializeHolidayLocation: initializeHolidayLocation,
  validateAllHolidayLocation: validateAllHolidayLocation,
  resetInboundValidation: resetInboundValidation,
  initializeRooms: initializeRooms,
  initializeHolidayCounter: initializeHolidayCounter,
  setHolidaysDates: setHolidaysDates
});

exports.default = _default;