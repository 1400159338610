"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFetchingOutbound = exports.isFetchingInbound = exports.isErrorMsgDisplayed = exports.getTrackingData = exports.getTotalPAXByType = exports.getTotalPAX = exports.getSubmitData = exports.getStartDate = exports.getRooms = exports.getOutboundDays = exports.getOutboundCode = exports.getOutboundAirport = exports.getInfantsByRoomId = exports.getInboundName = exports.getInboundDays = exports.getInboundCode = exports.getInboundAirport = exports.getErrorsByRoomId = exports.getEndDate = exports.getChildrenByRoomId = exports.getAdultsByRoomId = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _flightType = require("../../../Constants/flightType");

var _holiday = require("../../../Constants/holiday");

var _analytics = require("../../../Constants/analytics");

var _dates = require("../../../Constants/dates");

var _counters = require("../../../Constants/counters");

var _dates2 = require("../../../Utils/dates");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// Constants
var DATE_OF_DEPARTURE = _holiday.SUBMIT_QUERY_KEYS.DATE_OF_DEPARTURE,
    DATE_OF_RETURN = _holiday.SUBMIT_QUERY_KEYS.DATE_OF_RETURN,
    DESTINATION = _holiday.SUBMIT_QUERY_KEYS.DESTINATION,
    DESTINATION_NAME = _holiday.SUBMIT_QUERY_KEYS.DESTINATION_NAME,
    ORIGIN = _holiday.SUBMIT_QUERY_KEYS.ORIGIN,
    GROUPS = _holiday.SUBMIT_QUERY_KEYS.GROUPS;
var ADULTS_ANALYTICS = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.ADULTS_ANALYTICS,
    CHILDREN_ANALYTICS = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.CHILDREN_ANALYTICS,
    DEPARTURE_DATE = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.DEPARTURE_DATE,
    DESTINATION_ANALYTICS = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.DESTINATION_ANALYTICS,
    INFANTS_ANALYTICS = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.INFANTS_ANALYTICS,
    NAME = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.NAME,
    ORIGIN_ANALYTICS = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.ORIGIN_ANALYTICS,
    RETURN_DATE = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.RETURN_DATE,
    ROOM_COUNT = _analytics.HOLIDAYS_ANALYTICS_QUERY_KEYS.ROOM_COUNT;
var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;
var DATES = _holiday.HOLIDAY_KEYS.DATES,
    END_DATE = _holiday.HOLIDAY_KEYS.END_DATE,
    START_DATE = _holiday.HOLIDAY_KEYS.START_DATE,
    AIRPORT = _holiday.HOLIDAY_KEYS.AIRPORT,
    CODE = _holiday.HOLIDAY_KEYS.CODE,
    COUNT = _holiday.HOLIDAY_KEYS.COUNT,
    LOCATIONS = _holiday.HOLIDAY_KEYS.LOCATIONS,
    LOCATION_NAME = _holiday.HOLIDAY_KEYS.NAME,
    ROOMS = _holiday.HOLIDAY_KEYS.ROOMS,
    ROOM_COUNTER = _holiday.HOLIDAY_KEYS.ROOM_COUNTER,
    TRAVELLERS = _holiday.HOLIDAY_KEYS.TRAVELLERS,
    TOTAL_PAX = _holiday.HOLIDAY_KEYS.TOTAL_PAX,
    TOTAL_ROOMS = _holiday.HOLIDAY_KEYS.TOTAL_ROOMS;
var ERRORS_KEY = _counters.COUNTER_KEYS.ERRORS_KEY,
    TOTAL_KEY = _counters.COUNTER_KEYS.TOTAL_KEY;
var ADULTS_CHILDREN_RATIO = _counters.types.ADULTS_CHILDREN_RATIO,
    ADULTS_INFANT_RATIO = _counters.types.ADULTS_INFANT_RATIO; // Dates

var isFetchingInbound = function isFetchingInbound(state) {
  return state[DATES].getIn([_holiday.HOLIDAY_KEYS.INBOUND, _holiday.HOLIDAY_KEYS.IS_FETCHING]);
};

exports.isFetchingInbound = isFetchingInbound;

var isFetchingOutbound = function isFetchingOutbound(state) {
  return state[DATES].getIn([_holiday.HOLIDAY_KEYS.OUTBOUND, _holiday.HOLIDAY_KEYS.IS_FETCHING]);
};

exports.isFetchingOutbound = isFetchingOutbound;

var getEndDate = function getEndDate(state) {
  return state[DATES].get(END_DATE);
};

exports.getEndDate = getEndDate;

var getStartDate = function getStartDate(state) {
  return state[DATES].get(START_DATE);
};

exports.getStartDate = getStartDate;

var getInboundDays = function getInboundDays(state) {
  return state[DATES].getIn([_holiday.HOLIDAY_KEYS.INBOUND]);
};

exports.getInboundDays = getInboundDays;

var getOutboundDays = function getOutboundDays(state) {
  return state[DATES].getIn([_holiday.HOLIDAY_KEYS.OUTBOUND]);
}; // Locations


exports.getOutboundDays = getOutboundDays;

var getInboundAirport = function getInboundAirport(state) {
  return state[LOCATIONS].getIn([INBOUND, AIRPORT]);
};

exports.getInboundAirport = getInboundAirport;

var getOutboundAirport = function getOutboundAirport(state) {
  return state[LOCATIONS].getIn([OUTBOUND, AIRPORT]);
};

exports.getOutboundAirport = getOutboundAirport;

var getInboundCode = function getInboundCode(state) {
  return state[LOCATIONS].getIn([INBOUND, CODE]);
};

exports.getInboundCode = getInboundCode;

var getInboundName = function getInboundName(state) {
  return state[LOCATIONS].getIn([INBOUND, LOCATION_NAME]);
};

exports.getInboundName = getInboundName;

var getOutboundCode = function getOutboundCode(state) {
  return state[LOCATIONS].getIn([OUTBOUND, CODE]);
}; // Rooms


exports.getOutboundCode = getOutboundCode;

var getRooms = function getRooms(state) {
  return state[TRAVELLERS];
};

exports.getRooms = getRooms;

var getAdultsByRoomId = function getAdultsByRoomId(state, roomId) {
  return state[TRAVELLERS].getIn([ROOMS, roomId, ROOM_COUNTER, COUNT, _counters.ADULTS]);
};

exports.getAdultsByRoomId = getAdultsByRoomId;

var getChildrenByRoomId = function getChildrenByRoomId(state, roomId) {
  return state[TRAVELLERS].getIn([ROOMS, roomId, ROOM_COUNTER, COUNT, _counters.CHILDREN]);
};

exports.getChildrenByRoomId = getChildrenByRoomId;

var getInfantsByRoomId = function getInfantsByRoomId(state, roomId) {
  return state[TRAVELLERS].getIn([ROOMS, roomId, ROOM_COUNTER, COUNT, _counters.INFANTS]);
};

exports.getInfantsByRoomId = getInfantsByRoomId;

var getErrorsByRoomId = function getErrorsByRoomId(state, roomId) {
  return state[TRAVELLERS].getIn([ROOMS, roomId, ROOM_COUNTER, ERRORS_KEY]);
};

exports.getErrorsByRoomId = getErrorsByRoomId;

var getTotalPAX = function getTotalPAX(state) {
  return state[TRAVELLERS].get(TOTAL_PAX);
};

exports.getTotalPAX = getTotalPAX;

var getTotalPAXByType = function getTotalPAXByType(state, passengerType) {
  var failureValue = -1;
  var travellers = state.travellers;

  if (!passengerType) {
    return failureValue;
  }

  return travellers.get(ROOMS).reduce(function (acc, room) {
    var typePax = room.getIn([ROOM_COUNTER, 'count', passengerType]);
    return acc + typePax;
  }, 0);
};

exports.getTotalPAXByType = getTotalPAXByType;

var getRoomsDetails = function getRoomsDetails(state) {
  var travellers = state.travellers;
  var roomsContainer = {};
  travellers.get(ROOMS).valueSeq().forEach(function (room, i) {
    roomsContainer["".concat(GROUPS, "[").concat(i, "][").concat(_counters.ADULTS, "]")] = room.getIn([ROOM_COUNTER, COUNT, _counters.ADULTS]);
    roomsContainer["".concat(GROUPS, "[").concat(i, "][").concat(_counters.CHILDREN, "]")] = room.getIn([ROOM_COUNTER, COUNT, _counters.CHILDREN]);
    roomsContainer["".concat(GROUPS, "[").concat(i, "][").concat(_counters.INFANTS, "]")] = room.getIn([ROOM_COUNTER, COUNT, _counters.INFANTS]);
  });
  return roomsContainer;
};

var getSubmitData = function getSubmitData(state) {
  var _ref;

  return _ref = {}, (0, _defineProperty2.default)(_ref, ORIGIN, getOutboundCode(state)), (0, _defineProperty2.default)(_ref, DESTINATION, getInboundCode(state)), (0, _defineProperty2.default)(_ref, DESTINATION_NAME, getInboundName(state)), (0, _defineProperty2.default)(_ref, DATE_OF_DEPARTURE, getStartDate(state)), (0, _defineProperty2.default)(_ref, DATE_OF_RETURN, getEndDate(state)), (0, _defineProperty2.default)(_ref, ROOMS, _objectSpread({}, getRoomsDetails(state))), _ref;
};

exports.getSubmitData = getSubmitData;

var isErrorMsgDisplayed = function isErrorMsgDisplayed(state) {
  return state.travellers.get(ROOMS).some(function (room) {
    return room.getIn([ROOM_COUNTER, ERRORS_KEY, TOTAL_KEY]) === 2 || room.getIn([ROOM_COUNTER, ERRORS_KEY, _counters.ADULTS, _counters.MAX]) === 2 || room.getIn([ROOM_COUNTER, ERRORS_KEY, _counters.ADULTS, _counters.MIN]) === 2 || room.getIn([ROOM_COUNTER, ERRORS_KEY, _counters.CHILDREN, _counters.MAX]) === 2 || room.getIn([ROOM_COUNTER, ERRORS_KEY, _counters.INFANTS, _counters.MAX]) === 2 || room.getIn([ROOM_COUNTER, ERRORS_KEY, ADULTS_CHILDREN_RATIO]) === 2 || room.getIn([ROOM_COUNTER, ERRORS_KEY, ADULTS_INFANT_RATIO]) === 2;
  });
}; //analytics


exports.isErrorMsgDisplayed = isErrorMsgDisplayed;

var getTrackingData = function getTrackingData(state) {
  var _ref2;

  return _ref2 = {}, (0, _defineProperty2.default)(_ref2, ADULTS_ANALYTICS, getTotalPAXByType(state, _counters.ADULTS)), (0, _defineProperty2.default)(_ref2, CHILDREN_ANALYTICS, getTotalPAXByType(state, _counters.CHILDREN)), (0, _defineProperty2.default)(_ref2, DEPARTURE_DATE, (0, _dates2.dateFormatterToString)(getStartDate(state), _dates.DEFAULT_DATE_FORMAT)), (0, _defineProperty2.default)(_ref2, DESTINATION_ANALYTICS, getInboundCode(state)), (0, _defineProperty2.default)(_ref2, INFANTS_ANALYTICS, getTotalPAXByType(state, _counters.INFANTS)), (0, _defineProperty2.default)(_ref2, NAME, _analytics.HOLIDAYS_NAMESPACE), (0, _defineProperty2.default)(_ref2, ORIGIN_ANALYTICS, getOutboundCode(state)), (0, _defineProperty2.default)(_ref2, RETURN_DATE, (0, _dates2.dateFormatterToString)(getEndDate(state), _dates.DEFAULT_DATE_FORMAT)), (0, _defineProperty2.default)(_ref2, ROOM_COUNT, getRooms(state).get(TOTAL_ROOMS)), _ref2;
};

exports.getTrackingData = getTrackingData;