"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSiteLabels = void 0;

var getSiteLabels = function getSiteLabels(state) {
  return state.get('data');
};

exports.getSiteLabels = getSiteLabels;