// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._list__list--MnXcl{list-style:none;margin:1.25rem 0}@media only screen and (min-width:48em){._list__list--MnXcl{margin:0}}._list__listItem--JXRJa{border-bottom:.0625rem solid #dedede;display:block;line-height:1.125rem;width:100%}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/TravelAlerts/Navigation/_list.scss"],"names":[],"mappings":"AAAA,oBAAM,eAAA,CAAgB,gBAAgB,CAAC,wCAAwC,oBAAM,QAAQ,CAAC,CAAC,wBAAU,oCAAA,CAAqC,aAAA,CAAc,oBAAA,CAAqB,UAAU","sourcesContent":[".list{list-style:none;margin:1.25rem 0}@media only screen and (min-width:48em){.list{margin:0}}.listItem{border-bottom:.0625rem solid #dedede;display:block;line-height:1.125rem;width:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "_list__list--MnXcl",
	"listItem": "_list__listItem--JXRJa"
};
export default ___CSS_LOADER_EXPORT___;
