"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_KEYS = void 0;
var ALT = 'Alt';
var MEDIA = 'Media';
var RENDER_AS_PICTURE_ELEMENT = 'RenderAsPictureElement';
var RESPONSIVE_SOURCES = 'Sources';
var SIZES = 'Sizes';
var SRC = 'Src';
var WIDTH = 'Width';
var DEFAULT_KEYS = {
  ALT: ALT,
  MEDIA: MEDIA,
  RENDER_AS_PICTURE_ELEMENT: RENDER_AS_PICTURE_ELEMENT,
  RESPONSIVE_SOURCES: RESPONSIVE_SOURCES,
  SIZES: SIZES,
  SRC: SRC,
  WIDTH: WIDTH
};
exports.DEFAULT_KEYS = DEFAULT_KEYS;