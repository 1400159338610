"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.guidToId = exports.getSearchConfig = exports.getLastUrlSegment = exports.format2Array = exports.format = exports.extractScripts = exports.extractGuid = exports.extractAttribute = exports.extract = exports.ellipsis = exports.deSerialize = exports.createTreeDataAPIRequestUrl = exports.createAgnosticURL = exports.capitalize = exports.camelToKebab = exports.appendQuerystringToUrl = exports.alphabeticalSort = void 0;
exports.highlightStringMatch = highlightStringMatch;
exports.updateParam = exports.underscoreToCamel = exports.truncate = exports.titleCaseToCamel = exports.stringToKebab = exports.searchQuery = exports.removeQueryStringFromUrl = exports.positionCursorLast = exports.isInteger = exports.isAlpha = exports.initialCount = exports.immutableStringDefaultVal = exports.imageToSrc = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.regexp.replace.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.regexp.split.js");

require("core-js/modules/es6.regexp.match.js");

require("core-js/modules/es6.regexp.constructor.js");

require("core-js/modules/es6.object.assign.js");

require("core-js/modules/es6.number.constructor.js");

require("core-js/modules/es6.regexp.search.js");

var _fuse = _interopRequireDefault(require("fuse.js"));

var _immutable = require("immutable");

var _qs = _interopRequireDefault(require("qs"));

var _media = require("../Constants/media");

var _webforms = require("../Constants/webforms");

var _object = require("../Utils/object");

/**
 * String helpers
 * @namespace Utils/strings
 */

/**
 * Replicated c#'s string.format routine
 * @function format
 * @memberof Utils/strings
 * @param {string} args - Any string
 * @returns {string}
 */
var format = function format() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var tokens = args.slice(1);
  return args[0].replace(/{(\d+)}/g, function (match, number) {
    return typeof tokens[number] !== 'undefined' ? tokens[number] : match;
  });
};
/**
 * Capitalize string
 * @function capitalize
 * @memberof Utils/strings
 * @param {string} str
 * @returns {string}
 */


exports.format = format;

var capitalize = function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
/**
 * Truncate string
 * @function truncate
 * @memberof Utils/strings
 * @param {string} str
 * @param {number} len
 * @returns {string}
 */


exports.capitalize = capitalize;

var truncate = function truncate(str) {
  var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return len && str.length > len ? str.substring(0, len) : str;
};
/**
 * alphabeticalSort string
 * @function alphabeticalSort
 * @memberof Utils/strings
 * @param {string} a
 * @param {string} b
 * @returns {number} -1, 0 if equal, or postitive
 */


exports.truncate = truncate;

var alphabeticalSort = function alphabeticalSort(a, b) {
  return a.localeCompare(b);
};
/**
 * Truncate string
 * @function ellipsis
 * @memberof Utils/strings
 * @param {string} str
 * @param {number} len
 * @param {string} token
 * @returns {string}
 */


exports.alphabeticalSort = alphabeticalSort;

var ellipsis = function ellipsis(str) {
  var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '...';

  if (typeof str === 'string' && typeof token === 'string') {
    return len < str.length ? "".concat(truncate(str, len)).concat(token) : str;
  }

  return '';
};
/**
 * Converts underscore strings into camelCase
 * @function underscoreToCamel
 * @memberof Utils/strings
 * @param {string} str - Any string
 * @returns {string}
 */


exports.ellipsis = ellipsis;

var underscoreToCamel = function underscoreToCamel(str) {
  return str.toLowerCase().replace(/_(.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
};
/**
 * Converts camelCase strings into kebab-case
 * @function camelToKebab
 * @memberof Utils/strings
 * @param {string} str - Any string
 * @returns {string}
 */


exports.underscoreToCamel = underscoreToCamel;

var camelToKebab = function camelToKebab(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};
/**
 * Converts strings into kebab-case
 * @function stringToKebab
 * @memberof Utils/strings
 * @param {string} str - Any string
 * @returns {string}
 */


exports.camelToKebab = camelToKebab;

var stringToKebab = function stringToKebab(string) {
  return string.replace(/^\s+/, '') // trims preceding whitespace
  .replace(/\s+$/, '') // trims trailing whitespace
  .replace(/\s\s+/g, ' ') // trims multiple spaces
  .replace(/ /g, '-') // replaces spaces with hyphen
  .replace(/([a-z])([A-Z])/g, '$1-$2') // camelCase to kebab
  .toLowerCase();
};
/**
 * format components or string to placeholder
 * @param {string} sentence - string with placeholder e.g {0} {1}....
 * @param {Array} tokens - tokens of string or component
 * @return {Array} data - array of string or component
 */


exports.stringToKebab = stringToKebab;

var format2Array = function format2Array(sentence, tokens) {
  var placeholderRegex = /(\{\d\})/;
  var indexRegex = /\d/;
  return sentence ? sentence.split(placeholderRegex).map(function (item) {
    if (item.match(placeholderRegex)) {
      return Array.isArray(tokens) && tokens[item.match(indexRegex)] ? tokens[item.match(indexRegex)] : item;
    }

    return item;
  }).reduce(function (accumlate, current) {
    if (current) {
      accumlate.push(current);
    }

    return accumlate;
  }, []) : tokens;
};
/**
 * Determine if string contains all integers
 * @function isInteger
 * @memberof Utils/strings
 * @param {string} str - Any string
 * @returns {boolean}
 */


exports.format2Array = format2Array;

var isInteger = function isInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};
/**
 * Determine if string contains all alphabets
 * @function isAlpha
 * @memberof Utils/strings
 * @param {string} str - Any string
 * @returns {boolean}
 */


exports.isInteger = isInteger;

var isAlpha = function isAlpha(str) {
  return /^[a-zA-Z]+$/.test(str);
};
/**
 * Render field name should property be unavailable
 * @function immutableStringDefaultVal
 * @memberof Utils/strings
 * @param {string} args - a list of string parameters
 * @return {Function} A function that accepts any string parameters,
 * these parameters will be rendered to the page when a value is not met
 */


exports.isAlpha = isAlpha;

var immutableStringDefaultVal = function immutableStringDefaultVal(obj) {
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return obj && _immutable.Iterable.isIterable(obj) && typeof obj.getIn(args) === 'string' ? obj.getIn(args) : "{__".concat(args.join('_'), "__}");
  };
};
/**
 * Deserialize a simple json object that only contains primitive data types
 * @function deSerialize
 * @memberof Utils/strings
 * @param {string} serializedJavascript - a stringified json object
 * @return {Object} - The deserialized json object
 */


exports.immutableStringDefaultVal = immutableStringDefaultVal;

var deSerialize = function deSerialize(serializedJavascript) {
  return eval("(".concat(serializedJavascript, ")"));
}; // eslint-disable-line no-eval


exports.deSerialize = deSerialize;

var initialCount = function initialCount(query, setting, initial) {
  if (isInteger(query)) {
    return query;
  }

  return typeof setting === 'number' || isInteger(setting) ? setting : initial;
};
/**
 * Convert guid to id
 * @param {string} guid of format {ABC...} remove the curly bracket and to lower case
 */


exports.initialCount = initialCount;

var guidToId = function guidToId(guid) {
  return guid.replace(/^{+(.*)}+$/g, '$1').toLowerCase();
};
/**
 * Updates and appends params to url
 * @function updateParam
 * @memberof Utils/strings
 * @param {string} uri - The url to update or append param to
 * @param {string} key - The paramater key
 * @param {string} value - The parameter value
 * @returns {string} results - The updated url
 */


exports.guidToId = guidToId;

var updateParam = function updateParam(uri, key, value) {
  var re = new RegExp("([?&])".concat(key, "=.*?(&|$)"), 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';

  if (uri.match(re)) {
    return uri.replace(re, "$1".concat(key, "=").concat(encodeURIComponent(value), "$2"));
  }

  return "".concat(uri).concat(separator).concat(key, "=").concat(encodeURIComponent(value));
};
/**
 * extract first guid from a string
 * @function extractGuid
 * @memberof Utils/strings
 * @param {boolean} stripHyphen - boolean to strip hyphen
 * @param {string} value - string containing guid
 * @returns {string} guid or empty string
 */


exports.updateParam = updateParam;

var extractGuid = function extractGuid() {
  var stripHyphen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return function (value) {
    var re = /[0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12}/;
    var match = re.exec(value);
    var matchingValue = match ? match[0] : '';
    return stripHyphen ? matchingValue.replace(/-/g, '') : matchingValue;
  };
};
/**
 * extract first attribute from a string
 * @function extractAttribute
 * @memberof Utils/strings
 * @param {string} attribute - attribute to extract
 * @param {string} value - string containing attribute
 * @returns {string} attribute or empty string
 */


exports.extractGuid = extractGuid;

var extractAttribute = function extractAttribute(attribute) {
  return function (value) {
    var re = new RegExp("".concat(attribute, "=\"(.*?)\""));
    var match = re.exec(value);
    var matchingValue = match ? match[1] : '';
    return matchingValue;
  };
};
/**
 * get path to media asset
 * @function imageToSrc
 * @memberof Utils/strings
 * @param {string} mediaPath - media directory
 * @param {string} image - string guid ie. "<image mediaid="{CE43C299-C952-46E1-B4B4-D4E3F0963518}" />"
 * @returns {string} attribute or empty string ie. /_/media/CE43C299C95246E1B4B4D4E3F0963518
 */


exports.extractAttribute = extractAttribute;

var imageToSrc = function imageToSrc(image) {
  var mediaPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _media.MEDIA_PATH;

  if (!mediaPath || mediaPath === '' || !image) {
    return '';
  }

  var imageGUID = extractGuid(true)(image);
  var imageSrc = imageGUID ? format(mediaPath, imageGUID) : '';
  return imageSrc;
};
/**
 * wrapper substring base on token regions
 * @function highlightStringMatch
 * @memberof Utils/strings
 * @param {string} text - string
 * @param {array} regions with array pairs (can be multiple) ie. [0,3] will highlight first 3w char
 * @param {string} htmlTag - element to wrap - default to 'b'
 * @returns {string} string with characters
 */


exports.imageToSrc = imageToSrc;

function highlightStringMatch(text, regions) {
  var htmlTag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'b';
  if (!regions) return text;
  var content = '';
  var wrapperBoundary = 0;
  var openTag = "<".concat(htmlTag, ">");
  var closeTag = "</".concat(htmlTag, ">");
  regions.forEach(function (region) {
    var indexStart = region[0];
    var indexEnd = region[1];
    var wrapperPositionStart = indexStart;
    var wrapperPositionEnd = indexEnd + 1;
    content += "".concat(text.substring(wrapperBoundary, indexStart)).concat(openTag).concat(text.substring(wrapperPositionStart, wrapperPositionEnd)).concat(closeTag);
    wrapperBoundary = region[1] + 1;
  });
  content += text.substring(wrapperBoundary);
  return content;
}
/**
 * extract string between the provided wrapper
 * @function extract
 * @memberof Utils/strings
 * @param {string} before - before wrapper
 * @param {string} after - after wrapper
 * @param {string} value - string to extract
 * @returns {string} string content to extract
 */


var extract = function extract(before, after) {
  return function (value) {
    var re = new RegExp("".concat(before, "([\\s\\S]*?)").concat(after));
    var match = re.exec(value);
    return match ? match[1] : '';
  };
};
/**
 * extract multiple <script> content
 * @function extractScripts
 * @memberof Utils/strings
 * @param {string} value - string containing <script>
 * @returns {string} <script> content
 */


exports.extract = extract;

var extractScripts = function extractScripts(value) {
  var re = /<script\b[^>]*>([\s\S]*?)<\/script>/g;
  var match;
  var scripts = '';
  /*eslint-disable */

  while (match = re.exec(value)) {
    scripts += match[1];
  }
  /*eslint-enable */


  return scripts;
};
/**
 * ensures cursor at end of input
 * @function positionCursorLast
 * @memberof Utils/strings
 * @param {string} inputRef - ref to input element
 * @returns null
 */


exports.extractScripts = extractScripts;

var positionCursorLast = function positionCursorLast(inputRef) {
  if (!inputRef.current) {
    return;
  }

  var searchComponent = inputRef.current;
  var searchInput = searchComponent && searchComponent.getElementsByTagName('input')[0];

  if (!searchInput) {
    return;
  }

  searchInput.focus();
  var value = searchInput.value;
  var length = value.length;
  setTimeout(function () {
    searchInput.setSelectionRange(length, length);
  }, 1);
};
/**
 * Generate Tree Data API Request Url
 * @function createTreeDataAPIRequestUrl
 * @memberof Utils/strings
 * @param {string} apiEndPoint - base url
 * @param {Map} submitModel - submitModal
 * @returns {string} api request url
 */


exports.positionCursorLast = positionCursorLast;

var createTreeDataAPIRequestUrl = function createTreeDataAPIRequestUrl(apiEndPoint, submitModel) {
  var _fromJS;

  if (!apiEndPoint || !submitModel.size) {
    return null;
  }

  var ITEMS = _webforms.API_REQUEST_QUERY_KEY.ITEMS,
      SEPARATOR = _webforms.API_REQUEST_QUERY_KEY.SEPARATOR;
  var queries = (0, _immutable.fromJS)((_fromJS = {}, (0, _defineProperty2.default)(_fromJS, ITEMS, submitModel.get(ITEMS)), (0, _defineProperty2.default)(_fromJS, SEPARATOR, submitModel.get(SEPARATOR)), _fromJS));

  var queryStrings = _qs.default.stringify(queries.toJS(), {
    encode: true,
    skipNulls: true,
    addQueryPrefix: true
  });

  return "".concat(apiEndPoint).concat(queryStrings);
};
/**
 * Get last segment of an URL
 * @function getLastUrlSegment
 * @memberof Utils/strings
 * @param {string} url - full url
 * @returns {string} url segment
 */


exports.createTreeDataAPIRequestUrl = createTreeDataAPIRequestUrl;

var getLastUrlSegment = function getLastUrlSegment(url) {
  var returnUrl = url.replace(/\/$/, ''); // strip trailing slash

  return returnUrl.substring(returnUrl.lastIndexOf('/') + 1);
};
/**
 * Remove the querystring portion and hash from an URL
 * @function removeQueryStringFromUrl
 * @memberof Utils/strings
 * @param {string} url - full url
 * @returns {string} url with querystring and hash removed
 */


exports.getLastUrlSegment = getLastUrlSegment;

var removeQueryStringFromUrl = function removeQueryStringFromUrl(url) {
  if (!url) {
    return '';
  }

  var returnUrl = url.split('?')[0].split('#')[0];
  return returnUrl;
};
/**
 * Convert string from TitleCase to camelCase
 * @function titleCaseToCamel
 * @memberof Utils/strings
 * @param {string} text
 * @returns {string} text
 */


exports.removeQueryStringFromUrl = removeQueryStringFromUrl;

var titleCaseToCamel = function titleCaseToCamel(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
};
/**
 * Create protocol agnostic url from string
 * @function createAgnosticURL
 * @memberof Utils/strings
 * @param {string} url
 * @returns {string} url
 */


exports.titleCaseToCamel = titleCaseToCamel;

var createAgnosticURL = function createAgnosticURL(url) {
  if (!url) return '';
  if (url.substring(0, 7) === 'http://') return url.substring(5);
  if (url.substring(0, 8) === 'https://') return url.substring(6);
  if (url.substring(0, 2) !== '//') return "//".concat(url);
  return url;
};
/**
 * Appends querystring parameters to an url
 * @function appendQuerystringToUrl
 * @memberof Utils/strings
 * @param {string} url
 * @param {string} querystring - querystring parameters excluding ?
 * @returns {string} url
 */


exports.createAgnosticURL = createAgnosticURL;

var appendQuerystringToUrl = function appendQuerystringToUrl(url, querystring) {
  if (!url) return '';
  var urlHasQuerystring = url.indexOf('?') !== -1;
  var separator = urlHasQuerystring ? '&' : '?';
  return "".concat(url).concat(separator).concat(querystring);
};

exports.appendQuerystringToUrl = appendQuerystringToUrl;
var getDefaultSearchSettings = {
  keys: [{
    name: 'Name',
    weight: 0.5
  }, {
    name: 'Id',
    weight: 0.2
  }, {
    name: 'Country',
    weight: 0.1
  }, {
    name: 'CountryOverride',
    weight: 0.1
  }, {
    name: 'Region',
    weight: 0.1
  }],
  caseSensitive: false,
  distance: 0,
  findAllMatches: false,
  includeMatches: true,
  includeScore: true,
  isFuzzySearchEnabled: false,
  location: 0,
  minMatchCharLength: 0,
  minSearchCharLength: 0,
  shouldSort: true,
  threshold: 0.2
};
/**
 * Create fuzzy search configuration from the search configuration
 * @function getSearchConfig
 * @memberof Utils/strings
 * @param {Set} searchConfig
 * @param {Map<string, *>} fuzzySearchConfigSet
 * @param {[setting: string]: string | number} overrideSettings - optional, merges with default settings
 * @returns { [setting: string]: string | number }
 */

var getSearchConfig = function getSearchConfig(searchConfig, fuzzySearchConfigSet) {
  var overrideSettings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var fuzzySearchConfig = (0, _object.getMapSubset)(searchConfig, fuzzySearchConfigSet);
  var options = {};
  var defaults = getDefaultSearchSettings;

  if (overrideSettings) {
    defaults = Object.assign(defaults, overrideSettings);
  }

  defaults.isFuzzySearchEnabled = Number(fuzzySearchConfig.get('EnableFuzzySearch')) > defaults.minMatchCharLength;
  defaults.minSearchCharLength = defaults.isFuzzySearchEnabled ? fuzzySearchConfig.get('FuzzySearchMinMatchCharLength') : 0;

  if (defaults.isFuzzySearchEnabled) {
    options = {
      location: fuzzySearchConfig.get('FuzzySearchLocation'),
      distance: fuzzySearchConfig.get('FuzzySearchDistance'),
      maxPatternLength: fuzzySearchConfig.get('FuzzySearchMaxPatternLength'),
      minMatchCharLength: fuzzySearchConfig.get('FuzzySearchMinMatchCharLength'),
      threshold: fuzzySearchConfig.get('FuzzySearchThreshold')
    };
  }

  return Object.assign({}, defaults, options);
};
/**
 * Queries a Map by string using search config
 * @function searchQuery
 * @memberof Utils/strings
 * @param {string} needle
 * @param {Map<string, *>} haystack
 * @param { [setting: string]: string | number } searchConfig
 * @return {Map<string, *>}
 */


exports.getSearchConfig = getSearchConfig;

var searchQuery = function searchQuery(needle, haystack, searchConfig) {
  var fuse = new _fuse.default(haystack.toJS(), searchConfig);
  return (0, _immutable.fromJS)(fuse.search(needle));
};

exports.searchQuery = searchQuery;