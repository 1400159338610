"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.types = exports.THEMES = exports.PAX_TYPES = exports.MIN = exports.MAX = exports.INFANTS = exports.COUNT_QUERY_KEY = exports.COUNT_KEYS = exports.COUNTER_KEYS = exports.CHILDREN = exports.ADULTS = void 0;
// types
var ADULTS_CHILDREN_RATIO = 'adultsChildrenRatio';
var ADULTS_INFANT_RATIO = 'adultsInfantsRatio';
var MAXIMUM_ADULTS = 'MAXIMUM_ADULTS';
var MINIMUM_ADULTS = 'MINIMUM_ADULTS';
var MAXIMUM_CHILDREN = 'MAXIMUM_CHILDREN';
var MINIMUM_CHILDREN = 'MINIMUM_CHILDREN';
var MAXIMUM_INFANTS = 'MAXIMUM_INFANTS';
var MINIMUM_INFANTS = 'MINIMUM_INFANTS';
var TOTAL = 'TOTAL';
var MIN_TOTAL = 'MIN_TOTAL';
var INFANTS_ADULTS_RATIO = 'INFANTS_ADULTS_RATIO';
var CHILDREN_ADULTS_RATIO = 'CHILDREN_ADULTS_RATIO';
var FARE_IN_PAIRS = 'FARE_IN_PAIRS';
var VANILLA = 'vanilla';
var TRAVELLER = 'traveller'; // Common

var ADULTS = 'adults';
exports.ADULTS = ADULTS;
var CHILDREN = 'children';
exports.CHILDREN = CHILDREN;
var INFANTS = 'infants';
exports.INFANTS = INFANTS;
var MAX = 'max';
exports.MAX = MAX;
var MIN = 'min'; // Pax types

exports.MIN = MIN;
var PAX_TYPES = {
  ADULTS: ADULTS,
  CHILDREN: CHILDREN,
  INFANTS: INFANTS
}; // Count query key

exports.PAX_TYPES = PAX_TYPES;
var COUNT_QUERY_KEY = {
  ADULTS: ADULTS,
  CHILDREN: CHILDREN,
  INFANTS: INFANTS
};
exports.COUNT_QUERY_KEY = COUNT_QUERY_KEY;
var types = {
  ADULTS_CHILDREN_RATIO: ADULTS_CHILDREN_RATIO,
  ADULTS_INFANT_RATIO: ADULTS_INFANT_RATIO,
  MAXIMUM_ADULTS: MAXIMUM_ADULTS,
  MINIMUM_ADULTS: MINIMUM_ADULTS,
  MAXIMUM_CHILDREN: MAXIMUM_CHILDREN,
  MINIMUM_CHILDREN: MINIMUM_CHILDREN,
  MAXIMUM_INFANTS: MAXIMUM_INFANTS,
  MINIMUM_INFANTS: MINIMUM_INFANTS,
  TOTAL: TOTAL,
  MIN_TOTAL: MIN_TOTAL,
  INFANTS_ADULTS_RATIO: INFANTS_ADULTS_RATIO,
  CHILDREN_ADULTS_RATIO: CHILDREN_ADULTS_RATIO,
  FARE_IN_PAIRS: FARE_IN_PAIRS
};
exports.types = types;
var THEMES = {
  VANILLA: VANILLA,
  TRAVELLER: TRAVELLER
}; // Count keys

exports.THEMES = THEMES;
var COUNT_KEYS = {
  ADULTS_KEY: ADULTS,
  CHILDREN_KEY: CHILDREN,
  INFANTS_KEY: INFANTS
}; // Counter keys

exports.COUNT_KEYS = COUNT_KEYS;
var COUNT_KEY = 'count';
var ERRORS_KEY = 'errors';
var TOTAL_KEY = 'total';
var IS_LOADED_KEY = 'isLoaded';
var COUNTER_KEYS = {
  COUNT_KEY: COUNT_KEY,
  ERRORS_KEY: ERRORS_KEY,
  TOTAL_KEY: TOTAL_KEY,
  IS_LOADED_KEY: IS_LOADED_KEY
};
exports.COUNTER_KEYS = COUNTER_KEYS;