"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lockBodyScroll = lockBodyScroll;
exports.lockBodyScrollByRef = lockBodyScrollByRef;
exports.unlockBodyScroll = unlockBodyScroll;

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _bodyScrollLock = require("body-scroll-lock");

var bodyLockOptions = {
  allowTouchMove: function allowTouchMove(
  /** @type {HTMLElement | null} */
  el) {
    var element = el;

    while (element && element !== document.body) {
      if (element.getAttribute('data-body-scroll-lock-ignore') !== null) {
        return true;
      }

      element = element.parentElement;
    }

    return false;
  },
  reserveScrollBarGap: true
};
/**
 * lock the body background
 * requires a query selector to be present on the page
 * @function lockBodyScroll
 * @memberof Utils/bodyLock
 * @param {string} targetQuerySelector
 */

function lockBodyScroll() {
  var targetQuerySelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '[data-allow-scroll-anchor]';
  var scrollableElements = document.querySelectorAll(targetQuerySelector);
  Array.from(scrollableElements).forEach(function (el) {
    return (0, _bodyScrollLock.disableBodyScroll)(el, bodyLockOptions);
  });
}
/**
 * lock the body background by ref
 * @function lockBodyScrollByRef
 * @memberof Utils/bodyLock
 * @param {React.RefObject<any} ref
 */


function lockBodyScrollByRef(ref) {
  if (!ref.current) return;
  (0, _bodyScrollLock.disableBodyScroll)(ref.current, bodyLockOptions);
}
/**
 * unlock the body background
 * @function unlockBodyScroll
 * @memberof Utils/bodyLock
 */


function unlockBodyScroll() {
  (0, _bodyScrollLock.clearAllBodyScrollLocks)();
}