"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _momentEs = _interopRequireDefault(require("moment-es6"));

var _immutable = require("immutable");

var _locale = require("../../../Utils/locale");

var _actions = require("./actions");

var fetchLocale = function fetchLocale(culture) {
  return function (dispatch) {
    dispatch((0, _actions.fetchingLocale)());
    return (0, _locale.importLocale)(culture).then(function (locale) {
      _momentEs.default.defineLocale(culture, locale.default);

      _momentEs.default.locale(culture);

      dispatch((0, _actions.fetchingLocaleSuccess)((0, _immutable.fromJS)(locale.default)));
    });
  };
};

var _default = fetchLocale;
exports.default = _default;