"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSearchLocation = exports.setPax = exports.setLocation = exports.setInvalidLocation = exports.setDates = exports.setBusiness = exports.resetValidation = exports.resetSearchLocation = exports.resetLocation = exports.resetDates = exports.onInitAccommodationType = exports.onCounterInit = exports.onCounterIncrement = exports.onCounterDecrement = exports.onCounterChange = exports.onAccommodationTypeChange = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _immutable = require("immutable");

var _types = _interopRequireWildcard(require("./types"));

var _accommodation = require("../../../Constants/accommodation");

var _Counter = require("../../Common/Counter");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var HOTEL = _accommodation.ACCOMMODATION_TYPE_IDS.HOTEL,
    AIRBNB = _accommodation.ACCOMMODATION_TYPE_IDS.AIRBNB; // Accommodation Type

var ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH = _types.default.ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH,
    ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH = _types.default.ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH;
var onInitAccommodationType = (0, _reduxActions.createAction)(ON_INIT_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH, function (accommodationTypeLabels) {
  var _initData;

  var selected = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var hotel = accommodationTypeLabels.hotel,
      airbnb = accommodationTypeLabels.airbnb;
  var initData = (_initData = {}, (0, _defineProperty2.default)(_initData, HOTEL, hotel), (0, _defineProperty2.default)(_initData, AIRBNB, airbnb), _initData);
  return (0, _immutable.fromJS)(_objectSpread({
    data: initData
  }, selected && {
    selected: selected
  }));
});
exports.onInitAccommodationType = onInitAccommodationType;
var onAccommodationTypeChange = (0, _reduxActions.createAction)(ONCHANGE_ACCOMMODATION_TYPE_ACCOMMODATION_SEARCH, function (selected) {
  return (0, _immutable.fromJS)({
    selected: selected
  });
}); // Location

exports.onAccommodationTypeChange = onAccommodationTypeChange;
var RESET_LOCATION_ACCOMMODATION_SEARCH = _types.default.RESET_LOCATION_ACCOMMODATION_SEARCH,
    RESET_SEARCH_ACCOMMODATION_SEARCH = _types.default.RESET_SEARCH_ACCOMMODATION_SEARCH,
    SEARCH_LOCATION_ACCOMMODATION_SEARCH = _types.default.SEARCH_LOCATION_ACCOMMODATION_SEARCH,
    SET_LOCATION_ACCOMMODATION_SEARCH = _types.default.SET_LOCATION_ACCOMMODATION_SEARCH;
var resetLocation = (0, _reduxActions.createAction)(RESET_LOCATION_ACCOMMODATION_SEARCH, function () {
  return null;
});
exports.resetLocation = resetLocation;
var resetSearchLocation = (0, _reduxActions.createAction)(RESET_SEARCH_ACCOMMODATION_SEARCH, function () {
  return null;
});
exports.resetSearchLocation = resetSearchLocation;
var setSearchLocation = (0, _reduxActions.createAction)(SEARCH_LOCATION_ACCOMMODATION_SEARCH, function (query) {
  return {
    query: query
  };
});
exports.setSearchLocation = setSearchLocation;
var setLocation = (0, _reduxActions.createAction)(SET_LOCATION_ACCOMMODATION_SEARCH, function (selected) {
  return (0, _immutable.fromJS)({
    selected: selected
  });
}); // Dates

exports.setLocation = setLocation;
var SET_DATES_ACCOMMODATION_SEARCH = _types.default.SET_DATES_ACCOMMODATION_SEARCH,
    RESET_DATES_ACCOMMODATION_SEARCH = _types.default.RESET_DATES_ACCOMMODATION_SEARCH,
    RESET_VALIDATION_ACCOMMODATION_SEARCH = _types.default.RESET_VALIDATION_ACCOMMODATION_SEARCH,
    SET_INVALID_LOCATION_ACCOMMODATION_SEARCH = _types.default.SET_INVALID_LOCATION_ACCOMMODATION_SEARCH;
var resetDates = (0, _reduxActions.createAction)(RESET_DATES_ACCOMMODATION_SEARCH, function () {
  return null;
});
exports.resetDates = resetDates;
var setDates = (0, _reduxActions.createAction)(SET_DATES_ACCOMMODATION_SEARCH, function (dates) {
  return (0, _immutable.fromJS)({
    dates: dates
  });
}); // Business

exports.setDates = setDates;
var SET_BUSINESS_ACCOMMODATION_SEARCH = _types.default.SET_BUSINESS_ACCOMMODATION_SEARCH;
var setBusiness = (0, _reduxActions.createAction)(SET_BUSINESS_ACCOMMODATION_SEARCH, function (isBusinessTrip) {
  return {
    isBusinessTrip: isBusinessTrip
  };
}); // Guests counter

exports.setBusiness = setBusiness;

var _createCounterActions = (0, _Counter.createCounterActions)(_types.namespace),
    onCounterChange = _createCounterActions.onCounterChange,
    onCounterDecrement = _createCounterActions.onCounterDecrement,
    onCounterIncrement = _createCounterActions.onCounterIncrement,
    onCounterInit = _createCounterActions.onCounterInit,
    setPax = _createCounterActions.setPax; // Validation


exports.setPax = setPax;
exports.onCounterInit = onCounterInit;
exports.onCounterIncrement = onCounterIncrement;
exports.onCounterDecrement = onCounterDecrement;
exports.onCounterChange = onCounterChange;
var resetValidation = (0, _reduxActions.createAction)(RESET_VALIDATION_ACCOMMODATION_SEARCH, function () {
  return null;
});
exports.resetValidation = resetValidation;
var setInvalidLocation = (0, _reduxActions.createAction)(SET_INVALID_LOCATION_ACCOMMODATION_SEARCH, function (isInvalid) {
  return {
    isInvalid: isInvalid
  };
});
exports.setInvalidLocation = setInvalidLocation;