// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._message__message--\\+Z1AW{color:#222;font-family:Helvetica Neue,Roboto,Arial,sans-serif;font-size:.75rem;line-height:1rem;padding:.5rem .75rem;white-space:normal}._message__message--\\+Z1AW a{color:#0692fc;text-decoration:underline}._message__message--\\+Z1AW a:hover{text-decoration:none}._message__alert--KdCDN{background:#fff2cc;border:1px solid #ffe699}._message__error--6oU\\+n{background:#fe0002}._message__success--I8Y\\+y{background:#00c249}._message__info--lXz58{background:#e3f3fc}.is-gy ._message__message--\\+Z1AW a{color:#818181}.is-gy ._message__alert--KdCDN{background:#e6e6e6;border-color:#ccc}.is-gy ._message__error--6oU\\+n{background:#7f7f7f}.is-gy ._message__success--I8Y\\+y{background:#616161}.is-gy ._message__info--lXz58{background:#f0f0f0}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Message/_message.scss"],"names":[],"mappings":"AAAA,2BAAS,UAAA,CAAW,kDAAA,CAAyD,gBAAA,CAAiB,gBAAA,CAAiB,oBAAA,CAAqB,kBAAkB,CAAC,6BAAW,aAAA,CAAc,yBAAyB,CAAC,mCAAiB,oBAAoB,CAAC,wBAAO,kBAAA,CAAmB,wBAAwB,CAAC,yBAAO,kBAAkB,CAAC,2BAAS,kBAAkB,CAAC,uBAAM,kBAAkB,CAAC,oCAA2B,aAAa,CAAC,+BAAuB,kBAAA,CAAmB,iBAAiB,CAAC,gCAAuB,kBAAkB,CAAC,kCAAyB,kBAAkB,CAAC,8BAAsB,kBAAkB","sourcesContent":[".message{color:#222;font-family:\"Helvetica Neue\",\"Roboto\",\"Arial\",sans-serif;font-size:.75rem;line-height:1rem;padding:.5rem .75rem;white-space:normal}.message a{color:#0692fc;text-decoration:underline}.message a:hover{text-decoration:none}.alert{background:#fff2cc;border:1px solid #ffe699}.error{background:#fe0002}.success{background:#00c249}.info{background:#e3f3fc}:global(.is-gy) .message a{color:#818181}:global(.is-gy) .alert{background:#e6e6e6;border-color:#ccc}:global(.is-gy) .error{background:#7f7f7f}:global(.is-gy) .success{background:#616161}:global(.is-gy) .info{background:#f0f0f0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "_message__message--+Z1AW",
	"alert": "_message__alert--KdCDN",
	"error": "_message__error--6oU+n",
	"success": "_message__success--I8Y+y",
	"info": "_message__info--lXz58"
};
export default ___CSS_LOADER_EXPORT___;
