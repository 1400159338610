"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VARIATIONS = void 0;
// types
var CLUBJETSTAR = 'clubJetstar';
var VANILLA = 'vanilla';
var VARIATIONS = {
  CLUBJETSTAR: CLUBJETSTAR,
  VANILLA: VANILLA
};
exports.VARIATIONS = VARIATIONS;