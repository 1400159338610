"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es7.array.includes.js");

require("core-js/modules/es6.object.assign.js");

var _immutable = require("immutable");

var _types = _interopRequireDefault(require("../Common/Counter/types"));

var _numbers = require("../../Utils/numbers");

var _flightSearch = require("../../Constants/flightSearch");

var _counters = require("../../Constants/counters");

var ON_INIT_COUNTER = _types.default.ON_INIT_COUNTER,
    ON_INCREMENT_COUNTER = _types.default.ON_INCREMENT_COUNTER,
    ON_DECREMENT_COUNTER = _types.default.ON_DECREMENT_COUNTER,
    ONCHANGE_COUNTER = _types.default.ONCHANGE_COUNTER;

/*
Counters
*/
var MAX_ERRORS = 2;

var accumulate = function accumulate(data, id) {
  return data.filter(function (v, k) {
    return k !== id;
  }).reduce(function (sum, nextItem) {
    return sum + nextItem;
  }, 0);
};

var makeErrorLimit = function makeErrorLimit(max, min) {
  return function (predicate, num) {
    return (0, _numbers.limit)(predicate() ? num + 1 : 0, min, max);
  };
};

var reduceError = function reduceError(n, max) {
  return n === max ? max - 1 : n;
};

var resetErrors = function resetErrors(data, id) {
  return data.filter(function (v, k) {
    return ['total', 'minTotal', 'adultsChildrenRatio', 'adultsInfantsRatio', 'fareInPairs'].includes(k) ? v === MAX_ERRORS : k !== id && v.get('min') === MAX_ERRORS || v.get('max') === MAX_ERRORS;
  }).map(function (item) {
    return typeof item === 'number' ? reduceError(item, MAX_ERRORS) : item.update('max', function (n) {
      return reduceError(n, MAX_ERRORS);
    }).update('min', function (n) {
      return reduceError(n, MAX_ERRORS);
    });
  });
};

var getValidateCounters = function getValidateCounters(namespace, stateKey, counterRules) {
  var ADULTS_CHILDREN_INFANT_RATIO = counterRules.ADULTS_CHILDREN_INFANT_RATIO,
      ADULTS_INFANTS_RATIO = counterRules.ADULTS_INFANTS_RATIO,
      MIN_TOTAL = counterRules.MIN_TOTAL,
      TOTAL = counterRules.TOTAL;
  var rules = {
    adults: {
      min: 1,
      max: 50
    },
    children: {
      min: 0,
      max: 50
    },
    infants: {
      min: 0,
      max: 50
    },
    adultsChildrenRatio: ADULTS_CHILDREN_INFANT_RATIO,
    adultsInfantsRatio: ADULTS_INFANTS_RATIO,
    total: TOTAL,
    minTotal: MIN_TOTAL,
    sales: {
      fareInPairs: false
    }
  };
  return function (store) {
    return function (next) {
      return function (action) {
        var type = action.type;

        if (type === ON_INIT_COUNTER(namespace) || type === ON_INCREMENT_COUNTER(namespace) || type === ONCHANGE_COUNTER(namespace) || type === ON_DECREMENT_COUNTER(namespace)) {
          var _action$payload = action.payload,
              id = _action$payload.id,
              newCount = _action$payload.count;

          if (type === ON_INIT_COUNTER(namespace)) {
            rules = Object.assign({}, rules, action.payload.data.rules);
            return next(action);
          }

          var _rules = rules,
              totalAllowed = _rules.total,
              minTotal = _rules.minTotal,
              adultsChildrenRatio = _rules.adultsChildrenRatio,
              adultsInfantsRatio = _rules.adultsInfantsRatio;
          var _rules$id = rules[id],
              min = _rules$id.min,
              max = _rules$id.max;
          var counters = store.getState()[stateKey].counter;
          var count = counters.getIn(['count', id]);

          if (type === ON_INCREMENT_COUNTER(namespace)) {
            count = (0, _numbers.limit)(count + 1, min, max);
          }

          if (type === ON_DECREMENT_COUNTER(namespace)) {
            count = (0, _numbers.limit)(count - 1, min, max);
          }

          if (type === ONCHANGE_COUNTER(namespace)) {
            count = (0, _numbers.limit)(newCount, min, max);
          }

          var counts = Object.assign.apply(Object, [{}].concat((0, _toConsumableArray2.default)([_counters.ADULTS, _counters.CHILDREN, _counters.INFANTS].map(function (item) {
            return (0, _defineProperty2.default)({}, item, id === item ? count : counters.getIn(['count', item]));
          }))));
          var total = accumulate(counters.get('count'), id);

          if (stateKey === _flightSearch.STATE_KEY) {
            total -= counts[_counters.INFANTS];
          }

          var limitError = makeErrorLimit(MAX_ERRORS, 0);
          var errors = counters.get('errors').merge(resetErrors(counters.get('errors'), id)).update('total', function (n) {
            if (stateKey === _flightSearch.STATE_KEY && id === _counters.INFANTS) {
              return n;
            }

            var invalid = total + counts[id] >= totalAllowed;

            if (invalid) {
              counts[id] = (0, _numbers.limit)(totalAllowed - total, min, max);
            }

            return limitError(function () {
              return invalid;
            }, n);
          }).update('minTotal', function (n) {
            var invalid = total + counts[id] < minTotal;

            if (invalid) {
              counts[id] = (0, _numbers.limit)(total - counts[id], min, max);
            }

            return limitError(function () {
              return invalid;
            }, n);
          }).update('adultsChildrenRatio', function (n) {
            var infants = counts.infants,
                children = counts.children,
                adults = counts.adults;
            var kids = infants + children;
            var invalid = children > 0 && (0, _numbers.isGreaterThanRatio)(kids, adults, adultsChildrenRatio);

            if (invalid && id === _counters.ADULTS) {
              counts[id] = Math.floor(kids / adultsChildrenRatio) + (kids > adultsChildrenRatio ? 1 : 0);
            }

            if (invalid && id === _counters.CHILDREN) {
              counts[id] = adults * adultsChildrenRatio - infants;
            }

            if (invalid && id === _counters.INFANTS) {
              counts[id] = adults * adultsChildrenRatio - children;
            }

            return limitError(function () {
              return invalid;
            }, n);
          }).update('adultsInfantsRatio', function (n) {
            var infants = counts.infants,
                adults = counts.adults;
            var invalid = infants > 0 && (0, _numbers.isGreaterThanRatio)(infants, adults, adultsInfantsRatio);

            if (invalid && id === _counters.ADULTS) {
              counts[id] = infants / adultsInfantsRatio;
            }

            if (invalid && id === _counters.INFANTS) {
              counts[id] = adults * adultsInfantsRatio;
            }

            if (id === _counters.INFANTS || id === _counters.ADULTS) {
              return limitError(function () {
                return invalid;
              }, n);
            }

            return reduceError(n, MAX_ERRORS);
          }).update('fareInPairs', function (n) {
            if (!rules.sales.fareInPairs) {
              return n;
            }

            var children = counts.children,
                adults = counts.adults;
            var invalid = (children + adults) % 2 !== 0;
            return invalid ? 2 : n;
          }).updateIn([id, 'max'], function (n) {
            return limitError(function () {
              return counts[id] >= max;
            }, n);
          }).updateIn([id, 'min'], function (n) {
            return limitError(function () {
              return counts[id] <= min;
            }, n);
          });
          return next({
            type: action.type,
            payload: {
              count: counts[id],
              id: id,
              errors: errors,
              total: counts[id] + total
            }
          });
        }

        return next(action);
      };
    };
  };
};

var getCountersMiddlewares = function getCountersMiddlewares(_ref2) {
  var namespace = _ref2.namespace,
      stateKey = _ref2.stateKey,
      counterRules = _ref2.counterRules;
  return {
    validateCounters: getValidateCounters(namespace, stateKey, counterRules)
  };
};

var _default = getCountersMiddlewares;
exports.default = _default;