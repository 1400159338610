"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

var _immutable = require("immutable");

var _webforms = require("../../../Constants/webforms");

var actions = _interopRequireWildcard(require("./actions"));

var _selectors = require("./selectors");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// utils
// assets
//==============================================================================
var setWebform = actions.setWebform,
    setWebformElement = actions.setWebformElement,
    setWebformElementInWebform = actions.setWebformElementInWebform,
    setWebformElementValue = actions.setWebformElementValue,
    addInvalidWebform = actions.addInvalidWebform,
    addInvalidWebformElement = actions.addInvalidWebformElement,
    removeInvalidWebform = actions.removeInvalidWebform,
    removeInvalidWebformElement = actions.removeInvalidWebformElement,
    showWebformValidation = actions.showWebformValidation;
var ALL_WEBFORM_ELEMENT_IDS_KEY = _webforms.WEBFORMS_KEYS.ALL_WEBFORM_ELEMENT_IDS_KEY,
    ALL_WEBFORM_IDS_KEY = _webforms.WEBFORMS_KEYS.ALL_WEBFORM_IDS_KEY;

var setWebformValid = function setWebformValid(webformID) {
  return function (dispatch, getState) {
    var _getState = getState(),
        webforms = _getState.webforms;

    var isWebformInvalid = (0, _selectors.getIsWebformInvalid)(webforms, webformID);
    isWebformInvalid && dispatch(removeInvalidWebform(webformID));
  };
};

var setWebformInvalid = function setWebformInvalid(webformID) {
  return function (dispatch, getState) {
    var _getState2 = getState(),
        webforms = _getState2.webforms;

    var isWebformValid = (0, _selectors.getIsWebformValid)(webforms, webformID);
    isWebformValid && dispatch(addInvalidWebform(webformID));
  };
};

var setWebformElementValid = function setWebformElementValid(webformElementID) {
  return function (dispatch, getState) {
    var _getState3 = getState(),
        webforms = _getState3.webforms;

    var isWebformElementInvalid = (0, _selectors.getIsWebformElementInvalid)(webforms, webformElementID);
    isWebformElementInvalid && dispatch(removeInvalidWebformElement(webformElementID));
  };
};

var setWebformElementInvalid = function setWebformElementInvalid(webformElementID) {
  return function (dispatch, getState) {
    var _getState4 = getState(),
        webforms = _getState4.webforms;

    var isWebformElementValid = (0, _selectors.getIsWebformElementValid)(webforms, webformElementID);
    isWebformElementValid && dispatch(addInvalidWebformElement(webformElementID));
  };
};

var validateWebform = function validateWebform(webformID) {
  return function (dispatch, getState) {
    var _getState5 = getState(),
        webforms = _getState5.webforms;

    var elementsInWebform = (0, _selectors.getWebformElementsInWebform)(webforms, webformID);
    var invalidWebformElements = (0, _selectors.getInvalidWebformElements)(webforms, webformID);
    var hasInvalidElementInWebform = elementsInWebform.some(function (element) {
      return invalidWebformElements.includes(element);
    });
    var isWebformInvalid = (0, _selectors.getIsWebformValid)(webforms, webformID);

    if (hasInvalidElementInWebform && isWebformInvalid) {
      dispatch(addInvalidWebform(webformID));
    }

    if (!hasInvalidElementInWebform && !isWebformInvalid) {
      dispatch(removeInvalidWebform(webformID));
    }
  };
};

var validateWebformElement = function validateWebformElement(webformElementID, validationCriteria, webformID) {
  return function (dispatch, getState) {
    var _getState6 = getState(),
        webforms = _getState6.webforms;

    var validationOptions = (0, _immutable.Map)((0, _immutable.fromJS)({
      required: function required() {
        return (0, _selectors.getIsWebformElementPopulated)(webforms, webformElementID);
      }
    }));
    var isValid = validationCriteria.every(function (condition, rule) {
      return validationOptions.get(rule)();
    });
    isValid && setWebformElementValid(webformElementID)(dispatch, getState);
    !isValid && setWebformElementInvalid(webformElementID)(dispatch, getState);
    webformID && validateWebform(webformID)(dispatch, getState);
  };
};

var setWebformValidationToDisplay = function setWebformValidationToDisplay(webformID) {
  return function (dispatch, getState) {
    var _getState7 = getState(),
        webforms = _getState7.webforms;

    var isWebformValidationHidden = !(0, _selectors.getIsWebformValidationVisible)(webforms, webformID);
    isWebformValidationHidden && dispatch(showWebformValidation(webformID));
  };
};

var initWebform = function initWebform(webformID) {
  return function (dispatch, getState) {
    var _getState8 = getState(),
        webforms = _getState8.webforms;

    var isWebformInit = webformID && webforms.get(ALL_WEBFORM_IDS_KEY).includes(webformID);

    if (isWebformInit) {
      return;
    }

    dispatch(setWebform(webformID));
  };
};

var initWebformElement = function initWebformElement(webformElementID, webformID, value, validationCriteria) {
  return function (dispatch, getState) {
    var _getState9 = getState(),
        webforms = _getState9.webforms;

    var isWebformElementAlreadyInit = webformElementID && webforms.get(ALL_WEBFORM_ELEMENT_IDS_KEY).includes(webformElementID);

    if (isWebformElementAlreadyInit) {
      return;
    }

    dispatch(setWebformElement(webformElementID));

    if (value) {
      dispatch(setWebformElementValue(webformElementID, value));
    }

    if (webformID) {
      initWebform(webformID)(dispatch, getState);
      dispatch(setWebformElementInWebform(webformElementID, webformID));
    }

    validateWebformElement(webformElementID, validationCriteria, webformID)(dispatch, getState);
  };
};

var onWebformElementSelection = function onWebformElementSelection(webformElementID, value, validationCriteria, webformID) {
  return function (dispatch, getState) {
    dispatch(setWebformElementValue(webformElementID, value));
    validateWebformElement(webformElementID, validationCriteria, webformID)(dispatch, getState);
  };
};

var _default = _objectSpread(_objectSpread({}, actions), {}, {
  initWebform: initWebform,
  initWebformElement: initWebformElement,
  setWebformElementInvalid: setWebformElementInvalid,
  setWebformElementValid: setWebformElementValid,
  validateWebformElement: validateWebformElement,
  onWebformElementSelection: onWebformElementSelection,
  setWebformInvalid: setWebformInvalid,
  setWebformValid: setWebformValid,
  setWebformValidationToDisplay: setWebformValidationToDisplay,
  validateWebform: validateWebform
});

exports.default = _default;