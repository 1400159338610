"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NAMESPACE = exports.DIVISION_XS = exports.DIVISION = void 0;

require("core-js/modules/es6.object.keys.js");

var _fluxgrid = _interopRequireDefault(require("$legacy/sass/base/settings/components/_fluxgrid.scss"));

// Fluxgrid
var NAMESPACE = Object.keys(_fluxgrid.default).length ? _fluxgrid.default.namespace : 'fg-';
exports.NAMESPACE = NAMESPACE;
var DIVISION = Object.keys(_fluxgrid.default).length ? _fluxgrid.default.division : 12;
exports.DIVISION = DIVISION;
var DIVISION_XS = Object.keys(_fluxgrid.default).length ? _fluxgrid.default.divisionXS : 4;
exports.DIVISION_XS = DIVISION_XS;