"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CELL_Y_AXIS = exports.CELL_X_AXIS = exports.CELL_VISIBILITY = exports.CELL_SIZE_XS = exports.CELL_SIZE = exports.CELL_ORDER = exports.CELL = void 0;

var _fluxgridSettings = require("./fluxgridSettings");

var CELL = 'cell'; // Sizes

exports.CELL = CELL;
var CELL_SIZE_XS = {
  EXPANDED: ''
};
exports.CELL_SIZE_XS = CELL_SIZE_XS;

for (var i = 1; i <= _fluxgridSettings.DIVISION_XS; i++) {
  var j = i * _fluxgridSettings.DIVISION / _fluxgridSettings.DIVISION_XS;
  CELL_SIZE_XS["SPAN_".concat(j)] = j;
}

var CELL_SIZE = {
  EXPANDED: ''
};
exports.CELL_SIZE = CELL_SIZE;

for (var _i = 0; _i <= _fluxgridSettings.DIVISION; _i++) {
  CELL_SIZE["SPAN_".concat(_i)] = _i;
} // Visibility


var CELL_VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden'
}; // X axis

exports.CELL_VISIBILITY = CELL_VISIBILITY;
var CELL_X_AXIS = {
  CENTER: 'xCenter',
  START: 'xStart',
  END: 'xEnd'
}; // Y axis

exports.CELL_X_AXIS = CELL_X_AXIS;
var CELL_Y_AXIS = {
  STRETCH: 'yStretch',
  START: 'yStart',
  CENTER: 'yCenter',
  END: 'yEnd'
}; // Order

exports.CELL_Y_AXIS = CELL_Y_AXIS;
var CELL_ORDER = {
  FIRST: 'first',
  LAST: 'last',
  ORDERED: 'ordered'
};
exports.CELL_ORDER = CELL_ORDER;