"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.regexp.search.js");

require("core-js/modules/es6.regexp.replace.js");

var _qs = _interopRequireDefault(require("qs"));

var _types = _interopRequireDefault(require("../Ducks/Tabs/types"));

var _tabs = require("../../Constants/tabs");

var _Store = require("../Store");

var _strings = require("../../Utils/strings");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var TABS_ON_CHANGE = _types.default.TABS_ON_CHANGE;

var tabChange = function tabChange(store) {
  return function (next) {
    return function (action) {
      var type = action.type;

      if (type === TABS_ON_CHANGE) {
        var payload = action.payload;

        var query = _qs.default.parse(window.location.search, {
          ignoreQueryPrefix: true
        });

        var updatedQuery = _objectSpread(_objectSpread({}, query), {}, (0, _defineProperty2.default)({}, _tabs.DEFAULT_QS_TAB, payload + 1));

        var updatedUrl = _qs.default.stringify(updatedQuery, {
          addQueryPrefix: true,
          encode: false,
          skipNulls: true,
          sort: _strings.alphabeticalSort,
          strictNullHandling: true
        });

        _Store.history.replace(updatedUrl, updatedQuery);
      }

      return next(action);
    };
  };
};

var _default = tabChange;
exports.default = _default;