"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.KEYCODES = void 0;
var KEYCODES = {
  ENTER: 13,
  ESCAPE: 27,
  TAB: 9
};
exports.KEYCODES = KEYCODES;
var KEY_DOWN = 'keydown';
var KEY_PRESS = 'keypress';
var KEY_UP = 'keyup';
var CLICK = 'click';
var TOUCH_START = 'touchstart';
var TOUCH_END = 'touchend';
var TYPES = {
  KEY_DOWN: KEY_DOWN,
  KEY_PRESS: KEY_PRESS,
  KEY_UP: KEY_UP,
  CLICK: CLICK,
  TOUCH_START: TOUCH_START,
  TOUCH_END: TOUCH_END
};
exports.TYPES = TYPES;