"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WEBFORMS_KEYS = exports.SEPARATOR = exports.API_REQUEST_QUERY_KEY = void 0;
// Keys
//state key
var ALL_WEBFORM_ELEMENT_IDS_KEY = 'allWebformElementIDs';
var ALL_WEBFORM_IDS_KEY = 'allWebformIDs';
var BY_WEBFORM_ELEMENT_KEY = 'byWebformElement';
var BY_WEBFORM_KEY = 'byWebform';
var INVALID_ELEMENTS_KEY = 'invalidElements';
var INVALID_FORMS_KEY = 'invalidForms';
var SHOW_VALIDATION_BY_WEBFORM_KEY = 'displayValidationInWebform';
var WEBFORM_ELEMENT_VALUE_KEY = 'value';
var WEBFORMS_KEY = 'webforms';
var WEBFORM_ELEMENT_ID_KEY = 'webformElementID';
var WEBFORM_ID_KEY = 'webformID';
var WEBFORM_RENDERING_ID_KEY = 'WebformID';
var WEBFORMS_KEYS = {
  ALL_WEBFORM_ELEMENT_IDS_KEY: ALL_WEBFORM_ELEMENT_IDS_KEY,
  ALL_WEBFORM_IDS_KEY: ALL_WEBFORM_IDS_KEY,
  BY_WEBFORM_ELEMENT_KEY: BY_WEBFORM_ELEMENT_KEY,
  BY_WEBFORM_KEY: BY_WEBFORM_KEY,
  INVALID_ELEMENTS_KEY: INVALID_ELEMENTS_KEY,
  INVALID_FORMS_KEY: INVALID_FORMS_KEY,
  SHOW_VALIDATION_BY_WEBFORM_KEY: SHOW_VALIDATION_BY_WEBFORM_KEY,
  WEBFORM_ELEMENT_VALUE_KEY: WEBFORM_ELEMENT_VALUE_KEY,
  WEBFORMS_KEY: WEBFORMS_KEY,
  WEBFORM_ELEMENT_ID_KEY: WEBFORM_ELEMENT_ID_KEY,
  WEBFORM_ID_KEY: WEBFORM_ID_KEY,
  WEBFORM_RENDERING_ID_KEY: WEBFORM_RENDERING_ID_KEY
};
exports.WEBFORMS_KEYS = WEBFORMS_KEYS;
var ITEMS = 'itemIds';
var SEPARATOR_KEY = 'separator';
var SEPARATOR = '|';
exports.SEPARATOR = SEPARATOR;
var API_REQUEST_QUERY_KEY = {
  ITEMS: ITEMS,
  SEPARATOR: SEPARATOR_KEY
};
exports.API_REQUEST_QUERY_KEY = API_REQUEST_QUERY_KEY;