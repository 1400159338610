// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._search-text-link__buttonWrapper--Ll2A6{display:-webkit-box;display:-webkit-flex;display:flex;top:.75rem}@media only screen and (min-width:48em){._search-text-link__buttonWrapper--Ll2A6{justify-self:flex-end;top:0}}._search-text-link__CTALink--vDF0p{display:-webkit-box;display:-webkit-flex;display:flex}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/HotelsWidget/_search-text-link.scss"],"names":[],"mappings":"AAAA,yCAAe,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,UAAU,CAAC,wCAAwC,yCAAe,qBAAA,CAAsB,KAAK,CAAC,CAAC,mCAAS,mBAAA,CAAA,oBAAA,CAAA,YAAY","sourcesContent":[".buttonWrapper{display:flex;top:.75rem}@media only screen and (min-width:48em){.buttonWrapper{justify-self:flex-end;top:0}}.CTALink{display:flex}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "_search-text-link__buttonWrapper--Ll2A6",
	"CTALink": "_search-text-link__CTALink--vDF0p",
	"ctaLink": "_search-text-link__CTALink--vDF0p"
};
export default ___CSS_LOADER_EXPORT___;
