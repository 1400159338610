"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Tile", {
  enumerable: true,
  get: function get() {
    return _InfoTilesTile.default;
  }
});
exports.default = void 0;

var _InfoTiles = _interopRequireDefault(require("./InfoTiles"));

var _InfoTilesTile = _interopRequireDefault(require("./InfoTilesTile"));

var _default = _InfoTiles.default;
exports.default = _default;