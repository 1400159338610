"use strict";

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Analytics = void 0;

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es7.array.includes.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.object.assign.js");

require("core-js/modules/es6.regexp.search.js");

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _qs = _interopRequireDefault(require("qs"));

var Immutable = _interopRequireWildcard(require("immutable"));

var _dataLayer = require("./dataLayer");

var _flightSearch = require("../Constants/flightSearch");

var _tracking = require("../Constants/tracking");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Analytics helper
 * @namespace Utils/analytics
 * Example usage:
 * const analyticsDataLayer = new Analytics('datalayer-data');
 */

/** Class representing analytics object. */
var Analytics = function Analytics() {
  var _this = this;

  var dataLayerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'datalayer-data';
  (0, _classCallCheck2.default)(this, Analytics);
  (0, _defineProperty2.default)(this, "loaded", function (data) {
    var nameSpace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _flightSearch.FLIGHTSEARCH_NAMESPACE;

    if (!_this.datalayerDOM || !data) {
      return;
    }

    var evt = new CustomEvent('flight-search-loaded', {
      detail: {
        stateData: data
      }
    });

    _this.datalayerDOM.dispatchEvent(evt);

    _this.updateWindowObj(data, nameSpace);
  });
  (0, _defineProperty2.default)(this, "updated", function (changeType, data) {
    var nameSpace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _flightSearch.FLIGHTSEARCH_NAMESPACE;

    if (!_this.datalayerDOM || !data) {
      return;
    }

    var evt = new CustomEvent('flight-search-updated', {
      detail: {
        changeType: changeType,
        stateData: data
      }
    });

    _this.datalayerDOM.dispatchEvent(evt);

    _this.updateWindowObj(data, nameSpace);
  });
  (0, _defineProperty2.default)(this, "triggerEvent", function (eventName, data) {
    var nameSpace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _flightSearch.FLIGHTSEARCH_NAMESPACE;

    if (!_this.datalayerDOM || !data) {
      return;
    }

    var evt = new CustomEvent(eventName, {
      detail: data
    });

    _this.datalayerDOM.dispatchEvent(evt);

    _tracking.GLOBAL_NAMESPACE_CONFIG[nameSpace] && _this.updateWindowObj(data, nameSpace);
  });
  (0, _defineProperty2.default)(this, "updateWindowObj", function (data, nameSpace) {
    window[_flightSearch.NAMESPACE][nameSpace].data = _objectSpread(_objectSpread({}, window[_flightSearch.NAMESPACE][nameSpace].data), data);
  });
  (0, _defineProperty2.default)(this, "setQueryString", function (parsedQueryString) {
    _this.final = _this.data.set('parsedQueryString', parsedQueryString).toJS();
  });
  (0, _defineProperty2.default)(this, "parseUtmQueryString", function (qStr) {
    if (!qStr) {
      return _this.data.get('parsedQueryString');
    }

    var qsObj = _qs.default.parse(qStr, {
      ignoreQueryPrefix: true
    });

    var noUtm = '';
    var targetParams = ['rrid', 'rmid', 'utm_', 'b_cer', 'adobe_mc'];

    var parsedQueryString = _this.data.get('parsedQueryString'); // used to check substring of qs param to match multiple variants
    // ie. utm_source or utm_medium


    var partialStringMatchLength = 4; // iterate over query string and split into utm and nonUtm

    Object.keys(qsObj).forEach(function (value) {
      var snippet = value.toLowerCase();

      if (targetParams.includes(snippet) || targetParams.includes(snippet.substr(0, partialStringMatchLength))) {
        parsedQueryString = parsedQueryString.setIn(['utmParams', value], qsObj[value]);
      } else {
        noUtm = noUtm.concat(value, '=', qsObj[value], '&');
      }
    }); //Restore leading question mark and trim last ampersand

    if (noUtm.length > 0) {
      parsedQueryString = parsedQueryString.set('noUtm', "?".concat(noUtm.slice(0, -1)));
    }

    return parsedQueryString;
  });
  (0, _defineProperty2.default)(this, "addData", function (param, value) {
    _this.datalayerDOM.dataset[param] = value;
  });
  (0, _defineProperty2.default)(this, "getOriginParam", function () {
    return _this.data.getIn(['dataLayerParams', 'origin']);
  });
  (0, _defineProperty2.default)(this, "getDestinationParam", function () {
    return _this.data.getIn(['dataLayerParams', 'destination']);
  });
  var defaults = {
    dataLayerId: dataLayerId,
    dataLayerParams: {
      origin: 'originCode',
      destination: 'destinationCode'
    },
    parsedQueryString: {
      utmParams: {},
      noUtm: ''
    }
  };
  this.data = Immutable.fromJS(defaults);
  this.datalayerDOM = document.getElementById(dataLayerId); // set window obj

  window[_flightSearch.NAMESPACE] = window[_flightSearch.NAMESPACE] || {};

  if (!window[_flightSearch.NAMESPACE][_flightSearch.FLIGHTSEARCH_NAMESPACE]) {
    window[_flightSearch.NAMESPACE][_flightSearch.FLIGHTSEARCH_NAMESPACE] = {
      data: {}
    };
  }

  if (!this.datalayerDOM) {
    return false;
  }

  this.datalayerUtil = new _dataLayer.Datalayer('analyticsDataLayer', Object.assign({}, this.datalayerDOM.dataset));

  var _parsedQueryString = this.parseUtmQueryString(window.location.search).toJS();

  if (Object.keys(_parsedQueryString.utmParams).length) {
    this.datalayerUtil.add(_parsedQueryString.utmParams);
  }

  this.setQueryString(_parsedQueryString);

  if (Analytics.instance) {
    return Analytics.instance;
  }

  Analytics.instance = this;
  return this;
};

exports.Analytics = Analytics;