"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.namespace = exports.default = void 0;
var namespace = 'FLIGHT_SEARCH';
exports.namespace = namespace;
var ADD_INVALID_SECTOR_FLIGHT_SEARCH = 'ADD_INVALID_SECTOR_FLIGHT_SEARCH';
var ADD_MULTIPLE_SECTORS_FLIGHT_SEARCH = 'ADD_MULTIPLE_SECTORS_FLIGHT_SEARCH';
var ADD_SECTOR_FLIGHT_SEARCH = 'ADD_SECTOR_FLIGHT_SEARCH';
var COLLAPSE_LOCATION_FLIGHT_SEARCH = 'COLLAPSE_LOCATION_FLIGHT_SEARCH';
var EXPAND_LOCATION_FLIGHT_SEARCH = 'EXPAND_LOCATION_FLIGHT_SEARCH';
var FETCHING_PRICES_FLIGHT_SEARCH = 'FETCHING_PRICES_FLIGHT_SEARCH';
var FETCHING_PRICES_SUCCESS_FLIGHT_SEARCH = 'FETCHING_PRICES_SUCCESS_FLIGHT_SEARCH';
var LOAD_CALENDARS_FLIGHT_SEARCH = 'LOAD_CALENDARS_FLIGHT_SEARCH';
var LOAD_LOCATIONS_FLIGHT_SEARCH = 'LOAD_LOCATIONS_FLIGHT_SEARCH';
var ON_INIT_DROP_DOWN_FLIGHT_SEARCH = 'ON_INIT_DROP_DOWN_FLIGHT_SEARCH';
var ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH = 'ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH';
var ON_TOGGLE_DROP_DOWN_FLIGHT_SEARCH = 'ON_TOGGLE_DROP_DOWN_FLIGHT_SEARCH';
var ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH = 'ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH';
var ONCHANGE_FLEXIBLE_FLIGHT_SEARCH = 'ONCHANGE_FLEXIBLE_FLIGHT_SEARCH';
var ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH = 'ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH';
var REMOVE_INVALID_SECTOR_FLIGHT_SEARCH = 'REMOVE_INVALID_SECTOR_FLIGHT_SEARCH';
var REMOVE_SECTOR_FLIGHT_SEARCH = 'REMOVE_SECTOR_FLIGHT_SEARCH';
var RESET_CALENDAR_DATES = 'RESET_CALENDAR_DATES';
var RESET_GUIDANCE_MESSAGE = 'RESET_GUIDANCE_MESSAGE';
var RESET_LOCATION_FLIGHT_SEARCH = 'RESET_LOCATION_FLIGHT_SEARCH';
var RESET_PRICES_FLIGHT_SEARCH = 'RESET_PRICES_FLIGHT_SEARCH';
var RESET_SEARCH_LOCATION_FLIGHT_SEARCH = 'RESET_SEARCH_LOCATION_FLIGHT_SEARCH';
var SEARCH_LOCATION_FLIGHT_SEARCH = 'SEARCH_LOCATION_FLIGHT_SEARCH';
var SET_CALENDAR_DATES_FLIGHT_SEARCH = 'SET_CALENDAR_DATES_FLIGHT_SEARCH';
var SET_CALENDAR_DATES_NAVIGATION_FLIGHT_SEARCH = 'SET_CALENDAR_DATES_NAVIGATION_FLIGHT_SEARCH';
var SET_CALENDAR_DATES = 'SET_CALENDAR_DATES';
var SET_CALENDAR_INVALID_FLIGHT_SEARCH = 'SET_CALENDAR_INVALID_FLIGHT_SEARCH';
var SET_CALENDAR_NAVIGATION_NEXT_DATE_FLIGHT_SEARCH = 'SET_CALENDAR_NAVIGATION_NEXT_DATE_FLIGHT_SEARCH';
var SET_CALENDAR_NAVIGATION_PREV_DATE_FLIGHT_SEARCH = 'SET_CALENDAR_NAVIGATION_PREV_DATE_FLIGHT_SEARCH';
var SET_CALENDAR_SCHEDULE_FLIGHT_SEARCH = 'SET_CALENDAR_SCHEDULE_FLIGHT_SEARCH';
var SET_CALENDAR_SECTOR_FLIGHT_SEARCH = 'SET_CALENDAR_SECTOR_FLIGHT_SEARCH';
var SET_CURRENCY = 'SET_CURRENCY';
var SET_CALENDAR_START_DATES_FLIGHT_SEARCH = 'SET_CALENDAR_START_DATES_FLIGHT_SEARCH';
var SET_FLIGHT_SEARCH_CONFIG = 'SET_FLIGHT_SEARCH_CONFIG';
var SET_LOCATION_FLIGHT_SEARCH = 'SET_LOCATION_FLIGHT_SEARCH';
var SET_LOCATION_INVALID_FLIGHT_SEARCH = 'SET_LOCATION_INVALID_FLIGHT_SEARCH';
var SHOW_GUIDANCE_MESSAGE = 'SHOW_GUIDANCE_MESSAGE';
var TOGGLE_FLIGHT_SEARCH_SUBMIT_LOADING = 'TOGGLE_FLIGHT_SEARCH_SUBMIT_LOADING';
var TOGGLE_LOCATION_FLIGHT_SEARCH = 'TOGGLE_LOCATION_FLIGHT_SEARCH';
var _default = {
  ADD_INVALID_SECTOR_FLIGHT_SEARCH: ADD_INVALID_SECTOR_FLIGHT_SEARCH,
  ADD_MULTIPLE_SECTORS_FLIGHT_SEARCH: ADD_MULTIPLE_SECTORS_FLIGHT_SEARCH,
  ADD_SECTOR_FLIGHT_SEARCH: ADD_SECTOR_FLIGHT_SEARCH,
  COLLAPSE_LOCATION_FLIGHT_SEARCH: COLLAPSE_LOCATION_FLIGHT_SEARCH,
  EXPAND_LOCATION_FLIGHT_SEARCH: EXPAND_LOCATION_FLIGHT_SEARCH,
  FETCHING_PRICES_FLIGHT_SEARCH: FETCHING_PRICES_FLIGHT_SEARCH,
  FETCHING_PRICES_SUCCESS_FLIGHT_SEARCH: FETCHING_PRICES_SUCCESS_FLIGHT_SEARCH,
  LOAD_CALENDARS_FLIGHT_SEARCH: LOAD_CALENDARS_FLIGHT_SEARCH,
  LOAD_LOCATIONS_FLIGHT_SEARCH: LOAD_LOCATIONS_FLIGHT_SEARCH,
  ON_INIT_DROP_DOWN_FLIGHT_SEARCH: ON_INIT_DROP_DOWN_FLIGHT_SEARCH,
  ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH: ON_INIT_FLIGHT_TYPE_FLIGHT_SEARCH,
  ON_TOGGLE_DROP_DOWN_FLIGHT_SEARCH: ON_TOGGLE_DROP_DOWN_FLIGHT_SEARCH,
  ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH: ONCHANGE_CLUBJETSTAR_FLIGHT_SEARCH,
  ONCHANGE_FLEXIBLE_FLIGHT_SEARCH: ONCHANGE_FLEXIBLE_FLIGHT_SEARCH,
  ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH: ONCHANGE_FLIGHT_TYPE_FLIGHT_SEARCH,
  REMOVE_INVALID_SECTOR_FLIGHT_SEARCH: REMOVE_INVALID_SECTOR_FLIGHT_SEARCH,
  REMOVE_SECTOR_FLIGHT_SEARCH: REMOVE_SECTOR_FLIGHT_SEARCH,
  RESET_CALENDAR_DATES: RESET_CALENDAR_DATES,
  RESET_GUIDANCE_MESSAGE: RESET_GUIDANCE_MESSAGE,
  RESET_LOCATION_FLIGHT_SEARCH: RESET_LOCATION_FLIGHT_SEARCH,
  RESET_PRICES_FLIGHT_SEARCH: RESET_PRICES_FLIGHT_SEARCH,
  RESET_SEARCH_LOCATION_FLIGHT_SEARCH: RESET_SEARCH_LOCATION_FLIGHT_SEARCH,
  SEARCH_LOCATION_FLIGHT_SEARCH: SEARCH_LOCATION_FLIGHT_SEARCH,
  SET_CALENDAR_DATES_FLIGHT_SEARCH: SET_CALENDAR_DATES_FLIGHT_SEARCH,
  SET_CALENDAR_DATES_NAVIGATION_FLIGHT_SEARCH: SET_CALENDAR_DATES_NAVIGATION_FLIGHT_SEARCH,
  SET_CALENDAR_DATES: SET_CALENDAR_DATES,
  SET_CALENDAR_INVALID_FLIGHT_SEARCH: SET_CALENDAR_INVALID_FLIGHT_SEARCH,
  SET_CALENDAR_NAVIGATION_NEXT_DATE_FLIGHT_SEARCH: SET_CALENDAR_NAVIGATION_NEXT_DATE_FLIGHT_SEARCH,
  SET_CALENDAR_NAVIGATION_PREV_DATE_FLIGHT_SEARCH: SET_CALENDAR_NAVIGATION_PREV_DATE_FLIGHT_SEARCH,
  SET_CALENDAR_SCHEDULE_FLIGHT_SEARCH: SET_CALENDAR_SCHEDULE_FLIGHT_SEARCH,
  SET_CALENDAR_SECTOR_FLIGHT_SEARCH: SET_CALENDAR_SECTOR_FLIGHT_SEARCH,
  SET_CALENDAR_START_DATES_FLIGHT_SEARCH: SET_CALENDAR_START_DATES_FLIGHT_SEARCH,
  SET_CURRENCY: SET_CURRENCY,
  SET_FLIGHT_SEARCH_CONFIG: SET_FLIGHT_SEARCH_CONFIG,
  SET_LOCATION_FLIGHT_SEARCH: SET_LOCATION_FLIGHT_SEARCH,
  SET_LOCATION_INVALID_FLIGHT_SEARCH: SET_LOCATION_INVALID_FLIGHT_SEARCH,
  SHOW_GUIDANCE_MESSAGE: SHOW_GUIDANCE_MESSAGE,
  TOGGLE_FLIGHT_SEARCH_SUBMIT_LOADING: TOGGLE_FLIGHT_SEARCH_SUBMIT_LOADING,
  TOGGLE_LOCATION_FLIGHT_SEARCH: TOGGLE_LOCATION_FLIGHT_SEARCH
};
exports.default = _default;