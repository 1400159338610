"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _dateFns = require("date-fns");

var _accommodation = require("../../../Constants/accommodation");

var actions = _interopRequireWildcard(require("./actions"));

var _operations = require("../../Common/Counter/operations");

var _strings = require("../../../Utils/strings");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// Accommodation Type
var onInitAccommodationType = actions.onInitAccommodationType,
    onCounterChange = actions.onCounterChange,
    onCounterInit = actions.onCounterInit;
var HOTEL = _accommodation.ACCOMMODATION_TYPE_IDS.HOTEL,
    AIRBNB = _accommodation.ACCOMMODATION_TYPE_IDS.AIRBNB; // TODO: QS Support here for accommodation type

var initializeAccommodationType = function initializeAccommodationType(labels) {
  return function (dispatch) {
    var labelsFallback = (0, _strings.immutableStringDefaultVal)(labels);
    var accommodationTypeLabels = {
      hotel: labelsFallback(_accommodation.ACCOMMODATION_TYPE_LABELS[HOTEL]),
      airbnb: labelsFallback(_accommodation.ACCOMMODATION_TYPE_LABELS[AIRBNB])
    };
    dispatch(onInitAccommodationType(accommodationTypeLabels));
  };
};

var initialiseCounter = function initialiseCounter(settings) {
  return (0, _operations.createInitialiseCounter)({
    settings: settings,
    actions: {
      onCounterChange: onCounterChange,
      onCounterInit: onCounterInit
    },
    stateKey: _accommodation.STATE_KEY,
    counterRules: _accommodation.GUESTS_COUNTER_RULES,
    ignoreQueryParams: true
  });
}; // - Dates


var setDates = actions.setDates,
    resetValidation = actions.resetValidation,
    setInvalidLocation = actions.setInvalidLocation;

var initializeAccommodationDates = function initializeAccommodationDates(defaultNumberOfNights, minNights, maxNights) {
  return function (dispatch) {
    var defaultCheckIn = (0, _dateFns.startOfToday)();

    var getDefaultNights = function getDefaultNights() {
      if (defaultNumberOfNights < minNights) return minNights;
      if (defaultNumberOfNights > maxNights) return minNights;
      return defaultNumberOfNights;
    };

    var defaultCheckOut = (0, _dateFns.addDays)(defaultCheckIn, getDefaultNights());
    var dates = {
      checkIn: defaultCheckIn,
      checkOut: defaultCheckOut
    };
    dispatch(setDates(dates));
  };
}; // Validation


var validateAll = function validateAll() {
  return function (dispatch, getState) {
    dispatch(resetValidation());

    var _getState = getState(),
        accommodationSearch = _getState.accommodationSearch;

    var accommodationSelected = accommodationSearch.location.getIn(['selected', 'url']);

    if (accommodationSelected) {
      return true;
    }

    dispatch(setInvalidLocation(true));
    return false;
  };
};

var _default = _objectSpread(_objectSpread({}, actions), {}, {
  initializeAccommodationType: initializeAccommodationType,
  initializeAccommodationDates: initializeAccommodationDates,
  initialiseCounter: initialiseCounter,
  validateAll: validateAll
});

exports.default = _default;