"use strict";

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof3 = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.history = exports.default = exports.configureStore = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _redux = require("redux");

var Immutable = _interopRequireWildcard(require("immutable"));

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

var _history = require("history");

var _Ducks = _interopRequireDefault(require("./Ducks"));

var _Middlewares = require("../State/Middlewares");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var historyFlightSearch = _Middlewares.flightSearch.historyFlightSearch,
    validateTravellers = _Middlewares.flightSearch.validateTravellers,
    multiCityRedirect = _Middlewares.flightSearch.multiCityRedirect;
var validateGuests = _Middlewares.accommodationSearch.validateGuests;
var validateHolidayGuests = _Middlewares.holidaySearch.validateHolidayGuests;
var history = (0, _history.createBrowserHistory)();
/*eslint-disable */

exports.history = history;
var composeEnhancers = (typeof window === "undefined" ? "undefined" : (0, _typeof2.default)(window)) === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  serialize: {
    immutable: Immutable
  }
}) : _redux.compose;
/*eslint-enable */

var enhancer = composeEnhancers((0, _redux.applyMiddleware)(_reduxThunk.default, _Middlewares.flightSchedule, _Middlewares.userLocation, validateGuests, validateTravellers, validateHolidayGuests, historyFlightSearch, multiCityRedirect, _Middlewares.logger, _Middlewares.tabs));

var configureStore = function configureStore() {
  return (0, _redux.createStore)((0, _Ducks.default)(), enhancer);
};

exports.configureStore = configureStore;

var _default = configureStore();

exports.default = _default;