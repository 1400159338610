"use strict";

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanitizeMap = exports.prefixObjectKeys = exports.pick = exports.omit = exports.getRandomProperty = exports.getPathOr = exports.getPath = exports.getMapSubset = exports.getKeyByValue = exports.getFlightScheduleApiParams = exports.getCurrencySettings = exports.convertMapToObject = exports.cameliseKeys = exports.camelToKebabAttrs = void 0;

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.array.find.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es7.object.entries.js");

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

require("core-js/modules/es6.object.assign.js");

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _strings = require("./strings");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var camelToKebabAttrs = function camelToKebabAttrs(object, _props) {
  var props = _props;

  if ((0, _typeof2.default)(object) !== 'object') {
    throw new Error('camelToKebabAttrs: Must be an object');
  }

  if (props === undefined) {
    // default to manipulate all keys
    props = Object.keys(object);
  }

  var output = _objectSpread({}, object);

  props.forEach(function (prop) {
    if (object[prop]) {
      output[(0, _strings.camelToKebab)(prop)] = object[prop];
    }

    delete output[prop];
  });
  return output;
};
/**
 * prefixObjectKeys
 * @function prefixObjectKeys
 * @memberof Utils/object
 * @param {Object} object
 * @param {Array<string>} _props
 * @param {string} prefix
 * @returns {Object}
 */


exports.camelToKebabAttrs = camelToKebabAttrs;

var prefixObjectKeys = function prefixObjectKeys(object, prefix, _props) {
  var prefixKey = function prefixKey(key) {
    var prepareKey = key.charAt(0).toUpperCase() + key.slice(1);
    return "".concat(prefix).concat(prepareKey);
  };

  var props = _props;

  if ((0, _typeof2.default)(object) !== 'object') {
    throw new Error('prefixObjectKeys: object must be an object');
  }

  if (typeof prefix !== 'string') {
    throw new Error('prefixObjectKeys: prefix must be a string');
  }

  if (prefix === '') {
    throw new Error('prefixObjectKeys: prefix must not be an empty string');
  }

  if (props === undefined) {
    // default to manipulate all keys
    props = Object.keys(object);
  }

  var output = _objectSpread({}, object);

  props.forEach(function (prop) {
    if (object[prop]) {
      output[prefixKey(prop)] = object[prop];
    }

    delete output[prop];
  });
  return output;
};
/**
 * Filters a Map based on exception Map
 *
 * @function sanitizeMap
 * @memberof Utils/object
 * @param {Map} source
 * @param {Map} exceptions
 * @returns {Map}
 */


exports.prefixObjectKeys = prefixObjectKeys;

var sanitizeMap = function sanitizeMap(source, exceptions) {
  return source.filter(function (value, key) {
    return exceptions.get(key) !== value;
  });
};
/**
 * Returns a subset of a Map based on a Set of keys
 *
 * @function getMapSubset
 * @memberof Utils/object
 * @param {Map} source
 * @param {Set} subsetKeys
 * @returns {Map}
 */


exports.sanitizeMap = sanitizeMap;

var getMapSubset = function getMapSubset(source, subsetKeys) {
  return source.filter(function (value, key) {
    return subsetKeys.has(key);
  });
};
/**
 * Make object key from underscore to camelcase
 *
 * @template {any} InputType
 *
 * @function cameliseKeys
 * @memberof Utils/object
 * @param {InputType} object
 * @returns {import('./camelize').Camelize<InputType>} - return original object with camelcase keys
 */


exports.getMapSubset = getMapSubset;

var cameliseKeys = function cameliseKeys(object) {
  if (Array.isArray(object)) {
    return object.map(function (v) {
      return cameliseKeys(v);
    });
  } else if (object !== null && object.constructor === Object) {
    return Object.keys(object).reduce(function (result, key) {
      return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2.default)({}, (0, _strings.underscoreToCamel)(key), cameliseKeys(object[key])));
    }, {});
  }

  return object;
};
/**
 * Find first matching object key
 *
 * @function getKeyByValue
 * @memberof Utils/object
 * @param {Object} object
 * @param {string} value - value to find
 * @returns {string} - return key with matching value
 */


exports.cameliseKeys = cameliseKeys;

var getKeyByValue = function getKeyByValue(object, value) {
  if ((0, _typeof2.default)(object) !== 'object' || !value) {
    return undefined;
  }

  return Object.keys(object).find(function (key) {
    return object[key] === value;
  });
};
/**
 * Get random property
 *
 * @function getRandomProperty
 * @memberof Utils/object
 * @param {Object} object
 * @returns {Property} - return random property
 */


exports.getKeyByValue = getKeyByValue;

var getRandomProperty = function getRandomProperty(object) {
  // TODO: Add test for randomness
  var keys = Object.keys(object);
  return object[keys[keys.length * Math.random() << 0]]; //eslint-disable-line no-bitwise
};
/**
 * Get flight schedule API params object
 *
 * @function getFlightScheduleApiParams
 * @memberof Utils/object
 * @param {Map} config - sitevalues map
 * @param {string} versionNumber - flight schedule version
 * @returns {Object} - return flight schedule Api params object
 */


exports.getRandomProperty = getRandomProperty;

var getFlightScheduleApiParams = function getFlightScheduleApiParams(config, versionNumber) {
  if (!config || !config.size || !versionNumber) {
    return {};
  }

  var url = config.getIn(['data', 'schedulesApiUrl']);
  var locale = config.getIn(['data', 'locale']);

  if (!url || !locale) {
    return {};
  }

  return {
    url: url,
    storageKey: "".concat(locale, "-").concat(versionNumber.trim())
  };
};
/**
 * Get currency settings object
 *
 * @function getCurrencySettings
 * @memberof Utils/object
 * @param {Map} config - sitevalues map
 * @returns {Object} - return currency settings object
 */


exports.getFlightScheduleApiParams = getFlightScheduleApiParams;

var getCurrencySettings = function getCurrencySettings(config) {
  if (!config || !config.size) {
    return {};
  }

  var currencyCode = config.getIn(['data', 'currencyCode']);
  var currentCurrency = config.getIn(['data', 'currencies', currencyCode]); // can't use sitecore settings until we figure out where is the source of truth
  // const precision: number = Number(currentCurrency.get('DecimalPoints'));

  var symbol = config.getIn(['data', 'currencyFormat', 'currencySymbol']);
  var pattern = currentCurrency.get('Pattern');
  var currencyFormat = (0, _strings.format)(pattern, '%s', '%v');
  var thousand = config.getIn(['data', 'currencyFormat', 'currencyGroupSeparator']);
  var decimal = config.getIn(['data', 'currencyFormat', 'currencyDecimalSeparator']);
  return {
    symbol: symbol,
    thousand: thousand,
    decimal: decimal,
    format: currencyFormat,
    precision: 0 // can't use sitecore settings until we figure out where is the source of truth

  };
};
/**
 * Convert map to object
 *
 * @function convertMapToObject
 * @memberof Utils/object
 * @param {Map} map
 * @returns {Object}
 */


exports.getCurrencySettings = getCurrencySettings;

var convertMapToObject = function convertMapToObject(map) {
  return (// TODO: Re-write below without the need to disable eslint rules
    // TODO: Enhance function to allow for nested conversion
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
    (0, _toConsumableArray2.default)(map.entries()).reduce(function (obj, _ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return obj[key] = value, obj;
    }, {})
  );
};
/**
 * Create duplicate object without keys
 *
 * @function omit
 * @memberof Utils/object
 * @param {Object} obj
 * @param {Array<string>} keys
 * @returns {Object}
 */


exports.convertMapToObject = convertMapToObject;

var omit = function omit(obj, keys) {
  return Object.entries(obj).filter(function (_ref3) {
    var _ref4 = (0, _slicedToArray2.default)(_ref3, 1),
        key = _ref4[0];

    return !keys.includes(key);
  }).reduce(function (o, _ref5) {
    var _ref6 = (0, _slicedToArray2.default)(_ref5, 2),
        key = _ref6[0],
        val = _ref6[1];

    return Object.assign(o, (0, _defineProperty2.default)({}, key, val));
  }, {});
};
/**
 * Create duplicate object from keys
 *
 * @function pick
 * @memberof Utils/object
 * @param {Object} obj
 * @param {Array<string>} keys
 * @returns {Object}
 */


exports.omit = omit;

var pick = function pick(obj, keys) {
  return Object.entries(obj).filter(function (_ref7) {
    var _ref8 = (0, _slicedToArray2.default)(_ref7, 1),
        key = _ref8[0];

    return keys.includes(key);
  }).reduce(function (o, _ref9) {
    var _ref10 = (0, _slicedToArray2.default)(_ref9, 2),
        key = _ref10[0],
        val = _ref10[1];

    return Object.assign(o, (0, _defineProperty2.default)({}, key, val));
  }, {});
};
/**
 * Get value in nested object from keys
 *
 * @function getPath
 * @memberof Utils/object
 * @param {Object} obj
 * @param {Array<string|number>} path
 */


exports.pick = pick;

var getPath = function getPath(obj, path) {
  var _path = (0, _toArray2.default)(path),
      key = _path[0],
      nextPath = _path.slice(1);

  try {
    var value = obj[key];
    return nextPath.length ? getPath(value, nextPath) : value;
  } catch (e) {
    return null;
  }
};
/**
 * Get value in nested object from keys or return a default value if falsy
 *
 * @function getPathOr
 * @memberof Utils/object
 * @param {Object} obj
 * @param {Array<string|number>} path
 * @param {*} defaultValue
 */


exports.getPath = getPath;

var getPathOr = function getPathOr(obj, path, defaultValue) {
  return getPath(obj, path) || defaultValue;
};

exports.getPathOr = getPathOr;