// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._collapsible-text__icon--Ur4zu{display:inline-block;line-height:1em;vertical-align:middle}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/CollapsibleText/_collapsible-text.scss"],"names":[],"mappings":"AAAA,gCAAM,oBAAA,CAAqB,eAAA,CAAgB,qBAAqB","sourcesContent":[".icon{display:inline-block;line-height:1em;vertical-align:middle}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_collapsible-text__icon--Ur4zu"
};
export default ___CSS_LOADER_EXPORT___;
