// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._radio-group__radioGroup--Zsafg{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-flex-flow:wrap;flex-flow:wrap;-webkit-box-flex:1;-webkit-flex:1 0 100%;flex:1 0 100%}._radio-group__radioGroupCard--f5JLm{-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/RadioGroup/_radio-group.scss"],"names":[],"mappings":"AAAA,iCAAY,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,sBAAA,CAAA,cAAA,CAAe,kBAAA,CAAA,qBAAA,CAAA,aAAa,CAAC,qCAAgB,wBAAA,CAAA,qCAAA,CAAA,6BAA6B","sourcesContent":[".radioGroup{display:flex;flex-flow:wrap;flex:1 0 100%}.radioGroupCard{justify-content:space-between}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": "_radio-group__radioGroup--Zsafg",
	"radioGroupCard": "_radio-group__radioGroupCard--f5JLm"
};
export default ___CSS_LOADER_EXPORT___;
