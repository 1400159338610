"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HOLIDAYS_NAMESPACE = exports.HOLIDAYS_ANALYTICS_QUERY_KEYS = exports.ANALYTICS_HOTEL_SEARCH_CTA_CLICK = exports.ANALYTICS_HOLIDAY_SEARCH_CTA_CLICK = exports.ACCOMMODATION_NAMESPACE = exports.ACCOMMODATION_ANALYTICS_QUERY_KEYS = void 0;
var ADULTS_ANALYTICS = 'adults';
var CHILDREN_ANALYTICS = 'children';
var DEPARTURE_DATE = 'departureDate';
var DESTINATION_ANALYTICS = 'destination';
var INFANTS_ANALYTICS = 'infants';
var NAME = 'name';
var ORIGIN_ANALYTICS = 'origin';
var RETURN_DATE = 'returnDate';
var ROOM_COUNT = 'roomCount'; //Accommodation

var ACCOMMODATION_ANALYTICS_QUERY_KEYS = {
  DEPARTURE_DATE: DEPARTURE_DATE,
  DESTINATION_ANALYTICS: DESTINATION_ANALYTICS,
  NAME: NAME,
  RETURN_DATE: RETURN_DATE
};
exports.ACCOMMODATION_ANALYTICS_QUERY_KEYS = ACCOMMODATION_ANALYTICS_QUERY_KEYS;
var ACCOMMODATION_NAMESPACE = 'accommodation';
exports.ACCOMMODATION_NAMESPACE = ACCOMMODATION_NAMESPACE;
var ANALYTICS_HOTEL_SEARCH_CTA_CLICK = 'hotels-search-cta-click'; //Holidays

exports.ANALYTICS_HOTEL_SEARCH_CTA_CLICK = ANALYTICS_HOTEL_SEARCH_CTA_CLICK;
var HOLIDAYS_ANALYTICS_QUERY_KEYS = {
  ADULTS_ANALYTICS: ADULTS_ANALYTICS,
  CHILDREN_ANALYTICS: CHILDREN_ANALYTICS,
  DEPARTURE_DATE: DEPARTURE_DATE,
  DESTINATION_ANALYTICS: DESTINATION_ANALYTICS,
  INFANTS_ANALYTICS: INFANTS_ANALYTICS,
  NAME: NAME,
  ORIGIN_ANALYTICS: ORIGIN_ANALYTICS,
  RETURN_DATE: RETURN_DATE,
  ROOM_COUNT: ROOM_COUNT
};
exports.HOLIDAYS_ANALYTICS_QUERY_KEYS = HOLIDAYS_ANALYTICS_QUERY_KEYS;
var HOLIDAYS_NAMESPACE = 'holidays';
exports.HOLIDAYS_NAMESPACE = HOLIDAYS_NAMESPACE;
var ANALYTICS_HOLIDAY_SEARCH_CTA_CLICK = 'holidays-search-cta-click';
exports.ANALYTICS_HOLIDAY_SEARCH_CTA_CLICK = ANALYTICS_HOLIDAY_SEARCH_CTA_CLICK;