"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONTAINER_SIZE = exports.CONTAINER = void 0;
var CONTAINER = 'container'; // Sizes

exports.CONTAINER = CONTAINER;
var CONTAINER_SIZE = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
};
exports.CONTAINER_SIZE = CONTAINER_SIZE;