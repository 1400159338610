"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iconMap = exports.UTM_QUERY_VALUES = exports.UTM_QUERY_KEYS = exports.TYPE = exports.TRAVEL_TYPE_LEISURE = exports.TRAVEL_TYPE_BUSINESS = exports.SUBMIT = exports.STATE_KEY = exports.LOCATION = exports.HOTEL = exports.GUESTS_COUNTER_RULES = exports.GUESTS = exports.DEFAULT_ADULTS_COUNT = exports.DATES = exports.CALENDAR_FALLBACKS = exports.CALENDAR = exports.BUSINESS = exports.BOOKING_QUERY_KEYS = exports.AIRBNB = exports.ACCOMMODATION_TYPE_QS_PARAM = exports.ACCOMMODATION_TYPE_LABELS = exports.ACCOMMODATION_TYPE_IDS = exports.ACCOMMODATION_AUTO_DATA = void 0;

require("core-js/modules/es6.number.constructor.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _strings = require("../Utils/strings");

var _counters = require("./counters");

var _ACCOMMODATION_AUTO_D, _ACCOMMODATION_TYPE_L, _UTM_QUERY_VALUES, _ACCOMMODATION_TYPE_Q;

// common
var prefix = 'accommodation';
var STATE_KEY = 'accommodationSearch';
exports.STATE_KEY = STATE_KEY;
var DEFAULT_ADULTS_COUNT = 2;
exports.DEFAULT_ADULTS_COUNT = DEFAULT_ADULTS_COUNT;
var TYPE = 'type';
exports.TYPE = TYPE;
var LOCATION = 'location';
exports.LOCATION = LOCATION;
var DATES = 'dates';
exports.DATES = DATES;
var CALENDAR = 'calendar';
exports.CALENDAR = CALENDAR;
var GUESTS = 'guests';
exports.GUESTS = GUESTS;
var BUSINESS = 'business';
exports.BUSINESS = BUSINESS;
var SUBMIT = 'submit';
exports.SUBMIT = SUBMIT;

var camelCaseWithPrefix = function camelCaseWithPrefix(label) {
  return "".concat(prefix).concat((0, _strings.capitalize)(label));
};

var ACCOMMODATION_AUTO_DATA = (_ACCOMMODATION_AUTO_D = {}, (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, TYPE, camelCaseWithPrefix(TYPE)), (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, LOCATION, camelCaseWithPrefix(LOCATION)), (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, DATES, camelCaseWithPrefix(DATES)), (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, CALENDAR, camelCaseWithPrefix(CALENDAR)), (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, GUESTS, camelCaseWithPrefix(GUESTS)), (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, BUSINESS, camelCaseWithPrefix(BUSINESS)), (0, _defineProperty2.default)(_ACCOMMODATION_AUTO_D, SUBMIT, camelCaseWithPrefix(SUBMIT)), _ACCOMMODATION_AUTO_D); // Calendar

exports.ACCOMMODATION_AUTO_DATA = ACCOMMODATION_AUTO_DATA;
var MIN_NIGHTS = 1;
var MAX_NIGHTS = 28;
var CALENDAR_FALLBACKS = {
  MIN_NIGHTS: MIN_NIGHTS,
  MAX_NIGHTS: MAX_NIGHTS
}; // Type

exports.CALENDAR_FALLBACKS = CALENDAR_FALLBACKS;
var ACCOMMODATION_TYPE_IDS = {
  HOTEL: '1',
  AIRBNB: '2'
};
exports.ACCOMMODATION_TYPE_IDS = ACCOMMODATION_TYPE_IDS;
var HOTEL = ACCOMMODATION_TYPE_IDS.HOTEL,
    AIRBNB = ACCOMMODATION_TYPE_IDS.AIRBNB;
exports.AIRBNB = AIRBNB;
exports.HOTEL = HOTEL;
var HOTEL_LABEL = 'Hotels';
var AIRBNB_LABEL = 'AirBnb';
var ACCOMMODATION_TYPE_LABELS = (_ACCOMMODATION_TYPE_L = {}, (0, _defineProperty2.default)(_ACCOMMODATION_TYPE_L, HOTEL, HOTEL_LABEL), (0, _defineProperty2.default)(_ACCOMMODATION_TYPE_L, AIRBNB, AIRBNB_LABEL), _ACCOMMODATION_TYPE_L); // Travel Type

exports.ACCOMMODATION_TYPE_LABELS = ACCOMMODATION_TYPE_LABELS;
var TRAVEL_TYPE_BUSINESS = 'business';
exports.TRAVEL_TYPE_BUSINESS = TRAVEL_TYPE_BUSINESS;
var TRAVEL_TYPE_LEISURE = 'leisure'; //

exports.TRAVEL_TYPE_LEISURE = TRAVEL_TYPE_LEISURE;
var URL = 'url';
var CHECK_IN = 'checkIn';
var CHECK_OUT = 'checkOut';
var TRAVEL_TYPE = 'travelType';
var BOOKING_QUERY_KEYS = {
  URL: URL,
  ADULTS: _counters.ADULTS,
  CHILDREN: _counters.CHILDREN,
  INFANTS: _counters.INFANTS,
  CHECK_IN: CHECK_IN,
  CHECK_OUT: CHECK_OUT,
  TRAVEL_TYPE: TRAVEL_TYPE
}; // UTM Parameters

exports.BOOKING_QUERY_KEYS = BOOKING_QUERY_KEYS;
var SOURCE = 'utm_source';
var MEDIUM = 'utm_medium';
var CAMPAIGN = 'utm_campaign';
var CONTENT = 'utm_content';
var UTM_QUERY_KEYS = {
  SOURCE: SOURCE,
  MEDIUM: MEDIUM,
  CAMPAIGN: CAMPAIGN,
  CONTENT: CONTENT
};
exports.UTM_QUERY_KEYS = UTM_QUERY_KEYS;
var UTM_QUERY_VALUES = (_UTM_QUERY_VALUES = {}, (0, _defineProperty2.default)(_UTM_QUERY_VALUES, SOURCE, 'jetstar'), (0, _defineProperty2.default)(_UTM_QUERY_VALUES, MEDIUM, 'mps'), _UTM_QUERY_VALUES); // Guests

exports.UTM_QUERY_VALUES = UTM_QUERY_VALUES;
var GUESTS_COUNTER_RULES = {
  MIN_TOTAL: 1,
  TOTAL: 50,
  ADULTS_CHILDREN_INFANT_RATIO: Number.POSITIVE_INFINITY,
  ADULTS_INFANTS_RATIO: Number.POSITIVE_INFINITY
}; // Location

exports.GUESTS_COUNTER_RULES = GUESTS_COUNTER_RULES;
var HOTEL_PARAM = 'jetstar';
var AIRBNB_PARAM = 'airbnb';
var ACCOMMODATION_TYPE_QS_PARAM = (_ACCOMMODATION_TYPE_Q = {}, (0, _defineProperty2.default)(_ACCOMMODATION_TYPE_Q, HOTEL, HOTEL_PARAM), (0, _defineProperty2.default)(_ACCOMMODATION_TYPE_Q, AIRBNB, AIRBNB_PARAM), _ACCOMMODATION_TYPE_Q);
exports.ACCOMMODATION_TYPE_QS_PARAM = ACCOMMODATION_TYPE_QS_PARAM;
var REGION = 'region';
var PROPERTY = 'property';
var UNSPECIFIED = 'unspecified';
var iconMap = {
  REGION: REGION,
  PROPERTY: PROPERTY,
  UNSPECIFIED: UNSPECIFIED
};
exports.iconMap = iconMap;