"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CurrencyInput = _interopRequireDefault(require("./CurrencyInput"));

var _default = _CurrencyInput.default;
exports.default = _default;