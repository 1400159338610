"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchingLocaleSuccess = exports.fetchingLocale = void 0;

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var fetchingLocale = (0, _reduxActions.createAction)(_types.default.FETCHING_LOCALE, function () {
  return (0, _immutable.Map)({
    isFetching: true
  });
});
exports.fetchingLocale = fetchingLocale;
var fetchingLocaleSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_SUCCESS_LOCALE, function (data) {
  return (0, _immutable.Map)({
    isFetching: false
  }).set('data', data);
});
exports.fetchingLocaleSuccess = fetchingLocaleSuccess;