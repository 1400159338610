"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRID_Z_AXIS = exports.GRID_Y_AXIS = exports.GRID_X_AXIS = exports.GRID_WRAPPING = exports.GRID_DIRECTION = exports.GRID = void 0;
var GRID = 'grid'; // Direction

exports.GRID = GRID;
var GRID_DIRECTION = {
  ROW: 'row',
  REVERSE: 'rowReverse'
}; // Wrapping

exports.GRID_DIRECTION = GRID_DIRECTION;
var GRID_WRAPPING = {
  WRAP: 'wrap',
  NO_WRAP: 'noWrap'
}; // X axis

exports.GRID_WRAPPING = GRID_WRAPPING;
var GRID_X_AXIS = {
  START: 'xStart',
  CENTER: 'xCenter',
  END: 'xEnd',
  AROUND: 'xAround',
  BETWEEN: 'xBetween'
}; // Y axis

exports.GRID_X_AXIS = GRID_X_AXIS;
var GRID_Y_AXIS = {
  STRETCH: 'yStretch',
  START: 'yStart',
  CENTER: 'yCenter',
  END: 'yEnd'
}; // Z axis

exports.GRID_Y_AXIS = GRID_Y_AXIS;
var GRID_Z_AXIS = {
  STRETCH: 'zStretch',
  START: 'zStart',
  CENTER: 'zCenter',
  END: 'zEnd',
  AROUND: 'zAround',
  BETWEEN: 'zBetween'
};
exports.GRID_Z_AXIS = GRID_Z_AXIS;