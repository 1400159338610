"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldShowPrice = exports.shouldRequestCalendarPrices = exports.isLocationsValid = exports.isLocationsAndCalendarsLoaded = exports.isFlightSearchLoading = exports.isFareCacheEnabled = exports.isDOHOPOnlyRoute = exports.isCalendarValid = exports.getTotalTravellers = exports.getSubmitHolidayFromFlightsData = exports.getStandardDateFormats = exports.getSelectedLocationCode = exports.getSelectedLocation = exports.getSelectedFlightType = exports.getSectorsErrorIds = exports.getPricingByStartDate = exports.getPortName = exports.getLocationsError = exports.getIsSoldOut = exports.getIsInaccuratePrice = exports.getIncludeMember = exports.getFlightTypeValues = exports.getFlightTypeKeyValues = exports.getFlightSearchValidity = exports.getFlightSearchCurrencyCode = exports.getFlightSearchButtonState = exports.getFlightId = exports.getFare = exports.getDropDownState = exports.getDepartureTime = exports.getDate = exports.getCurrency = exports.getCorrelationId = exports.getCalendarStartDate = exports.getCalendarError = exports.getCalendarDate = exports.getArrivalTime = exports.getAnalyticsStateData = exports.doesCalendarHaveDatePrices = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.array.find.js");

require("core-js/modules/es6.number.constructor.js");

require("core-js/modules/es6.regexp.search.js");

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

var _immutable = require("immutable");

var _qs = _interopRequireDefault(require("qs"));

var _Button = require("../../../Views/Components/Button");

var _dates = require("../../../Constants/dates");

var _flightSearch = require("../../../Constants/flightSearch");

var _flightType = require("../../../Constants/flightType");

var _counters = require("../../../Constants/counters");

var _selectors = require("../../Common/Counter/selectors");

var _selectors2 = require("../FlightSchedule/selectors");

var _dates2 = require("../../../Utils/dates");

var _flightschedule = require("../../../Utils/flightschedule");

// constants
// state
// utils
var DEPARTURE_DATE = _flightSearch.QUERY_KEY.DEPARTURE_DATE,
    RETURN_DATE = _flightSearch.QUERY_KEY.RETURN_DATE;
var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;

var getTotalTravellers = function getTotalTravellers(state) {
  return state.counter.get('count').filter(function (count, id) {
    return id !== _counters.INFANTS;
  }).reduce(function (accumulate, currentValue) {
    return currentValue + accumulate;
  }, 0);
};

exports.getTotalTravellers = getTotalTravellers;

var getFlightTypeValues = function getFlightTypeValues(state) {
  return state.flightType.get('type').valueSeq();
};

exports.getFlightTypeValues = getFlightTypeValues;

var getFlightTypeKeyValues = function getFlightTypeKeyValues(state) {
  return state.flightType.get('type');
};

exports.getFlightTypeKeyValues = getFlightTypeKeyValues;

var getCurrency = function getCurrency(state) {
  return state.currency;
};

exports.getCurrency = getCurrency;

var getDropDownState = function getDropDownState(state) {
  return state.dropdowns;
};

exports.getDropDownState = getDropDownState;

var getSelectedFlightType = function getSelectedFlightType(state) {
  return state.flightType.get('selected');
};

exports.getSelectedFlightType = getSelectedFlightType;

var getStandardDateFormats = function getStandardDateFormats(state) {
  return state.config.getIn(['data', 'Dates', 'Standard']);
};

exports.getStandardDateFormats = getStandardDateFormats;

var getFlightSearchValidity = function getFlightSearchValidity(state) {
  return state.sectors.get('invalidIds').size === 0;
};

exports.getFlightSearchValidity = getFlightSearchValidity;

var findFlightSearchError = function findFlightSearchError(state, cb) {
  return state.get('sectorIds').find(cb);
};

var getLocationsError = function getLocationsError(state) {
  var locations = state.locations,
      sectors = state.sectors;
  return findFlightSearchError(sectors, function (sectorId) {
    return !locations.getIn([sectorId, 'inbound', 'code']);
  });
};

exports.getLocationsError = getLocationsError;

var getCalendarError = function getCalendarError(state) {
  var selectedFlightType = getSelectedFlightType(state);
  var path = ['selectedDate', 'date'];
  var calendars = state.calendars,
      sectors = state.sectors;
  return findFlightSearchError(sectors, function (sectorId) {
    return selectedFlightType === _flightType.FLIGHT_TYPE_IDS.RETURN && !calendars.getIn(['sectors', sectorId, 'inbound'].concat(path)) || !calendars.getIn(['sectors', sectorId, 'outbound'].concat(path));
  });
};

exports.getCalendarError = getCalendarError;

var getSectorsErrorIds = function getSectorsErrorIds(state) {
  var calendars = state.calendars,
      locations = state.locations,
      sectors = state.sectors;
  var errors = [];
  findFlightSearchError(sectors, function (sectorId) {
    var directions = locations.get(sectorId);
    directions && directions.forEach(function (values, direction) {
      if (values.get('invalid')) errors.push("".concat(sectorId, "-").concat(direction));
    }); // if the inbound OR outbound are invalid, the dates should also be invalid

    if (errors.length || calendars.getIn(['sectors', sectorId, 'invalid'])) errors.push("".concat(sectorId, "-dates"));
  });
  return errors;
};

exports.getSectorsErrorIds = getSectorsErrorIds;

var getCalendarStartDate = function getCalendarStartDate(state, flightDirection) {
  var calendars = state.calendars;
  var currentSectorId = calendars.get('currentSectorId');

  if (!currentSectorId && !calendars.get('sectors').isEmpty()) {
    return calendars.get('sectors').first().getIn([flightDirection, 'startDate']);
  }

  return calendars.getIn(['sectors', currentSectorId, flightDirection, 'startDate']);
};

exports.getCalendarStartDate = getCalendarStartDate;

var getCalendarDate = function getCalendarDate(state, flightDirection) {
  var calendars = state.calendars;
  var currentSectorId = calendars.get('currentSectorId');

  if (!currentSectorId && !calendars.get('sectors').isEmpty()) {
    return calendars.get('sectors').first().getIn([flightDirection, 'selectedDate']);
  }

  return calendars.getIn(['sectors', currentSectorId, flightDirection, 'selectedDate']);
};

exports.getCalendarDate = getCalendarDate;

var getSelectedLocation = function getSelectedLocation(state, sectorId, flightDirection) {
  var node = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'name';
  var locations = state.locations;

  if (locations.isEmpty()) {
    return null;
  }

  if (!sectorId) {
    return locations.first().getIn([flightDirection, node]);
  }

  return locations.getIn([sectorId, flightDirection, node]);
};

exports.getSelectedLocation = getSelectedLocation;

var getSelectedLocationCode = function getSelectedLocationCode(state, sectorId, flightDirection) {
  var node = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'code';
  var locations = state.locations;

  if (locations.isEmpty()) {
    return null;
  }

  if (!sectorId) {
    return locations.first().getIn([flightDirection, node]);
  }

  return locations.getIn([sectorId, flightDirection, node]);
};

exports.getSelectedLocationCode = getSelectedLocationCode;

var getPriceGroup = function getPriceGroup(isMemberPrice) {
  return isMemberPrice ? 'member' : 'starter';
};

var getFlexible = function getFlexible(state) {
  return Number(state.flexible.get('data')) === 1;
};

var getIsClubJetstar = function getIsClubJetstar(state) {
  return Number(state.clubjetstar.get('data')) === 1;
};

var getCurrentSectorId = function getCurrentSectorId(state) {
  return state.sectors.getIn(['sectorIds', 0]);
};

var getStartDate = function getStartDate(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'selectedDate', 'startDate']);
};

var getDate = function getDate(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'selectedDate', 'date']);
};

exports.getDate = getDate;

var getFlightId = function getFlightId(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'pricing', getStartDate(state, direction), getPriceGroup(isMemberPrice), 'days', getDate(state, direction), 0, 'flightId']);
};

exports.getFlightId = getFlightId;

var getCorrelationId = function getCorrelationId(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'pricing', getStartDate(state, direction), getPriceGroup(isMemberPrice), 'correlationId']);
};

exports.getCorrelationId = getCorrelationId;

var getIncludeMember = function getIncludeMember(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'pricing', getStartDate(state, direction), getPriceGroup(isMemberPrice), 'includeMember']);
};

exports.getIncludeMember = getIncludeMember;

var getDepartureTime = function getDepartureTime(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'pricing', getStartDate(state, direction), getPriceGroup(isMemberPrice), 'days', getDate(state, direction), 0, 'departureTime']);
};

exports.getDepartureTime = getDepartureTime;

var getArrivalTime = function getArrivalTime(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'pricing', getStartDate(state, direction), getPriceGroup(isMemberPrice), 'days', getDate(state, direction), 0, 'arrivalTime']);
};

exports.getArrivalTime = getArrivalTime;

var getPortName = function getPortName(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  return state.locations.getIn([getCurrentSectorId(state), direction, 'code']);
};

exports.getPortName = getPortName;

var getIsInaccuratePrice = function getIsInaccuratePrice(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'selectedDate', getPriceGroup(isMemberPrice), 'isInaccuratePrice']);
};

exports.getIsInaccuratePrice = getIsInaccuratePrice;

var getIsSoldOut = function getIsSoldOut(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'selectedDate', getPriceGroup(isMemberPrice), 'isSoldOut']);
};

exports.getIsSoldOut = getIsSoldOut;

var getFare = function getFare(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var isMemberPrice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var price = state.calendars.getIn(['sectors', getCurrentSectorId(state), direction, 'selectedDate', getPriceGroup(isMemberPrice), 'price']);
  return typeof price === 'number' ? price : null;
};

exports.getFare = getFare;

var shouldShowPrice = function shouldShowPrice(state) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTBOUND;
  var pricesHidden = arguments.length > 2 ? arguments[2] : undefined;
  var isMemberPrice = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var price = getFare(state, direction, isMemberPrice);
  var isInaccuratePrice = getIsInaccuratePrice(state, direction, isMemberPrice);
  var isSoldOut = getIsSoldOut(state, direction, isMemberPrice);
  return typeof price === 'number' && price >= 0 && !isInaccuratePrice && !isSoldOut && !pricesHidden;
};

exports.shouldShowPrice = shouldShowPrice;

var isCalendarValid = function isCalendarValid(state) {
  return typeof getCalendarError(state) === 'undefined';
};

exports.isCalendarValid = isCalendarValid;

var isLocationsValid = function isLocationsValid(state) {
  return typeof getLocationsError(state) === 'undefined';
};

exports.isLocationsValid = isLocationsValid;

var isLocationsAndCalendarsLoaded = function isLocationsAndCalendarsLoaded(state) {
  var locations = state.locations,
      calendars = state.calendars;
  return !locations.isEmpty() && !calendars.isEmpty();
};

exports.isLocationsAndCalendarsLoaded = isLocationsAndCalendarsLoaded;

var isFlightSearchLoading = function isFlightSearchLoading(state) {
  return state.isLoading;
};

exports.isFlightSearchLoading = isFlightSearchLoading;

var getFlightSearchButtonState = function getFlightSearchButtonState(state) {
  var submitLoading = isFlightSearchLoading(state);

  switch (true) {
    case submitLoading:
      return _Button.STATES.WAITING;

    default:
      return '';
  }
};

exports.getFlightSearchButtonState = getFlightSearchButtonState;

var getPricingByStartDate = function getPricingByStartDate(state, flightDirection, startDate) {
  var isMemberPrice = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var calendars = state.calendars;
  var currentSectorId = calendars.get('currentSectorId');

  if (!currentSectorId && !calendars.get('sectors').isEmpty()) {
    return calendars.get('sectors').first().getIn([flightDirection, 'pricing', startDate, getPriceGroup(isMemberPrice)]);
  }

  return calendars.getIn(['sectors', currentSectorId, flightDirection, 'pricing', startDate, getPriceGroup(isMemberPrice)]);
};

exports.getPricingByStartDate = getPricingByStartDate;

var getAnalyticsStateData = function getAnalyticsStateData(state, config, settings) {
  var qsObj = _qs.default.parse(window.location.search, {
    ignoreQueryPrefix: true
  }); //TODO retrieve dates from Redux rather than qs - issue with accessing via 'currentSectorId'


  var departureDate = (0, _dates2.dateFormatterToString)(qsObj[DEPARTURE_DATE], _dates.DEFAULT_DATE_FORMAT);
  var returnDate = (0, _dates2.dateFormatterToString)(qsObj[RETURN_DATE], _dates.DEFAULT_DATE_FORMAT);
  var isClubJetstar = getIsClubJetstar(state);
  var outbound = getCalendarDate(state, 'outbound');
  var inbound = getCalendarDate(state, 'inbound');
  var outboundStartDate = getCalendarStartDate(state, 'outbound');
  var outboundStarterPricing = outboundStartDate && getPricingByStartDate(state, 'outbound', outboundStartDate, false);
  var outboundMemberPricing = outboundStartDate && getPricingByStartDate(state, 'outbound', outboundStartDate, true);
  var data = {
    locations: {
      origin: getSelectedLocation(state, null, 'outbound', 'code'),
      destination: getSelectedLocation(state, null, 'inbound', 'code'),
      originName: getSelectedLocation(state, null, 'outbound'),
      destinationName: getSelectedLocation(state, null, 'inbound'),
      valid: typeof getLocationsError(state) === 'undefined'
    },
    passengers: {
      adults: (0, _selectors.getAdults)(state),
      children: (0, _selectors.getChildren)(state),
      infants: (0, _selectors.getInfants)(state),
      valid: getTotalTravellers(state) >= 1
    },
    dates: {
      departureDate: departureDate,
      returnDate: returnDate,
      valid: getFlightSearchValidity(state)
    },
    flightType: {
      value: getSelectedFlightType(state)
    },
    travelOnDate: {
      value: getFlexible(state)
    },
    modeCode: {
      value: null
    },
    saleId: {
      value: null
    },
    saleName: {
      value: null
    },
    isClubJetstar: isClubJetstar,
    fareCache: {
      outboundPrice: outbound && outbound.getIn([getPriceGroup(false), 'price']),
      outboundInaccuratePrice: outbound && outbound.getIn([getPriceGroup(false), 'isInaccuratePrice']),
      returnPrice: inbound && inbound.getIn([getPriceGroup(false), 'price']),
      returnInaccuratePrice: inbound && inbound.getIn([getPriceGroup(false), 'isInaccuratePrice']),
      accuracyPercent: outboundStarterPricing && outboundStarterPricing.get('accuracyPercent'),
      outboundMemberPrice: outbound && outbound.getIn([getPriceGroup(true), 'price']),
      outboundMemberInaccuratePrice: outbound && outbound.getIn([getPriceGroup(true), 'isInaccuratePrice']),
      returnMemberPrice: inbound && inbound.getIn([getPriceGroup(true), 'price']),
      returnMemberInaccuratePrice: inbound && inbound.getIn([getPriceGroup(true), 'isInaccuratePrice']),
      memberAccuracyPercent: outboundMemberPricing && outboundMemberPricing.get('accuracyPercent'),
      accuracyThreshold: (0, _typeof2.default)(settings) === 'object' && settings.get('AccuracyThreshold')
    }
  };
  return data;
}; // Return the currency from the outbound airport or the outbound airport country or the site config currency


exports.getAnalyticsStateData = getAnalyticsStateData;

var getFlightSearchCurrencyCode = function getFlightSearchCurrencyCode(flightSearch, flightSchedule, config) {
  var outboundAirport = flightSchedule.getIn(['data', 'Airports', 'ById', getPortName(flightSearch, OUTBOUND)]);
  var outboundAirportCurrencyCode = outboundAirport && outboundAirport.get('DefaultCurrencyCode');
  var outboundCountry = outboundAirport && outboundAirport.get('Country');
  var outboundCountryCurrencyCode = flightSchedule.getIn(['data', 'Countries', 'ById', outboundCountry, 'DefaultCurrencyCode']);
  var currencyCode = outboundAirportCurrencyCode || outboundCountryCurrencyCode || (config ? config.getIn(['data', 'currencyCode']) : null);
  return currencyCode;
};

exports.getFlightSearchCurrencyCode = getFlightSearchCurrencyCode;

var isDOHOPOnlyRoute = function isDOHOPOnlyRoute(flightSearch, flightSchedule, cityPairCarrierTypes, direction) {
  var sectorId = flightSearch.calendars.get('currentSectorId');
  var locations = flightSearch.locations.get(sectorId);
  var departures = locations.getIn(['outbound', 'code']);
  var arrivals = locations.getIn(['inbound', 'code']);

  var _cityPairCarrierTypes = cityPairCarrierTypes.toJS(),
      DohopOnly = _cityPairCarrierTypes.DohopOnly;

  var origin = departures;
  var destination = arrivals;

  if (direction === INBOUND) {
    origin = arrivals;
    destination = departures;
  }

  return (0, _flightschedule.carrierTypeLookup)(cityPairCarrierTypes, (0, _selectors2.getCityPairCarrierTypeData)(flightSchedule, origin), destination, origin) === DohopOnly;
};

exports.isDOHOPOnlyRoute = isDOHOPOnlyRoute;

var isFareCacheEnabled = function isFareCacheEnabled(flightSearch, config) {
  return !config.getIn(['data', 'disableFareCache']);
};

exports.isFareCacheEnabled = isFareCacheEnabled;

var doesCalendarHaveDatePrices = function doesCalendarHaveDatePrices(calendar, direction, startDate) {
  return calendar.hasIn([direction, 'pricing', startDate]);
};

exports.doesCalendarHaveDatePrices = doesCalendarHaveDatePrices;

var shouldRequestCalendarPrices = function shouldRequestCalendarPrices(flightSearch, flightSchedule, config, componentSchema, direction, startDate) {
  var sectorId = flightSearch.calendars.get('currentSectorId');
  var calendar = flightSearch.calendars.getIn(['sectors', sectorId]);
  var outboundCode = flightSearch.locations.getIn([sectorId, OUTBOUND, 'code']);
  var enablePrices = componentSchema.get('EnablePrices');
  var airportsWithPrices = componentSchema.get('AirportsWithPrices');
  var cityPairCarrierTypes = config.getIn(['data', 'cityPairCarrierTypes']); // don't call pricing API if route not solely provided by Jetstar

  return isFareCacheEnabled(flightSearch, config) && enablePrices && airportsWithPrices && airportsWithPrices.includes(outboundCode) && (!cityPairCarrierTypes || cityPairCarrierTypes && !isDOHOPOnlyRoute(flightSearch, flightSchedule, cityPairCarrierTypes, direction)) && !doesCalendarHaveDatePrices(calendar, direction, startDate);
};

exports.shouldRequestCalendarPrices = shouldRequestCalendarPrices;

var getSubmitHolidayFromFlightsData = function getSubmitHolidayFromFlightsData(state, holidayDestination) {
  var sectorId = state.sectors.get('sectorIds').get(0);
  var locations = state.locations;
  return {
    origin: locations.getIn([sectorId, 'outbound', 'code']),
    destination: holidayDestination[locations.getIn([sectorId, 'inbound', 'code'])],
    destinationName: '',
    departing: getDate(state, OUTBOUND),
    returning: getDate(state, INBOUND),
    rooms: {
      'groups[0][adults]': state.counter.getIn(['count', 'adults']),
      'groups[0][children]': state.counter.getIn(['count', 'children']),
      'groups[0][infants]': state.counter.getIn(['count', 'infants'])
    },
    pid: 'searchcta:holidays'
  };
};

exports.getSubmitHolidayFromFlightsData = getSubmitHolidayFromFlightsData;