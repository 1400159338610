"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCityPairCarrierTypeData = void 0;

var _immutable = require("immutable");

var getCityPairCarrierTypeData = function getCityPairCarrierTypeData(flightSchedule, origin) {
  return flightSchedule.getIn(['data', 'Airports', 'ById', origin, 'CityPairCarrierType']);
};

exports.getCityPairCarrierTypeData = getCityPairCarrierTypeData;