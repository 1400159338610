"use strict";

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateFlightTypeQuery = exports.validateFlexibleQuery = exports.validateClubJetstarQuery = exports.required = exports.regex = exports.email = void 0;

require("core-js/modules/es6.regexp.constructor.js");

require("core-js/modules/es6.number.constructor.js");

var EmailValidator = _interopRequireWildcard(require("email-validator"));

var _memoizee = _interopRequireDefault(require("memoizee"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// redux-form errors if validator functions are uniquly supplied by a factory
// to fix this, we memoize them
// bug: https://github.com/erikras/redux-form/issues/3981
var email = (0, _memoizee.default)(function (errorMessage) {
  return function (value) {
    return EmailValidator.validate(value) ? undefined : errorMessage || 'Invalid email address';
  };
});
exports.email = email;
var required = (0, _memoizee.default)(function (errorMessage) {
  return function (value) {
    return value ? undefined : errorMessage || 'Required';
  };
});
exports.required = required;
var regex = (0, _memoizee.default)(function (exp, errorMessage) {
  return function (value) {
    return new RegExp(exp).test(value) ? undefined : errorMessage || 'Invalid regex match';
  };
});
/**
 * Validate flight type values
 * @function validateFlightTypeQuery
 * @memberof Utils/validation
 * @param {Object} flightSearch - Immutable object
 * @param {string} queryVal - String value
 * @return {number} Returns a valid flight type value
 */

exports.regex = regex;

var validateFlightTypeQuery = function validateFlightTypeQuery(flightSearch, queryVal) {
  return /^\+?[1-3]{1}$/.test(queryVal) ? Number(queryVal) : flightSearch.flightType.get('selected');
};
/**
 * Validate flexible values
 * @function validateFlexibleQuery
 * @memberof Utils/validation
 * @param {Object} flightSearch - Immutable object
 * @param {string} queryVal - String value
 * @return {number} Returns a valid flight type value
 */


exports.validateFlightTypeQuery = validateFlightTypeQuery;

var validateFlexibleQuery = function validateFlexibleQuery(flightSearch, queryVal) {
  return /^\+?[0-1]{1}$/.test(queryVal) ? Number(queryVal) : flightSearch.flexible.get('data');
};
/**
 * Validate Club Jetstar values
 * @function validateClubJetstarQuery
 * @memberof Utils/validation
 * @param {Object} flightSearch - Immutable object
 * @param {string} queryVal - String value
 * @return {number} Returns a valid flight type value
 */


exports.validateFlexibleQuery = validateFlexibleQuery;

var validateClubJetstarQuery = function validateClubJetstarQuery(flightSearch, queryVal) {
  return /^\+?[0-1]{1}$/.test(queryVal) ? Number(queryVal) : null;
};

exports.validateClubJetstarQuery = validateClubJetstarQuery;