"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadFlightSchedule = exports.default = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.promise.js");

require("core-js/modules/es6.object.assign.js");

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _immutable = require("immutable");

var _api = require("../../../Utils/api");

var _localStorage = require("../../../Utils/localStorage");

var _actions = require("./actions");

var _flightschedule = require("../../../Utils/flightschedule");

var _performance = require("../../../Utils/performance");

var normalize = function normalize(response) {
  return function (dispatch, getState) {
    var data = (0, _flightschedule.normalizeFlightSchedule)(response.data);
    dispatch((0, _actions.fetchingFlightScheduleSuccess)((0, _immutable.Map)({}).set('schedule', data).set('storageKey', response.key)));
  };
};

var fetchFlightSchedule = function fetchFlightSchedule(url) {
  return (0, _api.fetchApi)(url).then(function (response) {
    if (response && (0, _typeof2.default)(response) === 'object' && response.data.length) {
      return response;
    }

    throw Error("Flight Schedule API - Incorrect response");
  }).catch(function (error) {
    throw Error("Flight Schedule API - ".concat(JSON.stringify(error)));
  });
};

var loadFlightSchedule = function loadFlightSchedule(_ref) {
  var url = _ref.url,
      storageKey = _ref.storageKey;
  return function (dispatch) {
    (0, _performance.createPerformanceMarker)('flightschedules_fetch_start');
    dispatch((0, _actions.fetchingFlightSchedule)());
    return new Promise(function (resolve, reject) {
      var success = function success(response) {
        (0, _performance.createPerformanceMarker)('flightschedules_fetch_end');
        (0, _performance.measurePerformance)('flightschedules_fetch', 'flightschedules_fetch_start', 'flightschedules_fetch_end');
        (0, _performance.createPerformanceMarker)('flightschedules_parse_start');
        var data = response.data;
        dispatch(normalize(Object.assign({}, {
          data: data,
          key: storageKey
        })));
        (0, _performance.createPerformanceMarker)('flightschedules_parse_end');
        (0, _performance.measurePerformance)('flightschedules_parse', 'flightschedules_parse_start', 'flightschedules_parse_end');
        resolve(data);
      };

      (0, _localStorage.getFlightSchedule)(storageKey).then(function (storedData) {
        if (storedData) {
          var data = storedData;
          var immutableData = (0, _immutable.fromJS)(data);
          dispatch((0, _actions.fetchingLocalFlightScheduleSuccess)(immutableData));
          resolve(immutableData);
        } else {
          fetchFlightSchedule(url).then(success).catch(reject);
        }
      }, function () {
        fetchFlightSchedule(url).then(success).catch(reject);
      });
    });
  };
};

exports.loadFlightSchedule = loadFlightSchedule;
var _default = loadFlightSchedule;
exports.default = _default;