"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fareCacheInsightStringKey = exports.fareCacheInsightActionKey = exports.GLOBAL_NAMESPACE_CONFIG = exports.EVENT_NAME = exports.ANALYTICS_EVENT_TYPES = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _flightSearch = require("../Constants/flightSearch");

var _analytics = require("../Constants/analytics");

var _GLOBAL_NAMESPACE_CON;

var EVENT_NAME = 'FareCache';
exports.EVENT_NAME = EVENT_NAME;
var ACTION = 'Action';
var ASSETS_VERSION = 'AssetsVersion';
var BOOKING_URL = 'bookingUrl';
var CLUB_JETSTAR = 'ClubJetstar';
var DESTINATION = 'Destination';
var DIRECTION = 'Direction';
var FARE_CACHE_API_REQUEST = 'FarecacheApiRequest';
var FARE_CACHE_API_RESPONSE = 'FarecacheApiResponse';
var HIDE_PRICING = 'HidePricing';
var INCLUDE_TAX = 'IncludeTax';
var MEMBER_PRICING = 'MemberPricing';
var ORIGIN = 'Origin';
var POSCODE = 'PosCode'; // const REVISION_ID: string ='RevisionId'; TODO: Implement Flight search settings version number - See JR-8876

var fareCacheInsightStringKey = {
  ACTION: ACTION,
  ASSETS_VERSION: ASSETS_VERSION,
  BOOKING_URL: BOOKING_URL,
  CLUB_JETSTAR: CLUB_JETSTAR,
  DESTINATION: DESTINATION,
  DIRECTION: DIRECTION,
  FARE_CACHE_API_REQUEST: FARE_CACHE_API_REQUEST,
  FARE_CACHE_API_RESPONSE: FARE_CACHE_API_RESPONSE,
  HIDE_PRICING: HIDE_PRICING,
  INCLUDE_TAX: INCLUDE_TAX,
  MEMBER_PRICING: MEMBER_PRICING,
  ORIGIN: ORIGIN,
  POSCODE: POSCODE // REVISION_ID, TODO: Implement Flight search settings version number - See JR-8876

}; // Supported global namespaces for tracking, currently only FLIGHTSEARCH is used but more to be added as needed

exports.fareCacheInsightStringKey = fareCacheInsightStringKey;
var GLOBAL_NAMESPACE_CONFIG = (_GLOBAL_NAMESPACE_CON = {}, (0, _defineProperty2.default)(_GLOBAL_NAMESPACE_CON, _flightSearch.FLIGHTSEARCH_NAMESPACE, true), (0, _defineProperty2.default)(_GLOBAL_NAMESPACE_CON, _analytics.ACCOMMODATION_NAMESPACE, false), (0, _defineProperty2.default)(_GLOBAL_NAMESPACE_CON, _analytics.HOLIDAYS_NAMESPACE, false), _GLOBAL_NAMESPACE_CON);
exports.GLOBAL_NAMESPACE_CONFIG = GLOBAL_NAMESPACE_CONFIG;
var ON_INBOUND_LOAD = 'onInboundLoad';
var ON_OUTBOUND_LOAD = 'onOutboundLoad';
var ON_SUBMISSION = 'onSubmission';
var fareCacheInsightActionKey = {
  ON_INBOUND_LOAD: ON_INBOUND_LOAD,
  ON_OUTBOUND_LOAD: ON_OUTBOUND_LOAD,
  ON_SUBMISSION: ON_SUBMISSION
};
exports.fareCacheInsightActionKey = fareCacheInsightActionKey;
var SHOW_FAST_RENEWAL_CTA = 'clubJetstar-membership-renew-now';
var ANALYTICS_EVENT_TYPES = {
  SHOW_FAST_RENEWAL_CTA: SHOW_FAST_RENEWAL_CTA
};
exports.ANALYTICS_EVENT_TYPES = ANALYTICS_EVENT_TYPES;