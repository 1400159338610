"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _cookie = require("../../Utils/cookie");

var _geolocation = require("../../Utils/geolocation");

var _types = _interopRequireDefault(require("../Ducks/UserLocation/types"));

var _analytics = require("../../Utils/analytics");

var FETCHING_SUCCESS_USER_LOCATION = _types.default.FETCHING_SUCCESS_USER_LOCATION,
    FETCHING_ERROR_USER_LOCATION = _types.default.FETCHING_ERROR_USER_LOCATION;

var userLocation = function userLocation(store) {
  return function (next) {
    return function (action) {
      var type = action.type,
          payload = action.payload;

      if (type === FETCHING_SUCCESS_USER_LOCATION) {
        var analyticsUtil = new _analytics.Analytics();
        var datalayerUtil = analyticsUtil.datalayerUtil;
        var originKey = analyticsUtil.data.getIn(['dataLayerParams', 'origin']);
        var data = payload.get('data');
        var configData = store.getState().config.get('data');
        var countryCode = configData.get('countryCode');
        var airportCode = configData.get('airportCode');
        var flightSchedule = store.getState().flightSchedule.get('data');
        var airports = flightSchedule.getIn(['Airports', 'ById']);
        var isCountryCodeMatch = data.get('countryCode') === countryCode;
        var closestAirport = (0, _geolocation.getNearestAirport)({
          lat: data.get('lat'),
          long: data.get('long'),
          airports: airports
        });
        var defaultAirport = airports.get(airportCode) && airports.get(airportCode).set('Key', airportCode);

        if (isCountryCodeMatch) {
          defaultAirport = closestAirport;
        }

        datalayerUtil && datalayerUtil.add((0, _defineProperty2.default)({}, originKey, closestAirport.get('Key')));
        defaultAirport && (0, _cookie.setDefaultAirport)(defaultAirport.get('Key'));
        return next({
          type: type,
          payload: payload.set('data', defaultAirport)
        });
      }

      if (type === FETCHING_ERROR_USER_LOCATION) {
        var _configData = store.getState().config.get('data');

        var _airportCode = _configData.get('airportCode');

        var _flightSchedule = store.getState().flightSchedule.get('data');

        (0, _cookie.setDefaultAirport)(_airportCode);
        return next({
          type: type,
          payload: (0, _immutable.Map)({
            isFetching: false
          }).set('data', _flightSchedule.getIn(['Airports', 'ById', _airportCode]).set('Key', _airportCode))
        });
      }

      return next(action);
    };
  };
};

var _default = userLocation;
exports.default = _default;