"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onProfileFetchSuccess = exports.onProfileFetch = exports.onLogin = exports.onCJRenewalFetchSuccess = exports.onCJRenewalFetch = exports.onAuthRedirect = exports.onAuthReady = exports.onAccountLogout = exports.init = void 0;

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var init = (0, _reduxActions.createAction)(_types.default.GLOBAL_LOGIN_INIT, function () {
  return null;
});
exports.init = init;
var onAccountLogout = (0, _reduxActions.createAction)(_types.default.ACCOUNT_ONLOGOUT, function () {
  return null;
});
exports.onAccountLogout = onAccountLogout;
var onCJRenewalFetch = (0, _reduxActions.createAction)(_types.default.CJ_RENEWAL_FETCH, function () {
  return {
    isFetching: true
  };
});
exports.onCJRenewalFetch = onCJRenewalFetch;
var onCJRenewalFetchSuccess = (0, _reduxActions.createAction)(_types.default.CJ_RENEWAL_FETCH_SUCCESS, function (url) {
  return url;
});
exports.onCJRenewalFetchSuccess = onCJRenewalFetchSuccess;
var onAuthReady = (0, _reduxActions.createAction)(_types.default.ON_AUTH_READY, function (isNewUser) {
  return {
    isNewUser: isNewUser
  };
});
exports.onAuthReady = onAuthReady;
var onAuthRedirect = (0, _reduxActions.createAction)(_types.default.ON_AUTH_REDIRECT, function () {
  return null;
});
exports.onAuthRedirect = onAuthRedirect;
var onProfileFetch = (0, _reduxActions.createAction)(_types.default.PROFILE_ONFETCH, function () {
  return null;
});
exports.onProfileFetch = onProfileFetch;
var onProfileFetchSuccess = (0, _reduxActions.createAction)(_types.default.PROFILE_ONFETCH_SUCCESS, function () {
  return null;
});
exports.onProfileFetchSuccess = onProfileFetchSuccess;
var onLogin = (0, _reduxActions.createAction)(_types.default.ON_LOGIN, function (response) {
  return {
    response: response
  };
});
exports.onLogin = onLogin;