"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createInitialiseCounter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.regexp.search.js");

var _qs = _interopRequireDefault(require("qs"));

var _selectors = require("./selectors");

var _counters = require("../../../Constants/counters");

var _strings = require("../../../Utils/strings");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var ADULTS = _counters.PAX_TYPES.ADULTS,
    CHILDREN = _counters.PAX_TYPES.CHILDREN,
    INFANTS = _counters.PAX_TYPES.INFANTS;

var createInitialiseCounter = function createInitialiseCounter(_ref) {
  var settings = _ref.settings,
      actions = _ref.actions,
      stateKey = _ref.stateKey,
      counterRules = _ref.counterRules,
      extraRules = _ref.extraRules,
      ignoreQueryParams = _ref.ignoreQueryParams;
  return function (dispatch, getState) {
    var state = getState()[stateKey] || {};

    if (!state.counter) {
      return;
    }

    if (state.counter.get('isLoaded')) {
      return;
    }

    var onCounterChange = actions.onCounterChange,
        onCounterInit = actions.onCounterInit;
    var query = ignoreQueryParams ? [] : _qs.default.parse(window.location.search, {
      ignoreQueryPrefix: true
    });
    var ADULTS_CHILDREN_INFANT_RATIO = counterRules.ADULTS_CHILDREN_INFANT_RATIO,
        ADULTS_INFANTS_RATIO = counterRules.ADULTS_INFANTS_RATIO,
        MIN_TOTAL = counterRules.MIN_TOTAL,
        TOTAL = counterRules.TOTAL;
    dispatch(onCounterInit({
      rules: _objectSpread({
        adults: {
          min: settings.get('MinimumAdults'),
          max: settings.get('MaximumAdults')
        },
        children: {
          min: settings.get('MinimumChildren'),
          max: settings.get('MaximumChildren')
        },
        infants: {
          min: settings.get('MinimumInfants'),
          max: settings.get('MaximumInfants')
        },
        total: settings.get('TotalPassengers') || TOTAL,
        minTotal: settings.get('MinimumNumberOfPassengers') || MIN_TOTAL,
        adultsChildrenRatio: settings.get('AdultChildRatio') || ADULTS_CHILDREN_INFANT_RATIO,
        adultsInfantsRatio: settings.get('AdultInfantRatio') || ADULTS_INFANTS_RATIO
      }, extraRules),
      isLoaded: true
    }));
    var adultsCount = (0, _selectors.getAdults)(state);
    var childrenCount = (0, _selectors.getChildren)(state);
    var infantsCount = (0, _selectors.getInfants)(state);
    dispatch(onCounterChange(ADULTS, (0, _strings.initialCount)(query[_counters.COUNT_QUERY_KEY.ADULTS], settings.get('NumberOfAdults'), adultsCount)));
    dispatch(onCounterChange(CHILDREN, (0, _strings.initialCount)(query[_counters.COUNT_QUERY_KEY.CHILDREN], settings.get('NumberOfChildren'), childrenCount)));
    dispatch(onCounterChange(INFANTS, (0, _strings.initialCount)(query[_counters.COUNT_QUERY_KEY.INFANTS], settings.get('NumberOfInfants'), infantsCount)));
  };
};

exports.createInitialiseCounter = createInitialiseCounter;