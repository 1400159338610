"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sumOfAll = exports.parseIntSafe = exports.limit = exports.isWithinLimits = exports.isOdd = exports.isGreaterThanRatio = void 0;

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.number.is-nan.js");

require("core-js/modules/es6.number.constructor.js");

/**
 * Aggregrate all arguments
 * @function sumOfAll
 * @memberof Utils/number
 * @param {Object} args
 * @returns {number}
 */
var sumOfAll = function sumOfAll() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return args.filter(function (x) {
    return typeof x === 'number';
  }).reduce(function (x, y) {
    return x + y;
  }, 0);
};
/**
 * Determine if values meet or exceed the given ratio
 * @function isGreaterThanRatio
 * @memberof Utils/number
 * @param {number} x
 * @param {number} y
 * @param {number} ratio
 * @returns {boolean}
 */


exports.sumOfAll = sumOfAll;

var isGreaterThanRatio = function isGreaterThanRatio(x, y, ratio) {
  return x / y >= ratio;
};
/**
 * Determine if provided arguments is an odd number
 * @function isOdd
 * @memberof Utils/number
 * @param {number} x
 * @param {number} y
 * @param {number} ratio
 * @returns {boolean}
 */


exports.isGreaterThanRatio = isGreaterThanRatio;

var isOdd = function isOdd(x, y) {
  return sumOfAll(x, y) % 2 !== 0;
};
/**
 * Return a number from a given range
 * @function limit
 * @memberof Utils/number
 * @param {number} number
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */


exports.isOdd = isOdd;

var limit = function limit(number, min, max) {
  return Math.min(Math.max(number, min), max);
};
/**
 * Returns boolean of whether a number is within a given range
 * @function isWithinLimit
 * @memberof Utils/number
 * @param {number} number
 * @param {number} min
 * @param {number} max
 * @param {min} inclusiveOfLimits - return true for number that is equal to min / max
 * @returns {boolean}
 */


exports.limit = limit;

var isWithinLimits = function isWithinLimits(number, min, max) {
  var inclusiveOfLimits = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

  if (inclusiveOfLimits) {
    return number >= min && number <= max;
  }

  return number > min && number < max;
};
/**
 * Returns an integer - never NaN
 * @function parseIntSafe
 * @memberof Utils/number
 * @param {string} string
 * @returns {number}
 */


exports.isWithinLimits = isWithinLimits;

var parseIntSafe = function parseIntSafe(str) {
  var n = parseInt(str, 10);
  return Number.isNaN(n) ? 0 : n;
};

exports.parseIntSafe = parseIntSafe;