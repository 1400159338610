"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    grey: '#CCCCCC',
    orange: '#F54E00',
    blue: '#0692fc',
    lightGrey: '#F4F4F4',
    borderGrey: '#DDD',
    pinlineGrey: '#d5d6d7',
    error: '#DD0000',
    infoBlue: '#e3f3ff',
    infoBorderBlue: '#bfd5e4'
  }
};
exports.default = _default;