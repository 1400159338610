"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var FETCHING_LOCALE = 'FETCHING_LOCALE';
var FETCHING_SUCCESS_LOCALE = 'FETCHING_SUCCESS_LOCALE';
var _default = {
  FETCHING_LOCALE: FETCHING_LOCALE,
  FETCHING_SUCCESS_LOCALE: FETCHING_SUCCESS_LOCALE
};
exports.default = _default;