"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isDatesCached = exports.getRoute = exports.getInitialRooms = exports.getHolidaySearchRequestModel = exports.getDays = exports.getCalendarStartDateKey = exports.generateNewRoom = exports.createBookingUrl = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.number.constructor.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es7.object.entries.js");

var _immutable = require("immutable");

var _momentEs = _interopRequireDefault(require("moment-es6"));

var _qs = _interopRequireDefault(require("qs"));

var shortid = _interopRequireWildcard(require("shortid"));

var _dates = require("../Constants/dates");

var _flightSearch = require("../Constants/flightSearch");

var _flightType = require("../Constants/flightType");

var _holiday = require("../Constants/holiday");

var _dates2 = require("./dates");

var _Counter = require("./../State/Common/Counter");

var _numbers = require("./numbers");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;
var ROOM_ID = _holiday.HOLIDAY_KEYS.ROOM_ID,
    ROOM_COUNTER = _holiday.HOLIDAY_KEYS.ROOM_COUNTER,
    SINGLE_ROOM_ENTRIES = _holiday.HOLIDAY_KEYS.SINGLE_ROOM_ENTRIES;

var getHolidaySearchRequestModel = function getHolidaySearchRequestModel(calendarStartDate, direction, inboundCode, outboundCode, totalPax) {
  var _fromJS;

  var fromDate = (0, _momentEs.default)(calendarStartDate).startOf('month');
  var toDate = (0, _momentEs.default)(calendarStartDate).add(Number(1), 'M').endOf('month');
  var departureByDirection = direction === INBOUND ? inboundCode : outboundCode;
  var arrivalByDirection = direction === INBOUND ? outboundCode : inboundCode;
  var finalTotalPax = (0, _numbers.isWithinLimits)(totalPax, _holiday.HOLIDAY_QUERY_RULES.MIN_TOTAL_PAX, _holiday.HOLIDAY_QUERY_RULES.MAX_TOTAL_PAX) ? totalPax : null;
  var FROM = _flightSearch.FARE_CACHE_QUERY_KEY.FROM,
      END = _flightSearch.FARE_CACHE_QUERY_KEY.END,
      DEPARTURES = _flightSearch.FARE_CACHE_QUERY_KEY.DEPARTURES,
      ARRIVALS = _flightSearch.FARE_CACHE_QUERY_KEY.ARRIVALS,
      PAX_COUNT = _flightSearch.FARE_CACHE_QUERY_KEY.PAX_COUNT,
      DIRECTION = _flightSearch.FARE_CACHE_QUERY_KEY.DIRECTION;
  return (0, _immutable.fromJS)((_fromJS = {}, (0, _defineProperty2.default)(_fromJS, FROM, fromDate), (0, _defineProperty2.default)(_fromJS, END, toDate), (0, _defineProperty2.default)(_fromJS, DEPARTURES, departureByDirection), (0, _defineProperty2.default)(_fromJS, ARRIVALS, arrivalByDirection), (0, _defineProperty2.default)(_fromJS, PAX_COUNT, finalTotalPax), (0, _defineProperty2.default)(_fromJS, DIRECTION, direction), _fromJS));
};

exports.getHolidaySearchRequestModel = getHolidaySearchRequestModel;

var getRoute = function getRoute(direction, inboundCode, outboundCode) {
  if (!inboundCode || !outboundCode) {
    return null;
  }

  return (direction === INBOUND ? inboundCode : outboundCode).concat(direction === INBOUND ? outboundCode : inboundCode).toLowerCase();
};

exports.getRoute = getRoute;

var getDays = function getDays(direction, inboundCode, outboundCode, inboundDays, outboundDays) {
  var routeKey = getRoute(direction, inboundCode, outboundCode);
  var directionDates = direction === OUTBOUND ? outboundDays : inboundDays;
  if (!directionDates || !routeKey) return null;
  var route = directionDates.getIn(['routes', routeKey]);
  var days = route ? route.get(_holiday.HOLIDAY_KEYS.DAYS) : null;
  return days;
};

exports.getDays = getDays;

var getCalendarStartDateKey = function getCalendarStartDateKey(date) {
  var key = (0, _dates2.getFirstDayOfMonth)(date)();
  return key;
};

exports.getCalendarStartDateKey = getCalendarStartDateKey;

var isDatesCached = function isDatesCached(calendarStartDate, direction, inboundCode, outboundCode, inboundDays, outboundDays) {
  var days = getDays(direction, inboundCode, outboundCode, inboundDays, outboundDays);
  return days != null && days.has(getCalendarStartDateKey(calendarStartDate));
};

exports.isDatesCached = isDatesCached;

var createOldBookingUrl = function createOldBookingUrl(_ref) {
  var _objectSpread2;

  var redirectUrl = _ref.redirectUrl,
      returnDate = _ref.returnDate,
      departureDate = _ref.departureDate,
      originCode = _ref.originCode,
      destinationCode = _ref.destinationCode,
      roomsDetails = _ref.roomsDetails;
  var destinationsList = (0, _immutable.fromJS)([originCode, destinationCode]).filter(function (item) {
    return item;
  }).join('/');
  var DATE_OF_DEPARTURE = _holiday.BOOKING_QUERY_KEYS.DATE_OF_DEPARTURE,
      DATE_OF_RETURN = _holiday.BOOKING_QUERY_KEYS.DATE_OF_RETURN;
  var bookingQuery = (0, _immutable.fromJS)(_objectSpread((_objectSpread2 = {}, (0, _defineProperty2.default)(_objectSpread2, DATE_OF_DEPARTURE, departureDate), (0, _defineProperty2.default)(_objectSpread2, DATE_OF_RETURN, returnDate), _objectSpread2), roomsDetails));

  var queryString = _qs.default.stringify(bookingQuery.toJS(), {
    encode: false,
    skipNulls: true
  });

  var querySeparator = redirectUrl.indexOf('?') !== -1 ? '&' : '?';
  return "".concat(redirectUrl).concat(destinationsList).concat(querySeparator).concat(queryString);
};

var createNewBookingUrl = function createNewBookingUrl(_ref2) {
  var redirectUrl = _ref2.redirectUrl,
      returnDate = _ref2.returnDate,
      departureDate = _ref2.departureDate,
      originCode = _ref2.originCode,
      destinationCode = _ref2.destinationCode,
      roomsDetails = _ref2.roomsDetails,
      utmSource = _ref2.utmSource,
      medium = _ref2.medium,
      campaign = _ref2.campaign,
      pid = _ref2.pid;

  if (Object.entries(roomsDetails).length > SINGLE_ROOM_ENTRIES) {
    return '';
  }

  var adults = roomsDetails['groups[0][adults]'];
  var children = roomsDetails['groups[0][children]'];
  var infants = roomsDetails['groups[0][infants]'];

  var queryString = _qs.default.stringify({
    originCode: originCode,
    adults: adults,
    children: children,
    infants: infants,
    departureDate: departureDate,
    returnDate: returnDate,
    utm_source: utmSource,
    medium: medium,
    campaign: campaign,
    pid: pid
  }, {
    encode: false,
    skipNulls: true
  });

  return "".concat(redirectUrl).concat(destinationCode || '', "?").concat(queryString);
};

var createBookingUrl = function createBookingUrl(submitModel, ctaUrl, ctaUseNewQuery) {
  var DATE_OF_DEPARTURE = _holiday.BOOKING_QUERY_KEYS.DATE_OF_DEPARTURE,
      DATE_OF_RETURN = _holiday.BOOKING_QUERY_KEYS.DATE_OF_RETURN,
      DESTINATION = _holiday.BOOKING_QUERY_KEYS.DESTINATION,
      ORIGIN = _holiday.BOOKING_QUERY_KEYS.ORIGIN,
      PID = _holiday.BOOKING_QUERY_KEYS.PID;
  var url = ctaUrl.get('Url');

  if (!url || url && url.indexOf('https://') !== 0) {
    return '';
  }

  var redirectUrl = url.substr(-1) === '/' ? url : "".concat(url, "/");
  var departureDate = (0, _dates2.dateFormatterToString)(submitModel.get(DATE_OF_DEPARTURE), _dates.DEFAULT_BOOKING_ENGINE_DATE_FORMAT);
  var returnDate = (0, _dates2.dateFormatterToString)(submitModel.get(DATE_OF_RETURN), _dates.DEFAULT_BOOKING_ENGINE_DATE_FORMAT);
  var originCode = submitModel.get(ORIGIN);
  var destinationCode = submitModel.get(DESTINATION);
  var roomsDetails = submitModel.get(_holiday.HOLIDAY_KEYS.ROOMS).toJS();
  var pid = submitModel.get(PID);
  var utmSource = _holiday.UTM_QUERY_PARAMS.UTM_SOURCE;
  var medium = _holiday.UTM_QUERY_PARAMS.MEDIUM;
  var campaign = _holiday.UTM_QUERY_PARAMS.CAMPAIGN;
  var bookingUrlParams = {
    destinationCode: destinationCode,
    redirectUrl: redirectUrl,
    returnDate: returnDate,
    departureDate: departureDate,
    originCode: originCode,
    roomsDetails: roomsDetails,
    utmSource: utmSource,
    medium: medium,
    campaign: campaign,
    pid: pid
  };
  return ctaUseNewQuery ? createNewBookingUrl(_objectSpread({}, bookingUrlParams)) : createOldBookingUrl(_objectSpread({}, bookingUrlParams));
};

exports.createBookingUrl = createBookingUrl;
var initialCounter = (0, _Counter.getInitialCounters)(_holiday.DEFAULT_ADULTS_COUNT);

var generateNewRoom = function generateNewRoom() {
  var _roomId;

  var roomId = shortid.generate();
  return (0, _defineProperty2.default)({}, roomId, (_roomId = {}, (0, _defineProperty2.default)(_roomId, ROOM_ID, roomId), (0, _defineProperty2.default)(_roomId, ROOM_COUNTER, initialCounter), _roomId));
};

exports.generateNewRoom = generateNewRoom;

var getInitialRooms = function getInitialRooms(defaultRooms) {
  var rooms = {};
  var i;

  for (i = 0; i < defaultRooms; i++) {
    rooms = _objectSpread(_objectSpread({}, rooms), generateNewRoom());
  }

  return rooms;
};

exports.getInitialRooms = getInitialRooms;