"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = exports.SIZES = exports.SIDES = void 0;
var NIL = '0';
var HALF = '0.5';
var THREE_QUARTERS = '0.75';
var ONE = '1';
var ONE_AND_HALF = '1.5';
var TWO = '2';
var TWO_AND_HALF = '2.5';
var THREE = '3';
var FOUR = '4';
var SIZES = {
  NIL: NIL,
  HALF: HALF,
  THREE_QUARTERS: THREE_QUARTERS,
  ONE: ONE,
  ONE_AND_HALF: ONE_AND_HALF,
  TWO: TWO,
  TWO_AND_HALF: TWO_AND_HALF,
  THREE: THREE,
  FOUR: FOUR
};
exports.SIZES = SIZES;
var MARGIN = 'Margin';
var PADDING = 'Padding';
var TYPES = {
  MARGIN: MARGIN,
  PADDING: PADDING
};
exports.TYPES = TYPES;
var ALL = 'All';
var BOTTOM = 'Bottom';
var HORIZONTAL = 'X';
var LEFT = 'Left';
var RIGHT = 'Right';
var TOP = 'Top';
var VERTICAL = 'Y';
var SIDES = {
  ALL: ALL,
  BOTTOM: BOTTOM,
  HORIZONTAL: HORIZONTAL,
  LEFT: LEFT,
  RIGHT: RIGHT,
  TOP: TOP,
  VERTICAL: VERTICAL
};
exports.SIDES = SIDES;