"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "globalLogin", {
  enumerable: true,
  get: function get() {
    return _reducers.default;
  }
});

var _reducers = _interopRequireDefault(require("./reducers"));