"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortUserStatesByPriority = void 0;

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.array.sort.js");

var _immutable = require("immutable");

var _personalisation = require("../Constants/personalisation");

// constants

/**
 * Return userState array sorted into priority order.
 * Order is important for personalized components to know which content to serve if a user has multiple userStates
 * @function sortUserStatesByPriority
 * @param {array} data - array of strings
 * @return {List} - data is sorted into userStates priority order
 */
var sortUserStatesByPriority = function sortUserStatesByPriority(data) {
  if (data === null || !Array.isArray(data)) {
    return (0, _immutable.List)();
  } // remove userStates that aren't listed in USERSTATES_ORDER


  var validUserStates = data.filter(function (item) {
    return Object.keys(_personalisation.USERSTATES_ORDER).indexOf(item) !== -1;
  });
  var sorted = validUserStates.sort(function (a, b) {
    return _personalisation.USERSTATES_ORDER[a] - _personalisation.USERSTATES_ORDER[b];
  });
  return (0, _immutable.List)(sorted);
};

exports.sortUserStatesByPriority = sortUserStatesByPriority;