"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchingUserLocationSuccess = exports.fetchingUserLocationError = exports.fetchingUserLocation = void 0;

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var fetchingUserLocation = (0, _reduxActions.createAction)(_types.default.FETCHING_USER_LOCATION, function () {
  return (0, _immutable.Map)({
    isFetching: true
  });
});
exports.fetchingUserLocation = fetchingUserLocation;
var fetchingUserLocationSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_SUCCESS_USER_LOCATION, function (data) {
  return (0, _immutable.Map)({
    isFetching: false
  }).set('data', data);
});
exports.fetchingUserLocationSuccess = fetchingUserLocationSuccess;
var fetchingUserLocationError = (0, _reduxActions.createAction)(_types.default.FETCHING_ERROR_USER_LOCATION, function (noop) {
  return noop;
});
exports.fetchingUserLocationError = fetchingUserLocationError;