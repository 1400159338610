"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "fetchComponentData", {
  enumerable: true,
  get: function get() {
    return _operations.fetchComponentData;
  }
});
Object.defineProperty(exports, "schema", {
  enumerable: true,
  get: function get() {
    return _reducers.default;
  }
});

var _operations = require("./operations");

var _reducers = _interopRequireDefault(require("./reducers"));