"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInitialCounters = exports.createCounterReducer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _actions = require("./actions");

// react
// global modules
// types
// components
// constants
// state
// utils
// assets
// state
var getInitialCounters = function getInitialCounters(defaultAdultCount) {
  return (0, _immutable.fromJS)({
    count: {
      adults: defaultAdultCount,
      children: 0,
      infants: 0
    },
    errors: {
      adults: {
        min: 0,
        max: 0
      },
      children: {
        min: 0,
        max: 0
      },
      infants: {
        min: 0,
        max: 0
      },
      total: 0,
      minTotal: 0,
      adultsInfantsRatio: 0,
      adultsChildrenRatio: 0,
      fareInPairs: 0
    },
    total: 0,
    isLoaded: false
  });
};

exports.getInitialCounters = getInitialCounters;

var updateCount = function updateCount(state, action) {
  return state.updateIn(['count', action.payload.id], function (val) {
    return typeof action.payload.count !== 'undefined' ? action.payload.count : val;
  }).update('errors', function (val) {
    return typeof action.payload.errors !== 'undefined' ? action.payload.errors : val;
  }).update('total', function (val) {
    return typeof action.payload.total !== 'undefined' ? action.payload.total : val;
  });
};

var createCounterReducer = function createCounterReducer(namespace) {
  var _handleActions;

  var defaultAdultCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, (0, _actions.createCounterChange)(namespace), function (state, action) {
    return updateCount(state, action);
  }), (0, _defineProperty2.default)(_handleActions, (0, _actions.createCounterDecrement)(namespace), function (state, action) {
    return updateCount(state, action);
  }), (0, _defineProperty2.default)(_handleActions, (0, _actions.createCounterIncrement)(namespace), function (state, action) {
    return updateCount(state, action);
  }), (0, _defineProperty2.default)(_handleActions, (0, _actions.createCounterInit)(namespace), function (state, action) {
    return state.set('isLoaded', action.payload.data.isLoaded);
  }), (0, _defineProperty2.default)(_handleActions, (0, _actions.createSetPax)(namespace), function (state, action) {
    var total = action.payload.data.get('total');
    var count = action.payload.data.get('count');
    var errors = action.payload.data.get('errors');
    return state.update('total', function (val) {
      return total;
    }).update('count', function (val) {
      return count;
    }).update('errors', function (val) {
      return errors;
    });
  }), _handleActions), getInitialCounters(defaultAdultCount));
};

exports.createCounterReducer = createCounterReducer;