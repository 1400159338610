"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setDefaultAirport = exports.setCookie = exports.setAirportPreference = exports.removeCookie = exports.isMobile = exports.getUserLocation = exports.getDefaultAirport = exports.getAirportPreference = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.promise.js");

var _serializeJavascript = _interopRequireDefault(require("serialize-javascript"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _immutable = require("immutable");

var _qs = _interopRequireDefault(require("qs"));

var _strings = require("./strings");

var USER_LOCATION_NAME = 'user-location';
var DEFAULT_AIRPORT_NAME = 'default-airport';
var SELECTED_AIRPORT_NAME = 'selected-airport';
var IS_MOBILE = 'isMobile';
/**
 * Cookie helpers
 * @namespace Utils/cookie
 */

/**
 * Get user location cookie generated from Akamai and parse the string to object
 * @function getUserLocation
 * @memberof Utils/cookie
 * @returns {promise}
 */

var getUserLocation = function getUserLocation() {
  return new Promise(function (resolve, reject) {
    try {
      var val = _jsCookie.default.get(USER_LOCATION_NAME);

      var userLocation;

      if (val) {
        var obj = (0, _serializeJavascript.default)(_qs.default.parse(val, {
          delimiter: ','
        }));
        userLocation = (0, _immutable.fromJS)((0, _strings.deSerialize)(obj)).mapKeys(function (k) {
          return (0, _strings.underscoreToCamel)(k);
        });
        return resolve(userLocation);
      }

      return reject(new Error('Cookie not found'));
    } catch (e) {
      return reject(e);
    }
  });
};
/**
 * Set default airport code cookie
 * @function setDefaultAirport
 * @memberof Utils/cookie
 * @param {string} airportCode
 * @memberof Utils/cookie
 */


exports.getUserLocation = getUserLocation;

var setDefaultAirport = function setDefaultAirport(airportCode) {
  if (typeof airportCode !== 'string') {
    return;
  }

  _jsCookie.default.set(DEFAULT_AIRPORT_NAME, airportCode, {
    expires: 5
  });
};
/**
 * Get default airport code cookie
 * @function getDefaultAirport
 * @memberof Utils/cookie
 * @returns {string|undefined}
 */


exports.setDefaultAirport = setDefaultAirport;

var getDefaultAirport = function getDefaultAirport() {
  return _jsCookie.default.get(DEFAULT_AIRPORT_NAME);
};
/**
 * Set selected airport code cookie
 * @function setAirportPreference
 * @memberof Utils/cookie
 * @param {string} airportCode
 */


exports.getDefaultAirport = getDefaultAirport;

var setAirportPreference = function setAirportPreference(airportCode) {
  if (typeof airportCode !== 'string') {
    return;
  }

  _jsCookie.default.set(SELECTED_AIRPORT_NAME, airportCode);
};
/**
 * Get selected airport code cookie
 * @function getAirportPreference
 * @memberof Utils/cookie
 * @returns {string|undefined}
 */


exports.setAirportPreference = setAirportPreference;

var getAirportPreference = function getAirportPreference() {
  return _jsCookie.default.get(SELECTED_AIRPORT_NAME);
};

exports.getAirportPreference = getAirportPreference;
var isMobile = _jsCookie.default.get(IS_MOBILE) === 'true';
/**
 * Set cookie
 * @function setCookie
 * @memberof Utils/cookie
 * @param {string} name cookie name
 * @param {string} val cookie value
 * @param {object} options optional param
 */

exports.isMobile = isMobile;

var setCookie = function setCookie(name, val) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (typeof name !== 'string' || typeof val !== 'string') {
    return;
  }

  _jsCookie.default.set(name, val, options);
};
/**
 * Remove cookie
 * @function removeCookie
 * @memberof Utils/cookie
 * @param {string} name cookie name
 * @param {object} options optional param
 */


exports.setCookie = setCookie;

var removeCookie = function removeCookie(name) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (typeof name !== 'string') {
    return;
  }

  _jsCookie.default.remove(name, options);
};

exports.removeCookie = removeCookie;