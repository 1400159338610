"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendAnalyticsEvent = void 0;

/* istanbul ignore file */
var sendAnalyticsEvent = function sendAnalyticsEvent(testType, testName, eventType) {
  window.bxOptimisationTests = window.bxOptimisationTests || [];
  window.bxOptimisationTests.push({
    optimisationTestType: testType,
    optimisationTestName: testName
  });
  var events = {
    loaded: 'optimisationTestLoaded',
    displayed: 'optimisationTestDisplayed',
    clicked: 'optimisationTestClicked',
    dismissed: 'optimisationTestDismissed'
  };
  var bxDatalayerEvent = new Event(events[eventType]);
  bxDatalayerEvent.data = {
    optimisationTestName: testName
  };
  document.dispatchEvent(bxDatalayerEvent);
};

exports.sendAnalyticsEvent = sendAnalyticsEvent;