"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appInsightsTrackEvent = void 0;

/**
 * Application Insights helpers
 */

/**
 * appInsightsTrackEvent
 * @namespace Utils/insights
 * @param {?string} eventName // Name of the event emitted
 * @param {?Object} stringProperties // Properties are string values that you can use to filter your telemetry in the usage reports.
 * @param {?Object} numericMetrics // Metrics are numeric values that can be presented graphically.
 */
var appInsightsTrackEvent = function appInsightsTrackEvent(eventName, stringProperties, numericMetrics) {
  if (!window.appInsights) {
    return;
  }

  var _window = window,
      appInsights = _window.appInsights;
  appInsights.trackEvent(eventName, stringProperties, numericMetrics);
};

exports.appInsightsTrackEvent = appInsightsTrackEvent;