"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchingLabelsSuccess = exports.fetchingLabels = void 0;

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = _interopRequireDefault(require("./types"));

var fetchingLabels = (0, _reduxActions.createAction)(_types.default.FETCHING_LABELS, function () {
  return (0, _immutable.Map)({
    isFetching: true
  });
});
exports.fetchingLabels = fetchingLabels;
var fetchingLabelsSuccess = (0, _reduxActions.createAction)(_types.default.FETCHING_SUCCESS_LABELS, function (data) {
  return (0, _immutable.Map)({
    isFetching: false
  }).set('data', data);
});
exports.fetchingLabelsSuccess = fetchingLabelsSuccess;