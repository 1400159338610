"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNearestAirport = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

require("core-js/modules/es6.number.constructor.js");

require("core-js/modules/es6.array.map.js");

var _immutable = require("immutable");

var geolib = require('geolib');

/**
 * Geolocation helpers
 * @namespace Utils/geolocation
 */

/**
 * Calculates the distance between two coordinates
 * @function format
 * @memberof Utils/geolocation
 * @param {number} lat - current users lattitude
 * @param {number} long - current users longitude
 * @param {object} airport
 * @param {number} airport.lat - airport lattitude
 * @param {number} airport.long - airport longitude
 * @returns {function}
 */
var makeCalcDistance = function makeCalcDistance(lat, _long) {
  return function (airport, key) {
    var airportCoord = {
      latitude: Number(airport.get('Lat')),
      longitude: Number(airport.get('Long'))
    };
    var userCoord = {
      latitude: lat,
      longitude: _long
    };
    return airport.set('Distance', geolib.getDistance(airportCoord, userCoord)).set('Key', key);
  };
};
/**
 * Determines the closest location based on calculated distance
 * @function distanceReducer
 * @memberof Utils/geolocation
 * @param {object} next
 * @param {number} next.distance - distance in kms
 * @param {object} prev
 * @param {number} prev.distance - distance in kms
 * @returns {object}
 */


var distanceReducer = function distanceReducer(prev, next) {
  if (prev.has('Distance')) {
    return next.get('Distance') < prev.get('Distance') ? next : prev;
  }

  return next;
};
/**
 * Determines the closest location based on calculated distance
 * @function getNearestAirport
 * @memberof Utils/geolocation
 * @param {number} lat - current users lattitude
 * @param {number} long - current users longitude
 * @param {object} airports
 * @returns {object}
 */


var getNearestAirport = function getNearestAirport(_ref) {
  var lat = _ref.lat,
      _long2 = _ref.long,
      airports = _ref.airports;
  var calcUserDistance = makeCalcDistance(lat, _long2);
  return airports.entrySeq().map(function (_ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref2, 2),
        key = _ref3[0],
        value = _ref3[1];

    return calcUserDistance(value, key);
  }).reduce(distanceReducer, (0, _immutable.fromJS)({}));
};

exports.getNearestAirport = getNearestAirport;