// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._dropdown__searchDropdown--H828f,._dropdown__travellerDropdown--CYRJI,._dropdown__vanillaDropdown--RpkjE{position:relative}._dropdown__dropdownPanel--Xl2rn{background:#fff;-webkit-box-shadow:0 .1875rem .5rem 0 rgba(0,0,0,.12);box-shadow:0 .1875rem .5rem 0 rgba(0,0,0,.12);position:absolute;z-index:99999}._dropdown__vanillaDropdown--RpkjE ._dropdown__dropdownPanel--Xl2rn{left:0;margin-top:.5rem;min-width:100%;top:100%;width:auto}._dropdown__searchDropdown--H828f ._dropdown__dropdownPanel--Xl2rn{border:1px solid #0692fc;top:0;width:100%}._dropdown__backDrop--xS7EZ{bottom:0;left:0;position:fixed;right:0;top:0;z-index:99999}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/Dropdown/_dropdown.scss"],"names":[],"mappings":"AAAA,0GAAoD,iBAAiB,CAAC,iCAAe,eAAA,CAAgB,qDAAA,CAAA,6CAAA,CAA8C,iBAAA,CAAkB,aAAa,CAAC,oEAAgC,MAAA,CAAO,gBAAA,CAAiB,cAAA,CAAe,QAAA,CAAS,UAAU,CAAC,mEAA+B,wBAAA,CAAyB,KAAA,CAAM,UAAU,CAAC,4BAAU,QAAA,CAAS,MAAA,CAAO,cAAA,CAAe,OAAA,CAAQ,KAAA,CAAM,aAAa","sourcesContent":[".vanillaDropdown,.searchDropdown,.travellerDropdown{position:relative}.dropdownPanel{background:#fff;box-shadow:0 .1875rem .5rem 0 rgba(0,0,0,.12);position:absolute;z-index:99999}.vanillaDropdown .dropdownPanel{left:0;margin-top:.5rem;min-width:100%;top:100%;width:auto}.searchDropdown .dropdownPanel{border:1px solid #0692fc;top:0;width:100%}.backDrop{bottom:0;left:0;position:fixed;right:0;top:0;z-index:99999}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchDropdown": "_dropdown__searchDropdown--H828f",
	"travellerDropdown": "_dropdown__travellerDropdown--CYRJI",
	"vanillaDropdown": "_dropdown__vanillaDropdown--RpkjE",
	"dropdownPanel": "_dropdown__dropdownPanel--Xl2rn",
	"backDrop": "_dropdown__backDrop--xS7EZ"
};
export default ___CSS_LOADER_EXPORT___;
