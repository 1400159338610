"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sectors = exports.locations = exports.isLoading = exports.initialSectors = exports.initialLocations = exports.initialFlightType = exports.initialFlexible = exports.initialCurrency = exports.initialConfig = exports.initialClubJetstar = exports.initialCalendars = exports.flightType = exports.flexible = exports.default = exports.currency = exports.config = exports.clubjetstar = exports.calendars = void 0;

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.function.name.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _immutable = require("immutable");

var _redux = require("redux");

var _reduxActions = require("redux-actions");

var _flightSearch = require("../../../Constants/flightSearch");

var _flightType = require("../../../Constants/flightType");

var _types = require("./types");

var _actions = require("./actions");

var _Counter = require("../../Common/Counter");

var _handleActions, _handleActions4, _handleActions5, _handleActions7;

var RETURN = _flightType.FLIGHT_TYPE_IDS.RETURN;
var initialFlightType = (0, _immutable.fromJS)({
  selected: RETURN,
  flightTypes: {}
});
exports.initialFlightType = initialFlightType;
var flightType = (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, _actions.onFlightTypeChange, function (state, action) {
  return state.update('selected', function (type) {
    return action.payload.get('selected');
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onInitFlightType, function (state, action) {
  var payload = action.payload;
  return state.setIn(['flightTypes', payload.get('id')], payload.get('data')).set('selected', payload.get('selected'));
}), _handleActions), initialFlightType);
exports.flightType = flightType;
var initialFlexible = (0, _immutable.fromJS)({
  data: 1
});
exports.initialFlexible = initialFlexible;
var flexible = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.onFlexibleChange, function (state, action) {
  return state.merge({
    data: action.payload.data
  });
}), initialFlexible);
exports.flexible = flexible;
var initialClubJetstar = (0, _immutable.fromJS)({
  data: null
});
exports.initialClubJetstar = initialClubJetstar;
var clubjetstar = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.onClubJetstarChange, function (state, action) {
  return state.merge({
    data: action.payload.data
  });
}), initialClubJetstar);
exports.clubjetstar = clubjetstar;
var initialSectors = (0, _immutable.fromJS)({
  sectorIds: [],
  invalidIds: []
});
exports.initialSectors = initialSectors;
var sectors = (0, _reduxActions.handleActions)((_handleActions4 = {}, (0, _defineProperty2.default)(_handleActions4, _actions.addSector, function (state, action) {
  return state.update('sectorIds', function (ids) {
    return ids.push(action.payload.sectorId);
  });
}), (0, _defineProperty2.default)(_handleActions4, _actions.addInvalidSector, function (state, action) {
  return state.update('invalidIds', function (ids) {
    return ids.push(action.payload.sectorId);
  });
}), (0, _defineProperty2.default)(_handleActions4, _actions.addMultipleSectors, function (state, action) {
  return state.update('sectorIds', function (ids) {
    return ids.concat(action.payload.sectorIds);
  });
}), (0, _defineProperty2.default)(_handleActions4, _actions.removeSector, function (state, action) {
  return state.update('sectorIds', function (ids) {
    return ids.filter(function (id) {
      return id !== action.payload.sectorId;
    });
  });
}), (0, _defineProperty2.default)(_handleActions4, _actions.removeInvalidSector, function (state, action) {
  return state.update('invalidIds', function (ids) {
    return ids.filter(function (id) {
      return id !== action.payload.sectorId;
    });
  });
}), _handleActions4), initialSectors);
exports.sectors = sectors;
var initialLocations = (0, _immutable.fromJS)({});
exports.initialLocations = initialLocations;
var locations = (0, _reduxActions.handleActions)((_handleActions5 = {}, (0, _defineProperty2.default)(_handleActions5, _actions.loadLocations, function (state, action) {
  return state.set(action.payload.sectorId, (0, _immutable.fromJS)({
    outbound: {
      name: null,
      code: null,
      query: '',
      resultsNotFound: false,
      invalid: false
    },
    inbound: {
      name: null,
      code: null,
      query: '',
      resultsNotFound: false,
      invalid: false
    }
  }));
}), (0, _defineProperty2.default)(_handleActions5, _actions.setInvalidLocation, function (state, action) {
  var _action$payload = action.payload,
      sectorId = _action$payload.sectorId,
      direction = _action$payload.direction,
      invalid = _action$payload.invalid;
  return state.setIn([sectorId, direction, 'invalid'], invalid);
}), (0, _defineProperty2.default)(_handleActions5, _actions.setLocation, function (state, action) {
  var _action$payload2 = action.payload,
      sectorId = _action$payload2.sectorId,
      direction = _action$payload2.direction,
      name = _action$payload2.name,
      code = _action$payload2.code;
  return state.setIn([sectorId, direction, 'name'], name).setIn([sectorId, direction, 'code'], code);
}), (0, _defineProperty2.default)(_handleActions5, _actions.resetLocation, function (state, action) {
  var _action$payload3 = action.payload,
      sectorId = _action$payload3.sectorId,
      direction = _action$payload3.direction;
  return state.setIn([sectorId, direction, 'name'], null).setIn([sectorId, direction, 'code'], null);
}), (0, _defineProperty2.default)(_handleActions5, _actions.searchLocation, function (state, action) {
  var _action$payload4 = action.payload,
      sectorId = _action$payload4.sectorId,
      direction = _action$payload4.direction,
      query = _action$payload4.query;
  return state.setIn([sectorId, direction, 'query'], query);
}), (0, _defineProperty2.default)(_handleActions5, _actions.resetSearchLocation, function (state, action) {
  var _action$payload5 = action.payload,
      sectorId = _action$payload5.sectorId,
      direction = _action$payload5.direction;
  return state.setIn([sectorId, direction, 'query'], '');
}), (0, _defineProperty2.default)(_handleActions5, _actions.searchLocation, function (state, action) {
  var _action$payload6 = action.payload,
      sectorId = _action$payload6.sectorId,
      direction = _action$payload6.direction,
      query = _action$payload6.query;
  return state.setIn([sectorId, direction, 'query'], query);
}), _handleActions5), initialLocations);
exports.locations = locations;
var isLoading = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.toggleFlightSearchSubmitLoader, function (state, action) {
  var loading = action.payload.loading;
  return loading;
}), false);
exports.isLoading = isLoading;
var initialCalendars = (0, _immutable.fromJS)({
  sectors: {},
  currentSectorId: null
});
exports.initialCalendars = initialCalendars;
var calendars = (0, _reduxActions.handleActions)((_handleActions7 = {}, (0, _defineProperty2.default)(_handleActions7, _actions.loadCalendars, function (state, action) {
  return state.setIn(['sectors', action.payload.sectorId], (0, _immutable.fromJS)({
    outbound: {
      isFetching: false,
      startDate: '',
      selectedDate: {
        date: null,
        starter: {},
        member: {}
      }
    },
    inbound: {
      isFetching: false,
      startDate: '',
      selectedDate: {
        date: null,
        starter: {},
        member: {}
      }
    },
    invalid: false,
    schedule: {
      startDate: null,
      endDate: null
    }
  }));
}), (0, _defineProperty2.default)(_handleActions7, _actions.setInvalidCalendar, function (state, action) {
  var _action$payload7 = action.payload,
      sectorId = _action$payload7.sectorId,
      invalid = _action$payload7.invalid;
  return state.setIn(['sectors', sectorId, 'invalid'], invalid);
}), (0, _defineProperty2.default)(_handleActions7, _actions.setCalendarSchedule, function (state, action) {
  var _action$payload8 = action.payload,
      sectorId = _action$payload8.sectorId,
      data = _action$payload8.data;
  return state.setIn(['sectors', sectorId, 'schedule'], data);
}), (0, _defineProperty2.default)(_handleActions7, _actions.resetCalendarDates, function (state, action) {
  var sectorId = action.payload.sectorId;
  return state.setIn(['sectors', sectorId, 'outbound', 'selectedDate'], (0, _immutable.fromJS)({
    date: null,
    starter: {},
    member: {}
  })).setIn(['sectors', sectorId, 'inbound', 'selectedDate'], (0, _immutable.fromJS)({
    date: null,
    starter: {},
    member: {}
  }));
}), (0, _defineProperty2.default)(_handleActions7, _actions.setCalendarDates, function (state, action) {
  var _action$payload9 = action.payload,
      sectorId = _action$payload9.sectorId,
      data = _action$payload9.data;
  return state.updateIn(['sectors', sectorId, 'outbound', 'selectedDate'], function (item) {
    return item.merge(data.get('outbound'));
  }).updateIn(['sectors', sectorId, 'inbound', 'selectedDate'], function (item) {
    return item.merge(data.get('inbound'));
  });
}), (0, _defineProperty2.default)(_handleActions7, _actions.setCalendarViewStartDates, function (state, action) {
  var _action$payload10 = action.payload,
      sectorId = _action$payload10.sectorId,
      data = _action$payload10.data;
  return state.setIn(['sectors', sectorId, 'outbound', 'startDate'], data.getIn(['outbound', 'startDate'])).setIn(['sectors', sectorId, 'inbound', 'startDate'], data.getIn(['inbound', 'startDate']));
}), (0, _defineProperty2.default)(_handleActions7, _actions.fetchingPrices, function (state, action) {
  var _action$payload11 = action.payload,
      direction = _action$payload11.direction,
      sectorId = _action$payload11.sectorId;
  return state.setIn(['sectors', sectorId, direction, 'isFetching'], true);
}), (0, _defineProperty2.default)(_handleActions7, _actions.fetchingPricesSuccess, function (state, action) {
  var _action$payload12 = action.payload,
      direction = _action$payload12.direction,
      sectorId = _action$payload12.sectorId,
      startDate = _action$payload12.startDate,
      starterData = _action$payload12.starterData,
      memberData = _action$payload12.memberData;
  return state.setIn(['sectors', sectorId, direction, 'isFetching'], false).setIn(['sectors', sectorId, direction, 'pricing', startDate, 'starter'], starterData).setIn(['sectors', sectorId, direction, 'pricing', startDate, 'member'], memberData);
}), (0, _defineProperty2.default)(_handleActions7, _actions.resetPrices, function (state, action) {
  var sectorId = action.payload.sectorId;
  return state.removeIn(['sectors', sectorId, 'outbound', 'pricing']).removeIn(['sectors', sectorId, 'inbound', 'pricing']);
}), (0, _defineProperty2.default)(_handleActions7, _actions.setCalendarSector, function (state, action) {
  var sectorId = action.payload.sectorId;
  return state.set('currentSectorId', sectorId);
}), (0, _defineProperty2.default)(_handleActions7, _actions.onGuidanceMessageReset, function (state, action) {
  var sectorId = action.payload.sectorId;
  return state.setIn(['sectors', sectorId, 'showGuidanceMessage'], false);
}), (0, _defineProperty2.default)(_handleActions7, _actions.onGuidanceMessageShow, function (state, action) {
  var sectorId = action.payload.sectorId;
  return state.setIn(['sectors', sectorId, 'showGuidanceMessage'], true);
}), _handleActions7), initialCalendars);
exports.calendars = calendars;
var initialConfig = (0, _immutable.fromJS)({
  id: ''
});
exports.initialConfig = initialConfig;
var config = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.onFlightSearchConfigSet, function (state, action) {
  var sourceIdentifier = action.payload.sourceIdentifier;
  return state.set('id', sourceIdentifier);
}), initialConfig);
exports.config = config;
var initialCurrency = (0, _immutable.fromJS)({
  symbol: '',
  format: '',
  precision: 0
});
exports.initialCurrency = initialCurrency;
var currency = (0, _reduxActions.handleActions)((0, _defineProperty2.default)({}, _actions.setCurrency, function (state, action) {
  var _action$payload13 = action.payload,
      symbol = _action$payload13.symbol,
      format = _action$payload13.format,
      precision = _action$payload13.precision;
  return state.set('symbol', symbol).set('format', format).set('precision', precision);
}), initialCurrency);
exports.currency = currency;

var _default = (0, _redux.combineReducers)({
  calendars: calendars,
  clubjetstar: clubjetstar,
  config: config,
  counter: (0, _Counter.createCounterReducer)(_types.namespace, _flightSearch.DEFAULT_ADULTS_COUNT),
  currency: currency,
  flexible: flexible,
  flightType: flightType,
  isLoading: isLoading,
  locations: locations,
  sectors: sectors
});

exports.default = _default;