// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._list-blank-state__blankStateWrapper--yWp96{height:100%;margin:0 -.75rem}._list-blank-state__blankStateWrapper--yWp96,._list-blank-state__messageWrapper--Ck\\+-c{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column}._list-blank-state__messageWrapper--Ck\\+-c{height:22.875rem;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;margin:auto 1.875rem}._list-blank-state__messageText--32koM,._list-blank-state__messageTitle--YjepY{color:#111;font-family:JetstarHappy,Jetstar Happy,Helvetica Neue,Roboto,Arial,sans-serif;font-size:1rem;line-height:1.5rem;margin-bottom:0;text-align:center}._list-blank-state__messageTitle--YjepY{font-weight:700}._list-blank-state__messageText--32koM{font-weight:300}", "",{"version":3,"sources":["webpack://./source/App/Views/Components/PortSelection/_list-blank-state.scss"],"names":[],"mappings":"AAAA,6CAAsD,WAAA,CAAY,gBAAiB,CAAC,wFAAjE,mBAAA,CAAA,oBAAA,CAAA,YAAA,CAAa,2BAAA,CAAA,4BAAA,CAAA,6BAAA,CAAA,qBAAsB,CAA8B,2CAAmD,gBAAA,CAAiB,uBAAA,CAAA,8BAAA,CAAA,sBAAA,CAAuB,oBAAoB,CAAC,+EAA2B,UAAA,CAAW,6EAAA,CAAwF,cAAA,CAAe,kBAAA,CAAmB,eAAA,CAAgB,iBAAiB,CAAC,wCAAc,eAAe,CAAC,uCAAa,eAAe","sourcesContent":[".blankStateWrapper{display:flex;flex-direction:column;height:100%;margin:0 -0.75rem}.messageWrapper{display:flex;flex-direction:column;height:22.875rem;justify-content:center;margin:auto 1.875rem}.messageTitle,.messageText{color:#111;font-family:\"JetstarHappy\",\"Jetstar Happy\",\"Helvetica Neue\",\"Roboto\",\"Arial\",sans-serif;font-size:1rem;line-height:1.5rem;margin-bottom:0;text-align:center}.messageTitle{font-weight:700}.messageText{font-weight:300}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blankStateWrapper": "_list-blank-state__blankStateWrapper--yWp96",
	"messageWrapper": "_list-blank-state__messageWrapper--Ck+-c",
	"messageText": "_list-blank-state__messageText--32koM",
	"messageTitle": "_list-blank-state__messageTitle--YjepY"
};
export default ___CSS_LOADER_EXPORT___;
