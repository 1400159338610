"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localToday = exports.localDate = exports.isPartialReturnDateState = exports.getScheduleMonths = exports.getLastDayOfMonth = exports.getFirstDayOfMonth = exports.getCalendarData = exports.defaultDateFormat = exports.dateFormatterToString = exports.dateFormatter = exports.addDayToEndDate = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.number.constructor.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.array.fill.js");

var _momentEs = _interopRequireDefault(require("moment-es6"));

var _immutable = require("immutable");

var _dates = require("./../Constants/dates");

var _flightType = require("./../Constants/flightType");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var INBOUND = _flightType.FLIGHT_DIRECTION.INBOUND,
    OUTBOUND = _flightType.FLIGHT_DIRECTION.OUTBOUND;
/**
 * Convert date string as moment object
 * @function dateFormatter
 * @memberof Utils/dates
 * @param {String} date - Default date format e.g. YYYYMMDD
 * @return {Function} Returns a valid date string as a moment object
 */

var dateFormatter = function dateFormatter(dateFormat) {
  return function (date) {
    if (date) {
      var formattedDate = (0, _momentEs.default)(date, [_dates.DEFAULT_DATE_FORMAT, dateFormat]);
      return formattedDate.isValid() ? formattedDate : null;
    }

    return null;
  };
};
/**
 * Convert date string to another string format
 * @function dateFormatterToString
 * @memberof Utils/dates
 * @param {String} date - Date e.g. YYYYMMDD
 * @param {String} dateFormat - Date format e.g. [DEFAULT_DATE_FORMAT, DEFAULT_QS_DATE_FORMAT]
 * * @return {String} Returns a valid date as a string
 */


exports.dateFormatter = dateFormatter;

var dateFormatterToString = function dateFormatterToString(date, dateFormat) {
  if (date) {
    var momentDate = (0, _momentEs.default)(date, [_dates.DEFAULT_DATE_FORMAT, _dates.DEFAULT_QS_DATE_FORMAT], true);
    return momentDate.isValid() ? momentDate.format(dateFormat) : null;
  }

  return null;
};

exports.dateFormatterToString = dateFormatterToString;
var initialCalendarData = (0, _immutable.fromJS)({
  days: {},
  weeks: {},
  months: []
});

var createMonths = function createMonths(id) {
  return function (item) {
    return item.indexOf(id) === -1 ? item.push(id) : item;
  };
};

var createWeeks = function createWeeks(id, startOfWeek) {
  return function (item) {
    if (item.has(id)) {
      return item.update(id, function (subitem) {
        return subitem.push(startOfWeek);
      });
    }

    return item.set(id, _immutable.List.of(startOfWeek));
  };
};

var addDay = function addDay(_ref) {
  var startOfWeek = _ref.startOfWeek,
      today = _ref.today,
      endDate = _ref.endDate,
      scheduleStartDate = _ref.scheduleStartDate,
      scheduleEndDate = _ref.scheduleEndDate,
      outboundSelectedDate = _ref.outboundSelectedDate,
      inboundSelectedDate = _ref.inboundSelectedDate,
      direction = _ref.direction,
      startDate = _ref.startDate,
      locations = _ref.locations;
  return function (val, count) {
    var currentDate = startOfWeek.clone().add(count, 'day');
    var fullDate = currentDate.format(_dates.DEFAULT_DATE_FORMAT);
    var day = currentDate.format(_dates.DEFAULT_DATE_DAY_FORMAT);
    var startOfMonth = currentDate.clone().startOf('month'); // const endOfMonth = currentDate.clone().endOf('month');

    var options = {};

    if (currentDate.isSameOrAfter(scheduleStartDate, 'day') && (fullDate === outboundSelectedDate || fullDate === inboundSelectedDate)) {
      options.ports = [];
    }

    if (fullDate === outboundSelectedDate && currentDate.isSameOrAfter(scheduleStartDate, 'day')) {
      options.isSelectedOutBound = true;
      options.ports.push(locations.getIn([INBOUND, 'code']));

      if (direction === INBOUND) {
        options.monthIndex = currentDate.get('month');
      }
    }

    if (fullDate === inboundSelectedDate && currentDate.isSameOrAfter(scheduleStartDate, 'day')) {
      options.isSelectedInBound = true;
      options.ports.push(locations.getIn([OUTBOUND, 'code']));
    }

    if (fullDate === startOfMonth.format(_dates.DEFAULT_DATE_FORMAT) && currentDate.isSameOrAfter(startDate, 'day')) {
      options.isMonthStart = true;

      if (currentDate.isSameOrAfter(scheduleStartDate, 'day')) {
        options.monthIndex = currentDate.get('month');
      }
    } // if (fullDate === endOfMonth.format(DEFAULT_DATE_FORMAT)) {
    //     options.isMonthEnd = true;
    // }


    if (fullDate === today.format(_dates.DEFAULT_DATE_FORMAT)) {
      options.isToday = true;
      options.monthIndex = currentDate.get('month');
    }

    if (currentDate.isBefore(startDate, 'day') || direction === INBOUND && Number(fullDate) < Number(outboundSelectedDate)) {
      options.isHidden = true;
    }

    if (currentDate.isAfter(scheduleEndDate, 'day')) {
      options.isOutOfBounds = true;
    }

    return _objectSpread(_objectSpread({}, options), {}, {
      fullDate: fullDate,
      day: day
    });
  };
};

var createDays = function createDays(_ref2) {
  var endDate = _ref2.endDate,
      outboundSelectedDate = _ref2.outboundSelectedDate,
      inboundSelectedDate = _ref2.inboundSelectedDate,
      startOfWeek = _ref2.startOfWeek,
      scheduleStartDate = _ref2.scheduleStartDate,
      scheduleEndDate = _ref2.scheduleEndDate,
      direction = _ref2.direction,
      startDate = _ref2.startDate,
      locations = _ref2.locations;
  return function (item) {
    if (item.has(startOfWeek)) {
      return item;
    }

    var today = (0, _momentEs.default)();
    var day = addDay({
      startOfWeek: startOfWeek,
      today: today,
      endDate: endDate,
      outboundSelectedDate: outboundSelectedDate,
      inboundSelectedDate: inboundSelectedDate,
      scheduleStartDate: scheduleStartDate,
      scheduleEndDate: scheduleEndDate,
      direction: direction,
      startDate: startDate,
      locations: locations
    });
    var days = (0, _immutable.fromJS)(Array(_dates.NUMBER_DAYS_IN_WEEK).fill().map(day));
    return item.set(startOfWeek.format(_dates.DEFAULT_DATE_FORMAT), days);
  };
};

var getCalendarData = function getCalendarData(_ref3) {
  var startDate = _ref3.startDate,
      endDate = _ref3.endDate,
      outboundSelectedDate = _ref3.outboundSelectedDate,
      inboundSelectedDate = _ref3.inboundSelectedDate,
      scheduleStartDate = _ref3.scheduleStartDate,
      scheduleEndDate = _ref3.scheduleEndDate,
      direction = _ref3.direction,
      locations = _ref3.locations;
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : initialCalendarData;

  if (startDate.isAfter(endDate, 'day')) {
    return data;
  }

  var startOfWeek = startDate.clone().startOf('week');
  var weekId = startOfWeek.format('YYYYMMDD');
  var monthId = startDate.format('YYYYMM');
  var months = createMonths(monthId);
  var weeks = createWeeks(monthId, weekId);
  var days = createDays({
    endDate: endDate,
    outboundSelectedDate: outboundSelectedDate,
    inboundSelectedDate: inboundSelectedDate,
    startOfWeek: startOfWeek,
    scheduleStartDate: scheduleStartDate,
    scheduleEndDate: scheduleEndDate,
    direction: direction,
    startDate: startDate,
    locations: locations
  });
  var newData = data.update('months', months).update('weeks', weeks).update('days', days);
  var newStartDate = startOfWeek.clone().add(1, 'week');
  return getCalendarData({
    startDate: newStartDate,
    endDate: endDate,
    outboundSelectedDate: outboundSelectedDate,
    inboundSelectedDate: inboundSelectedDate,
    scheduleStartDate: scheduleStartDate,
    scheduleEndDate: scheduleEndDate,
    direction: direction,
    locations: locations
  }, newData);
};

exports.getCalendarData = getCalendarData;

var getScheduleMonths = function getScheduleMonths(scheduleStartDate, scheduleEndDate) {
  var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (0, _immutable.fromJS)({
    months: [],
    monthsById: {}
  });

  if (scheduleStartDate.isAfter(scheduleEndDate, 'day')) {
    return data;
  }

  var startOfMonth = scheduleStartDate.clone().startOf('month');
  var id = startOfMonth.format('YYYYMM');
  var newData = data.update('monthsById', function (item) {
    return item.set(id, (0, _immutable.Map)({
      fullDate: data.get('months').size > 0 ? startOfMonth.format('YYYYMMDD') : scheduleStartDate.format('YYYYMMDD')
    }));
  }).update('months', function (item) {
    return item.push(id);
  });
  var newScheduleStartDate = startOfMonth.clone().add(1, 'month');
  return getScheduleMonths(newScheduleStartDate, scheduleEndDate, newData);
};
/**
 * Small utility to get first day of the month
 * @function getFirstDayOfMonth
 * @memberof Utils/dates
 * @param {String | Moment} date - a string in YYYYMMDD format or moment object
 * @return {Function} Returns the first day of the month - default in YYYYMMDD format
 */


exports.getScheduleMonths = getScheduleMonths;

var getFirstDayOfMonth = function getFirstDayOfMonth(date) {
  return function () {
    var dateFormat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _dates.DEFAULT_DATE_FORMAT;
    return (0, _momentEs.default)(date, dateFormat).startOf('month').format(dateFormat);
  };
};
/**
 * Small utility to get last day of the month
 * @function getLastDayOfMonth
 * @memberof Utils/dates
 * @param {String | Moment} date - a string in YYYYMMDD format or moment object
 * @return {Function} Returns the last day of the month - default in YYYYMMDD format
 */


exports.getFirstDayOfMonth = getFirstDayOfMonth;

var getLastDayOfMonth = function getLastDayOfMonth(date) {
  return function () {
    var dateFormat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _dates.DEFAULT_DATE_FORMAT;
    return (0, _momentEs.default)(date, dateFormat).endOf('month').format(dateFormat);
  };
};
/**
 * Small utility to add day to end date to account for API not including last day
 * @function addDayToEndDate
 * @memberof Utils/dates
 * @param {Moment} endDate
 * @return {string} Returns date, plus one day in DEFAULT_DATE_FORMAT format
 */


exports.getLastDayOfMonth = getLastDayOfMonth;

var addDayToEndDate = function addDayToEndDate(endDate) {
  return endDate.add(1, 'day').format(_dates.DEFAULT_DATE_FORMAT);
};

exports.addDayToEndDate = addDayToEndDate;
var defaultDateFormat = dateFormatter(_dates.DEFAULT_DATE_FORMAT);
/**
 * Small utility to return todays date for the users local time
 * @function localToday
 * @memberof Utils/dates
 * @return {Function} Returns todays date for user local time - default in YYYYMMDD format
 */

exports.defaultDateFormat = defaultDateFormat;

var localToday = function localToday(date) {
  var currentDate = date || (0, _momentEs.default)();
  return _momentEs.default.parseZone(currentDate).utcOffset() < 0 ? _momentEs.default.utc(currentDate).startOf('day') : (0, _momentEs.default)(currentDate).startOf('day');
};
/**
 * Small utility to return date for the users local time
 * @function localDate
 * @memberof Utils/dates
 * @param {String | Moment} date - a string in YYYYMMDD format or moment object
 * @return {Function} Returns date for user local time - default in YYYYMMDD format
 */


exports.localToday = localToday;

var localDate = function localDate(date, today) {
  return (0, _momentEs.default)(today).startOf('day').utcOffset() < 0 ? _momentEs.default.utc(date) : (0, _momentEs.default)(date, _dates.DEFAULT_MOMENT_FORMAT);
};
/**
 * Determine if only outbound date is selected
 * @function isPartialDateState
 * @memberof Utils/dates
 * @param {Map} flightSearch - Redux state
 * @param {string} sectorId - sector id
 * @return {boolean} true/false
 */


exports.localDate = localDate;

var isPartialReturnDateState = function isPartialReturnDateState(flightSearch, sectorId) {
  var calendars = flightSearch.calendars.getIn(['sectors', sectorId]);
  var dateNode = ['selectedDate', 'date'];

  if (!calendars) {
    return false;
  }

  var outbound = calendars.getIn(['outbound'].concat(dateNode));
  var inbound = calendars.getIn(['inbound'].concat(dateNode));
  return outbound && !inbound;
};

exports.isPartialReturnDateState = isPartialReturnDateState;